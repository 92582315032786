import { App } from 'vue';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import VueAxios from 'vue-axios';
import JwtService from '@/core/services/JwtService';
import { authModuleInstance } from '@/store/modules';
import { useRouter } from 'vue-router';

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;
  public static controller = new AbortController();
  public static runningRequests: { endpoint: string, controller: AbortController }[] = [];
  private static requestsToAvoid = [
    '/api/private/v1/keywords/keywordRank',
    '/api/private/v1/keywords/chartRank',
    '/api/private/v1/keywords/chartRanksMany',
    '/api/private/v1/campaigns/cpi-ad-assets'
  ];

  public static getRunningRequests(): { endpoint: string, controller: AbortController }[] {
    return this.runningRequests;
  }

  public static resetRunningRequests(): void {
    this.runningRequests = [];
  }

  public static resetController(): void {
    this.controller = new AbortController();
  }

  public static abortRequest(endpoint): void {
    this.runningRequests.forEach((request, index) => {
      if (!this.requestsToAvoid.includes(request.endpoint) && request.endpoint === endpoint) {
        this.runningRequests[index]?.controller.abort();
        this.runningRequests.splice(index, 1);
        this.resetController();
      }
    });
  }

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>, router = useRouter()) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    axios.defaults.withCredentials = true;
    axios.interceptors.response.use(function(response) {
      return response;
    }, function(error) {
      if (error.response && error.response.data.status === 'Email verify failed' && error.response.status === 403) {
        authModuleInstance.logOut();
        router.push({ name: 'sign-in' });
      }
      return Promise.reject(error);
    });
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${JwtService.getToken()}`;
    ApiService.vueInstance.axios.defaults.headers.common['Accept'] =
      'application/json';
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    this.abortRequest(resource);

    // Set current request to running requests map
    if (!this.requestsToAvoid.includes(resource)) {
      setTimeout(() => {
        this.runningRequests.push({ endpoint: resource, controller: this.controller });
      }, 100);
    }

    const result = ApiService.vueInstance.axios.get(resource, { ...params, signal: this.controller.signal });
    result
      .catch((err) => {
        if (this.vueInstance.axios.isCancel(err)) {
          localStorage.setItem('isCancel', JSON.stringify(true));
        }
      })
      .finally(() => {
        this.resetRunningRequests();
      });
    return result;
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = '' as string
  ): Promise<AxiosResponse> {
    const url = resource + (slug ? `/${slug}` : '');
    return ApiService.vueInstance.axios.get(url);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, { ...params, signal: this.controller.signal });
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, { ...params, signal: this.controller.signal });
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, { ...params, signal: this.controller.signal });
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource);
  }

  /**
   * @description Send the DELETE HTTP request
   * @returns Promise<AxiosResponse>
   * @param resource
   * @param params
   */
  public static fileUpload(resource: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }
}

export default ApiService;
