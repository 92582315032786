const select = {
  select: 'Select',
  placeholder: 'Select item',
  sort_by: 'Sort by',
  favorite: 'Favorite',
  title: 'Title',
  developer: 'Developer',
  mostPromoted: 'Most promoted',
  ipAddress: 'IP address',
  appPackage: 'App package',
  selectAppId: 'App id',
  developerName: 'Developer name',
  paypalEmail: 'Paypal email',
  paypalEmailFraud: 'Paypal email (fraud)',
  emailService: 'Email service',
  developerEmail: 'Developer email',
  cpiAppPackage: 'Cpi app package',
  userEmail: 'User email',
  keyword: 'Keyword',
};
export default select;
