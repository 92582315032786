const register = {
  forgotPassword: 'Forgot password?',
  enterYourEmailBelowToResetYourPassword: 'Enter your email below to reset your password',
  createNewPassword: 'Please enter your email address below to create a new password',
  loginErrorEmail: 'Please enter you email address',
  loginErrorEmailValid: 'Please enter a valid email address',
  loginErrorPassword: 'Please enter your password',
  loginErrorPasswordMin3: 'Please enter your password corrected min 3 ',
  email: 'Email',
  cancel: 'Cancel',
  submit: 'Submit',
  pleaseWait: 'Please wait...',
  weHaveSentYouEmail: 'We’ve sent you an email with instructions how to change your password.',
  okLetMeIn: 'Ok, let me in!',
  tryAgain: 'Try again!',
  resetPassword: 'Reset password?',
  password: 'Password',
  useMoreCharactersWithMixOfLetters: 'Use 8 or more characters with a mix of letters, numbers & symbols',
  confirmPassword: 'Confirm Password',
  passwordsMustMatch: 'Passwords must match',
  passwordConfirmation: 'Password Confirmation',
  yourPasswordIsSuccessfullyChanged: 'Your password is successfully changed!',
  loginToYourAccount: 'Login to your account',
  orLoginWithEmail: 'or login with email',
  newHere: 'New here?',
  createNewAccount: 'Create new account',
  createAnAccount: 'Create an Account',
  loginToAccount: 'Login to account',
  yourAccountIsNotActivated: 'Your account is not activated. Please check your email for verification instructions or click re-send.',
  activationEmeilWasSuccessfullySent: 'Activation email was successfully sent. Please check your email.',
  orEnterYourNewAccountDetailsBelow: 'or enter your new account details below',
  orEnterYourDetails: 'or enter your details',
  username: 'Username',
  iAmEighteenYearsOldOrOlder: 'I confirm, that I am 18 years old or older',
  alreadyHaveAnAccount: 'Already have an account?',
  login: 'Login',
  toc: 'Toc',
  agreement: 'Agreement',
  yourAccountHasBeenCreated: 'Your account has been created and you have received an activation email.Once activated, you will be able to log in',
  byCreatingAnAccountWithUs: 'By creating an account with us, you automatically accept our'
};

export default register;
