const topUpBalance = {
  topUpBalance: 'Пополнить баланс',
  pleaseEnterPaymentAmount: 'Пожалуйста, введите сумму платежа',
  depositAmount: 'Сумма вклада',
  bonus: 'бонус',
  pleaseSelectYourPreferredPaymentSystem: 'Пожалуйста, выберите предпочитаемую платежную систему',
  addFundsBy: 'Добавить средства через',
  usdtBitcoinEthereum: 'USDT (Tether), Bitcoin, Ethereum',
  or: 'или',
  cryptocurrencyPaymentWillBeAutomaticallyAdded: 'Обратите внимание, платеж в криптовалюте будет автоматически добавлен на ваш счет после того, как мы получим 3 или более подтверждений транзакции.',
  makePayment: 'Совершать платеж',
  weApplyPaymentBonusForEachSingleTransaction: 'Мы применяем платежный бонус за каждую отдельную транзакцию в зависимости от её суммы (исключая PayPal):',
  bonusValidFor: 'Бонус действителен в течении 120 дней',
  moreThan: 'больше, чем',
  forExample: 'Например, заплатив 2000$ - вы получите 2000+200 (10% бонус) = 2200$, или заплатив 7000$ - получите 7000+1050 (15% бонус) = 8050$!',
  inCaseOfRefundRequest: 'В случае запроса на возврат мы вернем сумму вашего платежа без всех бонусов!',
  ByAddingFundsToYourAccount: 'Добавляя средства на свой счет, вы автоматически принимаете наши',
  refundAndChargebackPolicy: 'Политика возврата и возврата.',
  ifYouNeedAnInvoice: 'Если вам нужен счет, отправьте электронное письмо на адрес {email} с указанием суммы и получателя платежа или свяжитесь со службой поддержки в чате.',
  withAmountAndYayeeDetails: 'с указанием суммы и получателя платежа.',
  billingHistory: 'История платежей',
  paymentsOf: 'платежей из',
  creditCard: 'Credit Card',
  googlePay: 'Google Pay',
  applePay: 'Apple Pay',
  payPal: 'PayPal',
  usdt: 'USDT (Tether)',
  bitcoin: 'Bitcoin',
  ethereum: 'Ethereum',
  usdCoin: 'USD Монета',
  binancePay: 'Binance Pay',
  alipay: 'Alipay',
  payoneer: 'Payoneer',
  weAccept: 'Мы принимаем',
  paymentsOnlyIn: 'выплаты только в',
  manual: 'руководство',
  mode: 'режим',
  toTopUpYourBalanceWith: 'Чтобы пополнить баланс с помощью',
  pleaseContactSupportForTransactionDetails: 'пожалуйста, свяжитесь со службой поддержки для деталей транзакции',
  wireBankTransfer: 'Wire банковский перевод',
  wireBank: 'Wire банк',
  wireTransfer: 'Wire перевод',
  pleaseContactSupportForInvoiceAndTransactionDetails: 'пожалуйста, свяжитесь со службой поддержки для получения счета и деталей транзакции',
  minimumPaymentAmountIs: 'Минимальная сумма платежа составляет',
  maximumPaymentAmountIs: 'Максимальная сумма платежа составляет',
  transactionFee: 'комиссия на перевод',
  topUpYourBalanceWithPayPal: 'Пополните свой баланс с помощью PayPal',
  dueYoNewPaypalRestrictions: 'Из-за новых ограничений Paypal мы вынуждены значительно увеличить комиссию за транзакцию для всех платежей через <b>Paypal</b>. Сейчас <b>6%</b>.',
  alsoWeveRemovedAllRegularBonuses: 'Мы <b>удалили</b> все обычные бонусы за платежи на сумму более 1000$, обработанные через Paypal. Пожалуйста, используйте кредитные карты или альтернативные способы пополнения баланса, чтобы получить эти бонусы.',
  topUpYourBalanceWithPayoneer: 'Пополнить баланс с помощью Payoneer',
  topUpYourBalanceWithBankTransfer: 'Пополнить баланс банковским переводом',
  paymentsOnlyInManualMode: 'платежи только в ручном режиме.',
  toTopUpYourBalanceWithPayoneerPlease: 'Чтобы пополнить баланс Payoneer, пожалуйста,',
  toTopUpYourBalanceWithBankTransferPlease: 'Чтобы завершить баланс банковским переводом, пожалуйста',
  contact: 'контакт',
  supportForTransactionDetails: 'поддержка деталей транзакции.',
  minimumPaymentAmountForThisPaymentMethodIs: 'Обратите внимание, минимальная сумма платежа для этого способа оплаты составляет',
  weAcceptSEPAOrSWIFTBankTransfers: 'Мы принимаем банковские переводы SEPA или SWIFT только в ручном режиме.'
};

export default topUpBalance;
