import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { AxiosRequestConfig } from 'axios';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { domainUrlAdmin } from '@/core/config/DomainConfig';
import { Getters } from '@/store/enums/GettersEnums';

@Module({ name: 'AdminEmailTemplateModule' })
export default class AdminEmailTemplateModule extends VuexModule implements StoreModel {
  error = {};
  emailTemplates = [];
  search = '';
  totalEmailTemplates = 0;

  /**
     * Get  paysystems
     * @returns object
     */
  get [Getters.GET_ADMIN_EMAIL_TEMPLATE]() {
    return this.emailTemplates;
  }

  /**
     * Get  errors
     * @returns object
     */
  get [Getters.GET_ADMIN_EMAIL_TEMPLATE_ERROR]() {
    return this.error;
  }

  /**
     * Get  errors
     * @returns object
     */
  get [Getters.GET_ADMIN_EMAIL_TEMPLATE_SEARCH]() {
    return this.search;
  }

  /**
     * Get  errors
     * @returns object
     */
  get [Getters.GET_ADMIN_EMAIL_TEMPLATE_TOTAL]() {
    return this.totalEmailTemplates;
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.error = {};
    this.emailTemplates = [];
    this.search = '';
    this.totalEmailTemplates = 0;
  };

    @Mutation
    [Mutations.SET_ADMIN_EMAIL_TEMPLATES](payload) {
      this.emailTemplates = payload;
    }

    @Mutation
    [Mutations.SET_ADMIN_EMAIL_TEMPLATES_ERRORS](error) {
      this.error = error;
    }

    @Mutation
    [Mutations.SET_ADMIN_EMAIL_TEMPLATE_SEARCH](payload: string) {
      this.search = payload;
    }

    @Mutation
    [Mutations.SET_ADMIN_TOTAL_EMAIL_TEMPLATES](payload: number) {
      this.totalEmailTemplates = payload;
    }

    @Action
    [Actions.GET_ADMIN_EMAIL_TEMPLATES](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}email-templates`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ADMIN_TOTAL_EMAIL_TEMPLATES, data.data.length);
          this.context.commit(Mutations.SET_ADMIN_EMAIL_TEMPLATES, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ADMIN_EMAIL_TEMPLATES_ERRORS, response?.data?.errors);
        });
    }

    @Action
    [Actions.UPDATE_ADMIN_EMAIL_TEMPLATES](id) {
      return ApiService.update(`${domainUrlAdmin}email-templates`, id, {})
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ADMIN_EMAIL_TEMPLATES_ERRORS, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ADMIN_EMAIL_TEMPLATES_ERRORS, response?.data?.errors);
        });
    }
}
