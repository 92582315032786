import store from '@/store';
import UserDispatcherService from '@/store/services/UserDispatcherService';

export class DataLayerUtil {
  public static initDataLayer = async () => {
    window.dataLayer = window.dataLayer || [];
    const isUserExists = window.dataLayer.find(el => el.user_id);
    if (!isUserExists) {
      const UserDispatcher = new UserDispatcherService(store);
      const user = UserDispatcher.getValidatedCurrentUser();
      const userId = user?.id;
      if (userId) {
        window.dataLayer.push({ 'user_id': userId });
      }
    }
  };
}
