import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { trim } from 'lodash';
import StoreGettersService from '@/store/services/StoreGettersService';
import { Getters } from '@/store/enums/GettersEnums';
import { useStore } from 'vuex';
import { ErrorTypeEnums } from '@/store/enums/ErrorTypeEnums';

export class AppsDispatcherService extends StoreGettersService {

  constructor (store = useStore()) {super(store);}

  public async getApp(packageId) {
    await this.getStore().dispatch(Actions.GET_APP, packageId);
  }

  public async updateApp(id) {
    await this.getStore().dispatch(Actions.UPDATE_APP, id);
    return this.getValidatedGetter(Getters.CURRENT_APP);
  }

  public async deleteApp(id) {
    await this.getStore().dispatch(Actions.DELETE_APP, id);
  }

  public async toggleFavoriteApp(id) {
    await this.getStore().dispatch(Actions.TOGGLE_FAVORITE_APP, id);
  }

  public async getUserApps(
    search,
    columnFilterValue,
    rowsPerPage,
    allAppsFilter,
    currentPage,
    sortBy,
    orderBy,
    currentStore
  ) {
    const params = {};

    if (search) {
      params['search'] = trim(search);
    }
    if (columnFilterValue) {
      params['searchColumn'] = columnFilterValue;
    }
    if (rowsPerPage) {
      params['limit'] = rowsPerPage;
    }
    if (allAppsFilter) {
      params['allAppsFilter'] = allAppsFilter;
    }
    if (currentPage) {
      params['page'] = currentPage;
    }
    if (sortBy) {
      params['sortBy'] = sortBy;
    }
    if (orderBy) {
      params['orderBy'] = orderBy;
    }
    if (currentStore) {
      params['appstoreId'] = currentStore;
    }

    this.getStore().commit(Mutations.SET_USER_APPS_QUERY_PARAMS, params);
    return await this.getStoreGettersUserApps(params);
  }

  public async getStoreGettersUserApps(params) {
    return await this.getAllRecordsByGetter(Getters.GET_ADMIN_USER_APPS, Actions.GET_USER_APPS, params, true);
  }

  public async addApp(appstore, country, packageId) {
    await this.getStore().dispatch(Actions.ADD_APP, {
      appstore: appstore,
      country: country,
      package: packageId,
    });
  }

  public async deleteBannedApps() {
    await this.getStore().dispatch(Actions.DELETE_BANNED_APPS, {});
  }

  public async getAppmanApp(params) {
    await this.getStore().dispatch(Actions.GET_APPMAN_APP, params);
  }
  public async getSearchApps(country, appstore, keyword) {
    const params = {
      country: country,
      appstore: appstore,
      keyword: keyword,
    };
    await this.getStore().dispatch(Actions.GET_SEARCH_APPS, params);
  }

  public async getTopAppsByKeyword(country, appstore, keyword) {
    const params = { 'country': country, 'store': appstore, 'keyword': keyword };
    await this.getStore().dispatch(Actions.GET_TOP_APPS_BY_KEYWORD, params);
  }

  public async getUserDetailsApps(user_id) {
    return await this.getStoreGettersUserDetailsApps({ user_id: user_id });
  }

  public async getStoreGettersUserDetailsApps(params) {
    return await this.getAllRecordsByGetter(Getters.GET_USER_DETAILS_APP_LIST, Actions.GET_USER_DETAILS_APPS, params, true);
  }

  public async getApps (reloadObject = false) {
    return await this.getAllRecordsByGetter(Getters.ALL_APPS, Actions.GET_APPS, {}, reloadObject);
  }

  public getValidatedCurrentApp () {
    return this.getValidatedGetter(Getters.CURRENT_APP);
  }

  public getValidatedLastAddedApp () {
    return this.getValidatedGetter(Getters.GET_LAST_ADDED_APP, ErrorTypeEnums.NOT_REACT);
  }

  public getValidatedAllApps () {
    return this.getValidatedGetter(Getters.ALL_APPS);
  }

  public getValidatedAppsStatus () {
    return this.getValidatedGetter(Getters.GET_APPS_STATUS);
  }

  public getValidatedAdminUserAppsCurrentPage () {
    return this.getValidatedGetter(Getters.GET_ADMIN_USER_APPS_CURRENT_PAGE);
  }
  public getValidatedAdminUserAppsSearch () {
    return this.getValidatedGetter(Getters.GET_ADMIN_USER_APPS_SEARCH, ErrorTypeEnums.NOT_REACT);
  }
  public getValidatedAdminUserAppsRows () {
    return this.getValidatedGetter(Getters.GET_ADMIN_USER_APPS_ROWS);
  }
  public getValidatedAdminUserAppsSortBy () {
    return this.getValidatedGetter(Getters.GET_ADMIN_USER_APPS_SORT_BY);
  }
  public getValidatedAdminUserAppsOrderBy () {
    return this.getValidatedGetter(Getters.GET_ADMIN_USER_APPS_ORDER_BY);
  }
  public getValidatedAdminUserAppsStore () {
    return this.getValidatedGetter(Getters.GET_ADMIN_USER_APPS_STORE, ErrorTypeEnums.NOT_REACT);
  }
  public getValidatedAdminUserAppsColumnSearchFilter () {
    return this.getValidatedGetter(Getters.GET_ADMIN_USER_APPS_COLUMN_SEARCH_FILTER, ErrorTypeEnums.NOT_REACT);
  }

  public getValidatedCurrentTotal () {
    return this.getValidatedGetter(Getters.CURRENT_TOTAL);
  }

  public getValidatedCurrentLang () {
    return this.getValidatedGetter(Getters.CURRENT_LANG);
  }
  public getValidatedCurrentAppmanApp () {
    return this.getValidatedGetter(Getters.CURRENT_APPMAN_APP);
  }
  public getValidatedAllSearchApps () {
    return this.getValidatedGetter(Getters.ALL_SEARCH_APPS, ErrorTypeEnums.WARNING);
  }

  public getValidatedAppsCount () {
    return this.getValidatedGetter(Getters.GET_APPS_COUNT);
  }
  public getValidatedAllTopAppsByKeyword () {
    return this.getValidatedGetter(Getters.ALL_TOP_APPS_BY_KEYWORD);
  }
  public getValidatedErrorsKeywords () {
    return this.getValidatedGetter(Getters.GET_ERRORS_KEYWORDS);
  }

  public getValidatedAdminUserAppsQueryParams () {
    return this.getValidatedGetter(Getters.GET_ADMIN_USER_APPS_QUERY_PARAMS, ErrorTypeEnums.NOT_REACT);
  }
  public getValidatedAdminUserAppsExport () {
    return this.getValidatedGetter(Getters.GET_ADMIN_USER_APPS_EXPORT, ErrorTypeEnums.NOT_REACT);
  }

  public getValidatedAdminUserAppsTotal () {
    return this.getValidatedGetter(Getters.GET_ADMIN_USER_APPS_TOTAL);
  }

  public getValidatedAdminUserAppsPage () {
    return this.getValidatedGetter(Getters.GET_ADMIN_USER_APPS_PAGE);
  }
  public getValidatedAdminUserAppsLastPage () {
    return this.getValidatedGetter(Getters.GET_ADMIN_USER_APPS_LAST_PAGE);
  }
}
