import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { AxiosRequestConfig } from 'axios';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import ApiService from '@/core/services/ApiService';
import { domainUrl, domainUrlAdmin } from '@/core/config/DomainConfig';
import { ErrorGetters } from '@/store/enums/ErrorGettersEnums';
import { Getters } from '@/store/enums/GettersEnums';

@Module({ name: 'UserDetailsReferralModule' })
export default class UserDetailsReferralModule extends VuexModule implements StoreModel {
  errors = {};
  userDetailsReferrals = [];
  totalDetailsReferrals = 0;
  currentPage = 1;
  lastPage = 1;
  page = 1;
  rowsPerPage = 10;
  search = '';
  limit = 10;
  orderBy = 'created_at';
  sortBy = 'desc';
  export = [];
  queryParams = {};

  /**
     * Get current Referrals object
     * @returns array
     */
  get [Getters.ALL_USER_DETAILS_REFERRALS]() {
    return this.userDetailsReferrals;
  }

  /**
     * Get search
     * @returns string
     */
  get [Getters.GET_USER_DETAILS_REFERRALS_SEARCH]() {
    return this.search;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_USER_DETAILS_REFERRALS_ORDER_BY]() {
    return this.orderBy;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_USER_DETAILS_REFERRALS_SORT_BY]() {
    return this.sortBy;
  }

  /**
     * Get limit
     * @returns string
     */
  get [Getters.GET_USER_DETAILS_REFERRALS_LIMIT]() {
    return this.limit;
  }

  /**
     * Get current Total Referrals
     * @returns number
     */
  get [Getters.GET_TOTAL_USER_DETAILS_REFERRALS]() {
    return this.totalDetailsReferrals;
  }


  /**
     * Get current page
     * @returns number
     */
  get [Getters.GET_USER_DETAILS_REFERRALS_CURRENT_PAGE]() {
    return this.currentPage;
  }

  /**
     * Get last page
     * @returns number
     */
  get [Getters.GET_USER_DETAILS_REFERRALS_LAST_PAGE]() {
    return this.lastPage;
  }

  /**
     * Get page
     * @returns number
     */
  get [Getters.GET_USER_DETAILS_REFERRALS_PAGE]() {
    return this.page;
  }

  /**
     * Get rows
     * @returns number
     */
  get [Getters.GET_USER_DETAILS_REFERRALS_ROWS]() {
    return this.rowsPerPage;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [ErrorGetters.GET_ERRORS_USER_DETAILS_REFERRALS]() {
    return this.errors;
  }

  get [Getters.GET_USER_DETAILS_REFERRALS_QUERY_PARAMS]() {
    return this.queryParams;
  }

  get [Getters.GET_USER_DETAILS_REFERRALS_EXPORT]() {
    return this.export;
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.errors = {};
    this.userDetailsReferrals = [];
    this.totalDetailsReferrals = 0;
    this.currentPage = 1;
    this.lastPage = 1;
    this.page = 1;
    this.rowsPerPage = 10;
    this.search = '';
    this.limit = 10;
    this.orderBy = 'id';
    this.sortBy = 'desc';
    this.export = [];
    this.queryParams = {};
  };

    @Mutation
    [Mutations.SET_USER_DETAILS_REFERRALS_ROWS](payload) {
      this.rowsPerPage = payload;
    }

    @Mutation
    [Mutations.SET_ERROR_USER_DETAILS_REFERRALS](error) {
      this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_ALL_USER_DETAILS_REFERRALS](payload) {
      this.userDetailsReferrals = payload;
    }

    @Mutation
    [Mutations.SET_TOTAL_USER_DETAILS_REFERRALS](payload) {
      this.totalDetailsReferrals = payload;
    }

    @Mutation
    [Mutations.SET_USER_DETAILS_REFERRALS_CURRENT_PAGE](payload) {
      this.currentPage = payload;
    }

    @Mutation
    [Mutations.SET_USER_DETAILS_REFERRALS_LAST_PAGE](payload) {
      this.lastPage = payload;
    }

    @Mutation
    [Mutations.SET_USER_DETAILS_REFERRALS_SEARCH](payload) {
      this.search = payload;
    }

    @Mutation
    [Mutations.SET_USER_DETAILS_REFERRALS_ORDER_BY](payload) {
      this.orderBy = payload;
    }

    @Mutation
    [Mutations.SET_USER_DETAILS_REFERRALS_SORT_BY](payload) {
      this.sortBy = payload;
    }

    @Mutation
    [Mutations.SET_USER_DETAILS_REFERRALS_QUERY_PARAMS](payload) {
      this.queryParams = payload;
    }

    @Mutation
    [Mutations.SET_USER_DETAILS_REFERRALS_EXPORT](payload) {
      this.export = payload;
    }

    @Action
    [Actions.GET_USER_DETAILS_REFERRALS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}users/referrals`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TOTAL_USER_DETAILS_REFERRALS, data.meta.total);
          this.context.commit(Mutations.SET_USER_DETAILS_REFERRALS_CURRENT_PAGE, data.meta.current_page);
          this.context.commit(Mutations.SET_USER_DETAILS_REFERRALS_LAST_PAGE, data.meta.last_page);
          this.context.commit(Mutations.SET_ALL_USER_DETAILS_REFERRALS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_DETAILS_REFERRALS, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_USER_DETAILS_REFERRALS_EXPORT](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}users/referrals`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER_DETAILS_REFERRALS_EXPORT, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_DETAILS_REFERRALS, response?.data?.errors);
        });
    }

    @Action
    [Actions.STORE_USER_DETAILS_REFERRALS](params) {
      return ApiService.post(`${domainUrlAdmin}users/referrals`, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR_USER_DETAILS_REFERRALS, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_DETAILS_REFERRALS, response?.data?.errors);
        });
    }

    @Action
    [Actions.DELETE_USER_DETAILS_REFERRALS](id) {
      return ApiService.delete(`${domainUrlAdmin}users/referrals/` + id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR_USER_DETAILS_REFERRALS, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_DETAILS_REFERRALS, response?.data?.errors);
        }).finally(() => {
          // this.context.commit(Mutations.SET_LOADING, false);
        });
    }
}
