const module = {
  over: 'Más de {total} miembros',
  clickAdd: 'Haga clic para agregar un Módulo',
  newModule: 'Nuevo Módulo',
  name: 'Nombre',
  actions: 'Comportamiento',
  check: 'Controlar',
  nameModule: 'Módulo de nombre',
  enterName: 'Ingrese su nombre',
  enterNamespace: 'Ingrese su Namespace',
  enterPermissionName: 'Ingrese el nombre del permiso',
  permissionName: 'Nombre del permiso',
  inputName: 'Por favor ingrese el nombre',
  inputNamespace: 'Por favor ingrese el Namespace',
  inputPermissionName: 'Por favor ingrese el Nombre del permiso',
  created: 'Crear Módulo',
  updated: 'Actualizar Módulo',
  namespace: 'Espacio de nombres',
  specifyName: 'Especifique un nombre de destino para uso futuro y referencia',
  specifyNamespace: 'Especifique un espacio de nombres de destino para uso futuro y referencia',
  specifyPermissionName: 'Especifique un Nombre del permiso de destino para uso futuro y referencia',

};
export default module;
