const statistics = {
  totalByProduct:'Всего по продукту',
  totalKeywords:'Всего ключевых слов',
  totalCampaigns:'Всего кампаний',
  date:'Дата',
  byKeyword:'По ключевому слову',
  byPackage:'По упаковке',
  rates:'Тарифы',
  reviews:'Отзывы',
  HQReviews:'HQ Отзывы',
  keyword:'Ключевое слово',
  installs:'Установленных',
  rank:'Ранг',
  export:'Экспорт',
  installsByDate:'Установок по дате',
  installsByKeyword:'Установок по ключевому слову',
  noInstallsFound:'Установок не найдено',
  howManyInstallsDoYouSellMonthly:'Сколько установок вы продаете ежемесячно?',
  accordingToGooglePlayConsole:'Согласно Google Play Console: ежедневные данные сообщаются в часовом поясе PST (UTC -9:00), почасовые - в часовом поясе локального браузера (UTC +2:00), вся выручка сообщается в UTC.',
  id:'ID',
  type:'Тип',
  country:'Страна',
  days:'Дни',
  price:'Цена',
  startTime:'Начало времени',
  status:'Статус',
  campaigns:'Кампании',
  noCampaignsFound:'Кампаний не найдено',
  overallInstalls:'Общие установки',
  overallSpendings:'Общие расходы',
  overallKeywords:'Общие ключевые слова',
  overallCampaigns:'Общие кампании',
  statisticByKeywords:'Статистика по ключевым словам',
  campaignsAndSpendings:'Кампании и расходы',
  totalSpend:'Общие расходы',
  totalInstalls:'Всего установок',
  reportByDate:'Отчет по дате',
  results:'результаты',
  noReportsFound:'Отчеты не найдены',
  spendings: 'Расходы',
  spend:'Тратить',
  reportByKeyword:'Отчет по ключевому слову',
  cumulativeReport:'Сводный отчет',
  spentFor:'Потрачено на',
  till:'до',
  reportFor:'Отчет за',
  reportForAllTime:'Отчет за все время',
};

export default statistics;
