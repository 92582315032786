const userPayment = {
  userPayments: 'Admin user payments',
  showing: 'showing',
  userPaymentsOf: 'payments of',
  noUserPaymentsFound: 'No user payments found',
  table: {
    bonusPayment: 'Bonus payment',
    regularPayment: 'Regular payment'
  },
  actionsDropdown: {
    edit: 'Edit',
    delete: 'Delete',
  },
  tableDropdown: {
    quickActions: 'Quick actions',
    refresh: 'Refresh',
    exportUserPayments: 'Export',
    exportUserPaymentsCurrent: 'Export current',
  },
  modal: {
    editPayment: 'Edit payment',
    email: 'Email',
    amount: 'Amount',
    date: 'Date',
    paymentType: 'Payment type',
    paymentDetails: 'Payment details',
    successDelete: 'Payment successfully deleted',
    successUpdate: 'Payment successfully updated',
  }
};

export default userPayment;
