import { Mutations } from '@/store/enums/StoreEnums';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { Getters } from '@/store/enums/GettersEnums';

@Module({ name: 'TopUpBalanceModule' })
export default class TopUpBalanceModule extends VuexModule implements StoreModel {

  totalBillings = 0;
  currentPage = 1;
  lastPage = 1;
  page = 1;
  rowsPerPage = 10;
  package = '';
  from = '';
  to = '';
  search = '';
  limit = 10;
  sortBy = 'date';
  sortType = 'desc';

  /**
     * Get current page
     * @returns number
     */
  get [Getters.GET_BILLINGS_CURRENT_PAGE]() {
    return this.currentPage;
  }

  /**
     * Get last page
     * @returns number
     */
  get [Getters.GET_BILLINGS_LAST_PAGE]() {
    return this.lastPage;
  }

  /**
     * Get page
     * @returns number
     */
  get [Getters.GET_BILLINGS_PAGE]() {
    return this.page;
  }

  /**
     * Get sort by
     * @returns string
     */
  get [Getters.GET_BILLINGS_SORT_BY]() {
    return this.sortBy;
  }

  /**
     * Get sort type
     * @returns string
     */
  get [Getters.GET_BILLINGS_SORT_TYPE]() {
    return this.sortType;
  }

  /**
     * Get rows
     * @returns number
     */
  get [Getters.GET_BILLINGS_ROWS]() {
    return this.rowsPerPage;
  }

  /**
     * Get package
     * @returns string
     */
  get [Getters.GET_BILLINGS_PACKAGE]() {
    return this.package;
  }

  /**
     * Get from
     * @returns string
     */
  get [Getters.GET_BILLINGS_FROM]() {
    return this.from;
  }

  /**
     * Get to
     * @returns string
     */
  get [Getters.GET_BILLINGS_TO]() {
    return this.to;
  }

  /**
     * Get search
     * @returns string
     */
  get [Getters.GET_BILLINGS_SEARCH]() {
    return this.search;
  }

  /**
     * Get limit
     * @returns string
     */
  get [Getters.GET_BILLINGS_LIMIT]() {
    return this.limit;
  }

  /**
     * Get current Total Billings
     * @returns number
     */
  get [Getters.GET_TOTAL_BILLINGS]() {
    return this.totalBillings;
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.totalBillings = 0;
    this.currentPage = 1;
    this.lastPage = 1;
    this.page = 1;
    this.rowsPerPage = 10;
    this.package = '';
    this.from = '';
    this.to = '';
    this.search = '';
    this.limit = 10;
  };

    @Mutation
    [Mutations.SET_BILLINGS_PAGE](payload) {
      this.page = payload;
    }

    @Mutation
    [Mutations.SET_BILLINGS_SORT_TYPE](payload) {
      this.sortType = payload;
    }

    @Mutation
    [Mutations.SET_BILLINGS_SORT_BY](payload) {
      this.sortBy = payload;
    }

    @Mutation
    [Mutations.SET_BILLINGS_ROWS](payload) {
      this.rowsPerPage = payload;
    }

    @Mutation
    [Mutations.SET_BILLINGS_PACKAGE](payload) {
      this.package = payload;
    }

    @Mutation
    [Mutations.SET_BILLINGS_SEARCH](payload) {
      this.search = payload;
    }

    @Mutation
    [Mutations.SET_BILLINGS_LIMIT](payload) {
      this.limit = payload;
    }

    @Mutation
    [Mutations.SET_TOTAL_BILLINGS](payload) {
      this.totalBillings = payload;
    }

    @Mutation
    [Mutations.SET_BILLINGS_CURRENT_PAGE](payload) {
      this.currentPage = payload;
    }

    @Mutation
    [Mutations.SET_BILLINGS_LAST_PAGE](payload) {
      this.lastPage = payload;
    }
}
