import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { AxiosRequestConfig } from 'axios';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { domainUrl, domainUrlAdmin } from '@/core/config/DomainConfig';
import { array } from 'yup';
import { ErrorGetters } from '@/store/enums/ErrorGettersEnums';
import { Getters } from '@/store/enums/GettersEnums';

@Module({ name: 'ManagerModule' })
export default class ManagerModule extends VuexModule implements StoreModel {

  errors = {};
  notices = [];
  noticeTemplates = [];
  unreadNotices = [];
  manager = {};
  managers = [];
  emptyLoadNoticeData = false;
  currentUserNoticeData = {
    campaignId: 0,
    userId: 0
  };
  chatPage = 1;

  /**
     * Get manager
     * @returns object
     */
  get [Getters.GET_MANAGER] () {
    return this.manager;
  }

  /**
     * Get managers
     * @returns array
     */
  get [Getters.GET_ALL_MANAGERS] () {
    return this.managers;
  }

  /**
     * Get notice templates
     * @returns array
     */
  get [Getters.GET_ALL_NOTICE_TEMPLATES] () {
    return this.noticeTemplates;
  }

  /**
     * Get errors
     * @returns array
     */
  get [ErrorGetters.GET_ERRORS_MANAGER] () {
    return this.errors;
  }

  /**
     * Get notices
     * @returns array
     */
  get [Getters.GET_ALL_NOTICES] () {
    return this.notices;
  }

  /**
     * Get notices
     * @returns array
     */
  get [Getters.GET_UNREAD_NOTICES] () {
    return this.unreadNotices;
  }

  /**
     * Get notices
     * @returns array
     */
  get [Getters.GET_CURRENT_USER_NOTICE_DATA] () {
    return this.currentUserNoticeData;
  }

  /**
     * Get notices
     * @returns array
     */
  get [Getters.GET_EMPTY_LOAD_NOTICE_DATA] () {
    return this.emptyLoadNoticeData;
  }

  /**
     * Get errors
     * @returns array
     */
  get [Getters.GET_CHAT_PAGE_NOTIFICATIONS] () {
    return this.chatPage;
  }

    @Mutation
  [Mutations.CLEAR_STORE] () {
    this.errors = {};
    this.notices = [];
    this.noticeTemplates = [];
    this.unreadNotices = [];
    this.manager = {};
    this.emptyLoadNoticeData = false;
    this.managers = [];
    this.chatPage = 1;
  };

    @Mutation
    [Mutations.SET_MANAGER] (payload) {
      this.manager = payload;
    }

    @Mutation
    [Mutations.SET_NOTICE_TEMPLATES] (payload) {
      this.noticeTemplates = payload;
    }

    @Mutation
    [Mutations.SET_CURRENT_USER_NOTICE_DATA] (payload) {
      this.currentUserNoticeData = payload;
    }

    @Mutation
    [Mutations.SET_MANAGERS] (payload) {
      this.managers = payload;
    }

    @Mutation
    [Mutations.SET_NOTICES] (payload) {
      this.notices = payload;
    }

    @Mutation
    [Mutations.ADD_NOTICES] (payload) {
      if (payload.length) {
        // @ts-ignore
        this.notices.push(...payload);
      } else {
        this.emptyLoadNoticeData = true;
      }

    }

    @Mutation
    [Mutations.SET_EMPTY_LOAD_NOTICE_DATA] (payload) {
      this.emptyLoadNoticeData = payload;
    }

    @Mutation
    [Mutations.SET_UNREAD_NOTICES] () {
      this.unreadNotices = this.notices.filter((notice: any) => !notice?.is_read);
    }

    @Mutation
    [Mutations.SET_ERRORS_MANAGER] (error) {
      this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_CHAT_PAGE_NOTIFICATIONS] (payload) {
      this.chatPage = payload;
    }

    @Action
    [Actions.GET_MANAGERS] (params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}managers`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_MANAGERS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERRORS_MANAGER, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_NOTICE_TEMPLATES] (params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}notices/notice-templates`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_NOTICE_TEMPLATES, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERRORS_MANAGER, response?.data?.errors);
        });
    }

    @Action
    [Actions.REQUEST_MANAGER] (params) {
      return ApiService.post(`${domainUrl}managers/request`, params)
        .then(({ data }) => {
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERRORS_MANAGER, response?.data?.errors);
        });
    }

    @Action
    [Actions.STORE_NOTICE] (params) {
      return ApiService.post(`${domainUrlAdmin}notices`, params)
        .then(({ data }) => {
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERRORS_MANAGER, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_NOTICES] (params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}notices`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_NOTICES, data.data);
          this.context.commit(Mutations.SET_UNREAD_NOTICES);
          this.context.commit(Mutations.SET_CHAT_PAGE_NOTIFICATIONS, 1);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERRORS_MANAGER, response?.data?.errors);
        });
    }

    @Action
    [Actions.LOAD_NOTICES] (params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}notices`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.ADD_NOTICES, data.data);
          this.context.commit(Mutations.SET_UNREAD_NOTICES);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERRORS_MANAGER, response?.data?.errors);
        });
    }

    @Action
    [Actions.VIEW_NOTICE] (id) {
      const config: AxiosRequestConfig = {
        params: { id: id }
      };
      return ApiService.put(`${domainUrl}notices/${id}/view`, config)
        .then(({ data }) => {
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERRORS_MANAGER, response?.data?.errors);
        });
    }
}
