import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { AxiosRequestConfig } from 'axios';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import ApiService from '@/core/services/ApiService';
import { UserDetailsTransactionTypeValueEnums } from '@/store/enums/UserDetailsEnums';
import { domainUrl, domainUrlAdmin } from '@/core/config/DomainConfig';
import { ErrorGetters } from '@/store/enums/ErrorGettersEnums';
import { Getters } from '@/store/enums/GettersEnums';

@Module({ name: 'UserDetailsTransactionsModule' })
export default class UserDetailsTransactionsModule extends VuexModule implements StoreModel {
  errors = {};
  userDetailsTransactions = [];
  userDetailsApps = [];
  totalDetailsTransactions = 0;
  currentPage = 1;
  lastPage = 1;
  page = 1;
  rowsPerPage = 10;
  search = '';
  limit = 10;
  orderBy = 'created_at';
  sortBy = 'desc';
  export = [];
  queryParams = {};
  transactionType = UserDetailsTransactionTypeValueEnums.ALL;

  /**
     * Get current Transaction object
     * @returns array
     */
  get [Getters.ALL_USER_DETAILS_TRANSACTIONS]() {
    return this.userDetailsTransactions;
  }

  /**
     * Get search
     * @returns string
     */
  get [Getters.GET_USER_DETAILS_TRANSACTIONS_SEARCH]() {
    return this.search;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_USER_DETAILS_TRANSACTIONS_ORDER_BY]() {
    return this.orderBy;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_USER_DETAILS_TRANSACTIONS_SORT_BY]() {
    return this.sortBy;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_USER_DETAILS_TRANSACTIONS_TYPE]() {
    return this.transactionType;
  }

  /**
     * Get limit
     * @returns string
     */
  get [Getters.GET_USER_DETAILS_TRANSACTIONS_LIMIT]() {
    return this.limit;
  }

  /**
     * Get current Total Transactions
     * @returns number
     */
  get [Getters.GET_TOTAL_USER_DETAILS_TRANSACTIONS]() {
    return this.totalDetailsTransactions;
  }


  /**
     * Get current page
     * @returns number
     */
  get [Getters.GET_USER_DETAILS_TRANSACTIONS_CURRENT_PAGE]() {
    return this.currentPage;
  }

  /**
     * Get last page
     * @returns number
     */
  get [Getters.GET_USER_DETAILS_TRANSACTIONS_LAST_PAGE]() {
    return this.lastPage;
  }

  /**
     * Get page
     * @returns number
     */
  get [Getters.GET_USER_DETAILS_TRANSACTIONS_PAGE]() {
    return this.page;
  }

  /**
     * Get rows
     * @returns number
     */
  get [Getters.GET_USER_DETAILS_TRANSACTIONS_ROWS]() {
    return this.rowsPerPage;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [ErrorGetters.GET_ERRORS_USER_DETAILS_TRANSACTIONS]() {
    return this.errors;
  }

  get [Getters.GET_USER_DETAILS_TRANSACTION_QUERY_PARAMS]() {
    return this.queryParams;
  }

  get [Getters.GET_USER_DETAILS_TRANSACTION_EXPORT]() {
    return this.export;
  }

  get [Getters.GET_USER_DETAILS_APP_LIST]() {
    return this.userDetailsApps;
  }


    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.errors = {};
    this.userDetailsTransactions = [];
    this.userDetailsApps = [];
    this.totalDetailsTransactions = 0;
    this.currentPage = 1;
    this.lastPage = 1;
    this.page = 1;
    this.rowsPerPage = 10;
    this.search = '';
    this.limit = 10;
    this.orderBy = 'id';
    this.sortBy = 'desc';
    this.export = [];
    this.queryParams = {};
    this.transactionType = UserDetailsTransactionTypeValueEnums.ALL;
  };

    @Mutation
    [Mutations.SET_USER_DETAILS_TRANSACTIONS_ROWS](payload) {
      this.rowsPerPage = payload;
    }

    @Mutation
    [Mutations.SET_ERROR_USER_DETAILS_TRANSACTIONS](error) {
      this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_ALL_USER_DETAILS_TRANSACTIONS](payload) {
      this.userDetailsTransactions = payload;
    }

    @Mutation
    [Mutations.SET_TOTAL_USER_DETAILS_TRANSACTIONS](payload) {
      this.totalDetailsTransactions = payload;
    }

    @Mutation
    [Mutations.SET_USER_DETAILS_TRANSACTIONS_CURRENT_PAGE](payload) {
      this.currentPage = payload;
    }

    @Mutation
    [Mutations.SET_USER_DETAILS_TRANSACTIONS_LAST_PAGE](payload) {
      this.lastPage = payload;
    }

    @Mutation
    [Mutations.SET_USER_DETAILS_TRANSACTIONS_SEARCH](payload) {
      this.search = payload;
    }

    @Mutation
    [Mutations.SET_USER_DETAILS_TRANSACTIONS_ORDER_BY](payload) {
      this.orderBy = payload;
    }

    @Mutation
    [Mutations.SET_USER_DETAILS_TRANSACTIONS_SORT_BY](payload) {
      this.sortBy = payload;
    }

    @Mutation
    [Mutations.SET_USER_DETAILS_TRANSACTIONS_TYPE](payload) {
      this.transactionType = payload;
    }

    @Mutation
    [Mutations.SET_USER_DETAILS_TRANSACTIONS_QUERY_PARAMS](payload) {
      this.queryParams = payload;
    }

    @Mutation
    [Mutations.SET_USER_DETAILS_TRANSACTIONS_EXPORT](payload) {
      this.export = payload;
    }

    @Mutation
    [Mutations.SET_USER_DETAILS_APPS](payload) {
      this.userDetailsApps = payload;
    }

    @Action
    [Actions.GET_USER_DETAILS_TRANSACTIONS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}transactions`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TOTAL_USER_DETAILS_TRANSACTIONS, data.meta.total);
          this.context.commit(Mutations.SET_USER_DETAILS_TRANSACTIONS_CURRENT_PAGE, data.meta.current_page);
          this.context.commit(Mutations.SET_USER_DETAILS_TRANSACTIONS_LAST_PAGE, data.meta.last_page);
          this.context.commit(Mutations.SET_ALL_USER_DETAILS_TRANSACTIONS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_DETAILS_TRANSACTIONS, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_USER_DETAILS_TRANSACTIONS_EXPORT](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}transactions`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER_DETAILS_TRANSACTIONS_EXPORT, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_DETAILS_TRANSACTIONS, response?.data?.errors);
        });
    }

    @Action
    [Actions.DELETE_USER_DETAILS_TRANSACTION](id) {
      return ApiService.delete(`${domainUrlAdmin}transactions/` + id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR_USER_DETAILS_TRANSACTIONS, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_DETAILS_TRANSACTIONS, response?.data?.errors);
        }).finally(() => {
          // this.context.commit(Mutations.SET_LOADING, false);
        });
    }

    @Action
    [Actions.GET_USER_DETAILS_APPS](params) {
      this.context.commit(Mutations.SET_LOADING, true);
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}apps`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER_DETAILS_APPS, data?.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_DETAILS_TRANSACTIONS, response?.data?.errors);
        });
    }
}
