const keywords = {
  keywordHeader: {
    addKeyword: 'Add keyword',
    importKeywordsFromFile: 'Import keywords from file',
    importFromFile: 'Import from file',
    importKeywords: 'Import keywords',
  },
  keywordModal: {
    copyKeywords: 'Copy keywords',
    areYouSureYouWantToCopyKeywordsFrom: 'Are you sure you want to copy keywords from',
    region: 'region',
  },
  keywordToolbar: {
    googlePlayApps: 'Google Play apps',
    appStoreApps: 'App Store apps'
  },
  appKeywordSelect: {
    selectYourApp: 'Select your app',
    pleaseEnterApplicationPackage: 'Please enter application package, trackid or store URL',
    beforeAddingNewApplication: 'Before adding new application, please check',
    appIsFree: "app is FREE (we can't promote paid apps)",
    appIsAvailableInSelectedCountry: 'app is available in selected country for large number of devices',
    appDoesntRequireSystemPermissions: "app doesn't require any special system permissions (like Root or NFC)"
  },
  createKeywordModal: {
    addKeywords: 'Add keywords',
    addNewKeyword: 'Add new keyword',
    pleaseAddKeyword: 'Please add keyword(s) for promotion',
    addKeywordsByPressingEnter: 'Add keywords one by one by pressing Enter, for example: spider, chess game or awesome app',
    dontAddQuotesSlashesHashtagsToKeywords: "Please don't add any quotes, slashes, hashtags or special symbols to your keywords.",
    add: 'Add'
  },
  keywordChart: {
    keywordRankHistory: 'Keyword rank history',
    threeMonths: '3 Months',
    month: 'Month',
    noHistory: 'No history data available',
    noKeywordSelected: 'No keyword selected',
    week: 'Week'
  },
  keywordChartDropdown: {
    quickActions: 'Quick actions',
    refreshChart: 'Refresh'
  },
  keywordTable: {
    myKeywords: 'My Keywords',
    selected: 'Selected',
    keywordsOf: 'keywords of',
    found: 'Found',
    keywordFrom: 'keyword(s) from',
    nothingFoundBySearch: 'Nothing found by search',
    noKeywordsFound: 'No keywords found',
    pleaseSelectAtLeastOneKeyword: 'Please select at least one keyword',
  },
  keywordTableDropdown: {
    quickActions: 'Quick actions',
    exportKeywords: 'Export',
    updateAllRanks: 'Update all ranks',
    deleteKeywords: 'Delete selected',
    clearKeywords: 'Delete all',
    promoteKeywords: 'Promote keywords'
  },
  keywordEasyDataTable: {
    noKeywordsFound: 'No keywords found',
    rank:
                'Rank',
    scoreIsSearchPopularityIndex:
                'Score is a Search Popularity index, ranked from 5 [bad] to 100 [best]. Keywords with greater score could potentially drive more organic traffic.',
    estimatedMaximumDailySearches:
                'Estimated maximum daily searches by this keyword',
    only:
                'Only',
    keywordsCouldBeSelected:
                'keywords could be selected',
    favorite:
                'Favorite',
    promote:
                'Promote',
    topApps:
                'Top apps',
    suggestedKeywords:
                'Suggested keywords',
    delete:
                'Delete',
    keywordRankIsValueBetween:
                'Keyword rank is a value between 1 to 250 showing how high your app appears in the search results for a this specified keyword.',
    theHigherYourKeywordRank:
                'The higher your keyword rank is, the more likely it is that users will find your app when they search for that keyword.',
    estimatedMaximumDaily:
                'Estimated maximum daily searches by this keyword.'
  }
  ,
  keywordSuggested: {
    noSuggestedKeywordsFound: 'No suggested keywords found',
    addSuggestedKeywords:
                'Add suggested keywords'
  }
  ,
  rank: {
    checkActualRank: 'Check actual rank',
    showRankHistoryChart:
                'Show rank history chart',
    outOfRank:
                'Out of rank',
    rankError:
                'Rank error',
    needClickLoading:
                'Need click loading',
    rankNotChecked:
                'Rank is not checked',
    lastUpdate:
                'Last update'
  }
}
;

export default keywords;
