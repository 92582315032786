import { Actions } from '@/store/enums/StoreEnums';
import { useStore } from 'vuex';
import { Getters } from '@/store/enums/GettersEnums';
import { ErrorTypeEnums } from '@/store/enums/ErrorTypeEnums';
import { DomainEnums } from '@/store/enums/DomainEnums';

export default abstract class StoreGettersService {

  protected getComponentName() {
    return this.constructor.name;
  }

  private readonly _store = useStore();

  protected constructor(store = useStore()) {
    this._store = store;
  }

  public getStore() {
    return this._store;
  }

  public async getAllRecordsByGetter(getter: Getters, action: Actions, params = {}, reloadObject = false) {
    if (reloadObject || !Object.keys(this.getStore().getters[getter]).length) {
      await this.getStore().dispatch(action, params);
    }
    return this.getStore().getters[getter];
  }

  protected getValidatedGetter(getter: Getters, errorType: ErrorTypeEnums = ErrorTypeEnums.EASY_FATAL) {
    const getterData = this.getStore().getters[getter];
    if (this.dataIsEmpty(getterData) && window.location.hostname !== DomainEnums.PRODUCTION_DOMAIN) {
      this.displayErrorByType(getter, errorType);
    }
    return this.getStore().getters[getter];
  }

  private dataIsEmpty(data: any) {
    if (data === undefined || data === null) {
      return true;
    }
    switch (typeof data) {
      case 'boolean':
        return false;
      case 'string':
        return data === '';
      case 'number':
        return data === 0;
      case 'object':
        return !Object.keys(data).length;
      default:
        return true;
    }
  }

  private displayErrorByType(getter: Getters, type: ErrorTypeEnums) {
    const message = `error getter: ${getter}` /*, classname: ${this.getComponentName()}*/;
    switch (type) {
      case ErrorTypeEnums.HARD_FATAL:
        throw new Error(message);
      case ErrorTypeEnums.WARNING:
        console.warn(message);
        break;
      case ErrorTypeEnums.NOT_REACT:
        break;
      case ErrorTypeEnums.EASY_FATAL:
      default:
        console.error(message);
        break;
    }
  }

}
