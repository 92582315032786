import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import store from '@/store';
import { Mutations } from '@/store/enums/StoreEnums';
import settings from '@/router/settings';
import auth from '@/router/auth';
import dashboard from '@/router/dashboard';
import errors from '@/router/errors';
import app from '@/router/app';
import keyword from '@/router/keyword';
import addCampaigns from '@/router/addCampaigns';
import myTransactions from '@/router/myTransactions';
import pricaplans from '@/router/priceplans';
import myProfile from '@/router/myProfile';
import topUpBalance from '@/router/topUpBalance';
import myCampaigns from '@/router/myCampaigns';
import bonusProgram from '@/router/bonusProgram';
import referralProgram from '@/router/referralProgram';
import resellersProgram from '@/router/resellersProgram';
import statistics from '@/router/statistict';
import contacts from '@/router/contacts';
import starting from '@/router/starting';
import terms from '@/router/terms';
import privacyPolicy from '@/router/privacyPolicy';
import apiSupport from '@/router/apiSupport';
import partnersProgram from '@/router/partnersProgram';
import { loadData } from '@/assets/ts/_utils/MainHelper';
import useRolesMixin from '@/mixins/rolesMixin';
import { DataLayerUtil } from '@/assets/ts/_utils/DataLayerUtil';
import ApiService from '@/core/services/ApiService';
import UserDispatcherService from '@/store/services/UserDispatcherService';

const routes: Array<RouteRecordRaw> = [
  dashboard,
  settings,
  auth,
  errors,
  app,
  keyword,
  addCampaigns,
  myTransactions,
  pricaplans,
  myProfile,
  topUpBalance,
  myCampaigns,
  bonusProgram,
  referralProgram,
  resellersProgram,
  statistics,
  contacts,
  starting,
  terms,
  privacyPolicy,
  apiSupport,
  partnersProgram,
  {
    path: '/:pathMatch(.*)*',
    redirect: '/dashboard'
  }
];
const { canShowComponentByRole } = useRolesMixin(store);
const UserDispatcher = new UserDispatcherService(store);
const router = createRouter({
  history: createWebHistory(),
  routes
});
const delay = delayed => (
  new Promise(resolve => setTimeout(resolve, delayed))
);

router.beforeEach(async (to, from, next) => {
  // Scroll page to top on every route change

  const roles: string[] = (to?.meta?.roles as string[]) ?? [];
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 0);

  // reset config to initial state
  await store.commit(Mutations.RESET_LAYOUT_CONFIG);
  if (to.query.access_token && to.name === 'dashboard') {
    await store.commit(Mutations.SET_AUTH, { access_token: to.query.access_token.toString() });
    return next({
      name: 'dashboard'
    });
  }
  
  await UserDispatcher.getCurrentUser(true);
  if (store.getters.isUserAuthenticated) {
    if (to.name === 'sign-in' || to.name === 'sign-up' || to.name === 'forgot-password') {
      return next({
        name: 'dashboard'
      });
    } else {
      if (roles && roles?.length > 0 && !canShowComponentByRole(roles)) {
        return next({
          name: 'dashboard'
        });
      } else {

        await loadData(store);
        return next();
      }
    }

  } else {
    if (
      to.name === 'sign-in' ||
            to.name === 'sign-up' ||
            to.name === 'password-reset' ||
            to.name === 'forgot-password' ||
            to.name === 'terms-basic' ||
            to.name === 'privacy-policy-basic' ||
            to.name === '404' ||
            to.name === '500' ||
            to.name === '503'
    ) {
      return next();
    } else {
      return next({
        name: 'sign-in'
      });

    }
  }
});

router.afterEach((to, from) => {
  DataLayerUtil.initDataLayer();
  ApiService.resetRunningRequests();
});

export default router;
