const topUpBalance = {
  topUpBalance: 'Поповнити баланс',
  pleaseEnterPaymentAmount: 'Будь ласка, введіть суму платежу',
  depositAmount: 'Сума вкладу',
  bonus: 'бонус',
  pleaseSelectYourPreferredPaymentSystem: 'Будь ласка, виберіть потрібну платіжну систему',
  addFundsBy: 'Додати кошти через',
  usdtBitcoinEthereum: 'USDT (Tether), Bitcoin, Ethereum',
  or: 'або',
  cryptocurrencyPaymentWillBeAutomaticallyAdded: 'Зверніть увагу, що платіж у криптовалюті буде автоматично доданий на ваш рахунок після того, як ми отримаємо 3 або більше підтверджень транзакції.',
  makePayment: 'Здійснювати платіж',
  weApplyPaymentBonusForEachSingleTransaction: 'Ми застосовуємо платіжний бонус за кожну окрему транзакцію в залежності від її суми (за винятком PayPal):',
  bonusValidFor: 'Бонус діє 120 днів',
  moreThan: 'більше ніж',
  forExample: 'Наприклад, заплативши 2000$ - ви отримаєте 2000+200 (10% бонус) = 2200$, або заплативши 7000$ - отримаєте 7000+1050 (15% бонус) = 8050$!',
  inCaseOfRefundRequest: 'У разі запиту на повернення ми повернемо суму вашого платежу без будь-яких бонусів!',
  ByAddingFundsToYourAccount: 'Додаючи кошти на свій рахунок, ви автоматично приймаєте наші',
  refundAndChargebackPolicy: 'Политика возвратаПолітика повернення та повернення. и возврата.',
  ifYouNeedAnInvoice: 'Якщо вам потрібен рахунок-фактура, надішліть електронний лист на адресу {email} із інформацією про суму й одержувача або зв’яжіться зі службою підтримки в чаті',
  withAmountAndYayeeDetails: 'із зазначенням суми та даних одержувача.',
  billingHistory: 'Історія платежів',
  paymentsOf: 'платежів з',
  creditCard: 'Credit Card',
  googlePay: 'Google Pay',
  applePay: 'Apple Pay',
  payPal: 'PayPal',
  usdt: 'USDT (Tether)',
  bitcoin: 'Bitcoin',
  ethereum: 'Ethereum',
  usdCoin: 'USD Монета',
  binancePay: 'Binance Pay',
  alipay: 'Alipay',
  payoneer: 'Payoneer',
  weAccept: 'Ми приймаємо',
  paymentsOnlyIn: 'виплати тільки в',
  manual: 'керівництво',
  mode: 'режим',
  toTopUpYourBalanceWith: 'Щоб поповнити баланс за допомогою',
  pleaseContactSupportForTransactionDetails: "будь ласка, зв'яжіться зі службою підтримки для деталей транзакції",
  wireBankTransfer: 'Wire банківський переказ',
  wireBank: 'Wire банк',
  wireTransfer: 'Wire переказ',
  pleaseContactSupportForInvoiceAndTransactionDetails: "будь ласка, зв'яжіться зі службою підтримки для отримання рахунку та деталей транзакції",
  minimumPaymentAmountIs: 'Мінімальна сума платежу складає',
  maximumPaymentAmountIs: 'Максимальна сума платежу складає',
  transactionFee: 'комісія на переклад',
  topUpYourBalanceWithPayPal: 'Поповніть свій баланс за допомогою PayPal',
  dueYoNewPaypalRestrictions: 'У зв’язку з новими обмеженнями Paypal ми змушені значно підвищити комісію за транзакції для всіх платежів через <b>Paypal</b>. Зараз це <b>6%</b>.',
  alsoWeveRemovedAllRegularBonuses: 'Ми <b>видалили</b> всі звичайні бонуси за платежі, що перевищують 1000 доларів, оброблені через Paypal. Щоб отримати ці бонуси, використовуйте кредитні картки або альтернативні способи поповнення балансу.',
  topUpYourBalanceWithPayoneer: 'Поповнити баланс за допомогою Payoneer',
  topUpYourBalanceWithBankTransfer: 'Поповнити баланс банківським переказом',
  paymentsOnlyInManualMode: 'платежі лише у ручному режимі.',
  toTopUpYourBalanceWithPayoneerPlease: 'Щоб поповнити баланс Payoneer, будь ласка,',
  toTopUpYourBalanceWithBankTransferPlease: 'Щоб завершити баланс банківським переказом, будь ласка',
  contact: 'контакт',
  supportForTransactionDetails: 'підтримка деталей транзакції.',
  minimumPaymentAmountForThisPaymentMethodIs: 'Зверніть увагу, мінімальна сума платежу для цього способу оплати становить',
  weAcceptSEPAOrSWIFTBankTransfers: 'Ми приймаємо банківські перекази SEPA або SWIFT лише у ручному режимі.'
};

export default topUpBalance;
