import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { AxiosRequestConfig } from 'axios';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { domainUrl } from '@/core/config/DomainConfig';
import { ErrorGetters } from '@/store/enums/ErrorGettersEnums';
import { Getters } from '@/store/enums/GettersEnums';

@Module({ name: 'ReferralModule' })
export default class ReferralModule extends VuexModule implements StoreModel {

  usersReferrals = [];
  usersReferralsQueryParams = {};
  usersReferralsExport = [];
  usersReferralsTotal = 0;
  usersReferralsLastPage = 0;
  usersReferralsCurrentPage = 1;
  usersReferralsRowsPerPage = 5;
  usersReferralsSearch = '';
  usersReferralsOrderBy = 'payments_new';
  usersReferralsSortBy = 'desc';
  usersPayouts = [];
  widgetsReferrals = {};
  errors = {};

  /**
     * Get current users referrals array
     * @returns array
     */
  get [Getters.GET_ALL_USERS_REFERRALS]() {
    return this.usersReferrals;
  }

  get [Getters.GET_ALL_USERS_REFERRAL_TOTAL]() {
    return this.usersReferralsTotal;
  }

  get [Getters.GET_USERS_REFERRALS_QUERY_PARAMS]() {
    return this.usersReferralsQueryParams;
  }

  get [Getters.GET_USERS_REFERRALS_EXPORT_DATA]() {
    return this.usersReferralsExport;
  }


  get [Getters.GET_USERS_REFERRALS_LAST_PAGE]() {
    return this.usersReferralsLastPage;
  }

  get [Getters.GET_USERS_REFERRALS_ROWS_PER_PAGE]() {
    return this.usersReferralsRowsPerPage;
  }

  get [Getters.GET_USERS_REFERRALS_CURRENT_PAGE]() {
    return this.usersReferralsCurrentPage;
  }

  get [Getters.GET_USERS_REFERRALS_SEARCH]() {
    return this.usersReferralsSearch;
  }

  get [Getters.GET_USERS_REFERRALS_ORDER_BY]() {
    return this.usersReferralsOrderBy;
  }

  get [Getters.GET_USERS_REFERRALS_SORT_BY]() {
    return this.usersReferralsSortBy;
  }


  /**
     * Get current users payouts array
     * @returns array
     */
  get [Getters.GET_ALL_USERS_PAYOUTS]() {
    return this.usersPayouts;
  }

  /**
     * Get current widgets referrals object
     * @returns object
     */
  get [Getters.GET_ALL_WIDGETS_REFERRALS]() {
    return this.widgetsReferrals;
  }

  /**
     * Get errors
     * @returns array
     */
  get [ErrorGetters.GET_ERRORS_REFERRALS]() {
    return this.errors;
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.usersReferrals = [];
    this.usersReferralsTotal = 0;
    this.usersPayouts = [];
    this.widgetsReferrals = {};
    this.errors = {};
    this.usersReferralsQueryParams = {};
    this.usersReferralsExport = [];
  };

    @Mutation
    [Mutations.SET_USERS_REFERRALS](payload) {
      this.usersReferrals = payload;
    }

    @Mutation
    [Mutations.SET_USERS_REFERRALS_TOTAL](payload) {
      this.usersReferralsTotal = payload;
    }

    @Mutation
    [Mutations.SET_USERS_PAYOUTS](payload) {
      this.usersPayouts = payload;
    }


    @Mutation
    [Mutations.SET_WIDGETS_REFERRALS](payload) {
      this.widgetsReferrals = payload;
    }

    @Mutation
    [Mutations.SET_ERROR_REFERRAL](payload) {
      this.errors = payload;
    }


    @Mutation
    [Mutations.SET_USERS_REFERRALS_LAST_PAGE](payload) {
      this.usersReferralsLastPage = payload;
    }

    @Mutation
    [Mutations.SET_USERS_REFERRALS_CURRENT_PAGE](payload) {
      this.usersReferralsCurrentPage = payload;
    }

    @Mutation
    [Mutations.SET_USERS_REFERRALS_ROWS_PER_PAGE](payload) {
      this.usersReferralsRowsPerPage = payload;
    }

    @Mutation
    [Mutations.SET_USERS_REFERRALS_SEARCH](payload) {
      this.usersReferralsSearch = payload;
    }

    @Mutation
    [Mutations.SET_USERS_REFERRALS_QUERY_PARAMS](payload) {
      this.usersReferralsQueryParams = payload;
    }

    @Mutation
    [Mutations.SET_USERS_REFERRALS_EXPORT](payload) {
      this.usersReferralsExport = payload;
    }

    @Mutation
    [Mutations.SET_USERS_REFERRALS_ORDER_BY](payload) {
      this.usersReferralsOrderBy = payload;
    }

    @Mutation
    [Mutations.SET_USERS_REFERRALS_SORT_BY](payload) {
      this.usersReferralsSortBy = payload;
    }


    @Action
    [Actions.GET_USERS_REFERRALS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}users/referrals`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USERS_REFERRALS, data.data);
          this.context.commit(Mutations.SET_USERS_REFERRALS_TOTAL, data.meta.total);
          this.context.commit(Mutations.SET_USERS_REFERRALS_LAST_PAGE, data.meta.last_page);
          this.context.commit(Mutations.SET_USERS_REFERRALS_CURRENT_PAGE, data.meta.current_page);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_REFERRAL, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_USERS_REFERRALS_EXPORT](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}users/referrals`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USERS_REFERRALS_EXPORT, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_REFERRAL, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_USERS_PAYOUTS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}users/referral/payouts`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USERS_PAYOUTS, data.payments);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_REFERRAL, response?.data?.errors);
        });
    }

    @Action
    [Actions.POST_USERS_PAYOUT](params) {
      return ApiService.post(`${domainUrl}users/referral/payout`, params)
        .then(({ data }) => {
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_REFERRAL, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_WIDGETS_REFERRALS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}widgets/referrals`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_WIDGETS_REFERRALS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_REFERRAL, response?.data?.errors);
        });
    }
}
