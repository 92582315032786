import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { AxiosRequestConfig } from 'axios';
import ApiService from '@/core/services/ApiService';
import { AccessListTypeEnums } from '@/store/enums/AccessListEnums';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { domainUrlAdmin } from '@/core/config/DomainConfig';
import { ErrorGetters } from '@/store/enums/ErrorGettersEnums';
import { Getters } from '@/store/enums/GettersEnums';

@Module({ name: 'AccessListModule' })
export default class AccessListModule extends VuexModule implements StoreModel {
  errors = {};
  accessLists = [];
  totalAccessLists = 0;
  currentPage = 1;
  lastPage = 1;
  page = 1;
  rowsPerPage = 10;
  search = '';
  limit = 10;
  listType = AccessListTypeEnums.WHITELIST;
  category = 0;
  orderBy = 'date';
  sortBy = 'desc';

  /**
     * Get current page
     * @returns number
     */
  get [Getters.GET_ACCESS_LISTS_CURRENT_PAGE]() {
    return this.currentPage;
  }

  /**
     * Get last page
     * @returns number
     */
  get [Getters.GET_ACCESS_LISTS_LAST_PAGE]() {
    return this.lastPage;
  }

  /**
     * Get page
     * @returns number
     */
  get [Getters.GET_ACCESS_LISTS_PAGE]() {
    return this.page;
  }

  /**
     * Get rows
     * @returns number
     */
  get [Getters.GET_ACCESS_LISTS_ROWS]() {
    return this.rowsPerPage;
  }

  /**
     * Get type
     * @returns number
     */
  get [Getters.GET_ACCESS_LISTS_TYPE]() {
    return this.listType;
  }

  /**
     * Get search
     * @returns string
     */
  get [Getters.GET_ACCESS_LISTS_SEARCH]() {
    return this.search;
  }

  /**
     * Get limit
     * @returns string
     */
  get [Getters.GET_ACCESS_LISTS_LIMIT]() {
    return this.limit;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [ErrorGetters.GET_ERRORS_ACCESS_LISTS]() {
    return this.errors;
  }

  /**
     * Get current Transaction object
     * @returns array
     */
  get [Getters.ALL_ACCESS_LISTS]() {
    return this.accessLists;
  }

  /**
     * Get current Total Transactions
     * @returns number
     */
  get [Getters.GET_TOTAL_ACCESS_LISTS]() {
    return this.totalAccessLists;
  }

  /**
     * Get current Total Transactions
     * @returns number
     */
  get [Getters.GET_ACCESS_LISTS_CATEGORY]() {
    return this.category;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ERRORS_ACCESS_LIST]() {
    return this.errors;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ACCESS_LISTS_ORDER_BY]() {
    return this.orderBy;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ACCESS_LISTS_SORT_BY]() {
    return this.sortBy;
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.errors = {};
    this.accessLists = [];
    this.totalAccessLists = 0;
    this.currentPage = 1;
    this.lastPage = 1;
    this.page = 1;
    this.rowsPerPage = 10;
    this.search = '';
    this.limit = 10;
    this.listType = AccessListTypeEnums.WHITELIST;
    this.category = 0;
    this.orderBy = 'date';
    this.sortBy = 'desc';
  };

    @Mutation
    [Mutations.SET_ACCESS_LISTS_ROWS](payload) {
      this.rowsPerPage = payload;
    }

    @Mutation
    [Mutations.SET_ERROR_ACCESS_LISTS](error) {
      this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_ALL_ACCESS_LISTS](payload) {
      this.accessLists = payload;
    }

    @Mutation
    [Mutations.SET_TOTAL_ACCESS_LISTS](payload) {
      this.totalAccessLists = payload;
    }

    @Mutation
    [Mutations.SET_ACCESS_LISTS_CURRENT_PAGE](payload) {
      this.currentPage = payload;
    }

    @Mutation
    [Mutations.SET_ACCESS_LISTS_LAST_PAGE](payload) {
      this.lastPage = payload;
    }

    @Mutation
    [Mutations.SET_ACCESS_LISTS_TYPE](payload) {
      this.listType = payload;
    }

    @Mutation
    [Mutations.SET_ACCESS_LISTS_SEARCH](payload) {
      this.search = payload;
    }

    @Mutation
    [Mutations.SET_ACCESS_LISTS_CATEGORY](payload) {
      this.category = payload;
    }

    @Mutation
    [Mutations.SET_ACCESS_LISTS_ORDER_BY](payload) {
      this.orderBy = payload;
    }

    @Mutation
    [Mutations.SET_ACCESS_LISTS_SORT_BY](payload) {
      this.sortBy = payload;
    }

    @Action
    [Actions.GET_ACCESS_LIST](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };

      return ApiService.query(`${domainUrlAdmin}user-access-list`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TOTAL_ACCESS_LISTS, data.meta.total);
          this.context.commit(Mutations.SET_ACCESS_LISTS_CURRENT_PAGE, data.meta.current_page);
          this.context.commit(Mutations.SET_ACCESS_LISTS_LAST_PAGE, data.meta.last_page);
          this.context.commit(Mutations.SET_ALL_ACCESS_LISTS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_ACCESS_LISTS, response?.data?.errors);
        });
    }

    @Action
    [Actions.ADD_ACCESS_LIST](params) {
      return ApiService.post(`${domainUrlAdmin}user-access-list`, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR_ACCESS_LISTS, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_ACCESS_LISTS, response?.data?.errors);
        }).finally(() => {
          // this.context.commit(Mutations.SET_LOADING, false);
        });
    }

    @Action
    [Actions.DELETE_ACCESS_LIST](id) {
      return ApiService.delete(`${domainUrlAdmin}user-access-list/` + id)
        .then(({ data }) => {
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_ACCESS_LISTS, response?.data?.errors);
        }).finally(() => {
          // this.context.commit(Mutations.SET_LOADING, false);
        });
    }
}
