import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { AxiosRequestConfig } from 'axios';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import ApiService from '@/core/services/ApiService';
import { UserDetailsBalanceTypeValueEnums } from '@/store/enums/UserDetailsEnums';
import { domainUrlAdmin } from '@/core/config/DomainConfig';
import { ErrorGetters } from '@/store/enums/ErrorGettersEnums';
import { Getters } from '@/store/enums/GettersEnums';

@Module({ name: 'UserDetailsPaymentsModule' })
export default class UserDetailsPaymentsModule extends VuexModule implements StoreModel {
  errors = {};
  userDetailsPayments = [];
  totalDetailsPayments = 0;
  currentPage = 1;
  lastPage = 1;
  page = 1;
  rowsPerPage = 10;
  search = '';
  limit = 10;
  orderBy = 'created_at';
  sortBy = 'desc';
  balanceType = UserDetailsBalanceTypeValueEnums.ALL;

  /**
     * Get current Transaction object
     * @returns array
     */
  get [Getters.ALL_USER_DETAILS_PAYMENTS]() {
    return this.userDetailsPayments;
  }

  /**
     * Get search
     * @returns string
     */
  get [Getters.GET_USER_DETAILS_PAYMENTS_SEARCH]() {
    return this.search;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_USER_DETAILS_PAYMENTS_ORDER_BY]() {
    return this.orderBy;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_USER_DETAILS_PAYMENTS_SORT_BY]() {
    return this.sortBy;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_USER_DETAILS_PAYMENTS_BALANCE_TYPE]() {
    return this.balanceType;
  }

  /**
     * Get limit
     * @returns string
     */
  get [Getters.GET_USER_DETAILS_PAYMENTS_LIMIT]() {
    return this.limit;
  }

  /**
     * Get current Total Transactions
     * @returns number
     */
  get [Getters.GET_TOTAL_USER_DETAILS_PAYMENTS]() {
    return this.totalDetailsPayments;
  }


  /**
     * Get current page
     * @returns number
     */
  get [Getters.GET_USER_DETAILS_PAYMENTS_CURRENT_PAGE]() {
    return this.currentPage;
  }

  /**
     * Get last page
     * @returns number
     */
  get [Getters.GET_USER_DETAILS_PAYMENTS_LAST_PAGE]() {
    return this.lastPage;
  }

  /**
     * Get page
     * @returns number
     */
  get [Getters.GET_USER_DETAILS_PAYMENTS_PAGE]() {
    return this.page;
  }

  /**
     * Get rows
     * @returns number
     */
  get [Getters.GET_USER_DETAILS_PAYMENTS_ROWS]() {
    return this.rowsPerPage;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [ErrorGetters.GET_ERRORS_USER_DETAILS_PAYMENTS]() {
    return this.errors;
  }


    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.errors = {};
    this.userDetailsPayments = [];
    this.totalDetailsPayments = 0;
    this.currentPage = 1;
    this.lastPage = 1;
    this.page = 1;
    this.rowsPerPage = 10;
    this.search = '';
    this.limit = 10;
    this.orderBy = 'id';
    this.sortBy = 'desc';
    this.balanceType = UserDetailsBalanceTypeValueEnums.PAYMENTS;
  };

    @Mutation
    [Mutations.SET_USER_DETAILS_PAYMENTS_ROWS](payload) {
      this.rowsPerPage = payload;
    }

    @Mutation
    [Mutations.SET_ERROR_USER_DETAILS_PAYMENTS](error) {
      this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_ALL_USER_DETAILS_PAYMENTS](payload) {
      this.userDetailsPayments = payload;
    }

    @Mutation
    [Mutations.SET_TOTAL_USER_DETAILS_PAYMENTS](payload) {
      this.totalDetailsPayments = payload;
    }

    @Mutation
    [Mutations.SET_USER_DETAILS_PAYMENTS_CURRENT_PAGE](payload) {
      this.currentPage = payload;
    }

    @Mutation
    [Mutations.SET_USER_DETAILS_PAYMENTS_LAST_PAGE](payload) {
      this.lastPage = payload;
    }

    @Mutation
    [Mutations.SET_USER_DETAILS_PAYMENTS_SEARCH](payload) {
      this.search = payload;
    }

    @Mutation
    [Mutations.SET_USER_DETAILS_PAYMENTS_ORDER_BY](payload) {
      this.orderBy = payload;
    }

    @Mutation
    [Mutations.SET_USER_DETAILS_PAYMENTS_SORT_BY](payload) {
      this.sortBy = payload;
    }

    @Mutation
    [Mutations.SET_USER_DETAILS_PAYMENTS_BALANCE_TYPE](payload) {
      this.balanceType = payload;
    }

    @Action
    [Actions.GET_USER_DETAILS_PAYMENTS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}payments`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TOTAL_USER_DETAILS_PAYMENTS, data.meta.total);
          this.context.commit(Mutations.SET_USER_DETAILS_PAYMENTS_CURRENT_PAGE, data.meta.current_page);
          this.context.commit(Mutations.SET_USER_DETAILS_PAYMENTS_LAST_PAGE, data.meta.last_page);
          this.context.commit(Mutations.SET_ALL_USER_DETAILS_PAYMENTS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_DETAILS_PAYMENTS, response?.data?.errors);
        });
    }

    @Action
    [Actions.CHANGE_USER_BALANCE](params) {
      const { id, ...data } = params;
      return ApiService.update(`${domainUrlAdmin}payments/change-user-balance`, id, data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR_USER_DETAILS_PAYMENTS, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_DETAILS_PAYMENTS, response?.data?.errors);
        });
    }
}
