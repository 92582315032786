enum Payments {
    FIVE_HUNDRED = 500,
    THOUSAND = 1000,
    FIVE_THOUSANDS = 5000,
    TEN_THOUSANDS = 10000,
    SUCCESS = 'success',
    ERROR = 'error',
}

enum PaymentsValue {
    VISA_MASTERCARD = 30,
    PAY_PAL = 1,
    COINBASE = 13,
    BINANCE = 32,
    ALIPAY = 31,
    PAYONEER = 19,
    BANK_TRANSFER = 3,
    WAY_FOR_PAY = 14,
    FONDY = 33,
    OXPROCESSING = 35,
    WISE = 36,
    TRANSFER_BETWEEN_ACCOUNTS = 34,
}

enum PaymentsIcon {
    VISA_MASTERCARD = 'payments__icon--visa-mastercard h-38px',
    PAY_PAL = 'payments__icon--pay-pal h-30px',
    COINBASE = 'payments__icon--coinbase h-30px',
    BINANCE = 'payments__icon--binance h-30px',
    ALIPAY = 'payments__icon--alipay h-30px',
    OXPROCESSING = 'payments__icon--oxprocessing h-30px',
    PAYONEER = 'payments__icon--payoneer h-30px',
    BANK_TRANSFER = 'payments__icon--bank-transfer h-30px',
    WAY_FOR_PAY = 'payments__icon--way-for-pay h-38px',
    FONDY = 'payments__icon--fondy h-38px',
    TRANSFER_BETWEEN_ACCOUNTS = 'payments__icon--transfer-between-accounts h-30px',
    WISE = 'payments__icon--wise h-30px',
}

export { Payments, PaymentsValue, PaymentsIcon };
