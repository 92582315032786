const referralProgram = {
  welcomeToOurProgram:'Добро пожаловать в нашу реферальную программу!',
  youCanEarnMoney:'Вы можете зарабатывать, рекомендуя наш сервис другим пользователям.',
  oneUserRegistersUsingYourReferralLink:'Как только пользователь зарегистрируется по вашей реферальной ссылке, вы будете получать <b>10%</b> от всех его платежей в нашем сервисе пожизненно. Вы можете получить реферальный платеж в любое время, прямо на свой баланс или на свой счет Paypal.',
  refLink:'Реф ссылка',
  pleaseBeAdviced:'Обратите внимание, что использование мультиаккаунтов на нашей платформе запрещено. Если мы обнаружим, что вы каким-либо образом злоупотребляете нашей системой, ваши реферальные платежи не будут зачислены на ваш счет, и вы не сможете получить их через Paypal. Непрерывные нарушения могут привести к приостановке действия учетной записи, при этом средства будут удерживаться на вашей приостановленной учетной записи. Например, осуществление платежей для вашей основной и реферальной учетной записи с одного и того же электронного адреса Paypal запрещено.',
  date:'Дата',
  totalPayments:'Всего платежей',
  level:'Уровень',
  userName:'Имя пользователя',
  userEmail:'Электронная почта пользователя',
  newPayments:'Новые платежи',
  newPayouts:'Новые выплаты',
  earnings:'Доход',
  registered:'Зарегистрировано',
  paymentsHistory:'История платежей',
  payoutsHistory:'История выплат',
  payments:'платежи',
  showing:'Показаны',
  myReferrals:'Мои рефералы',
  referrals:'рекомендации',
  noPaymentsFound:'Платежи не найдены',
  noReferralsFound:'Рефералы не найдены',
  showAll:'Показать всех',
  showActive:'Показать активных',
  showOnly:'Показать только',
  myReferralLink:'Моя реферальная ссылка',
  totalReferrals:'Всего рефералов',
  paymentsOfReferrals:'Выплаты рефералов',
  totalPayouts:'Всего выплат',
  newPayout:'Новая выплата',
  requestPayout:'Запрос выплат',
  status:'Статус',
  all:'Все',
  hasPayments:'Есть платежи',
  withPayments:'С платежами',
  yourPayoutRequestHasBeenCanceled:'Ваш запрос на выплату был отменен. Пожалуйста, свяжитесь со службой поддержки',
  yourPayoutHasBeenCompletedSuccessfully:'Ваша выплата успешно завершена. Количество',
  hasBeenAddedToYourAccount:'$ has был добавлен в вашу учетную запись.',
};

export default referralProgram;
