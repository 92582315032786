const referralProgram = {
  welcomeToOurProgram:'¡Bienvenido a nuestro programa de referidos!',
  youCanEarnMoney:'Puedes ganar dinero recomendando nuestro servicio a otros usuarios.',
  oneUserRegistersUsingYourReferralLink:'Una vez que un usuario se registre utilizando su enlace de referencia, recibirá el <b>10%</b> de todos sus pagos en nuestro servicio de por vida. Puede recibir un pago de referencia en cualquier momento que desee, directamente a su saldo oa su cuenta de Paypal.',
  refLink:'Enlace de referencia',
  pleaseBeAdviced:'Tenga en cuenta que el uso de cuentas múltiples en nuestra plataforma está prohibido, si descubrimos que está abusando de nuestro sistema de alguna manera, sus pagos de referencia no se acreditarán en su cuenta, ni podrá recibirlos a través de Paypal. Las infracciones continuas pueden provocar la suspensión de la cuenta con fondos retenidos en su cuenta suspendida. Por ejemplo, está prohibido realizar pagos para su cuenta principal y de referencia desde el mismo correo electrónico de Paypal.',
  date:'Fecha',
  totalPayments:'Pagos totales',
  level:'Nivel',
  userName:'Nombre de usuario',
  userEmail:'Correo electrónico del usuario',
  newPayments:'Nuevos pagos',
  newPayouts:'Nuevos pagos',
  earnings:'Ganancias',
  registered:'Registrado',
  paymentsHistory:'Historial de pagos',
  payoutsHistory:'Historial de pagos',
  payments:'pagos',
  showing:'Demostración',
  myReferrals:'Mis referencias',
  referrals:'referencias',
  noPaymentsFound:'No se encontraron pagos',
  noReferralsFound:'No se encontraron referencias',
  showAll:'Mostrar todo',
  showActive:'Mostrar activo',
  showOnly:'Mostrar solo',
  myReferralLink:'Mi enlace de referencia',
  totalReferrals:'Referencias totales',
  paymentsOfReferrals:'Pagos de referidos',
  totalPayouts:'Pagos totales',
  newPayout:'Nuevo pago',
  requestPayout:'Solicitud de Pago',
  status:'Estado',
  all:'Todo',
  hasPayments:'Tiene pagos',
  withPayments:'Con pagos',
  yourPayoutRequestHasBeenCanceled:'Su solicitud de pago ha sido cancelada. Póngase en contacto con soporte',
  yourPayoutHasBeenCompletedSuccessfully:'Su pago se ha completado con éxito. La cantidad de',
  hasBeenAddedToYourAccount:'$ ha sido añadido a tu cuenta.',
};

export default referralProgram;
