const contacts = {
  contactUs: 'Contact us',
  dearUserIfYouHaveAnyQuestions: 'Dear User! If you have any questions, feel free to contact our support team or your manager through any messenger mentioned on the platform. You can also reach the support team via live chat. Additionally, our {linkFaq} section contains answers to the most common questions you may have working with the platform.',
  howCanWeHelpYou: 'How can we help You?',
  subject: 'Subject',
  enterTheSubject: 'Enter the Subject',
  message: 'Message',
  pleaseProvideYourSupplies: 'Please provide your supplies line by line',
  weWillAnswerYou: "We will answer you as quickly as we can. We assure you, you won't have to wait long.",
  sendMessage: 'Send message',
  youCanAlsoContactOurSupportTeam: 'You can also contact our support team:'
};

export default contacts;
