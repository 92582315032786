import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { AxiosRequestConfig } from 'axios';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { domainUrl } from '@/core/config/DomainConfig';
import { Getters } from '@/store/enums/GettersEnums';

@Module({ name: 'PaymentSystemModule' })
export default class PaymentSystemModule extends VuexModule implements StoreModel {

  error = {};
  paysystems: any[] = [];
  bonus = {};
  paymentConfigurations = {};
  paymentLink = '';
  options = {};
  selectedPaysystemId: number|null = null;

  /**
     * Get selected paysystem id
     * @returns number|null
     */
  get [Getters.GET_SELECTED_PAYSYSTEM_ID]() {
    return this.selectedPaysystemId;
  }

  /**
     * Get  paysystems
     * @returns object
     */
  get [Getters.GET_PAYMENTS]() {
    return this.paysystems;
  }

  /**
     * Get  bonus
     * @returns object
     */
  get [Getters.GET_BONUS]() {
    return this.bonus;
  }

  /**
     * Get options
     * @returns object
     */
  get [Getters.GET_OPTIONS]() {
    return this.options;
  }

  /**
     * Get  errors
     * @returns object
     */
  get [Getters.GET_PAYMENT_CONFIGURATIONS]() {
    return this.paymentConfigurations;
  }

  /**
     * Get payment link
     * @returns string
     */
  get [Getters.GET_PAYMENT_LINK]() {
    return this.paymentLink;
  }

  /**
     * Get  errors
     * @returns object
     */
  get [Getters.GET_PAYMENT_ERROR]() {
    return this.error;
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.error = {};
    this.paysystems = [];
    this.bonus = {};
    this.paymentConfigurations = {};
    this.paymentLink = '';
    this.options = {};
    this.selectedPaysystemId = null;
  };


    @Mutation
    [Mutations.SET_SELECTED_PAYSYSTEM_ID](payload) {
      this.selectedPaysystemId = payload;
    }

    @Mutation
    [Mutations.SET_PAYSYSTEMS](payload) {
      this.paysystems = payload;
    }

    @Mutation
    [Mutations.SET_BONUS](payload) {
      this.bonus = payload;
    }

    @Mutation
    [Mutations.SET_OPTIONS](payload) {
      this.options = payload;
    }

    @Mutation
    [Mutations.SET_PAYMENT_CONFIGURATIONS](payload) {
      this.paymentConfigurations = payload;
    }

    @Mutation
    [Mutations.SET_PAYMENT_LINK](payload) {
      this.paymentLink = payload;
    }

    @Mutation
    [Mutations.SET_PAYMENT_ERRORS](error) {
      this.error = error;
    }

    @Action
    [Actions.PAYMENT_AUTH_CHECKOUT](data) {
      return ApiService.post(`${domainUrl}checkout/${data.paysystem_id}/auth`, {})
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PAYMENT_LINK, data.url);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_PAYMENT_ERRORS, response?.data?.errors);
        });
    }

    @Action
    [Actions.POST_PAYMENT_CHECKOUT](data) {
      return ApiService.post(`${domainUrl}checkout`, data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PAYMENT_LINK, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_PAYMENT_ERRORS, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_PAYMENT_CONFIGURATION](data) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: { amount: data.amount }
      };
      return ApiService.query(`${domainUrl}payments/${data.paymentId}/get-configuration`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PAYMENT_CONFIGURATIONS, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_PAYMENT_ERRORS, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_PAYSYSTEMS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}paysystems`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PAYSYSTEMS, data.data);
          this.context.commit(Mutations.SET_BONUS, data.bonus);
          this.context.commit(Mutations.SET_OPTIONS, data.options);
          if (this.paysystems.length) {
            this.context.commit(Mutations.SET_SELECTED_PAYSYSTEM_ID, this.paysystems[0]?.id);
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_PAYMENT_ERRORS, response?.data?.errors);
        });
    }

    @Action
    [Actions.TRANSFER_BETWEEN_ACCOUNTS](params) {
      return ApiService.post(`${domainUrl}payments/legacy/withdraw`, params)
        .then(({ data }) => {
          return data.success;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_PAYMENT_ERRORS, response?.data?.errors);
        });
    }
}
