const tooltipDirective = (app) => {
  app.directive('tooltip', {
    mounted(el, binding) {
      init(el, binding);
    },
    updated(el, binding) {
      init(el, binding);
    }
  });
};

function init(el, binding) {
  const position = binding.arg || 'top';
  const tooltipText = binding.value || '';
  el.classList.add('white-space-pre');
  el.setAttribute('position', position);
  if (binding.value) {
    el.setAttribute('tooltip', tooltipText);
  }
}

export default tooltipDirective;
