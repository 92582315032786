import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Main } from '@/store/enums/MainEnums';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { AxiosRequestConfig } from 'axios';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { domainUrl } from '@/core/config/DomainConfig';
import { ErrorGetters } from '@/store/enums/ErrorGettersEnums';
import { Getters } from '@/store/enums/GettersEnums';

export interface KeywordInterface {
    id: number,
    name: string
}


export interface KeywordInfo {
    errors: unknown;
    keyword: KeywordInterface;
    total: unknown;
    isLoading: unknown;
    page: unknown;
    country_id: unknown;
    app_id: unknown;
    chartKeyword: unknown;
}

@Module({ name: 'KeywordModule' })
export default class KeywordModule extends VuexModule implements KeywordInfo, StoreModel {
  errors = {};
  keyword = {} as KeywordInterface;
  keywords: KeywordInterface[] = [];
  clonedKeywords: KeywordInterface[] = [];
  exportKeywords: KeywordInterface[] = [];
  keywords_add = [];
  keyword_rank;
  keyword_rank_update = [];
  promoteKeywords: KeywordInterface[] = [];
  suggestKeywords = [];
  topAppsByKeyword = [];
  chartKeyword = {};
  lastAddedChartKeyword = '';
  chartKeywords = [];
  serialsChart = [];
  rankKeyword = {};
  total = 0;
  favCount = 0;
  allCount = 0;
  isLoading = false;
  isKeywordLoading = false;
  country_id = Main.DEFAULT_COUNTRY;
  app_id = 0;
  arIds = [];
  appstore = Main.STORE_GOOGLE_MARKET;
  page = 1;


  /**
     * Get current Keyword object
     * @returns array
     */
  get [Getters.CURRENT_CHART_KEYWORD]() {
    return this.chartKeyword;
  }

  /**
     * Get promote Keywors array
     * @returns array
     */
  get [Getters.GET_PROMOTE_KEYWORDS]() {
    return this.promoteKeywords;
  }

  /**
     * Get current Keyword object
     * @returns array
     */
  get [Getters.ALL_KEYWORD_RANK_UPDATE]() {
    return this.keyword_rank_update;
  }

  /**
     * Get current Keyword object
     * @returns array
     */
  get [Getters.CURRENT_KEYWORD]() {
    return this.keyword;
  }

  get [Getters.GET_LAST_ADDED_CHART_KEYWORD]() {
    return this.lastAddedChartKeyword;
  }

  get [Getters.GET_IS_KEYWORD_LOADING]() {
    return this.isKeywordLoading;
  }

  /**
     * Get current Keyword object
     * @returns array
     */
  get [Getters.ALL_CHART_KEYWORDS]() {
    return this.chartKeywords;
  }

  /**
     * Get clone keywords
     * @returns array
     */
  get [Getters.ALL_CLONED_KEYWORDS]() {
    return this.clonedKeywords;
  }

  get [Getters.ALL_SERIALS_CHART]() {
    return this.serialsChart;
  }


  /**
     * Get current Keywords objects
     * @returns KeywordInterface
     */
  get [Getters.ALL_KEYWORDS]() {
    return this.keywords;
  }

  /**
     * Get current Keywords objects
     * @returns KeywordInterface
     */
  get [Getters.ALL_EXPORT_KEYWORDS]() {
    return this.exportKeywords;
  }

  /**
     * Get current Keywords Add objects
     * @returns KeywordInterface
     */
  get [Getters.ALL_KEYWORDS_ADD]() {
    return this.keywords_add;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [ErrorGetters.GET_ERRORS_KEYWORDS]() {
    return this.errors;
  }

  /**
     * Get current Top Apps By Keyword objects
     * @returns App
     */
  get [Getters.CURRENT_RANK_KEYWORD]() {
    return this.rankKeyword;
  }

  /**
     * Get current Top Apps By Keyword objects
     * @returns App
     */
  get [Getters.ALL_TOP_APPS_BY_KEYWORD]() {
    return this.topAppsByKeyword;
  }

  /**
     * Get current Suggest Keyword objects
     * @returns App
     */
  get [Getters.ALL_SUGGEST_KEYWORD]() {
    return this.suggestKeywords;
  }

  /**
     * Get current app_id
     * @returns app_id
     */
  get [Getters.GET_CURRENT_APP_ID]() {
    return this.app_id;
  }

  /**
     * Get current keyword.id
     * @returns keyword.id
     */
  get [Getters.GET_CURRENT_APP_KEYWORD_ID]() {
    return this.keyword.id;
  }

  /**
     * Get current total count
     * @returns Keyword
     */
  get [Getters.CURRENT_TOTAL_KEYWORD]() {
    return this.total;
  }

  /**
     * Get current fav keyword count
     * @returns number
     */
  get [Getters.CURRENT_FAV_KEYWORD_COUNT]() {
    return this.favCount;
  }

  /**
     * Get all keyword count
     * @returns number
     */
  get [Getters.CURRENT_ALL_KEYWORD_COUNT]() {
    return this.allCount;
  }

  /**
     * Get current appstore
     * @returns appstore
     */
  get [Getters.GET_CURRENT_KEYWORD_APPSTORE]() {
    return this.appstore;
  }

  /**
     * Get current country_id
     * @returns country_id
     */
  get [Getters.GET_CURRENT_COUNTRY_ID]() {
    return this.country_id;
  }

  /**
     * Get current arIds
     * @returns array
     */
  get [Getters.GET_AR_IDS]() {
    return this.arIds;
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.errors = {};
    this.keyword = {} as KeywordInterface;
    this.keywords = [];
    this.clonedKeywords = [];
    this.keywords_add = [];
    this.keyword_rank;
    this.keyword_rank_update = [];
    this.suggestKeywords = [];
    this.topAppsByKeyword = [];
    this.chartKeyword = {};
    this.lastAddedChartKeyword = '';
    this.chartKeywords = [];
    this.rankKeyword = {};
    this.total = 0;
    this.favCount = 0;
    this.allCount = 0;
    this.isLoading = false;
    this.country_id = Main.DEFAULT_COUNTRY;
    this.app_id = 0;
    this.arIds = [];
    this.appstore = Main.STORE_GOOGLE_MARKET;
    this.page = 1;
  };

    @Mutation
    [Mutations.SET_IS_KEYWORD_LOADING](payload) {
      this.isKeywordLoading = payload;
    }

    @Mutation
    [Mutations.SET_GRAPHIC_KEYWORD](chartKeyword) {
      this.chartKeyword = chartKeyword;
    }

    @Mutation
    [Mutations.SET_LAST_ADDED_KEYWORD_NAME](payload) {
      this.lastAddedChartKeyword = payload;
    }

    @Mutation
    [Mutations.SET_SERIALS_CHART](serialsChart) {
      this.serialsChart = serialsChart;
    }

    @Mutation
    [Mutations.SET_KEYWORDS_ID](arIds) {
      this.arIds = arIds;
    }

    @Mutation
    [Mutations.SET_PROMOTE_KEYWORDS](payload) {
      this.promoteKeywords = payload;
    }

    @Mutation
    [Mutations.SET_KEYWORD_RANK_UPDATE](keyword_rank_update) {
      // @ts-ignore
      this.keyword_rank_update.push(keyword_rank_update);
    }

    @Mutation
    [Mutations.SET_KEYWORD_RANK_UPDATE_EMPTY]() {
      // @ts-ignore
      this.keyword_rank_update = [];
    }

    @Mutation
    [Mutations.SET_GRAPHIC_KEYWORDS](chartKeywords) {
      this.chartKeywords = chartKeywords;
    }

    @Mutation
    [Mutations.SET_APP_ID](app_id) {
      this.app_id = app_id;
    }

    @Mutation
    [Mutations.SET_APP_KEYWORD_ID](app_keyword_id) {
      this.keyword.id = app_keyword_id;
    }

    @Mutation
    [Mutations.SET_COUNTRY_ID](country_id) {
      this.country_id = country_id;
    }

    @Mutation
    [Mutations.SET_KEYWORD_APPSTORE](appstore) {
      this.appstore = appstore;
    }

    @Mutation
    [Mutations.SET_ERROR_KEYWORD](error) {
      this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_KEYWORD](keyword) {
      this.keyword = keyword;
    }

    @Mutation
    [Mutations.SET_KEYWORDS](keywords) {
      this.keywords = keywords;
    }

    @Mutation
    [Mutations.SET_CLONED_KEYWORDS](keywords) {
      this.clonedKeywords = keywords;
    }

    @Mutation
    [Mutations.SET_EXPORT_KEYWORDS](exportKeywords) {
      this.exportKeywords = exportKeywords;
    }

    @Mutation
    [Mutations.SET_KEYWORDS_ADD](keywords_add) {
      this.keywords_add = keywords_add;
    }

    @Mutation
    [Mutations.SET_TOTAL_PAGES](total) {
      this.total = total;
    }

    @Mutation
    [Mutations.SET_FAVORITE_COUNT](payload) {
      this.favCount = payload;
    }

    @Mutation
    [Mutations.SET_ALL_COUNT](payload) {
      this.allCount = payload;
    }

    @Mutation
    [Mutations.SET_LOADING](isLoading) {
      this.isLoading = isLoading;
    }

    @Mutation
    [Mutations.SET_SUGGEST_KEYWORDS](suggestKeywords) {
      this.suggestKeywords = suggestKeywords;
    }

    @Mutation
    [Mutations.SET_KEYWORD_RANK](keyword_rank) {
      this.keyword_rank = keyword_rank;
    }

    @Mutation
    [Mutations.SET_RANK_KEYWORD](rankKeyword) {
      this.rankKeyword = rankKeyword;
    }

    @Mutation
    [Mutations.SET_TOP_APPS_BY_KEYWORD](topAppsByKeyword) {
      this.topAppsByKeyword = topAppsByKeyword;
    }

    @Mutation
    [Mutations.SET_PAGE](page) {
      this.page = page;
    }

    @Action
    [Actions.DELETE_KEYWORDS_MANY](params) {
      // this.context.commit(Mutations.SET_LOADING, true)
      return ApiService.post(`${domainUrl}keywords/destroyMany`, params)
        .then(({ data }) => {
          const app_keywords_id = params.app_keywords_id.split(',');
          let countDelete = 0;
          const deleteKeyword = this.keywords.filter((obj) => {
            // @ts-ignore
            if (!app_keywords_id.includes(obj.id.toString())) {
              return obj;
            } else {
              countDelete++;
            }
          });
          this.context.commit(Mutations.SET_KEYWORDS, deleteKeyword);
          this.context.commit(Mutations.SET_TOTAL_PAGES, this.total - countDelete);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_KEYWORD, response?.data?.errors);
        }).finally(() => {
        });
    }

    @Action
    [Actions.DELETE_KEYWORD](id) {
      return ApiService.delete(`${domainUrl}keywords/` + id)
        .then(({ data }) => {
          const deleteKeyword = this.keywords.filter((obj) => {
            // @ts-ignore
            if (obj.id !== id) {
              return obj;
            }
          });
          this.context.commit(Mutations.SET_KEYWORDS, deleteKeyword);
          this.context.commit(Mutations.SET_TOTAL_PAGES, this.total - 1);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_KEYWORD, response?.data?.errors);
        }).finally(() => {
        });
    }

    @Action
    [Actions.TOGGLE_FAVORITE_KEYWORD](id: any) {
      const config: AxiosRequestConfig = {
        params: { id: id }
      };
      return ApiService.put(`${domainUrl}keywords/toggle`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_KEYWORD, data.data);
          const updateKeywords = this.keywords.map((obj) => {
            // @ts-ignore
            if (obj.id === data.data.id) {
              return data.data;
            }
            return obj;
          });
          this.context.commit(Mutations.SET_KEYWORDS, updateKeywords);
          this.context.commit(Mutations.SET_ERROR_KEYWORD, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_KEYWORD, response?.data?.errors);
        }).finally(() => {
        });
    }

    @Action
    [Actions.CLONE_KEYWORDS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}keywords/clone`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CLONED_KEYWORDS, data);
        })
        .catch(() => {
        });
    }

    @Action
    [Actions.GET_KEYWORDS_COUNT](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}keywords/count`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_FAVORITE_COUNT, data.favCount);
          this.context.commit(Mutations.SET_ALL_COUNT, data.allCount);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_KEYWORD, response?.data?.errors);
        }).finally(() => {
          // this.context.commit(Mutations.SET_LOADING, false);
        });
    }

    @Action
    [Actions.GET_KEYWORDS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}keywords`, config)
        .then(({ data }) => {
          if (params.isExport) {
            this.context.commit(Mutations.SET_EXPORT_KEYWORDS, data.data);
          } else {
            this.context.commit(Mutations.SET_TOTAL_PAGES, data.meta.total);
            this.context.commit(Mutations.SET_FAVORITE_COUNT, data.favCount);
            this.context.commit(Mutations.SET_ALL_COUNT, data.allCount);
            this.context.commit(Mutations.SET_KEYWORDS_ID, data.arIds);
            this.context.commit(Mutations.SET_KEYWORDS, data.data);
          }

        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_KEYWORD, response?.data?.errors);
        }).finally(() => {
          // this.context.commit(Mutations.SET_LOADING, false);
        });
    }

    @Action
    [Actions.ADD_KEYWORD](params) {
      return ApiService.post(`${domainUrl}keywords`, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_KEYWORDS_ADD, data.data);
          const resultAdd: any = [];
          let countAdd = 0;
          data.data.forEach((el) => {
            // @ts-ignore
            const itemFind = this.keywords.find(item => item.keyword === el.keyword);
            if (!itemFind) {
              resultAdd.push(el);
              countAdd++;
            }
          });
          if (resultAdd.length > 0) {
            this.context.commit(Mutations.SET_KEYWORDS, [...resultAdd, ...this.keywords]);
            this.context.commit(Mutations.SET_TOTAL_PAGES, this.total + countAdd);
          }

          this.context.commit(Mutations.SET_ERROR_KEYWORD, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_KEYWORD, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_SUGGEST_KEYWORDS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}keywords/suggests`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SUGGEST_KEYWORDS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_KEYWORD, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_CHART_KEYWORD](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}keywords/chartRank`, config)
        .then(({ data }) => {
          let updateChartKeywords;
          if (this.chartKeywords.some(el => el['id'] === data.data.id)) {
            updateChartKeywords = this.chartKeywords.map((obj) => {
              // @ts-ignore
              if (obj.id === data.data.id) {
                return data.data;
              }
              return obj;
            });
          } else {
            if (params.isMain) {
              updateChartKeywords = [...this.chartKeywords, data.data];
            } else {
              updateChartKeywords = [...this.chartKeywords];
            }

          }
          this.context.commit(Mutations.SET_GRAPHIC_KEYWORDS, updateChartKeywords);
          this.context.commit(Mutations.SET_ERROR_KEYWORD, {});
          this.context.commit(Mutations.SET_GRAPHIC_KEYWORD, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_KEYWORD, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_CHART_KEYWORDS_MANY](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}keywords/chartRanksMany`, config)
        .then(({ data }) => {

          this.context.commit(Mutations.SET_GRAPHIC_KEYWORDS, data.data);
          this.context.commit(Mutations.SET_ERROR_KEYWORD, {});
          this.context.commit(Mutations.SET_GRAPHIC_KEYWORD, data.data);
          this.context.commit(Mutations.SET_PROMOTE_KEYWORDS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_KEYWORD, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_TOP_APPS_BY_KEYWORD](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}keywords/topKeyword`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TOP_APPS_BY_KEYWORD, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_KEYWORD, response?.data?.errors);
        });
    }


    @Action
    [Actions.GET_RANK_KEYWORD](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}keywords/keywordRank`, config)
        .then(({ data }) => {
          const updateKeywords = this.keywords.map((obj) => {
            if (data.data.keyword === this.lastAddedChartKeyword) {
              this.context.commit(Mutations.SET_LAST_ADDED_KEYWORD_NAME, '');
            }
            // @ts-ignore
            if (obj.id === data.data.id) {
              return data.data;
            }
            return obj;
          });
          this.context.commit(Mutations.SET_KEYWORDS, updateKeywords);
          this.context.commit(Mutations.SET_RANK_KEYWORD, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_KEYWORD, response?.data?.errors);
        });
    }

    @Action
    [Actions.UPDATE_ALL_RANKS](params) {
      return ApiService.post(`${domainUrl}keywords/updateAllRanks`, params)
        .then(({ data }) => {
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_KEYWORD, response?.data?.errors);
        });
    }

}
