import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { AxiosRequestConfig } from 'axios';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { domainUrl } from '@/core/config/DomainConfig';
import { Getters } from '@/store/enums/GettersEnums';

@Module({ name: 'PriceplansModule' })
export default class PriceplansModule extends VuexModule implements StoreModel {

  errors = {};
  priceplans = [];
  currentPriceplan = {};
  totalPayments = {};
  allPayments = {};

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ERRORS_PRICEPLANS]() {
    return this.errors;
  }

  /**
     * Get current Transaction object
     * @returns array
     */
  get [Getters.GET_ALL_PRICEPLANS]() {
    return this.priceplans;
  }

  /**
     * Get current Transaction object
     * @returns object
     */
  get [Getters.GET_PRICEPLAN]() {
    return this.currentPriceplan;
  }

  /**
     * Get current Transaction object
     * @returns object
     */
  get [Getters.GET_ALL_PAYMENTS]() {
    return this.totalPayments;
  }

  /**
     * Get current Transaction object
     * @returns object
     */
  get [Getters.GET_LIST_PAYMENTS]() {
    return this.allPayments;
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.errors = {};
    this.priceplans = [];
    this.currentPriceplan = {};
    this.totalPayments = {};
    this.allPayments = {};
  };

    @Mutation
    [Mutations.SET_ERROR_PRICEPLANS](error) {
      this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_ALL_PRICEPLANS](payload) {
      this.priceplans = payload;
    }

    @Mutation
    [Mutations.SET_CURRENT_PRICEPLAN](payload) {
      this.currentPriceplan = payload;
    }

    @Mutation
    [Mutations.SET_TOTAL_PAYMENTS](payload) {
      this.totalPayments = payload;
    }

    @Mutation
    [Mutations.SET_LIST_PAYMENTS](payload) {
      this.allPayments = payload;
    }

    @Action
    [Actions.GET_PRICEPLANS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}priceplans`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ALL_PRICEPLANS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_PRICEPLANS, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_CURRENT_PRICEPLAN](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}priceplans/current`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CURRENT_PRICEPLAN, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_PRICEPLANS, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_PAYMENTS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}payments`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LIST_PAYMENTS, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_PRICEPLANS, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_TOTAL_PAYMENTS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}payments/total`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TOTAL_PAYMENTS, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_PRICEPLANS, response?.data?.errors);
        });
    }
}
