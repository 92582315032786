import role from '@/core/plugins/lang/en-US/role';
import functions from '@/core/plugins/lang/en-US/function';
import module from '@/core/plugins/lang/en-US/module';
import select from '@/core/plugins/lang/en-US/select';
import application from '@/core/plugins/lang/en-US/application';
import apps from '@/core/plugins/lang/en-US/apps';
import keywords from '@/core/plugins/lang/en-US/keywords';
import addCampaigns from '@/core/plugins/lang/en-US/addCampaigns';
import myTransactions from '@/core/plugins/lang/en-US/myTransactions';
import priceplans from '@/core/plugins/lang/en-US/priceplans';
import myProfile from '@/core/plugins/lang/en-US/myProfile';
import topUpBalance from '@/core/plugins/lang/en-US/topUpBalance';
import register from '@/core/plugins/lang/en-US/register';
import myCampaigns from '@/core/plugins/lang/en-US/myCampaigns';
import bonusProgram from '@/core/plugins/lang/en-US/bonusProgram';
import referralProgram from '@/core/plugins/lang/en-US/referralProgram';
import resellersProgram from '@/core/plugins/lang/en-US/resellersProgram';
import statistics from '@/core/plugins/lang/en-US/statistics';
import dashboard from '@/core/plugins/lang/en-US/dashboard';
import accessLists from '@/core/plugins/lang/en-US/accessLists';
import userLists from '@/core/plugins/lang/en-US/userLists';
import userDetails from '@/core/plugins/lang/en-US/userDetails';
import reflinks from '@/core/plugins/lang/en-US/reflinks';
import contacts from '@/core/plugins/lang/en-US/contacts';
import adminReseller from '@/core/plugins/lang/en-US/adminReseller';
import userOrder from '@/core/plugins/lang/en-US/userOrder';
import CoreSetting from '@/core/plugins/lang/en-US/coreSetting';
import vendor from '@/core/plugins/lang/en-US/vendor';
import userPayment from '@/core/plugins/lang/en-US/userPayment';
import sendManagerRequest from '@/core/plugins/lang/en-US/sendManagerRequest';
import userApps from '@/core/plugins/lang/en-US/userApps';
import apiSupport from '@/core/plugins/lang/en-US/apiSupport';
import validation from '@/core/plugins/lang/en-US/validation';
import salesReport from '@/core/plugins/lang/en-US/salesReport';
import advertisingCampaigns from '@/core/plugins/lang/en-US/advertisingCampaigns';
import marketingActions from '@/core/plugins/lang/en-US/marketingActions';
import clientReport from '@/core/plugins/lang/en-US/clientReport';
import partnersProgram from '@/core/plugins/lang/en-US/partnerProgram';
import googleAds from '@/core/plugins/lang/en-US/googleAds';

const index = {
  dashboard: 'Dashboard',
  keywords: 'Keywords',
  home: 'Home',
  layoutBuilder: 'Layout builder',
  craft: 'Crafted',
  pages: 'Pages',
  profile: 'Profile',
  profileOverview: 'Overview',
  adminRole: 'Role',
  settingsRoles: 'Roles',
  adminFunctions: 'Functions',
  adminModules: 'Modules',
  adminAccessLists: 'Access Lists',
  adminUserLists: 'User Lists',
  adminUserPayments: 'User Payments',
  adminReflinks: 'Reflinks',
  adminGoogleAdsReport: 'Ads Report',
  adminSalesReport: 'Sales Report',
  adminClientsReport: 'Clients Report',
  adminMarketingActions: 'Marketing Actions',
  adminVendor: 'Vendors',
  logViewer: 'Log Viewer',
  horizon: 'Horizon',
  pulse: 'Pulse',
  adminUserApps: 'User Apps',
  adminCoreSettings: 'Core Settings',
  adminUserOrders: 'User Campaigns',
  projects: 'Projects',
  appstoreTemporaryDisabled: 'Appstore is temporary disabled',
  campaigns: 'Campaigns',
  documents: 'Documents',
  connections: 'Connections',
  productTemporaryDisabled: 'Product is temporary disabled',
  allProductsTemporaryDisabled: 'All products is temporary disabled',
  wizards: 'Wizards',
  horizontal: 'Horizontal',
  vertical: 'Vertical',
  account: 'Account',
  accountOverview: 'Overview',
  administrative: 'Administrative',
  permissions: 'Permissions',
  devTools: 'Dev tools',
  authentication: 'Authentication',
  basicFlow: 'Basic Flow',
  signIn: 'Sign-in',
  signUp: 'Sign-up',
  close: 'Close',
  open: 'Open',
  passwordReset: 'Password Reset',
  multiStepSignUp: 'Multi-steps Sign up',
  error404: 'Error 404',
  error500: 'Error 500',
  app: 'App',
  appsKeywords: 'Apps&Keywords',
  myApps: 'My apps',
  myKeywords: 'My keywords',
  services: 'Services',
  myCampaigns: 'My campaigns',
  addCampaign: 'Add campaign',
  addCPICampaign: 'Add CPI campaign',
  addSmartCampaign: 'Add SMART campaign',
  addHighQualityReviews: 'Add HIgh Quality Reviews',
  finance: 'Finance',
  topUpBalance: 'Top-up balance',
  myTransactions: 'My transactions',
  other: 'Other',
  referralProgram: 'Referral program',
  bonusProgram: 'Bonus Program',
  partnersProgram: 'Partners Program',
  APISupport: 'API support',
  faq: 'FAQ',
  blog: 'Blog',
  statistics: 'Statistics',
  apps: 'Apps',
  chat: 'Chat',
  privateChat: 'Private Chat',
  groupChat: 'Group Chat',
  drawerChat: 'Drawer Chat',
  widgets: 'Widgets',
  widgetsLists: 'Lists',
  priceplans: 'Priceplans',
  widgetsCharts: 'Charts',
  widgetsMixed: 'Mixed',
  widgetsTables: 'Tables',
  widgetsFeeds: 'Feeds',
  changelog: 'Changelog',
  docsAndComponents: 'Docs & Components',
  megaMenu: 'Mega Menu',
  exampleLink: 'Example link',
  modals: 'Modals',
  general: 'General',
  inviteFriends: 'Invite Friends',
  viewUsers: 'View Users',
  upgradePlan: 'Upgrade Plan',
  shareAndEarn: 'Share & Earn',
  forms: 'Forms',
  newTarget: 'New Target',
  newCard: 'New Card',
  newAddress: 'New Address',
  createAPIKey: 'Create API Key',
  twoFactorAuth: 'Two Factor Auth',
  createApp: 'Create App',
  createAccount: 'Create new account',
  documentation: 'Documentation',
  components: 'Components',
  resources: 'Resources',
  activity: 'Activity',
  customers: 'Customers',
  gettingStarted: 'Getting Started',
  customersListing: 'Customers Listing',
  customerDetails: 'Customers Details',
  calendarApp: 'Calendar',
  subscriptions: 'Subscriptions',
  getStarted: 'Getting Started',
  subscriptionList: 'Subscription List',
  addSubscription: 'Add Subscription',
  viewSubscription: 'View Subscription',
  myProfile: 'My Profile',
  language: 'Language',
  english: 'English',
  spanish: 'Spanish',
  russia: 'Russia',
  russian: 'Russian',
  ukraine: 'Ukraine',
  ukrainian: 'Ukrainian',
  french: 'French',
  italian: 'Italian',
  deutsch: 'Deutsch',
  portuguese: 'Portuguese',
  chinese: 'Chinese',
  translationInProgress: 'Translation in progress',
  accountSettings: 'Account settings',
  signOut: 'Sign out',
  forgotPassword: 'Forgot password?',
  enterEmailResetPassword: 'Enter your email below to reset your password.',
  email: 'Email',
  submit: 'Submit',
  save: 'Save',
  add: 'Add new',
  password: 'Password',
  cancel: 'Cancel',
  continue: 'Continue',
  pleaseWait: 'Please wait...',
  OK: 'OK',
  swalFireText: 'You have successfully logged in!',
  swalConfirmButtonText: 'OK',
  swalFormSuccessfully: 'Form has been successfully submitted!',
  swalTryAgain: 'Try again!',
  swalSorryLooksTryAgain: 'Sorry, looks like there are some errors detected, please try again.',
  swalSuccessfullyLogin: 'You have successfully logged in!',
  swalSuccessfullyRegister: 'You have successfully registered account',
  continueApple: 'Continue with Apple',
  continueFacebook: 'Continue with Facebook',
  continueGoogle: 'Continue with Google',
  sigInApple: ' Sign in with Apple',
  sigInFacebook: 'Sign in with Facebook',
  sigInGoogle: 'Sign in with Google',
  or: 'or login with email',
  orSignUp: 'o ingresa tus datos',
  newHere: 'New here?',
  singKeyapp: 'Login to your account',
  alreadyHaveAccount: 'Already have an account?',
  lastName: 'Last Name',
  name: 'Name',
  surname: 'Surname',
  passwordConfirmation: 'Password Confirmation',
  firstName: 'First Name',
  userName: 'Username',
  singInHere: 'Sign in here',
  eightMoreCharacters: 'Use 8 or more characters with a mix of letters, numbers & symbols.',
  confirmPassword: 'Confirm Password',
  termsAndConditions: 'Terms and conditions',
  iAgree: 'I Agree &',
  seemsNothingHere: 'Seems there is nothing here',
  returnHome: 'Return Home',
  goToHomepage: 'Go to homepage',
  systemError: 'System Error',
  somethingWentWrong: 'Something went wrong! <br />\n Please try again later.',
  paginationText: 'Showing { from } to { to } of { total } entries',
  loading: 'Loading...',
  checking: 'checking',
  delete: 'Delete',
  reset: 'Reset',
  edit: 'Edit',
  error: 'error',
  out: 'out',
  copiedToClipboard: 'copied to clipboard',
  resellersProgram: 'Resellers Program',
  allCountries: 'All countries',
  allPriceplans: 'All priceplans',
  appstore: 'Appstore',
  store: 'Store',
  pickTargetCountry: 'Pick target country',
  chooseAppForPromotionOr: 'Choose app for promotion or ',
  addNew: 'add new',
  mainBalance: 'Main balance',
  bonusBalance: 'Bonus balance',
  myPriceplan: 'My Priceplan',
  yourPersonalManager: 'Your personal manager',
  iNeedPersonalManager: 'I need a personal manager',
  personalManager: 'Personal manager',
  requestForPersonalManagerAssignmentIsSent: 'Request for personal manager assignment is sent. Your new personal manager will contact you soon.',
  pleaseFillYourAdvanceProfile: 'Please fill your Advance profile and leave any contact details for better communication.',
  tryAgainLater: 'Try again later',
  personalManagerAssignmentInProgress: 'Personal manager assignment in progress',
  ourTeamIsWorking: 'Our team is working diligently to select a qualified and dedicated professional who will be responsible for addressing your needs and providing personalized support.',
  forAllYourInquiriesAndCorrespondence: 'For all your inquiries and correspondence, kindly direct your emails to the following address:',
  weAppreciateYourCommunication: 'We appreciate your communication and will respond to your message as promptly as possible. Thank you for reaching out!',
  newMessageToMyKeyappTopManager: 'New message to my manager keyapp.top',
  newMessageToKeyappTopSupport: 'New support message keyapp.top',
  supportTeam: 'Support team',
  yearsAgo: 'years ago',
  monthsAgo: 'months ago',
  weeksAgo: 'weeks ago',
  daysAgo: 'days ago',
  hoursAgo: 'hours ago',
  minutesAgo: 'minutes ago',
  contacts: 'Contacts',
  noNewMessagesFromManagers: 'No new messages from managers',
  appleAppstoreTooltip: 'Apple Appstore',
  googlePlayStoreTooltip: 'Google Play Store',
  showUserDetails: 'Show User Details',
  loginToUserAccount: 'Login to user’s account',
  currentAccount: 'Current account',
  myBalance: 'My balance',
  legacyAccount: 'Legacy account',
  legacyBalance: 'Legacy balance',
  legacyWithdrawal: 'Legacy withdrawal',
  legacyDashboard: 'Legacy dashboard',
  month01: 'january',
  month02: 'february',
  month03: 'march',
  month04: 'april',
  month05: 'may',
  month06: 'june',
  month07: 'july',
  month08: 'august',
  month09: 'september',
  month10: 'october',
  month11: 'november',
  month12: 'december',
  noOptionsFound: 'No options found',
  supportEmail: 'Support email',
  productIsInDevelopment: 'Product is in development',
  yes: 'Yes',
  no: 'No',
  dataIsInvalid: 'Data is not valid',
  fiveRates: '5* positive rates (100%)',
  fourRates: '5* + 4* positive rates (80% + 20%)',
  confirm: 'Confirm',
  country: 'Country',
  countries: 'Countries',
  cpi: 'CPI',
  impressions: 'Impressions',
  clicks: 'Clicks',
  installs: 'Installs',
  cost: 'Cost',
  payments: 'Payments',
  validationErrors: validation,
  role: role,
  functions: functions,
  module: module,
  select: select,
  application: application,
  _apps: apps,
  keywordsPage: keywords,
  addCampaigns: addCampaigns,
  myTransactionsPage: myTransactions,
  priceplansPage: priceplans,
  myProfilePage: myProfile,
  topUpBalancePage: topUpBalance,
  register: register,
  myCampaignsPage: myCampaigns,
  bonusProgramPage: bonusProgram,
  partnersProgramPage: partnersProgram,
  referralProgramPage: referralProgram,
  resellersProgramPage: resellersProgram,
  statisticsPage: statistics,
  dashboardPage: dashboard,
  accessListPage: accessLists,
  userListPage: userLists,
  userDetailsPage: userDetails,
  reflinksPage: reflinks,
  contactsPage: contacts,
  adminResellerPage: adminReseller,
  userOrderPage: userOrder,
  coreSettingPage: CoreSetting,
  vendorPage: vendor,
  userPaymentPage: userPayment,
  sendManagerRequestModal: sendManagerRequest,
  userAppsPage: userApps,
  apiSupportPage: apiSupport,
  salesReportPage: salesReport,
  advertisingCampaignsPage: advertisingCampaigns,
  marketingActionsPage: marketingActions,
  clientReportPage: clientReport,
  googleAdsPage: googleAds,
};
export default index;
