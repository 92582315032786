import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { AxiosRequestConfig } from 'axios';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { domainUrl } from '@/core/config/DomainConfig';
import { Getters } from '@/store/enums/GettersEnums';

@Module({ name: 'StatisticsModule' })
export default class StatisticsModule extends VuexModule implements StoreModel {
  errors = {};
  products = [];
  keywords = [];
  campaigns = [];
  productsExport = [];
  keywordsExport = [];
  campaignsExport = [];
  productsQueryParams = {};
  keywordsQueryParams = {};
  campaignsQueryParams = {};

  get [Getters.GET_STATISTICS_ERRORS]() {
    return this.errors;
  }

  get [Getters.GET_ALL_PRODUCTS]() {
    return this.products;
  }

  get [Getters.GET_ALL_KEYWORDS]() {
    return this.keywords;
  }

  get [Getters.GET_ALL_CAMPAIGNS]() {
    return this.campaigns;
  }

  get [Getters.GET_ALL_PRODUCTS_EXPORT]() {
    return this.productsExport;
  }

  get [Getters.GET_ALL_KEYWORDS_EXPORT]() {
    return this.keywordsExport;
  }

  get [Getters.GET_ALL_CAMPAIGNS_EXPORT]() {
    return this.campaignsExport;
  }

  get [Getters.GET_ALL_PRODUCTS_QUERY_PARAMS]() {
    return this.productsQueryParams;
  }

  get [Getters.GET_ALL_KEYWORDS_QUERY_PARAMS]() {
    return this.keywordsQueryParams;
  }

  get [Getters.GET_ALL_CAMPAIGNS_QUERY_PARAMS]() {
    return this.campaignsQueryParams;
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.errors = {};
    this.products = [];
    this.keywords = [];
    this.campaigns = [];
    this.productsExport = [];
    this.keywordsExport = [];
    this.campaignsExport = [];
    this.productsQueryParams = {};
    this.keywordsQueryParams = {};
    this.campaignsQueryParams = {};
  };

    @Mutation
    [Mutations.SET_ERROR_STATISTICS](error) {
      this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_STATISTICS_PRODUCTS](payload) {
      this.products = payload;
    }

    @Mutation
    [Mutations.SET_STATISTICS_KEYWORDS](payload) {
      this.keywords = payload;
    }

    @Mutation
    [Mutations.SET_STATISTICS_CAMPAIGNS](payload) {
      this.campaigns = payload;
    }

    @Mutation
    [Mutations.SET_STATISTICS_PRODUCTS_EXPORT](payload) {
      this.productsExport = payload;
    }

    @Mutation
    [Mutations.SET_STATISTICS_KEYWORDS_EXPORT](payload) {
      this.keywordsExport = payload;
    }

    @Mutation
    [Mutations.SET_STATISTICS_CAMPAIGNS_EXPORT](payload) {
      this.campaignsExport = payload;
    }

    @Mutation
    [Mutations.SET_STATISTICS_PRODUCTS_QUERY_PARAMS](payload) {
      this.productsQueryParams = payload;
    }

    @Mutation
    [Mutations.SET_STATISTICS_KEYWORDS_QUERY_PARAMS](payload) {
      this.keywordsQueryParams = payload;
    }

    @Mutation
    [Mutations.SET_STATISTICS_CAMPAIGNS_QUERY_PARAMS](payload) {
      this.campaignsQueryParams = payload;
    }

    @Action
    [Actions.GET_STATISTICS_PRODUCTS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}statistics/products`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_STATISTICS_PRODUCTS, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_STATISTICS, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_STATISTICS_KEYWORDS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}statistics/keywords`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_STATISTICS_KEYWORDS, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_STATISTICS, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_STATISTICS_CAMPAIGNS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}statistics/campaigns`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_STATISTICS_CAMPAIGNS, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_STATISTICS, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_STATISTICS_PRODUCTS_EXPORT](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}statistics/products`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_STATISTICS_PRODUCTS_EXPORT, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_STATISTICS, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_STATISTICS_KEYWORDS_EXPORT](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}statistics/keywords`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_STATISTICS_KEYWORDS_EXPORT, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_STATISTICS, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_STATISTICS_CAMPAIGNS_EXPORT](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}statistics/campaigns`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_STATISTICS_CAMPAIGNS_EXPORT, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_STATISTICS, response?.data?.errors);
        });
    }
}
