const addCampaigns = {
  products: 'Products',
  services: 'Services',
  pickAvaialbleCampaignTypes: 'Pick one of available campaign types',
  notes: 'Notes',
  pleaseDontChangeYourAppsIcon: "Please, don't change your app's icon, name or publisher name while running a campaign. Your app will be searched by using keywords and the only way to find it is by looking at the icon and comparing application name as well as all the changes influence the app ranks.",
  whatIsAdvertisingCpiCampign: 'What is Advertising CPI Campign?',
  cpi: 'CPI (cost-per-install) campaign - is a tool used to attract users to your app. These users are not motivated with gems or coins as those who install your app when you order keyword installs. These users will install your app only if they like it. That means you will attract people who generate in app activity and income for you.',
  keyword: 'Keyword',
  install: 'install',
  direct: 'Direct',
  installs: 'Installs',
  guaranteed: 'Guaranteed',
  reviews: 'Reviews',
  smart: 'Smart',
  campaign: 'Campaign',
  addCampaigns: 'Add Campaigns',
  addCampaign: 'Add campaign',
  advertising: 'Advertising',
  summary: 'Summary',
  product: 'Product',
  keywordInstalls: 'Keyword installs',
  installsByKeywordsForYourApplication: 'Installs by keywords for your application in selected region',
  installsByDirectLink: 'Installs by the direct application link in selected region',
  reviewsForYourApplication: 'Reviews for your application in selected region with 30-day guarantee for refill & refund',
  installsGeneratedByAppsYourApplication: 'Installs, generated by Ads for your application in selected regions',
  cpiShort: 'CPI (cost-per-install) campaign - is a tool used to attract users to your app.',
  advertisingCpiCampaign: 'Advertising CPI Campaign',
  application: 'Application',
  targetCountry: 'Target country',
  daysInCampaign: 'Days in campaign',
  finishAt: 'Finish on',
  campaignOrders: 'Campaign orders',
  days: 'days',
  directInstalls: 'Direct installs',
  rates: 'Rates',
  total: 'Total',
  day: 'Day',
  fixedPartner: 'Fixed partner',
  description: 'Description',
  saveDraft: 'Save Draft',
  draft: 'Draft',
  delay: 'Delay',
  startCampaign: 'Start Campaign',
  startNow: 'Start Now',
  iAgreeTo: 'I agree to',
  terms: 'terms',
  ofUse: 'of use',
  textSuccessfully: 'Campaign was created successfully',
  textSuccessfullyDraft: 'New draft campaign #{id} was created',
  textErrorCountReviews: 'Minimum installs  should be {minInstalls} * {days}. Please add more reviews.',
  refundPolicyForRatesAndReviews: 'Refund policy for Rates & Reviews',
  almostAllRatesAndReviewsAreGettingDeleted: 'Warning! Almost all rates and reviews ordered in this section are getting deleted by any appstore, we will NOT refund money for these rates and reviews, and NOT refill them. Use them at your own risk!',
  allRatesAndReviewsWillAppearOnMarket: "All rates and reviews can appear on your app's market page after store moderation if it was successful in 1-3 days or may not appear if they do not go through store moderation. In 1-2 days most of (successfully published after moderation) rates and reviews ordered in this section will be removed and it highly depends on the kind of your app, its organic traffic and usual daily rates increase, so by continuing you agree to take this risk.",
  ifYouNeedReviewsAndRates: 'If you need reviews and rates to stay on the app page, as well as get them with the guarantee of money back or reposting during 30 days after the order is completed, please go to Add Guranteed Reviews campaign.',
  evenIfItIsOkay: "Even if it is okay for you that rates and reviews ordered in this section are deleted, do not order the tons of them. Rates behavior should look like real user flow, proportionally to your app's organic traffic in the selected country and similar to your current number of rates.",
  iveReadAndAreeToThe: "I've read and agree to the",
  chargebackAndRefundPolicy: 'Chargeback and Refund Policy',
  deleteAllKeywords: 'Delete all keywords',
  minimumOrderInstallsIs: 'Minimum order installs is',
  keywordInstall: {
    keywordInstallAddKeywordsBody: {
      keywords: 'Keywords',
      advancedMode: 'Advanced mode',
      dayOne: 'Day 1',
      addKeyword: 'Add keyword'
    },
    keywordInstallKeywordsTable: {
      noKeywordsFound: 'No keywords found',
      selectKeyword: 'Select keyword',
      addKeywordInstalls: 'Add Keyword installs',
      installsDistribution: 'Installs distribution',
      increase: 'Increase',
      parabolic: 'Parabolic',
      equal: 'Equal',
      days: 'Days'
    },
    directInstallTable: {
      addDirectInstalls: 'Add Direct installs',
      days: 'Days',
      packageOrDirectInstalls: 'Package or Direct installs',
      delete: 'Delete'
    },
    ratesAndReviewsKeywordInstallBody: {
      ratesAndReviews: 'Rates and reviews',
      addRatesAndReviewsToCampaign: 'Add rates and reviews to campaign',
      days: 'Days',
      rates: 'Rates',
      delete: 'Delete',
      reviews: 'Reviews',
      importReviews: 'Import reviews',
      errorCheckedTerms: 'You must agree to terms of use before ordering rates or reviews.'
    },
    promotionRuleKeywordInstallBody: {
      promotionRule: 'Promotion rule',
      dontPromoteKeywordsWhenTheyReachRank: "Don't promote keywords when they reach rank",
      installsDeliveryType: 'Installs delivery type',
      spreadInstallsForTwentyFourHours: 'Spread installs for 24h',
      allInstallsAtOnce: 'All installs at once',
      deeplinkTitle: 'Deeplink',
      deeplink: "Deep link is executed after app's installation for example run?source=keyapp, ",
      deeplinkIos: 'read more in our blog'

    },
    addMyReviewsModal: {
      pleaseWait: 'Processing, please wait...',
      addMyReviews: 'Add my Reviews',
      added: 'Added',
      newReview: 'Add new review',
      addManyRandomReview: 'Add 5 random review',
      deleteAll: 'Delete all',
      saveReview: 'Save review',
      deleteReview: 'Delete review',
      editReview: 'Edit review',
      reviews: 'reviews',
      youHaveNoAddedReviewsYet: 'You have no added reviews yet',
      add: 'Add',
      errorLength: 'Minimum {min} reviews required.',
      titleText: 'Add new review title',
      bodyText: 'Add new review text'
    },
    descriptionKeywordInstallBody: {
      description: 'Description',
      leaveAnyNotesForYourcampaign: 'Leave any notes for your campaign (only in English, 1000 symbols maximum)'
    }
  },
  guaranteedReviews: {
    reviewGuaranteedReviewsBody: {
      mustBeAtLeast: 'Must be at least',
      guaranteedReviews: 'Guaranteed reviews',
      addGuaranreedReviewsToYourApplication: 'Add guaranreed reviews to your application',
      myCustom: 'My custom',
      reviews: 'reviews',
      writeReviews: 'Write reviews',
      forMe: 'for me',
      improveYourRatesAndAppsReputation: "Improve your rates and apps' reputation with our reviews. Get 30 days guarantee for any order.",
      campaignDetails: 'Campaign details',
      days: 'Days',
      _reviews: 'Reviews',
      importReviews: 'Import reviews',
      delete: 'Delete',
      authorGender: 'Author gender',
      male: 'Male',
      female: 'Female',
      both: 'Both',
      pickPreferredGenderOfAccount: 'Pick a preferred gender of account that will write a review',
      reviewLanguage: 'Review language',
      pickPreferredLanguageForReviews: 'Pick a preferred language for reviews, or default language of a chosen country will be used',
      description: 'Description',
      leaveAnyNotesForYourOrder: 'Leave any notes for your order (only in English, 1000 symbols), e.g. preferred keywords, phrases, etc.',
      notes: 'Notes',
      thisIsGuaranteedReviewsOrder: 'This is a guaranteed reviews order, meaning that if you purchase 100 reviews, we ensure that at least 90 of them will remain on your app page for a minimum of 30 days. If any reviews are removed or not moderated by the store, we will replace them. Should you notice any missing reviews, feel free to reach out to our team for a refill.'
    }
  },
  startModalCpi: {
    customer: 'Customer'
  },
  advertisingCampaign: {
    advertisingCampaignTab: {
      back: 'Back',
      startCampaign: 'Start Campaign',
      pleaseWait: 'Please wait...',
      next: 'Next',
    },
    advertisingCampaignStep1: {
      addTargetCountriesForAppPromotion: 'Add target countries for app promotion',
      usuallyWeRecommendToStartCpiAampaignsInSuchCases: 'Usually we recommend to start CPI campaigns in such cases',
      youDontHave: "You don't have",
      organicTrafficOnYourApp: 'organic traffic on your app but you want to start keyword promotion campaigns. When you order keyword installs and CPI installs at the same time, it increases your app keywords ranks much faster',
      youHave: 'You have',
      newAppAndYouWantToGetKeywordsIndexationFaster: 'a new app and you want to get keywords indexation faster',
      newAppAndYouWantToGetYourMainPageInstallsNumberHigher: 'a new app and you want to get your main page installs number higher',
      toGetBetterCategoryRankings: 'to get better category rankings',
      youEarnMore: 'You earn more',
      fromEachInstallThenYouPayForIt: 'from each install then you pay for it',
      toAttractUsersFromSpecifiedRegion: 'to attract users from specified region',
      cryptocurrenciesBinaryOptionsPersonalLoansAppsAreForbidden: 'Cryptocurrencies, Binary options, Personal loans and similar apps are forbidden',
      youWant: 'You want',
      pleaseNote: 'Please note'
    },
    advertisingCampaignStep2: {
      selectPromotionCountries: 'Select promotion countries',
      addTargetCountriesForAppPromotion: 'Add target countries for app promotion',
      filterByCpi: 'Filter by CPI',
      filterByGeo: 'Filter by GEO',
      filterByLanguage: 'Filter by language',
      highCpi: 'High CPI',
      mediumCpi: 'Medium CPI',
      lowCpi: 'Low CPI',
      europe: 'Europe',
      asia: 'Asia',
      africa: 'Africa',
      latinAmerica: 'Latin America',
      worldwide: 'Worldwide',
      english: 'English',
      spanish: 'Spanish',
      russian: 'Russian',
      targetedCountries: 'Targeted countries'
    },
    advertisingCampaignStep3: {
      campaignBudget: 'Campaign Budget',
      defineDailyBudget: 'Define daily budget, total days and target CPI',
      dailyBudget: 'Daily budget',
      targetCpi: 'Target CPI',
      daysInCampaign: 'Days in campaign',
      unlimited: 'Unlimited',
      days: 'days',
      daysInCampaignMustBeAtLeast: 'Days in campaign must be at least',
    },
    advertisingCampaignStep4: {
      headlines: 'Headlines',
      addHeadlinesAndDescriptionsForAds: 'Add headlines and descriptions for Ads',
      headlineOne: 'Headline #1',
      weUseHeadlinesAndDescriptionsToAdvertiseYourApp: 'We use headlines and descriptions to advertise your app. Attractive headlines and descriptions can help you to get better CTR (more clicks from views), so your average CPI will get lower.',
      generate: 'Generate',
      headlineTwo: 'Headline #2',
      misleadingDescriptions: 'Misleading descriptions can increase CTR but users will not install your app when they check your app page. So you will get low conversion rate and your CPI will increase.',
      description: 'Description',
      checkThatYourTextMakesSenseForUsers: 'Check that your text makes sense for users. It is important because our partners from advertising networks will apply approval process to your ads. If you break some rules your ads want pass approving process.',
      headlineOneIsNotSet: 'Headline #1 is not set.',
      headlineOneIsTooShort: 'Headline #1 is too short. Minimum length is:',
      headlineOneIsTooLong: 'Headline #1 is too long. Maximum length is:',
      headlineTwoIsNotSet: 'Headline #2 is not set.',
      headlineTwoIsTooShort: 'Headline #2 is too short. Minimum length is:',
      headlineTwoIsTooLong: 'Headline #2 is too long. Maximum length is:',
      descriptionIsNotSet: 'Description is not set.',
      descriptionIsTooShort: 'Description is too short. Minimum length is:',
      descriptionIsTooLong: 'Description is too long. Maximum length is:',
      headlinesAndDescriptionsShouldBeUnique: 'Headlines and descriptions should be unique.',
    },
    advertisingCampaignStep5: {
      finish: 'Finish!',
      summary: 'Summary',
      confirmCampaignDetails: 'Confirm campaign details',
      application: 'Application',
      nameOfApplication: 'Name of application...',
      developer: 'Developer',
      countries: 'Countries',
      duration: 'Duration',
      targetCpi: 'Target CPI',
      dailyBudget: 'Daily budget',
      startCharge: 'Start charge',
      toStartThisCampaign: 'To launch this campaign, we must first manually verify it. Upon starting, you will be charged <strong>{amount} $</strong>. This amount, which is actually double the daily budget, is required as a prepayment for future campaign expenditures.',
      weWillChargeYourAccount: 'We will charge your account every day for the amount you spent on promotion during previous day. If you do not have enough funds on your balanceto cover previous day spendings we will use your Start charge and pause campaign until you top-up your balance and manually unpause campaign.',
      refund: 'Refund',
      youMayCancelYourCampaign: 'You may cancel your campaign at any time, but we will make a refund only after 24h after cancelation to stop campaign in all ad networks and get actual campaign spendings. Same rule applies to completing campaigns. Refund amount is calculated as a difference between campaign price and campaign spendings.',
      dailyCharge: 'Daily charge'
    },
    advertisingCampaignAside: {
      letsStart: 'Let’s start!',
      createNewCpiCampaign: 'create new CPI campaign',
      budget: 'Budget',
      adjustBudget: 'adjust budget, CPI and duration',
      regions: 'Regions',
      selectTargetedCountries: 'select targeted countries',
      headlines: 'Headlines',
      inputTextForAds: 'input text for Ads',
      finish: 'Finish',
      startYourNewCampaign: 'start your new campaign',
      selectCountries: 'Select countries',
      detailsOfStepOneHere: 'details of step 1 here',
      stepTwo: 'Step 2',
      detailsOfStepTwoHere: 'details of step 2 here',
      stepThree: 'Step 3',
      detailsOfStepThreeHere: 'details of step 3 here',
      stepFour: 'Step 4',
      detailsOfStepFourHere: 'details of step 4 here',
      stepFive: 'Step 5',
      detailsOfStepFiveHere: 'details of step 5 here'
    }
  },
  delayedModal: {
    delayedCampaign: 'Delayed Campaign',
    selectTheDate: 'Select the date when you want to start your campaign',
    yourAccountWillBeDebited: 'Your account will be debited in {date}',
    date: 'Date',
    time: 'Time',
    dateOrTimeIsEmpty: 'Date or time is empty',
    pleaseChangeDateOrTime: 'Please enter date and time more than 5 minutes later',
  }
};

export default addCampaigns;
