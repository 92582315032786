const module = {
  over: 'Over {total} members',
  clickAdd: 'Click to add a Module',
  newModule: 'New Module',
  name: 'Name',
  actions: 'Actions',
  check: 'Check',
  nameModule: 'Name Module',
  namespace: 'Namespace',
  enterName: 'Enter name',
  enterPermissionName: 'Enter Permission Name',
  permissionName: 'Permission Name',
  enterNamespace: 'Enter Namespace',
  inputName: 'Please input name',
  inputNamespace: 'Please input Namespace',
  inputPermissionName: 'Please input Permission Name',
  created: 'Create Module',
  updated: 'Update Module',
  specifyName: 'Specify a target name for future usage and reference',
  specifyNamespace: 'Specify a target Namespace for future usage and reference',
  specifyPermissionName: 'Specify a target Permission Name for future usage and reference',

};
export default module;
