const contacts = {
  contactUs: 'Связаться с нами',
  dearUserIfYouHaveAnyQuestions: 'Дорогой пользователь! если у вас есть вопросы, вы можете связаться с вашим персональным менеджером, используя форму ниже или любой мессенджер. Вы также можете воспользоваться нашим {linkFaq}, там есть ответы на большинство вопросов.',
  howCanWeHelpYou: 'Как мы можем вам помочь?',
  subject: 'Предмет',
  enterTheSubject: 'Введите тему',
  message: 'Сообщение',
  pleaseProvideYourSupplies: 'Пожалуйста, предоставьте свои поставки построчно',
  weWillAnswerYou: 'Мы ответим вам как можно быстрее. Уверяем вас, вам не придется долго ждать.',
  sendMessage: 'Отправить сообщение',
  youCanAlsoContactOurSupportTeam: 'Вы также можете связаться с нашей службой поддержки:'
};

export default contacts;
