import { createStore } from 'vuex';
import { config } from 'vuex-module-decorators';
import {
  AccessListModule,
  AddCampaignsModule,
  AdminEmailTemplateModule,
  AdminMarketingActionModule,
  AdminPaymentSystemModule,
  AdminProductModule,
  AdminReflinkModule,
  AdminUserAppModule,
  AdminUserOrderModule,
  AdminUserPaymentModule,
  AdminVendorModule,
  AlertModule,
  AppModule,
  AuthModule,
  BodyModule,
  BreadcrumbsModule,
  ClientsReportModule,
  ConfigModule,
  ContactsModule,
  CountryModule,
  ErrorModule,
  GoogleAdsReportModule,
  KeywordModule,
  LanguageModule,
  ManagerModule,
  MarketingActionModule,
  MyCampaignsModule,
  MyProfileModule,
  NotificationModule,
  PaymentSystemModule,
  PermissionModule,
  PriceplansModule,
  ReferralModule,
  ResellersProgramModule,
  RoleModule,
  SalesReportMyClientModule,
  SalesReportNewClientModule,
  SalesReportStatisticModule,
  StartingModule,
  StatisticsModule,
  TopUpBalanceModule,
  TransactionModule,
  UserDetailsMessagesModule,
  UserDetailsPaymentsModule,
  UserDetailsReferralModule,
  UserDetailsTransactionsModule,
  UserListModule,
  WidgetsModule
} from '@/store/modules';

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    RoleModule,
    PermissionModule,
    CountryModule,
    AppModule,
    KeywordModule,
    AddCampaignsModule,
    MyCampaignsModule,
    TransactionModule,
    PriceplansModule,
    MyProfileModule,
    LanguageModule,
    WidgetsModule,
    StatisticsModule,
    PaymentSystemModule,
    ManagerModule,
    TopUpBalanceModule,
    AccessListModule,
    UserListModule,
    StartingModule,
    UserDetailsPaymentsModule,
    AdminPaymentSystemModule,
    ReferralModule,
    AdminReflinkModule,
    ResellersProgramModule,
    ErrorModule,
    ContactsModule,
    AdminUserOrderModule,
    AlertModule,
    NotificationModule,
    AdminVendorModule,
    AdminUserPaymentModule,
    UserDetailsMessagesModule,
    AdminUserAppModule,
    SalesReportMyClientModule,
    AdminEmailTemplateModule,
    SalesReportNewClientModule,
    AdminProductModule,
    SalesReportStatisticModule,
    AdminMarketingActionModule,
    MarketingActionModule,
    ClientsReportModule,
    UserDetailsTransactionsModule,
    UserDetailsReferralModule,
    GoogleAdsReportModule,
  }
});

export default store;
