const CoreSetting = {
  paysystemTable: {
    id: 'ID',
    name: 'Name',
    active: 'Active',
    paysystems: 'Paysystems',
    showing: 'showing',
    noPaysystemsFound: 'No paysystems found',
  },
  emailTemplatesTable: {
    id: 'ID',
    name: 'Name',
    active: 'Active',
    emailTemplates: 'Email notifications',
    showing: 'showing',
    noEmailTemplatesFound: 'No email templates found',
  },
  productTable: {
    name: 'Name',
    active: 'Active',
    products: 'Products',
    showing: 'showing',
    noProductsFound: 'No products found',
  },
};

export default CoreSetting;
