const application = {
  promote:'Пропагувати',
  addKeywords:'Додайте ключові слова',
  update:'Оновлення',
  delete:'Видалити',
  addApp:'Додати новий додаток',
  created:'Додати новий додаток',
  inputSelectCountry:'Виберіть країну',
  inputSelectApplication:'Виберіть додаток',
  inputAppstore:'Будь ласка, виберіть Appstore',
  deleteApp:'Додаток успішно видалено',
  updateApp:'Додаток успішно оновлено',
  search:'Пошук',
  sure:'Ви впевнені?',
  not:'Ні, скасуйте',
};
export default application;
