const validation = {
  emailIsRequiredField: 'Email is a required field',
  usernameIsRequiredField: 'Username is a required field',
  passwordIsRequiredField: 'Password is a required field',
  tocIsRequiredField: 'Toc is a required field',
  nicknameIsRequiredField: 'Nickname is a required field',
  fillNameIsRequiredField: 'Full name is a required field',
  businessTypeIsRequiredField: 'Business type is a required field',
  countryIsRequiredField: 'Country is a required field',
  phoneIsRequiredField: 'Phone is a required field',
  phoneMustContainOnlyNumbers: 'Phone must contain only numbers',
  thereShouldBePhoneOrMessanger: 'Either a phone number or a messenger must be specified',
  nicknameIsNotValid: 'Nickname is not valid',
  fullNameIsNotValid: 'Full name in not valid',
  emailMustBeAtLeast: 'Email must be at least 4 characters',
  emailIsNotValid: 'Email is not valid',
  passwordMustBeAtLeast: 'Password must be at least 8 characters',
  userNameMustBeAtLeast: 'User name must be at least 3 characters',
  passwordsMustMatch: 'Passwords must match',
  imageTooLarge: 'Image too large',
};

export default validation;
