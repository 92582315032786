import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { AxiosRequestConfig } from 'axios';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { domainUrl } from '@/core/config/DomainConfig';
import { NotificationModel } from '@/assets/ts/_utils/models/NotificationModel';
import { Getters } from '@/store/enums/GettersEnums';

@Module({ name: 'NotificationModule' })
export default class NotificationModule extends VuexModule implements StoreModel {

  errors = {};
  notifications = [];

  /**
     * Get all notifications
     * @returns array
     */
  get [Getters.GET_ALL_NOTIFICATIONS]() {
    return this.notifications;
  }

  /**
     * Get errors
     * @returns array
     */
  get [Getters.GET_ERRORS_NOTIFICATIONS]() {
    return this.errors;
  }


    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.errors = {};
    this.notifications = [];
  };

    @Mutation
    [Mutations.SET_NOTIFICATIONS](payload) {
      this.notifications = payload;
    }

    @Mutation
    [Mutations.SET_ERROR_NOTIFICATIONS](error) {
      this.errors = { ...error };
    }


    @Action
    [Actions.GET_NOTIFICATIONS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}notifications`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_NOTIFICATIONS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_NOTIFICATIONS, response?.data?.errors);
        });
    }

    @Action
    [Actions.DEACTIVATE_NOTIFICATION](id: number) {
      const config: AxiosRequestConfig = {};
      return ApiService.put(`${domainUrl}notifications/deactivate/${id}`, config)
        .then(() => {
          if (this.notifications.length) {
            this.context.commit(Mutations.SET_NOTIFICATIONS, this.notifications?.filter((notification: NotificationModel) => notification?.id !== id));
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_NOTIFICATIONS, response?.data?.errors);
          if (this.notifications.length) {
            this.context.commit(Mutations.SET_NOTIFICATIONS, this.notifications?.filter((notification: NotificationModel) => notification?.id !== id));
          }
        });
    }
}
