const apps = {
  appToolbar: {
    all:'Всі',
    android:'Android',
    ios:'iOS',
    favorite:'Улюблений',
    banned:'Заборонено',
    allApps:'Всі програми',
    googlePlayApps:'Google Play програми',
    appStoreApps:'App Store програми',
    favoriteApps:'Улюблені програми',
    bannedApps:'Забанені програми',
  },
  appList: {
    addNewApp:'Добавити нові програми',
    shown:'Проказано',
    appsFrom:'програми з',
    added:'добавлено',
    found:'Знайдено',
    applicationsBySearch:'програми по пошуку',
    nothingFoundBySearch:'Пошуком нічего не знайдено',
    startWithAddingNewApplication:'Почніть з добавлення нової програми',
  },
  appItem: {
    unavailable:'Недоступний',
    promote:'Просувати',
    createKeywordsCampaign:'Створити кампанію з ключовими словами',
    createDirectInstallsCampaign:'Створити кампанію з прямими встановленнями',
    createReviewCampaign:'Створити оглядову кампанію',
    createCpiCampaign:'Створити CPI кампанію',
    addKeywords:'Добавити ключові слова',
    refreshApp:'Оновити програму',
    deleteApp:'Видалити програму',
    applicationId:'ID програми',
    downloads:'Завантаження',
    category:'Категорії',
    contentRating:'Рейтинг контента',
  },
  appstoreToggle: {
    store:'Збереження',
    googlePlay:'Google Play',
    appStore:'App Store',
  },
  countrySelect: {
    country:'Країна',
  },
  appSelect: {
    pleaseEnterApplicationPackage:'Будь ласка, введіть пакет програми, ідентифікатор відстеження або URL-адресу магазину',
    beforeAddingNewApplication:'Перед додаванням нової програми перевірте',
    appIsFree:'додаток БЕЗКОШТОВНО (ми не можемо просувати платні програми)',
    appIsAvailableInSelectedCountry:'програма доступна у вибраній країні для великої кількості пристроїв',
    appDoesntRequireSystemPermissions:'програма не вимагає будь-яких спеціальних системних дозволів (таких як Root або NFC)',
  },
  appDropdown: {
    quickActions:'Швидкі дії',
    update:'Оновити',
    updating:'Оновлення...',
    delete:'Видалити',
    myKeywords:'Мої ключові слова',
    statistics:'Статистика',
    externalAnalytics:'Зовнішня аналітика',
    showInAppfollow:'Показати в AppFollow',
    showInAsoMobile:'Показати в AsoMobile',
    showInAsoTools:'Показати в AsoTools',
  }
};
export default apps;
