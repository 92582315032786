import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { AxiosRequestConfig } from 'axios';
import ApiService from '@/core/services/ApiService';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { domainUrlAdmin } from '@/core/config/DomainConfig';
import { Getters } from '@/store/enums/GettersEnums';

@Module({ name: 'ClientsReportModule' })
export default class ClientsReportModule extends VuexModule implements StoreModel {
  errors = {};
  clientsReport = [];
  export = [];
  queryParams = {};
  totalClientsReport = 0;
  currentPage = 1;
  page = 1;
  lastPage = 1;
  rowsPerPage = 10;
  search = '';
  limit = 10;
  orderBy = 'id';
  sortBy = 'desc';
  country = '';
  manager = 0;
  priceplanId = 0;

  /**
     * Get current page
     * @returns number
     */
  get [Getters.GET_CLIENTS_REPORT_CURRENT_PAGE]() {
    return this.currentPage;
  }

  get [Getters.GET_CLIENTS_REPORT_PAGE]() {
    return this.page;
  }

  get [Getters.GET_CLIENTS_REPORT_PRICEPLAN_ID]() {
    return this.priceplanId;
  }

  /**
     * Get last page
     * @returns number
     */
  get [Getters.GET_CLIENTS_REPORT_LAST_PAGE]() {
    return this.lastPage;
  }

  /**
     * Get rows
     * @returns number
     */
  get [Getters.GET_CLIENTS_REPORT_ROWS]() {
    return this.rowsPerPage;
  }

  /**
     * Get search
     * @returns string
     */
  get [Getters.GET_CLIENTS_REPORT_SEARCH]() {
    return this.search;
  }

  /**
     * Get limit
     * @returns string
     */
  get [Getters.GET_CLIENTS_REPORT_LIMIT]() {
    return this.limit;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ERRORS_CLIENTS_REPORT]() {
    return this.errors;
  }

  /**
     * Get current Transaction object
     * @returns array
     */
  get [Getters.ALL_CLIENTS_REPORT]() {
    return this.clientsReport;
  }

  /**
     * Get current Transaction object
     * @returns array
     */
  get [Getters.ALL_CLIENTS_REPORT_EXPORT]() {
    return this.export;
  }

  /**
     * Get current Total Transactions
     * @returns number
     */
  get [Getters.GET_TOTAL_CLIENTS_REPORT]() {
    return this.totalClientsReport;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_CLIENTS_REPORT_ORDER_BY]() {
    return this.orderBy;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_CLIENTS_REPORT_QUERY_PARAMS]() {
    return this.queryParams;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_CLIENTS_REPORT_SORT_BY]() {
    return this.sortBy;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_CLIENTS_REPORT_COUNTRY]() {
    return this.country;
  }

  get [Getters.GET_CLIENTS_REPORT_MANAGER]() {
    return this.manager;
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.errors = {};
    this.clientsReport = [];
    this.export = [];
    this.queryParams = {};
    this.totalClientsReport = 0;
    this.currentPage = 1;
    this.lastPage = 1;
    this.rowsPerPage = 10;
    this.search = '';
    this.limit = 10;
    this.orderBy = 'id';
    this.sortBy = 'desc';
    this.country = '';
    this.manager = 0;
    this.priceplanId = 0;
  };

    @Mutation
    [Mutations.SET_CLIENTS_REPORT_ROWS](payload) {
      this.rowsPerPage = payload;
    }

    @Mutation
    [Mutations.SET_ERROR_CLIENTS_REPORT](error) {
      this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_ALL_CLIENTS_REPORT](payload) {
      this.clientsReport = payload;
    }

    @Mutation
    [Mutations.SET_CLIENTS_REPORT_EXPORT](payload) {
      this.export = payload;
    }

    @Mutation
    [Mutations.SET_TOTAL_CLIENTS_REPORT](payload) {
      this.totalClientsReport = payload;
    }

    @Mutation
    [Mutations.SET_CLIENTS_REPORT_CURRENT_PAGE](payload) {
      this.currentPage = payload;
    }

    @Mutation
    [Mutations.SET_CLIENTS_REPORT_LAST_PAGE](payload) {
      this.lastPage = payload;
    }

    @Mutation
    [Mutations.SET_CLIENTS_REPORT_SEARCH](payload) {
      this.search = payload;
    }

    @Mutation
    [Mutations.SET_CLIENTS_REPORT_ORDER_BY](payload) {
      this.orderBy = payload;
    }

    @Mutation
    [Mutations.SET_CLIENTS_REPORT_SORT_BY](payload) {
      this.sortBy = payload;
    }

    @Mutation
    [Mutations.SET_CLIENTS_REPORT_QUERY_PARAMS](payload) {
      this.queryParams = payload;
    }

    @Mutation
    [Mutations.SET_CLIENTS_REPORT_COUNTRY](payload) {
      this.country = payload;
    }

    @Mutation
    [Mutations.SET_CLIENTS_REPORT_MANAGER](payload) {
      this.manager = payload;
    }

    @Mutation
    [Mutations.SET_CLIENTS_REPORT_PRICEPLAN](payload) {
      this.priceplanId = payload;
    }

    @Action
    [Actions.GET_CLIENTS_REPORT](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };

      return ApiService.query(`${domainUrlAdmin}clients-report`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TOTAL_CLIENTS_REPORT, data.meta.total);
          this.context.commit(Mutations.SET_CLIENTS_REPORT_CURRENT_PAGE, data.meta.current_page);
          this.context.commit(Mutations.SET_CLIENTS_REPORT_LAST_PAGE, data.meta.last_page);
          this.context.commit(Mutations.SET_ALL_CLIENTS_REPORT, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_CLIENTS_REPORT, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_CLIENTS_REPORT_EXPORT](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}clients-report`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CLIENTS_REPORT_EXPORT, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_CLIENTS_REPORT, response?.data?.errors);
        });
    }

}
