const contacts = {
  contactUs: 'Contacta con nosotros',
  dearUserIfYouHaveAnyQuestions: '¡Querido usuario! Si tiene alguna pregunta, puede contactar a su gerente personal utilizando el formulario a continuación o usando cualquier mensajero. También puede usar nuestras preguntas frecuentes {linkFaq}, hay respuestas a la mayoría de las preguntas.',
  howCanWeHelpYou: '¿Como podemos ayudarte?',
  subject: 'Sujeto',
  enterTheSubject: 'Ingrese el Asunto',
  message: 'Mensaje',
  pleaseProvideYourSupplies: 'Proporcione sus suministros línea por línea',
  weWillAnswerYou: 'Te responderemos lo más rápido que podamos. Te aseguramos que no tendrás que esperar mucho.',
  sendMessage: 'Enviar mensaje',
  youCanAlsoContactOurSupportTeam: 'También puede ponerse en contacto con nuestro equipo de soporte:'
};

export default contacts;
