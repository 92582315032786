const keywords = {
  keywordHeader: {
    addKeyword: 'Добавить ключевое слово',
    importKeywordsFromFile: 'Импорт ключевых слов из файла'
  },
  keywordToolbar: {
    googlePlayApps: 'Google Play приложения',
    appStoreApps: 'App Store приложения'
  },
  appKeywordSelect: {
    selectYourApp: 'Выберите свое приложение',
    pleaseEnterApplicationPackage: 'Пожалуйста, введите пакет приложения, идентификатор отслеживания или URL-адрес магазина',
    beforeAddingNewApplication: 'Перед добавлением нового приложения проверьте',
    appIsFree: 'приложение БЕСПЛАТНО (мы не можем продвигать платные приложения)',
    appIsAvailableInSelectedCountry: 'приложение доступно в выбранной стране для большого количества устройств',
    appDoesntRequireSystemPermissions: 'приложение не требует каких-либо специальных системных разрешений (таких как Root или NFC)'
  },
  createKeywordModal: {
    addKeywords: 'Добавить ключевые слова',
    addNewKeyword: 'Добавить новое ключевое слово',
    pleaseAddKeyword: 'Пожалуйста, добавьте ключевые слова для продвижения',
    addKeywordsByPressingEnter: 'Например, паук, игра в шахматы или классное приложение, добавляйте ключевые слова по одному, нажимая Enter.',
    dontAddQuotesSlashesHashtagsToKeywords: 'Пожалуйста, не добавляйте к ключевым словам кавычки, косые черты, хэштеги или специальные символы.',
    add: 'Добавить'
  },
  keywordChart: {
    keywordRankHistory: 'История рейтинга ключевых слов',
    threeMonths: '3 Месяца',
    noHistory: 'Нет доступных исторических данных',
    month: 'Месяц',
    week: 'Неделя'
  },
  keywordChartDropdown: {
    quickActions: 'Быстрые действия',
    refreshChart: 'Обновить'
  },
  keywordTable: {
    myKeywords: 'Мои ключевые слова',
    selected: 'Выбрано',
    keywordsOf: 'ключевые слова',
    found: 'Найденный',
    keywordFrom: 'ключевые слова из',
    nothingFoundBySearch: 'Поиском ничего не найдено',
    noKeywordsFound: 'Ключевые слова не найдены'
  },
  keywordTableDropdown: {
    quickActions: 'Быстрые действия',
    exportKeywords: 'Экспорт ключевых слов',
    updateAllRanks: 'Обновить все ранги',
    deleteKeywords: 'Удалить ключевые слова',
    clearKeywords: 'Очистить ключевые слова'
  },
  keywordEasyDataTable: {
    noKeywordsFound: 'Ключевые слова не найдены',
    rank: 'Категория',
    scorescoreIsSearchPopularityIndex_is_search_popularity_index: 'Score — это индекс популярности в поиске, ранжированный от 1 [плохой] до 100 [лучший]. Ключевые слова с более высокой оценкой потенциально могут привлечь больше органического трафика.',
    estimatedMaximumDailySearches: 'Расчетное максимальное число ежедневных поисков по этому ключевому слову',
    only: 'Только',
    keywordsCouldBeSelected: 'ключевые слова могут быть выбраны',
    favorite: 'Любимый',
    promote: 'Продвигать',
    topApps: 'Лучшие приложения',
    suggestedKeywords: 'Предлагаемые ключевые слова',
    delete: 'Удалить'
  },
  keywordSuggested: {
    noSuggestedKeywordsFound: 'Предложенные ключевые слова не найдены',
    addSuggestedKeywords: 'Добавить предлагаемые ключевые слова'
  },
  rank: {
    checkActualRank: 'Проверить фактическую категорию',
    showRankHistoryChart: 'Показать диаграмму истории категорий',
    outOfRank: 'Вне категории',
    rankError: 'Ошибка категории',
    needClickLoading: 'Нужна загрузка по клику',
    rankNotChecked: 'Ранг не проверяется',
    lastUpdate: 'Последнее обновление'
  }
};

export default keywords;
