import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { AxiosRequestConfig } from 'axios';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import ApiService from '@/core/services/ApiService';
import { domainUrlAdmin } from '@/core/config/DomainConfig';
import { Getters } from '@/store/enums/GettersEnums';

@Module({ name: 'UserDetailsMessagesModule' })
export default class UserDetailsMessagesModule extends VuexModule implements StoreModel {
  errors = {};
  userDetailsMessages = [];
  totalDetailsMessages = 0;
  search = '';
  manager = 0 as number | string;
  campaign = 0 as number | string;

  /**
     * Get current Transaction object
     * @returns array
     */
  get [Getters.ALL_USER_DETAILS_MESSAGES]() {
    return this.userDetailsMessages;
  }

  /**
     * Get search
     * @returns string
     */
  get [Getters.GET_USER_DETAILS_MESSAGES_SEARCH]() {
    return this.search;
  }

  /**
     * Get current Total Transactions
     * @returns number
     */
  get [Getters.GET_TOTAL_USER_DETAILS_MESSAGES]() {
    return this.totalDetailsMessages;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ERRORS_USER_DETAILS_MESSAGES]() {
    return this.errors;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_USER_DETAILS_MESSAGES_MANAGER]() {
    return this.manager;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_USER_DETAILS_MESSAGES_CAMPAIGN]() {
    return this.campaign;
  }


    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.errors = {};
    this.userDetailsMessages = [];
    this.totalDetailsMessages = 0;
    this.search = '';
    this.manager = 0;
    this.campaign = 0;
  };

    @Mutation
    [Mutations.SET_ERROR_USER_DETAILS_MESSAGES](error) {
      this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_ALL_USER_DETAILS_MESSAGES](payload) {
      this.userDetailsMessages = payload;
    }

    @Mutation
    [Mutations.SET_TOTAL_USER_DETAILS_MESSAGES](payload) {
      this.totalDetailsMessages = payload;
    }

    @Mutation
    [Mutations.SET_USER_DETAILS_MESSAGES_SEARCH](payload) {
      this.search = payload;
    }

    @Mutation
    [Mutations.SET_USER_DETAILS_MESSAGES_MANAGER](payload) {
      this.manager = payload;
    }

    @Mutation
    [Mutations.SET_USER_DETAILS_MESSAGES_CAMPAIGN](payload) {
      this.campaign = payload;
    }

    @Action
    [Actions.GET_USER_DETAILS_MESSAGES](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}notices`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TOTAL_USER_DETAILS_MESSAGES, data.data.length);
          this.context.commit(Mutations.SET_ALL_USER_DETAILS_MESSAGES, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_DETAILS_MESSAGES, response?.data?.errors);
        });
    }

}
