enum PromotionRuleTypeEnums {
    // constant
    Rule0 = 0,
    Rule1 = 1,
    Rule3 = 3,
    Rule5 = 5,
    Rule10 = 10,
    Rule30 = 30
}

export { PromotionRuleTypeEnums };
