enum UserDetailsMenuEnums {
    EDIT_DETAILS = 'Edit Details',
    PAYMENTS = 'Payments',
    TRANSACTIONS = 'Transactions',
    CHANGE_BALANCE = 'Change balance',
    MESSAGES = 'Messages',
    REFERRALS = 'Referrals',
    RESELLER_PROGRAM = 'Reseller program',
}

enum UserDetailsMenuValueEnums {
    EDIT_DETAILS = 'edit-details',
    PAYMENTS = 'payments',
    TRANSACTIONS = 'transactions',
    CHANGE_BALANCE = 'change-balance',
    MESSAGES = 'messages',
    REFERRALS = 'referrals',
    RESELLER_PROGRAM = 'reseller-program',
}

enum UserDetailsBalanceTypeEnums {
    ALL = 'All',
    PAYMENTS = 'Payments',
    BONUS_PAYMENTS = 'Bonus Payments',
}

enum UserDetailsBalanceTypeValueEnums {
    ALL = 0,
    PAYMENTS = 1,
    BONUS_PAYMENTS = 2,
}

enum UserDetailsTransactionTypeEnums {
    ALL = 'All',
    CAMPAIGNS = 'Campaigns',
    PAYMENTS = 'Payments',
}

enum UserDetailsTransactionTypeValueEnums {
    ALL = 0,
    CAMPAIGNS = 1,
    PAYMENTS = 2,
}

export {
  UserDetailsMenuEnums,
  UserDetailsMenuValueEnums,
  UserDetailsBalanceTypeEnums,
  UserDetailsBalanceTypeValueEnums,
  UserDetailsTransactionTypeEnums,
  UserDetailsTransactionTypeValueEnums,
};
