const module = {
  over: 'Более {total} участников',
  clickAdd: 'Нажмите, чтобы добавить модуль',
  newModule: 'Новый модуль',
  name: 'Название',
  actions: 'Действия',
  check: 'Проверять',
  nameModule: 'Название модуля',
  enterName: 'Введите Название',
  enterNamespace: 'Введите Пространство имен',
  enterPermissionName: 'Введите Название разрешения',
  permissionName: 'Название разрешения',
  inputName: 'Пожалуйста, введите Название',
  inputNamespace: 'Пожалуйста, введите Пространство имен',
  inputPermissionName: 'Пожалуйста, введите Название разрешения',
  created: 'Создание модуля',
  updated: 'Обновление модуля',
  namespace: 'Пространство имен',
  specifyName: 'Укажите целевое название для будущего использования и ссылки',
  specifyNamespace: 'Укажите целевое Пространство имен для будущего использования и ссылки',
  specifyPermissionName: 'Укажите целевое Название разрешения для будущего использования и ссылки',

};
export default module;
