const bonusProgram = {
  getFreeBonusProgram:'Obtenga el programa de bonificación gratis',
  welcomeToGetFreeBonusesPage:'Bienvenido a la página "Obtenga bonos gratis", aquí podrá obtener una recarga gratuita de su cuenta completando conjuntos de las siguientes tareas y promocionando nuestro servicio. Por ahora puede ganar estos bonos:',
  getTwentyPercentMoney:'Obtenga un reembolso del 20 % del dinero de su pedido en su cuenta o 100 $ para su historia de éxito',
  getOneHundredBaks:'Obtenga 100 $ por su revisión de Youtube',
  getThirtyBaksForAndroid:'Obtenga 30 $ en su cuenta para la revisión del foro de Android',
  getThirtyBaksForFacebook:'Obtenga 30 $ en su cuenta por publicación de Facebook',
  successStoryOrCase:'Caso o caso de éxito',
  inOrderToGetYourTwentyPercentMoneyBack:'Para obtener su bonificación de devolución de dinero del 20 % (obtendrá el 20 % de su dinero, que haya gastado en su pedido más grande para promocionar una aplicación sobre la que está escribiendo su historia de éxito, por ejemplo, si escribe una historia sobre un aplicación en la que gastó 500 $ en la promoción, y tiene 5 pedidos, obtendrá un reembolso del 20% del dinero por su pedido más alto y no de sus gastos de 500 $) tiene que buscar y registrarse en cualquier foro dedicado de Android, crear un tema, y publique su historia de éxito con nuestro servicio, esta historia de éxito debe cumplir con los siguientes requisitos:',
  postHasToBeEightHundredSymbols:'Su publicación en el foro debe tener un mínimo de 800 símbolos',
  postHasToIncludeLink:'Su publicación en el foro debe incluir un enlace a nuestro sitio web',
  postHasToDescribe:'Su publicación en el foro debe describir su trabajo paso a paso con nuestro servicio y lo que logró al usarlo',
  postHasToIncludeScreenshots:'Su publicación en el foro debe incluir al menos 2 capturas de pantalla de su aplicación y el rendimiento de sus palabras clave',
  youCantMention:'No puede mencionar en su publicación del foro que recibe bonos gratis por hacerlo',
  postHasToStayAlive:'Tu publicación debe permanecer viva durante 2 días como mínimo.',
  youTubeVideoReview:'Reseña de videos de YouTube',
  inOrderToGetOneHundredBaks:'Para obtener su bono de $ 100, debe hacer una guía sobre cómo usar nuestro sitio (o un video de revisión) en su canal de YouTube, que cumpla con los siguientes requisitos: debe gastar al menos $ 100 en nuestro servicio, eso significa que necesitamos revisión de calidad (guía) de nuestro sitio de nuestros clientes habituales. Su video de YouTube debe incluir una breve descripción de nuestro servicio (algo como: "Este servicio ayuda a los desarrolladores a promocionar sus aplicaciones de Android. KeyApp. Los siguientes servicios se ofrecen principalmente: instalaciones de búsqueda de palabras clave e instalaciones de aplicaciones estándar"). Su revisión de video de YouTube debe incluir una guía sobre cómo configurar una campaña en nuestro sitio en la pestaña "Mis pedidos". En su video de YouTube, debe describir las siguientes pestañas de su gabinete personal: "Mis palabras clave", "Agregar dinero", "Programa de referencia", "Blog" (solo unas pocas palabras sobre sus opciones principales).',
  videoHastoBeLong:'Su revisión de video de YouTube debe tener una duración mínima de 2 minutos.',
  youAreAlwaysWelcome:'Siempre es bienvenido si hizo una guía o reseña en su idioma nativo (aceptamos videos no solo en inglés).',
  videoHasToIncludeWebsiteLink:'Su video de YouTube debe incluir un enlace a nuestro sitio web',
  videoHasToIncludeFBLink:'Su video de YouTube debe incluir un enlace a nuestra página de FB: ',
  videoHasToDesctiveLikesDislikes:'Su video de YouTube tiene que describir lo que le gusta o lo que no le gusta de nuestro servicio.',
  youCantMentionInVideo:'No puede mencionar en su video de YouTube que recibe bonos gratis por hacerlo.',
  videoReviewHasToStayAlive:'Su revisión de video de YouTube debe permanecer activa durante 2 días como mínimo.',
  androidRelatedForum:'Foro relacionado con Android',
  inOrderToGetThirtyBaks:'Para obtener su bono de $ 30, debe buscar y registrarse en cualquier foro dedicado de Android, crear un tema y publicar una revisión real sobre nuestro servicio, esta revisión debe cumplir con los siguientes requisitos:',
  postHasToBeFourHundredSymbols:'Tu publicación en el foro debe tener una longitud mínima de 400 símbolos.',
  postHastoDescribeLikesDislikes:'Tu publicación en el foro debe describir lo que te gusta o no de nuestro servicio.',
  postHasToIncludeWebsiteLink:'Su publicación en el foro debe incluir un enlace a nuestro sitio web',
  youCantMentionInPost:'No puede mencionar en su publicación del foro que recibe bonos gratis por hacerlo',
  facebookPost:'publicación de Facebook',
  inOrderToGetThirtyBaksBonus:'Para obtener su bono de <b>30$</b>, debe hacer una publicación en su cuenta de Facebook, que cumpla con los siguientes requisitos:',
  facebookHasToIncludeScreenshot:'Su publicación de Facebook debe incluir una captura de pantalla del rendimiento de clasificación de sus palabras clave, o el rendimiento general de su aplicación en progreso, resalte el área donde comenzó la promoción con nosotros. Las capturas de pantalla de la página "mis pedidos" en keyapp.top no son elegibles ya que no muestran la diferencia en el progreso de la promoción de su aplicación antes y después de sus campañas con nosotros."',
  facebookHasToBeLong:'Tu publicación de Facebook debe tener una longitud mínima de 400 símbolos.',
  facebookHasToIncludeScreenshotKeyword:'Su publicación de Facebook debe incluir una captura de pantalla del rendimiento de clasificación de sus palabras clave o del rendimiento general de su aplicación. Resalte el área donde comenzó la promoción con nosotros.',
  facebookHasToIncludeWebsiteLink:'Su publicación de Facebook debe incluir un enlace a nuestro sitio web.',
  facebookHasToIncludeFBLink:'Su publicación de Facebook debe incluir un enlace a nuestra página de Facebook: ',
  facebookHasToDescribeLikesDislikes:'Su publicación de Facebook tiene que describir lo que le gusta o lo que no le gusta de nuestro servicio.',
  youCantMentionInFB:'No puedes mencionar en tu publicación de Facebook que recibes bonos gratis por hacerlo.',
  googleMapsReview:'Revisión de mapas de Google',
  inOrderToGetTenBaks:'Para obtener su bono de $ 10, debe hacer una revisión en ',
  googleMaps:'Google maps',
  whichMeetsFollowingRequirements:', que cumpla con los siguientes requisitos. Su revisión en Google Maps debe:',
  minimumLong:'ser 350 símbolos de largo mínimo',
  describeLikesDislikes:'describir sus gustos o disgustos de nuestro servicio',
  youCantMentionReview:'no puede mencionar en su reseña en Google Maps que recibe bonificaciones gratuitas de Me gusta por hacerlo',
  yourReviewHasStayAlive:'tu reseña en Google Maps tiene que permanecer viva durante 2 meses como mínimo',
  youCanUseYour:'puedes usar tu ',
  youCanMakeOnlyOneYouTubeVideo:'Solo puedes hacer 1 video de YouTube por un usuario.',
  youCanWriteOnlyOneForumPost:'Puede escribir solo una publicación en el foro de buena calidad para recibir una bonificación.',
  youCanWriteOnlyOneFacebookPost:'Puede escribir solo una publicación de Facebook de buena calidad para recibir una bonificación.',
  youCanWriteOnlyOneReviewOnGoogleMaps:'Puede escribir solo una reseña en los mapas de Google de buena calidad para recibir una bonificación.',
  pleaseEmail:'Por favor envíe un correo electrónico ',
  usLinkToYourPost:' envíenos un enlace a su publicación con el título "Bonificación de publicación", la revisaremos y, si cumple con todos los requisitos, obtendrá su bonificación.',
  usLinkToYourVideo:' envíenos un enlace a su video con el título "Revisión de video de YouTube", lo revisaremos y, si cumple con todos los requisitos, obtendrá su bonificación.',
  usLinkToYourReview:' envíenos un enlace a su revisión en Google Maps con el título "Revisión en Google Maps", la revisaremos y, si cumple con todos los requisitos, obtendrá su bonificación.',
  referralLink:'Enlace de referencia',
};

export default bonusProgram;
