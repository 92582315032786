import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Main } from '@/store/enums/MainEnums';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { AxiosRequestConfig } from 'axios';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { domainUrl } from '@/core/config/DomainConfig';
import { serializedPropsElement } from '@/assets/ts/_utils';
import { ErrorGetters } from '@/store/enums/ErrorGettersEnums';
import { Getters } from '@/store/enums/GettersEnums';

export interface App {
    id: string,
    uuid: string,
    title: string,
    store: number,
    icon: string,
    package?: string,
    developer?: { name?: string },
    appstore?: { tag?: string },
}

export interface CreateApp {
    selectCountry: string | unknown,
    appstore: number | unknown,
    selectApplication: string
}

export interface AppInfo {
    errors: unknown;
    app: App;
    apps: App[];
    search_apps: unknown;
}

@Module({ name: 'AppModule' })
export default class AppModule extends VuexModule implements AppInfo, StoreModel {
  errors = {};
  app = {} as App;
  appstore = Main.STORE_GOOGLE_MARKET;
  appman_app = {} as App;
  apps = [] as App[];
  total = 0;
  count = 0;
  search_apps = [];
  isLoading = false;
  lastAddedApp = {} as App;

  get [Getters.GET_LAST_ADDED_APP](): App {
    return this.lastAddedApp;
  }

  /**
     * Get count
     * @returns number
     */
  get [Getters.GET_APPS_COUNT](): number {
    return this.count;
  }

  /**
     * Get current app object
     * @returns App
     */
  get [Getters.CURRENT_APP](): App {
    return this.app;
  }

  /**
     * Get current appstore
     * @returns
     */
  get [Getters.CURRENT_APPSTORE]() {
    return this.appstore;
  }

  /**
     * Get current Appman app object
     * @returns Appman
     */
  get [Getters.CURRENT_APPMAN_APP](): App {
    return this.appman_app;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [ErrorGetters.GET_ERRORS_APP]() {
    return this.errors;
  }

  /**
     * Get current Apps objects
     * @returns App
     */
  get [Getters.ALL_APPS]() {
    return this.apps;
  }

  /**
     * Get current Countries objects
     * @returns App
     */
  get [Getters.ALL_SEARCH_APPS]() {
    return this.search_apps;
  }

  /**
     * Get current total count
     * @returns Apps
     */
  get [Getters.CURRENT_TOTAL]() {
    return this.total;
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.errors = {};
    this.app = {} as App;
    this.appstore = Main.STORE_GOOGLE_MARKET;
    this.appman_app = {} as App;
    this.apps = [];
    this.total = 0;
    this.count = 0;
    this.search_apps = [];
    this.isLoading = false;
  };

    @Mutation
    [Mutations.SET_APPS_COUNT](payload) {
      this.count = payload;
    }

    @Mutation
    [Mutations.SET_LAST_ADDED_APP](payload) {
      this.lastAddedApp = payload;
    }

    @Mutation
    [Mutations.SET_LOADING](isLoading) {
      this.isLoading = isLoading;
    }

    @Mutation
    [Mutations.SET_TOTAL_PAGES](total) {
      this.total = total;
    }

    @Mutation
    [Mutations.SET_ERROR_APP](error) {
      this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_APPS](apps) {
      this.apps = apps;
    }

    @Mutation
    [Mutations.SET_SEARCH_APPS](search_apps) {
      this.search_apps = search_apps;
    }

    @Mutation
    [Mutations.SET_APPMAN_APP](appman_app) {
      this.appman_app = appman_app;
    }

    @Mutation
    [Mutations.SET_APP](app) {
      this.app = app;
    }

    @Mutation
    [Mutations.SET_APPSTORE](appstore) {
      this.appstore = appstore;
    }

    @Action
    [Actions.GET_APPMAN_APP](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}apps/showAppman`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_APPMAN_APP, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_APP, response?.data?.errors);
          this.context.commit(Mutations.SET_APPMAN_APP, {});
        });
    }

    @Action
    [Actions.GET_SEARCH_APPS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}apps/search`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SEARCH_APPS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_APP, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_APPS](params) {
      this.context.commit(Mutations.SET_LOADING, true);
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}apps`, config)
        .then(({ data }) => {
          const serializedData = data.data.map(el => ({ ...el, app: { ...serializedPropsElement(el.app) } }));
          this.context.commit(Mutations.SET_TOTAL_PAGES, serializedData?.length);
          this.context.commit(Mutations.SET_APPS, serializedData);
          this.context.commit(Mutations.SET_APPS_COUNT, data?.count);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_ERROR_APP, response?.data?.errors);
        }).finally(() => {
          this.context.commit(Mutations.SET_LOADING, false);
        });
    }

    @Action
    [Actions.DELETE_APP](id) {
      return ApiService.delete(`${domainUrl}apps/` + id)
        .then(({ data }) => {
          const deleteApps = this.apps.filter((obj) => {
            // @ts-ignore
            if (obj.app.package !== id) {
              return obj;
            }
          });
          this.context.commit(Mutations.SET_APPS, deleteApps);
          this.context.commit(Mutations.SET_TOTAL_PAGES, this.apps.length);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_APP, response?.data?.errors);
        }).finally(() => {
          this.context.commit(Mutations.SET_LOADING, false);
        });
    }

    @Action
    [Actions.DELETE_BANNED_APPS](id) {
      return ApiService.delete(`${domainUrl}apps/destroyBanned`)
        .then(({ data }) => {
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_APP, response?.data?.errors);
        }).finally(() => {
          this.context.commit(Mutations.SET_LOADING, false);
        });
    }

    @Action
    [Actions.UPDATE_APP](id) {
      return ApiService.update(`${domainUrl}apps`, id, {})
        .then(({ data }) => {
          this.context.commit(Mutations.SET_APP, data.data);
          this.context.commit(Mutations.SET_ERROR_APP, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_APP, response?.data?.errors);
        }).finally(() => {
          this.context.commit(Mutations.SET_LOADING, false);
        });
    }

    @Action
    [Actions.TOGGLE_FAVORITE_APP](id: any) {
      const config: AxiosRequestConfig = {
        params: { id: id }
      };
      return ApiService.put(`${domainUrl}apps/toggle`, config)
        .then(({ data }) => {
          const serializedData = data.data.map(el => ({ ...el, app: { ...serializedPropsElement(el.app) } }));
          this.context.commit(Mutations.SET_APP, serializedData);
          const updateApps = this.apps.map((obj) => {
            // @ts-ignore
            if (obj.app.package === data.data.app.package) {
              return data.data;
            }
            return obj;
          });
          this.context.commit(Mutations.SET_APPS, updateApps);
          this.context.commit(Mutations.SET_ERROR_APP, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_APP, response?.data?.errors);
        }).finally(() => {
          this.context.commit(Mutations.SET_LOADING, false);
        });
    }

    @Action
    [Actions.ADD_APP](params) {
      return ApiService.post(`${domainUrl}apps`, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LAST_ADDED_APP, data.data);
          const serializedData = data.data.map(el => ({ ...el, app: { ...serializedPropsElement(el.app) } }));
          this.context.commit(Mutations.SET_APP, serializedData);
          if (!this.apps.some(el => el?.uuid === data.data?.uuid)) {
            this.context.commit(Mutations.SET_APPS, [data.data, ...this.apps]);
          }
          this.context.commit(Mutations.SET_ERROR_APP, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_APP, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_APP](id) {
      return ApiService.get(`${domainUrl}apps`, id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_APP, { app: data.data });
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_APP, response?.data?.errors);
        });
    }


}
