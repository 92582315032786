const dashboard = {
  campaigns:'Кампанії',
  leftTo:'Залишилось до',
  myTotalCampaigns:'Усього моїх кампаній',
  activeCampaigns:'Активні кампанії',
  completedCampaigns:'Завершені кампанії',
  guarantedReviews:'Гарантовані відгуки',
  smartCampaign:'Розумна кампанія',
  advertisingCampaign:'Рекламна кампанія',
  improveYourRatesAndApps:'Покращуйте свої рейтинги та репутацію додатків за допомогою наших оглядів. Отримайте 30-денну гарантію на будь-яке замовлення.',
  cpi:'Компанія CPI (cost-per-install) — це інструмент, який використовується для залучення користувачів до вашої програми.',
  geos:'GEOs',
  availableCountriesForPromotion:'Доступні країни для просування',
  topRegions:'Найкращі регіони',
  mostPopularRegionsForPromotion:'Найпопулярніші регіони для просування',
  lastCampaigns:'Останні кампанії',
  myApps:'Мої програми',
  platformNews:'Новини платформи',
  FAQs:'FAQs',
  application:'Програма',
  currentPlan:'Поточний план',
  FAQAndASONews:'FAQ & ASO новини',
  keyappAcademy:'Keyapp Академія',
};

export default dashboard;
