import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { AxiosRequestConfig } from 'axios';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { domainUrl } from '@/core/config/DomainConfig';
import { ErrorGetters } from '@/store/enums/ErrorGettersEnums';

@Module({ name: 'ErrorModule' })
export default class ErrorModule extends VuexModule implements StoreModel {
  textError = '';

  /**
     * Get current Language code
     * @returns
     */
  get [ErrorGetters.GET_TEXT_ERROR](): string {
    return this.textError;
  }


    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.textError = '';
  };

    @Mutation
    [Mutations.SET_TEXT_ERROR](textError) {
      this.textError = textError;
    }


    @Action
    [Actions.GET_ERROR](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}errors`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TEXT_ERROR, data.errors);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_TEXT_ERROR, response?.data?.errors);
        });
    }


}
