const module = {
  over: 'Понад {total} учасників',
  clickAdd: 'Натисніть, щоб додати модуль',
  newModule: 'Новий модуль',
  name: 'Назва',
  actions: 'Дії',
  check: 'Перевірте',
  nameModule: 'Назва модуля',
  enterName: 'Введіть назву',
  enterNamespace: 'Введіть Простір імен',
  enterPermissionName: 'Введіть назву дозволу',
  permissionName: 'Назва дозволу',
  inputName: 'Будьласка введіть назву',
  inputNamespace: 'Будьласка введіть Простір імен',
  inputPermissionName: 'Будьласка введіть назву дозволу',
  created: 'Створення модуля',
  updated: 'Обновлення модуля',
  namespace: 'Простір імен',
  specifyName: 'Вкажіть цільову назву для подальшого використання та довідок',
  specifyNamespace: 'Вкажіть Простір імен для подальшого використання та довідок',
  specifyPermissionName: 'Вкажіть Назву дозволу для подальшого використання та довідок',
};
export default module;
