enum RolesEnums {
    ALL = 'all',
    ADMIN = 'admin',
    USER = 'user',
    MANAGER = 'manager',
    SUPPORT = 'support',
    PPC = 'ppc',
    MARKETER = 'marketer',
}

export { RolesEnums };
