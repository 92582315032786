import role from '@/core/plugins/lang/ru-RU/role';
import functions from '@/core/plugins/lang/ru-RU/function';
import module from '@/core/plugins/lang/ru-RU/module';
import select from '@/core/plugins/lang/ru-RU/select';
import application from '@/core/plugins/lang/ru-RU/application';
import apps from '@/core/plugins/lang/ru-RU/apps';
import keywords from '@/core/plugins/lang/ru-RU/keywords';
import addCampaigns from '@/core/plugins/lang/ru-RU/addCampaigns';
import myTransactions from '@/core/plugins/lang/ru-RU/myTransactions';
import priceplans from '@/core/plugins/lang/ru-RU/priceplans';
import myProfile from '@/core/plugins/lang/ru-RU/myProfile';
import topUpBalance from '@/core/plugins/lang/ru-RU/topUpBalance';
import register from '@/core/plugins/lang/ru-RU/register';
import myCampaigns from '@/core/plugins/lang/ru-RU/myCampaigns';
import bonusProgram from '@/core/plugins/lang/ru-RU/bonusProgram';
import referralProgram from '@/core/plugins/lang/ru-RU/referralProgram';
import resellersProgram from '@/core/plugins/lang/ru-RU/resellersProgram';
import statistics from '@/core/plugins/lang/ru-RU/statistics';
import dashboard from '@/core/plugins/lang/ru-RU/dashboard';
import contacts from '@/core/plugins/lang/ru-RU/contacts';

const index = {
  appsKeywords:'ПРИЛОЖЕНИЯ&КЛЮЧЕВЫЕ СЛОВА',
  myApps:'Мои приложения',
  myKeywords:'Мои ключевые слова',
  services:'Услуги',
  myCampaigns:'Мои кампании',
  addCampaign:'Добавить кампанию',
  addCPICampaign:'Добавить кампанию с ценой за установку',
  addSmartCampaign:'Добавить SMART-кампанию',
  addHighQualityReviews:'Добавить качественные обзоры',
  finance:'ФИНАНСЫ',
  topUpBalance:'Пополнить баланс',
  myTransactions:'Мои транзакции',
  other:'ДРУГИЕ',
  referralProgram:'Реферальная программа',
  bonusProgram:'Бонусная программа',
  APISupport:'Поддержка API',
  faq:'Часто задаваемые вопросы',
  blog:'Блог',
  close:'Закрыть',
  open: 'Открыть',
  dashboard: 'Панель',
  keywords: 'Ключевые слова',
  home: 'Главная',
  app:'Приложение',
  layoutBuilder: 'Конструктор макетов',
  craft: 'Создано',
  pages: 'Страницы',
  profile: 'Профиль',
  profileOverview: 'Обзор',
  settingsRole: 'Роль',
  settingsRoles: 'Роли',
  settingsFunctions: 'Функции',
  settingsModules: 'Модули',
  projects: 'Проекты',
  campaigns: 'Компании',
  documents: 'Документы',
  connections: 'Коннекты',
  horizontal: 'Горизонтальный',
  vertical: 'Вертикальный',
  account: 'Учетная запись',
  accountOverview: 'Обзор',
  settings: 'Настройки',
  authentication: 'Аутентификация',
  basicFlow: 'Основной поток',
  signIn: 'Войти',
  signUp: 'Зарегистрироваться',
  passwordReset: 'Восстановление пароля',
  multiStepSignUp: 'Многоэтапная регистрация',
  error404: 'Ошибка 404',
  error500: 'Ошибка 500',
  apps: 'Программы',
  chat: 'Чат',
  privateChat: 'Приватный чат',
  groupChat: 'Групповой чат',
  widgets: 'Виджеты',
  widgetsLists: 'Списки',
  statistics: 'Статистики',
  widgetsCharts: 'Графики',
  widgetsMixed: 'Смешанный',
  widgetsTables: 'Таблицы',
  changelog: 'Список изменений',
  docsAndComponents: 'Документы & компоненты',
  megaMenu: 'Мега меню',
  exampleLink: 'Пример ссылки',
  modals: 'Модалки',
  general: 'Общий',
  inviteFriends: 'Пригласить друзей',
  viewUsers: 'Просмотр пользователей',
  upgradePlan: 'План обновления',
  shareAndEarn: 'Делитесь и зарабатывайте',
  forms: 'Формы',
  newTarget: 'Новая цель',
  newCard: 'Новая карта',
  newAddress: 'Новый адрес',
  createAPIKey: 'Создать ключ API',
  twoFactorAuth: 'Двухфакторная аутентификация',
  createApp: 'Создать приложение',
  createAccount: 'Create new account',
  documentation: 'Документация',
  components: 'Составные части',
  resources: 'Ресурсы',
  activity: 'Мероприятия',
  customers: 'Клиенты',
  gettingStarted: 'Начиная',
  customersListing: 'Список клиентов',
  customerDetails: 'Детали клиентов',
  calendarApp: 'Календарь',
  subscriptions: 'Подписки',
  getStarted: 'Начиная',
  subscriptionList: 'Список подписки',
  addSubscription: 'Добавить подписку',
  viewSubscription: 'Посмотреть подписку',
  myProfile: 'Мой профиль',
  language: 'Язык',
  english: 'Английский',
  spanish: 'Испанский',
  russia: 'Руский',
  ukraine: 'Украина',
  accountSettings: 'Настройки Аккаунта',
  signOut: 'Выйти',
  priceplans: 'Тарифы',
  forgotPassword: 'Забыли пароль ?',
  enterEmailResetPassword: 'Введите ваш электронный адрес, чтобы сбросить пароль ?',
  email: 'Емейл',
  submit: 'Отправить',
  save: 'Сохранить',
  add: 'Добавить новое',
  password: 'Пароль',
  cancel: 'Отмена',
  continue: 'Продолжить',
  pleaseWait: 'Пожалуйста подождите...',
  swalFireText: 'Вы успешно вошли в систему!',
  swalConfirmButtonText: 'OK',
  swalTryAgain: 'Попробуйте еще раз!',
  swalSuccessfullyLogin: 'Вы успешно вошли в систему!',
  swalSuccessfullyRegister: 'You have successfully registered account',
  swalFormSuccessfully: 'Форма успешно отправлена!',
  swalSorryLooksTryAgain: 'Извините, похоже, обнаружены некоторые ошибки, попробуйте еще раз.',
  continueApple: 'Продолжить с Apple',
  continueFacebook: 'Продолжить с Facebook',
  continueGoogle: 'Продолжить с Google',
  sigInApple: 'Войти через Apple',
  sigInFacebook: 'Войти через Facebook',
  sigInGoogle: 'Войти через Google',
  or: 'or login with email',
  orSignUp: 'или введите свои данные',
  newHere: 'Новенький тут?',
  singKeyapp: 'Login to your account',
  alreadyHaveAccount: 'У вас уже есть аккаунт?',
  lastName: 'Фамилия',
  name: 'Имя',
  surname: 'Фамилия',
  passwordConfirmation: 'Подтверждение пароля',
  firstName: 'Имя',
  userName: 'Username',
  singInHere: 'Войдите здесь',
  eightMoreCharacters: 'Используйте 8 или более символов, сочетая буквы, цифры и символы.',
  confirmPassword: 'Подтвердить Пароль',
  termsAndConditions: 'Условия и положения',
  iAgree: 'Я согласен &',
  seemsNothingHere: 'Кажется, здесь ничего нет',
  returnHome: 'Вернуться домой',
  goToHomepage: 'Перейдите на домашнюю страницу',
  systemError: 'Системная ошибка',
  loading: 'Загрузка...',
  checking:'проверка',
  somethingWentWrong: 'Что-то пошло не так! <br />\n Пожалуйста, попробуйте позже.',
  paginationText: 'Показаны { from } до { to } из { total } записей',
  delete:'Удалить',
  edit:'Изменить',
  copiedToClipboard:'скопировано в буфер обмена',
  error:'ошибка',
  out:'вне',
  resellersProgram:'Программа для реселлеров',
  allCountries:'Все страны',
  appstore:'Appstore',
  pickTargetCountry:'Выберите целевую страну',
  chooseAppForPromotionOr:'Выберите приложение для продвижения или ',
  addNew:'добавьте новое',
  mainBalance:'Основной баланс',
  bonusBalance:'Бонусный баланс',
  myPriceplan:'Мой тарифный план',
  yourPersonalManager:'Ваш персональный менеджер',
  iNeedPersonalManager:'Мне нужен персональный менеджер',
  personalManager:'Персональный менеджер',
  requestForPersonalManagerAssignmentIsSent:'Запрос на назначение персонального менеджера отправлен. Ваш новый персональный менеджер свяжется с вами в ближайшее время.',
  pleaseFillYourAdvanceProfile:'Пожалуйста, заполните свой профиль Advance и оставьте любые контактные данные для лучшего общения.',
  tryAgainLater:'Попробуйте позже',
  personalManagerAssignmentInProgress:'Выполняется назначение персонального менеджера', 
  ourTeamIsWorking:'Наша команда усердно работает над подбором квалифицированного и преданного своему делу профессионала, который будет отвечать за удовлетворение ваших потребностей и предоставление индивидуальной поддержки.',
  forAllYourInquiriesAndCorrespondence:'Для всех ваших запросов и корреспонденции, пожалуйста, направляйте свои электронные письма по следующему адресу:',
  weAppreciateYourCommunication:'Мы ценим ваше общение и ответим на ваше сообщение как можно скорее. Спасибо за обращение!',
  newMessageToMyKeyappTopManager:'Новое сообщение моему менеджеру keyapp.top',
  newMessageToKeyappTopSupport:'Новое сообщение в службу поддержки keyapp.top',
  supportTeam:'Группа поддержки',
  yearsAgo:'лет назад',
  monthsAgo:'месяцев назад',
  weeksAgo:'недель назад',
  daysAgo:'дней назад',
  hoursAgo:'часов назад',
  minutesAgo:'минут назад',
  new:'Новое',
  noNewMessagesFromManagers: 'Нет новых сообщений от менеджеров',
  role: role,
  functions: functions,
  module: module,
  select:select,
  application: application,
  _apps:apps,
  keywordsPage:keywords,
  addCampaigns:addCampaigns,
  myTransactionsPage:myTransactions,
  priceplansPage:priceplans,
  myProfilePage:myProfile,
  topUpBalancePage:topUpBalance,
  register:register,
  myCampaignsPage:myCampaigns,
  bonusProgramPage:bonusProgram,
  referralProgramPage:referralProgram,
  resellersProgramPage:resellersProgram,
  statisticsPage:statistics,
  dashboardPage:dashboard,
  contactsPage:contacts,
};
export default index;
