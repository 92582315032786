import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { AxiosRequestConfig } from 'axios';
import ApiService from '@/core/services/ApiService';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { domainUrlAdmin } from '@/core/config/DomainConfig';
import { ErrorGetters } from '@/store/enums/ErrorGettersEnums';
import { Getters } from '@/store/enums/GettersEnums';

@Module({ name: 'UserListModule' })
export default class UserListModule extends VuexModule implements StoreModel {
  errors = {};
  userList = [];
  export = [];
  currentUserList = {};
  queryParams = {};
  currentUserId = 0;
  totalUserList = 0;
  currentPage = 1;
  lastPage = 1;
  page = 1;
  rowsPerPage = 10;
  search = '';
  limit = 10;
  orderBy = 'created_at';
  sortBy = 'desc';
  role = 0;
  priceplan = 0;
  columnSearchFilter = '';

  get [Getters.GET_USER_LISTS_COLUMN_SEARCH_FILTER]() {
    return this.columnSearchFilter;
  }

  /**
     * Get current page
     * @returns number
     */
  get [Getters.GET_USER_LISTS_CURRENT_PAGE]() {
    return this.currentPage;
  }

  /**
     * Get current page
     * @returns number
     */
  get [Getters.GET_CURRENT_USER_LIST]() {
    return this.currentUserList;
  }

  /**
     * Get last page
     * @returns number
     */
  get [Getters.GET_USER_LISTS_LAST_PAGE]() {
    return this.lastPage;
  }

  /**
     * Get page
     * @returns number
     */
  get [Getters.GET_USER_LISTS_PAGE]() {
    return this.page;
  }

  /**
     * Get rows
     * @returns number
     */
  get [Getters.GET_USER_LISTS_ROWS]() {
    return this.rowsPerPage;
  }

  /**
     * Get search
     * @returns string
     */
  get [Getters.GET_USER_LISTS_SEARCH]() {
    return this.search;
  }

  /**
     * Get limit
     * @returns string
     */
  get [Getters.GET_USER_LISTS_LIMIT]() {
    return this.limit;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [ErrorGetters.GET_ERRORS_USER_LISTS]() {
    return this.errors;
  }

  /**
     * Get current Transaction object
     * @returns array
     */
  get [Getters.ALL_USER_LISTS]() {
    return this.userList;
  }

  /**
     * Get current Transaction object
     * @returns array
     */
  get [Getters.ALL_USER_LISTS_EXPORT]() {
    return this.export;
  }

  /**
     * Get current Total Transactions
     * @returns number
     */
  get [Getters.GET_TOTAL_USER_LISTS]() {
    return this.totalUserList;
  }

  /**
     * Get current Total Transactions
     * @returns number
     */
  get [Getters.GET_USER_LISTS_ROLE]() {
    return this.role;
  }

  /**
     * Get current Total Transactions
     * @returns number
     */
  get [Getters.GET_USER_LISTS_PRICEPLAN]() {
    return this.priceplan;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_USER_LISTS_ORDER_BY]() {
    return this.orderBy;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_USER_LISTS_QUERY_PARAMS]() {
    return this.queryParams;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_USER_LISTS_SORT_BY]() {
    return this.sortBy;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_CURRENT_USER_ID]() {
    return this.currentUserId;
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.errors = {};
    this.userList = [];
    this.export = [];
    this.currentUserList = {};
    this.queryParams = {};
    this.currentUserId = 0;
    this.totalUserList = 0;
    this.currentPage = 1;
    this.lastPage = 1;
    this.page = 1;
    this.rowsPerPage = 10;
    this.search = '';
    this.limit = 10;
    this.orderBy = 'id';
    this.sortBy = 'desc';
    this.role = 0;
    this.priceplan = 0;
    this.columnSearchFilter = '';
  };

    @Mutation
    [Mutations.SET_USER_LISTS_ROWS](payload) {
      this.rowsPerPage = payload;
    }

    @Mutation
    [Mutations.SET_CURRENT_USER_ID](payload) {
      this.currentUserId = payload;
    }

    @Mutation
    [Mutations.SET_ERROR_USER_LISTS](error) {
      this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_ALL_USER_LISTS](payload) {
      this.userList = payload;
    }

    @Mutation
    [Mutations.SET_USER_LISTS_EXPORT](payload) {
      this.export = payload;
    }

    @Mutation
    [Mutations.SET_TOTAL_USER_LISTS](payload) {
      this.totalUserList = payload;
    }

    @Mutation
    [Mutations.SET_USER_LISTS_CURRENT_PAGE](payload) {
      this.currentPage = payload;
    }

    @Mutation
    [Mutations.SET_USER_LISTS_LAST_PAGE](payload) {
      this.lastPage = payload;
    }

    @Mutation
    [Mutations.SET_USER_LISTS_SEARCH](payload) {
      this.search = payload;
    }

    @Mutation
    [Mutations.SET_USER_LISTS_ROLE](payload) {
      this.role = payload;
    }

    @Mutation
    [Mutations.SET_USER_LISTS_PRICEPLAN](payload) {
      this.priceplan = payload;
    }

    @Mutation
    [Mutations.SET_USER_LISTS_ORDER_BY](payload) {
      this.orderBy = payload;
    }

    @Mutation
    [Mutations.SET_USER_LIST](payload) {
      this.currentUserList = payload;
    }

    @Mutation
    [Mutations.SET_USER_LISTS_SORT_BY](payload) {
      this.sortBy = payload;
    }

    @Mutation
    [Mutations.SET_USER_LISTS_QUERY_PARAMS](payload) {
      this.queryParams = payload;
    }

    @Mutation
    [Mutations.SET_USER_LISTS_COLUMN_SEARCH_FILTER](payload) {
      this.columnSearchFilter = payload;
    }

    @Action
    [Actions.GET_USER_LIST](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };

      return ApiService.query(`${domainUrlAdmin}users`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TOTAL_USER_LISTS, data.meta.total);
          this.context.commit(Mutations.SET_USER_LISTS_CURRENT_PAGE, data.meta.current_page);
          this.context.commit(Mutations.SET_USER_LISTS_LAST_PAGE, data.meta.last_page);
          this.context.commit(Mutations.SET_ALL_USER_LISTS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_LISTS, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_USER_LIST_EXPORT](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}users`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER_LISTS_EXPORT, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_LISTS, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_USER](id) {
      return ApiService.get(`${domainUrlAdmin}users`, id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER_LIST, data.data);
          this.context.commit(Mutations.SET_ERROR_USER_LISTS, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_LISTS, response?.data?.errors);
        });
    }

    @Action
    [Actions.UPDATE_USER_LIST](params) {
      const { id, ...data } = params;
      return ApiService.update(`${domainUrlAdmin}users`, id, data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PERMISSION_FUNCTION, data.data);
          this.context.commit(Mutations.SET_ERROR_USER_LISTS, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_LISTS, response?.data?.errors);
        }).finally(() => {
          // this.context.commit(Mutations.SET_LOADING, false);
        });
    }

    @Action
    [Actions.DELETE_USER_LIST](id) {
      return ApiService.delete(`${domainUrlAdmin}users/` + id)
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_LISTS, response?.data?.errors);
        }).finally(() => {
          // this.context.commit(Mutations.SET_LOADING, false);
        });
    }
}
