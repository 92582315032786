import { RolesEnums } from '@/store/enums/RolesEnums';

const terms = {
  path: '/',
  redirect: '/privacy',
  component: () => import('@/layout/Layout.vue'),
  meta: { roles: [RolesEnums.ALL] },
  children: [
    {
      path: '/privacy',
      name: 'privacy-policy-page',
      meta: { roles: [RolesEnums.ALL] },
      component: () => import('@/views/pages/PrivacyPolicy.vue')
    }

  ]
};
export default terms;
