const statistics = {
  totalByProduct:'Усього за продуктом',
  totalKeywords:'Усього ключових слів',
  totalCampaigns:'Усього кампаній',
  date:'Дата',
  byKeyword:'За ключовим словом',
  byPackage:'За упаковкою',
  rates:'Тарифи',
  reviews:'Відгуки',
  HQReviews:'HQ Відгуки',
  keyword:'Ключове слово',
  installs:'Встановлених',
  rank:'Ранг',
  export:'Експорт',
  installsByDate:'Установок за датою',
  installsByKeyword:'Установок за ключовим словом',
  noInstallsFound:'Установок не найдено',
  howManyInstallsDoYouSellMonthly:'Скільки установок ви продаєте щомісяця?',
  accordingToGooglePlayConsole:'Згідно Google Play Console: щоденні дані повідомляються в часовому поясі PST (UTC -9:00), погодинні - у часовому поясі локального браузера (UTC +2:00), весь виторг повідомляється в UTC.',
  id:'ID',
  type:'Тип',
  country:'Країна',
  days:'Дні',
  price:'Ціна',
  startTime:'Початок часу',
  status:'Статус',
  campaigns:'Кампанії',
  noCampaignsFound:'Кампаній не знайдено',
  overallInstalls:'Загальні встановлення',
  overallSpendings:'Загальні витрати',
  overallKeywords:'Загальні ключові слова',
  overallCampaigns:'Загальні кампанії',
  statisticByKeywords:'Статистика за ключовими словами',
  campaignsAndSpendings:'Кампанії та витрати',
  totalSpend:'Загальні витрати',
  totalInstalls:'Усього встановлень',
  reportByDate:'Звіт за датою',
  results:'результати',
  noReportsFound:'Звіти не знайдено',
  spendings: 'Витрати',
  spend:'Витрачати',
  reportByKeyword:'Звіт за ключовим словом',
  cumulativeReport:'Зведений звіт',
  spentFor:'Витрачено на',
  till:'до',
  reportFor:'Звіт за',
  reportForAllTime:'Звіт за весь час',
};

export default statistics;
