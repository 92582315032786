const myCampaignsPage = {
  notFoundCampaigns: 'Кампаний не найдено',
  header: {
    toolbar: {
      all: 'Все',
      active: 'Активный',
      pause: 'Остановление',
      draft: 'Черновик',
      failed: 'Не удалось',
      completed: 'Завершенный',
      canceled: 'Отменено',
      archived: 'В архиве'
    }
  },
  table: {
    myCampaigns: 'Мои кампании',
    showing: 'Показаны',
    campaignsOf: 'кампании',
    found: 'Найденный',
    campaignFrom: 'кампания(и) от',
    nothingFoundBySearch: 'Поиском ничего не нашел',
    noCampaignsFound: 'Кампаний не найдено',
    noOrdersFound: 'Заказов не найдено',
    showAll: 'показать все',
    showDetails: 'показать детали',
    details: 'детали',
    spreadInstallsFor24h: 'Распространение установок за 24 часа',
    showReviews: 'Показать отзывы',
    promotionRuleStopsPromotionIfRankReachedRequiredLevel: 'Правило повышения останавливает продвижение, если ранг достиг необходимого уровня',
    installsDeliveryType: 'Устанавливает тип доставки',
    vendor: 'Поставщик',
    changeVendor: 'Сменить поставщика'
  },
  tableDropdown: {
    quickActions: 'Меню быстрых действий',
    exportCampaigns: 'Экспорт кампаний',
    refresh: 'Обновить',
    archiveAll: 'Заархивируй все'
  },
  actionsDropdown: {
    start: 'Начать',
    pause: 'Пауза',
    cancel: 'Отмена',
    archive: 'Архив',
    edit: 'Редактировать',
    resume: 'Восстановить',
    details: 'Детали',
    update: 'Обновить',
    reject: 'Отклонить',
    repeat: 'Повторение',
    moderation: 'Отправить на модерацию',
    forcedCompletion: 'Принудительное завершение',
    forcedDraft: 'Принудительный черновик',
  },
  modalReview: {
    title: 'Мои отзывы о кампании',
    exportReviews: 'Экспорт отзывов'
  }

};
export default myCampaignsPage;
