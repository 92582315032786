import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { AxiosRequestConfig } from 'axios';
import ApiService from '@/core/services/ApiService';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { domainUrlAdmin } from '@/core/config/DomainConfig';
import { ErrorGetters } from '@/store/enums/ErrorGettersEnums';
import { Getters } from '@/store/enums/GettersEnums';

@Module({ name: 'SalesReportMyClientModule' })
export default class SalesReportMyClientModule extends VuexModule implements StoreModel {
  errors = {};
  salesReportMyClients = [];
  export = [];
  queryParams = {};
  totalSalesReportMyClients = 0;
  currentPageSalesReportMyClients = 1;
  lastPageSalesReportMyClients = 1;
  pageSalesReportMyClients = 1;
  rowsPerPageSalesReportMyClients = 10;
  searchSalesReportMyClients = '';
  limitSalesReportMyClients = 10;
  orderBySalesReportMyClients = 'id';
  sortBySalesReportMyClients = 'desc';
  priceplan = 0;
  offerStatusSalesReportMyClients = 0;

  /**
     * Get current page
     * @returns number
     */
  get [Getters.GET_SALES_REPORT_MY_CLIENTS_CURRENT_PAGE]() {
    return this.currentPageSalesReportMyClients;
  }

  /**
     * Get last page
     * @returns number
     */
  get [Getters.GET_SALES_REPORT_MY_CLIENTS_LAST_PAGE]() {
    return this.lastPageSalesReportMyClients;
  }

  /**
     * Get page
     * @returns number
     */
  get [Getters.GET_SALES_REPORT_MY_CLIENTS_PAGE]() {
    return this.pageSalesReportMyClients;
  }

  /**
     * Get rows
     * @returns number
     */
  get [Getters.GET_SALES_REPORT_MY_CLIENTS_ROWS]() {
    return this.rowsPerPageSalesReportMyClients;
  }

  /**
     * Get search
     * @returns string
     */
  get [Getters.GET_SALES_REPORT_MY_CLIENTS_SEARCH]() {
    return this.searchSalesReportMyClients;
  }

  /**
     * Get limit
     * @returns string
     */
  get [Getters.GET_SALES_REPORT_MY_CLIENTS_LIMIT]() {
    return this.limitSalesReportMyClients;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [ErrorGetters.GET_ERRORS_SALES_REPORT_MY_CLIENTS]() {
    return this.errors;
  }

  /**
     * Get current Transaction object
     * @returns array
     */
  get [Getters.ALL_SALES_REPORT_MY_CLIENTS]() {
    return this.salesReportMyClients;
  }

  /**
     * Get current Transaction object
     * @returns array
     */
  get [Getters.ALL_SALES_REPORT_MY_CLIENTS_EXPORT]() {
    return this.export;
  }

  /**
     * Get current Total Transactions
     * @returns number
     */
  get [Getters.GET_TOTAL_SALES_REPORT_MY_CLIENTS]() {
    return this.totalSalesReportMyClients;
  }

  /**
     * Get current Total Transactions
     * @returns number
     */
  get [Getters.GET_SALES_REPORT_MY_CLIENTS_PRICEPLAN]() {
    return this.priceplan;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_SALES_REPORT_MY_CLIENTS_ORDER_BY]() {
    return this.orderBySalesReportMyClients;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_SALES_REPORT_MY_CLIENTS_QUERY_PARAMS]() {
    return this.queryParams;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_SALES_REPORT_MY_CLIENTS_SORT_BY]() {
    return this.sortBySalesReportMyClients;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_SALES_REPORT_MY_CLIENTS_OFFER_STATUS]() {
    return this.offerStatusSalesReportMyClients;
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.errors = {};
    this.salesReportMyClients = [];
    this.export = [];
    this.queryParams = {};
    this.totalSalesReportMyClients = 0;
    this.currentPageSalesReportMyClients = 1;
    this.lastPageSalesReportMyClients = 1;
    this.pageSalesReportMyClients = 1;
    this.rowsPerPageSalesReportMyClients = 10;
    this.searchSalesReportMyClients = '';
    this.limitSalesReportMyClients = 10;
    this.orderBySalesReportMyClients = 'id';
    this.sortBySalesReportMyClients = 'desc';
    this.priceplan = 0;
    this.offerStatusSalesReportMyClients = 0;
  };

    @Mutation
    [Mutations.SET_SALES_REPORT_MY_CLIENTS_ROWS](payload) {
      this.rowsPerPageSalesReportMyClients = payload;
    }

    @Mutation
    [Mutations.SET_SALES_REPORT_MY_CLIENTS_OFFER_STATUS](payload) {
      this.offerStatusSalesReportMyClients = payload;
    }


    @Mutation
    [Mutations.SET_ERROR_SALES_REPORT_MY_CLIENTS](error) {
      this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_ALL_SALES_REPORT_MY_CLIENTS](payload) {
      this.salesReportMyClients = payload;
    }

    @Mutation
    [Mutations.SET_SALES_REPORT_MY_CLIENTS_EXPORT](payload) {
      this.export = payload;
    }

    @Mutation
    [Mutations.SET_TOTAL_SALES_REPORT_MY_CLIENTS](payload) {
      this.totalSalesReportMyClients = payload;
    }

    @Mutation
    [Mutations.SET_SALES_REPORT_MY_CLIENTS_CURRENT_PAGE](payload) {
      this.currentPageSalesReportMyClients = payload;
    }

    @Mutation
    [Mutations.SET_SALES_REPORT_MY_CLIENTS_LAST_PAGE](payload) {
      this.lastPageSalesReportMyClients = payload;
    }

    @Mutation
    [Mutations.SET_SALES_REPORT_MY_CLIENTS_SEARCH](payload) {
      this.searchSalesReportMyClients = payload;
    }

    @Mutation
    [Mutations.SET_SALES_REPORT_MY_CLIENTS_PRICEPLAN](payload) {
      this.priceplan = payload;
    }

    @Mutation
    [Mutations.SET_SALES_REPORT_MY_CLIENTS_ORDER_BY](payload) {
      this.orderBySalesReportMyClients = payload;
    }

    @Mutation
    [Mutations.SET_SALES_REPORT_MY_CLIENTS_SORT_BY](payload) {
      this.sortBySalesReportMyClients = payload;
    }

    @Mutation
    [Mutations.SET_SALES_REPORT_MY_CLIENTS_QUERY_PARAMS](payload) {
      this.queryParams = payload;
    }

    @Action
    [Actions.GET_SALES_REPORT_MY_CLIENTS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };

      return ApiService.query(`${domainUrlAdmin}sales-reports`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TOTAL_SALES_REPORT_MY_CLIENTS, data.meta.total);
          this.context.commit(Mutations.SET_SALES_REPORT_MY_CLIENTS_CURRENT_PAGE, data.meta.current_page);
          this.context.commit(Mutations.SET_SALES_REPORT_MY_CLIENTS_LAST_PAGE, data.meta.last_page);
          this.context.commit(Mutations.SET_ALL_SALES_REPORT_MY_CLIENTS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_SALES_REPORT_MY_CLIENTS, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_SALES_REPORT_MY_CLIENTS_EXPORT](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}sales-reports`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SALES_REPORT_MY_CLIENTS_EXPORT, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_SALES_REPORT_MY_CLIENTS, response?.data?.errors);
        });
    }

    @Action
    [Actions.CHANGE_SALES_REPORT_MY_CLIENTS_STATUS](params) {
      return ApiService.post(`${domainUrlAdmin}sales-reports/change-status`, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR_SALES_REPORT_MY_CLIENTS, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_SALES_REPORT_MY_CLIENTS, response?.data?.errors);
        });
    }

    @Action
    [Actions.CHANGE_SALES_REPORT_MY_CLIENTS_HISTORY_NOTE](params) {
      return ApiService.post(`${domainUrlAdmin}sales-reports/change-history-note`, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR_SALES_REPORT_MY_CLIENTS, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_SALES_REPORT_MY_CLIENTS, response?.data?.errors);
        });
    }
}
