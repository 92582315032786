const resellersProgram = {
  resellersProgram:'Программа для реселлеров',
  dearUser:'Дорогой пользователь! Вы можете подать заявку здесь на Reseller Base и Reseller Gold ',
  pricePlans:'тарифные планы',
  itGivesYouSignificantDiscount:'Это дает вам значительную скидку на стоимость установки. Пожалуйста, прочтите ',
  requirements:'требования ',
  carefullyBeforeFillingInTheForm:'внимательно перед заполнением формы',
  feelFreeTo:'Не стесняйся ',
  contact:'контакт ',
  usIfYouHaveAnyQuestions:'нам, если у вас есть какие-либо вопросы об этой форме утверждения торгового посредника и ',
  weNeedMoreInformationAboutYou:'Нам нужно больше информации о Вас',
  whereDoYourCustomersComeFrom:'Откуда приходят ваши клиенты?',
  yourWebsitesBlog:'Ваши сайты, блог',
  pleaseDescribeYourSources:'Пожалуйста, опишите ваши источники, чтобы мы могли понять, что у вас есть регулярный поток новых клиентов.',
  whatChannelsDoYouUseToFindClients:'Какие каналы вы используете для поиска клиентов?',
  whatServicesDoYouProvide:'Какие услуги вы предоставляете?',
  whatOtherSuppliersDoYouHave:'Какие еще у вас есть поставщики?',
  pleaseProvideYourSuppliesLineByLine:'Пожалуйста, предоставьте свои поставки построчно',
  youMayBeUsingOneOfOurServices:'Вы можете использовать одну из наших услуг, даже не подозревая об этом. Если один из ваших провайдеров принадлежит нам, мы объединим ваши тарифные планы, чтобы вы тратили меньше.',
  howManyInstallsDoYouSellMonthly:'Сколько установок вы продаете ежемесячно?',
  applyAsReseller:'Подать заявку в качестве торгового посредника',
  seo:'SEO',
  paidSearch:'Платный поиск',
  forumsBlogs:'Форумы, блоги',
  referralProgram:'Реферальная программа',
  smm:'SMM',
  other:'Другой',
  androidInstalls:'Android устанавливает',
  iosInstalls:'iOS устанавливает',
  aso:'ASO',
  userAcquisition:'Привлечение пользователей',
  marketing:'Маркетинг',
  less:'меньше',
  pleaseSelectAnyService:'Выберите любую услугу',
  pleaseSelectAnyChannel:'Выберите любой канал',
  yourReSellerRequestWasCreatedSuccessfully:'Ваш запрос реселлера был успешно создан!',
};

export default resellersProgram;
