const googleAds = {
  googleAds: 'Ads Report',
  showing: 'Showing',
  googleAdsOf: 'records of',
  noGoogleAdsFound: 'Records not found',
  addNewRecords: 'Add new records',
  successfullyAdded: 'All records successfully added',
  delete: 'Delete',
};

export default googleAds;
