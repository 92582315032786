import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { AxiosRequestConfig } from 'axios';
import ApiService from '@/core/services/ApiService';
import { domainUrlAdmin } from '@/core/config/DomainConfig';
import { ErrorGetters } from '@/store/enums/ErrorGettersEnums';
import { Getters } from '@/store/enums/GettersEnums';

@Module({ name: 'AdminReflinkModule' })
export default class AdminReflinkModule extends VuexModule implements StoreModel {
  errors = {};
  reflinks = [];
  currentReflinkId = 0;
  totalReflinks = 0;
  currentPage = 1;
  lastPage = 1;
  page = 1;
  rowsPerPage = 10;
  search = '';
  limit = 10;
  category = 0;
  orderBy = 'created_at';
  sortBy = 'desc';
  queryParams = {};
  export = [];

  /**
     * Get current page
     * @returns number
     */
  get [Getters.GET_ADMIN_REFLINKS_CURRENT_PAGE]() {
    return this.currentPage;
  }

  /**
     * Get current page
     * @returns number
     */
  get [Getters.GET_CURRENT_ADMIN_REFLINKS_ID]() {
    return this.currentReflinkId;
  }

  /**
     * Get last page
     * @returns number
     */
  get [Getters.GET_ADMIN_REFLINKS_LAST_PAGE]() {
    return this.lastPage;
  }

  /**
     * Get page
     * @returns number
     */
  get [Getters.GET_ADMIN_REFLINKS_PAGE]() {
    return this.page;
  }

  /**
     * Get rows
     * @returns number
     */
  get [Getters.GET_ADMIN_REFLINKS_ROWS]() {
    return this.rowsPerPage;
  }

  /**
     * Get search
     * @returns string
     */
  get [Getters.GET_ADMIN_REFLINKS_SEARCH]() {
    return this.search;
  }

  /**
     * Get limit
     * @returns string
     */
  get [Getters.GET_ADMIN_REFLINKS_LIMIT]() {
    return this.limit;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [ErrorGetters.GET_ERRORS_ADMIN_REFLINKS]() {
    return this.errors;
  }

  /**
     * Get current Transaction object
     * @returns array
     */
  get [Getters.ALL_ADMIN_REFLINKS]() {
    return this.reflinks;
  }

  /**
     * Get current Total Transactions
     * @returns number
     */
  get [Getters.GET_TOTAL_ADMIN_REFLINKS]() {
    return this.totalReflinks;
  }

  /**
     * Get current Total Transactions
     * @returns number
     */
  get [Getters.GET_ADMIN_REFLINKS_CATEGORY]() {
    return this.category;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ADMIN_REFLINKS_ORDER_BY]() {
    return this.orderBy;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ADMIN_REFLINKS_SORT_BY]() {
    return this.sortBy;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ADMIN_REFLINKS_QUERY_PARAMS]() {
    return this.queryParams;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ADMIN_REFLINKS_EXPORT_DATA]() {
    return this.export;
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.errors = {};
    this.reflinks = [];
    this.totalReflinks = 0;
    this.currentPage = 1;
    this.lastPage = 1;
    this.page = 1;
    this.rowsPerPage = 10;
    this.search = '';
    this.limit = 10;
    this.category = 0;
    this.orderBy = 'date';
    this.sortBy = 'desc';
    this.queryParams = {};
    this.export = [];
  };

    @Mutation
    [Mutations.SET_ADMIN_REFLINKS_ROWS](payload) {
      this.rowsPerPage = payload;
    }

    @Mutation
    [Mutations.SET_CURRENT_ADMIN_REFLINKS_ID](payload) {
      this.currentReflinkId = payload;
    }

    @Mutation
    [Mutations.SET_ERROR_ADMIN_REFLINKS](error) {
      this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_ALL_ADMIN_REFLINKS](payload) {
      this.reflinks = payload;
    }

    @Mutation
    [Mutations.SET_TOTAL_ADMIN_REFLINKS](payload) {
      this.totalReflinks = payload;
    }

    @Mutation
    [Mutations.SET_ADMIN_REFLINKS_CURRENT_PAGE](payload) {
      this.currentPage = payload;
    }

    @Mutation
    [Mutations.SET_ADMIN_REFLINKS_LAST_PAGE](payload) {
      this.lastPage = payload;
    }

    @Mutation
    [Mutations.SET_ADMIN_REFLINKS_SEARCH](payload) {
      this.search = payload;
    }

    @Mutation
    [Mutations.SET_ADMIN_REFLINKS_CATEGORY](payload) {
      this.category = payload;
    }

    @Mutation
    [Mutations.SET_ADMIN_REFLINKS_ORDER_BY](payload) {
      this.orderBy = payload;
    }

    @Mutation
    [Mutations.SET_ADMIN_REFLINKS_SORT_BY](payload) {
      this.sortBy = payload;
    }

    @Mutation
    [Mutations.SET_ADMIN_REFLINKS_QUERY_PARAMS](payload) {
      this.queryParams = payload;
    }

    @Mutation
    [Mutations.SET_ADMIN_REFLINKS_EXPORT](payload) {
      this.export = payload;
    }

    @Action
    [Actions.GET_ADMIN_REFLINKS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}reflinks`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TOTAL_ADMIN_REFLINKS, data.meta.total);
          this.context.commit(Mutations.SET_ADMIN_REFLINKS_CURRENT_PAGE, data.meta.current_page);
          this.context.commit(Mutations.SET_ADMIN_REFLINKS_LAST_PAGE, data.meta.last_page);
          this.context.commit(Mutations.SET_ALL_ADMIN_REFLINKS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_ADMIN_REFLINKS, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_ADMIN_REFLINKS_EXPORT](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}reflinks`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ADMIN_REFLINKS_EXPORT, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_ADMIN_REFLINKS, response?.data?.errors);
        });
    }

    @Action
    [Actions.ADD_ADMIN_REFLINKS](params) {
      return ApiService.post(`${domainUrlAdmin}reflinks`, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR_ADMIN_REFLINKS, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_ADMIN_REFLINKS, response?.data?.errors);
        }).finally(() => {
          this.context.commit(Mutations.SET_LOADING, false);
        });
    }

    @Action
    [Actions.UPDATE_ADMIN_REFLINK](params) {
      const { id, ...data } = params;
      return ApiService.update(`${domainUrlAdmin}reflinks`, id, data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR_ADMIN_REFLINKS, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_ADMIN_REFLINKS, response?.data?.errors);
        }).finally(() => {
          this.context.commit(Mutations.SET_LOADING, false);
        });
    }

    @Action
    [Actions.DELETE_ADMIN_REFLINKS](id) {
      return ApiService.delete(`${domainUrlAdmin}reflinks/` + id)
        .then(({ data }) => {
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_ADMIN_REFLINKS, response?.data?.errors);
        }).finally(() => {
          this.context.commit(Mutations.SET_LOADING, false);
        });
    }
}
