import { useStore } from 'vuex';

const useRolesMixin = (storeParams: any = null) => {
  const store = (storeParams) ?? useStore();
  const canShowComponentByRole = (arRoles: string[], componentName: null | string = null): boolean => {
    const userRoles: string[] = store?.getters?.getUserRoles ?? [];
    return userRoles.some(value => arRoles.includes(value));
  };

  return {
    canShowComponentByRole
  };
};

export default useRolesMixin;
