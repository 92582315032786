const referralProgram = {
    allUsers: 'All users',
    usersWithPayments: 'Users with payments',
    welcomeToOurProgram: 'Welcome to our referral program!',
    youCanEarnMoney: 'You can earn money by recommending our service to other users.',
    oneUserRegistersUsingYourReferralLink: 'Once a user registers using your referral link you will receive <b>10%</b> from all his payments in our service for lifetime. You can receive a referral payout any time you want, directly to your account balance or with preferred withdrawal method (crypto, wire, etc.)',
    refLink: 'Ref link',
    pleaseBeAdviced: 'Please be advised that using multiaccounting in our platform is prohibited, if we find that you are abusing our system in any way your referral payouts will not be credited to your account, nor you will be able to receive them as withdrawal. Continuous violations may lead to account suspension with funds being held in your suspended account.For example making payments for your main and referral account from same Paypal email is prohibited.',
    date: 'Date',
    totalPayments: 'Total payments',
    level: 'Level',
    userName: 'User name',
    userEmail: 'User email',
    newPayments: 'New payments',
    newPayouts: 'New payments',
    earnings: 'Earnings',
    registered: 'Registered',
    paymentsHistory: 'Payments history',
    payoutsHistory: 'Payouts history',
    payments: 'payments',
    payouts: 'payouts',
    showing: 'Showing',
    myReferrals: 'My referrals',
    referrals: 'referrals',
    noPaymentsFound: 'No payments found',
    noReferralsFound: 'No referrals found',
    show: 'Show',
    showAll: 'Show all',
    showActive: 'Show active',
    showOnly: 'Show only',
    myReferralLink: 'My referral link',
    totalReferrals: 'Total referrals',
    paymentsOfReferrals: 'Payments of referrals',
    totalPayouts: 'Total payouts',
    newPayout: 'Next payout',
    requestPayout: 'Request payout',
    status: 'Status',
    all: 'All',
    hasPayments: 'Has Payments',
    withPayouts: 'With payments',
    yourPayoutRequestHasBeenCanceled: 'Your payout request has been canceled. Please contact support',
    yourPayoutHasBeenCompletedSuccessfully: 'Your payout has been completed successfully. The amount of',
    hasBeenAddedToYourAccount: '$ has been added to your account.',
};

export default referralProgram;
