const register = {
  forgotPassword:'Забули пароль?',
  enterYourEmailBelowToResetYourPassword:'Введіть свою електронну адресу нижче, щоб скинути пароль.',
  email:'Електронна пошта',
  cancel:'Скасувати',
  submit:'Надіслати',
  pleaseWait:'Будь ласка, зачекайте...',
  weHaveSentYouEmail:'Ми надіслали вам електронний лист із інструкціями щодо зміни пароля.',
  okLetMeIn:'Гаразд, впусти мене!',
  tryAgain:'Спробуйте знову!',
  resetPassword:'Скинути пароль?',
  password:'Пароль',
  useMoreCharactersWithMixOfLetters:'Використовуйте 8 або більше символів із поєднанням літер, цифр і символів.',
  confirmPassword:'Підтвердьте пароль',
  passwordsMustMatch:'Паролі мають збігатися',
  passwordConfirmation:'Підтвердження паролю',
  yourPasswordIsSuccessfullyChanged:'Ваш пароль успішно змінено!',
  loginToYourAccount:'Увійдіть до свого облікового запису',
  orLoginWithEmail:'або увійдіть за допомогою електронної пошти',
  newHere:'Новий тут?',
  createNewAccount:'Створити новий обліковий запис',
  createAnAccount:'Створити аккаунт',
  loginToAccount:'Увійти в обліковий запис',
  yourAccountIsNotActivated:'Ваш обліковий запис не активовано. Будь ласка, перевірте свою електронну пошту на наявність інструкцій щодо перевірки або натисніть кнопку «Надіслати повторно».',
  activationEmeilWasSuccessfullySent:'Електронний лист для активації успішно надіслано. Будь ласка, перевірте свою електронну пошту.',
  orEnterYourNewAccountDetailsBelow:'або введіть дані свого нового облікового запису нижче',
  orEnterYourDetails: 'або введіть свої дані',
  username:'Ім\'я користувача',
  iAmEighteenYearsOldOrOlder:'Я підтверджую, що мені 18 років або більше',
  alreadyHaveAnAccount:'Вже є аккаунт?',
  login:'Логін',
  toc:'Toc',
  agreement:'Згодження',
  yourAccountHasBeenCreated:'Ваш обліковий запис створено, і ви отримали електронний лист для активації. Після активації ви зможете увійти',
  byCreatingAnAccountWithUs:'Створюючи обліковий запис у нас, ви автоматично приймаєте наші',
};

export default register;
