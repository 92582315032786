import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { AxiosRequestConfig } from 'axios';
import { MyCampaignsModel } from '@/assets/ts/_utils/models/MyCampaignsModel';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { domainUrl, domainUrlAdmin } from '@/core/config/DomainConfig';
import { serializedPropsElement } from '@/assets/ts/_utils';
import { ErrorGetters } from '@/store/enums/ErrorGettersEnums';
import { Getters } from '@/store/enums/GettersEnums';

export interface MyCampaignsInfo {
    errors: unknown;
    campaigns: unknown;
    beforeChangeStatusCampaign: unknown;
}

@Module({ name: 'MyCampaignsModule' })
export default class MyCampaignsModule extends VuexModule implements MyCampaignsInfo, StoreModel {
  errors = {};
  campaigns = [] as MyCampaignsModel[];
  exportCampaigns = [] as MyCampaignsModel[];
  loadedCpiModalIds: string[] = [];
  campaignPartners = [];
  campaignCurrent = {} as MyCampaignsModel;
  totalCampaigns = 0;
  campaignsCounts = {};
  entity = {};
  queryParams = {};
  campaignsCurrentPage = 1;
  campaignsLastPage = 1;
  beforeChangeStatusCampaign = {} as MyCampaignsModel;
  campaignsLoaded = [] as MyCampaignsModel[];
  campaignsShowAllOrders: number[] = [];
  updateCampaigns = Date.now();
  myCampaignsFilters = [];
  myCampaignsFilterShouldBeReloaded = true;
  campaignIdTopFilter: number | null = null;
  orderBy: string = 'default';
  sortBy: string = 'asc';
  rowPerPage: number = 10;

  /**
     * Get campaignIdTopFilter
     * @returns number
     */
  get [Getters.GET_CAMPAIGN_ID_TOP_FILTER]() {
    return this.campaignIdTopFilter;
  }

  get [Getters.GET_MY_CAMPAIGNS_FILTER_SHOULD_BE_RELOADED]() {
    return this.myCampaignsFilterShouldBeReloaded;
  }

  /**
     * Get campaignIdTopFilter
     * @returns number
     */
  get getCampaignOrderBy() {
    return this.orderBy;
  }

  /**
     * Get campaignIdTopFilter
     * @returns number
     */
  get getCampaignSortBy() {
    return this.sortBy;
  }

  get getCampaignRowPerPage() {
    return this.rowPerPage;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [ErrorGetters.GET_ERRORS_MY_CAMPAIGNS]() {
    return this.errors;
  }

  /**
     * Get current beforeChangeStatusCampaign
     * @returns beforeChangeStatusCampaign
     */
  get [Getters.GET_BEFORE_CHANGE_STATUS_CAMPAIGN]() {
    return this.beforeChangeStatusCampaign;
  }

  /**
     * Get  campaigns
     * @returns object
     */
  get [Getters.GET_MY_CAMPAIGNS_COUNT]() {
    return this.campaignsCounts;
  }

  /**
     * Get  campaigns Query Params
     * @returns object
     */
  get [Getters.GET_MY_CAMPAIGNS_QUERY_PARAMS]() {
    return this.queryParams;
  }

  /**
     * Get  campaigns entity
     * @returns string
     */
  get [Getters.GET_MY_CAMPAIGNS_ENTITY]() {
    return this.entity;
  }

  /**
     * Get  campaigns
     * @returns array
     */
  get [Getters.GET_MY_CAMPAIGNS]() {
    return this.campaigns;
  }

  /**
     * Get export  campaigns
     * @returns array
     */
  get [Getters.GET_EXPORT_MY_CAMPAIGNS]() {
    return this.exportCampaigns;
  }

  /**
     * Get campaigns archived
     * @returns number
     */
  get [Getters.GET_TOTAL_CAMPAIGNS]() {
    return this.totalCampaigns;
  }

  /**
     * Get campaigns archived
     * @returns number
     */
  get [Getters.GET_CURRENT_PAGE]() {
    return this.campaignsCurrentPage;
  }

  /**
     * Get campaigns archived
     * @returns number
     */
  get [Getters.GET_LAST_PAGE]() {
    return this.campaignsLastPage;
  }

  /**
     * Get campaigns archived
     * @returns object
     */
  get [Getters.GET_CURRENT_CAMPAIGN]() {
    return this.campaignCurrent;
  }

  /**
     * Get loaded campaigns
     * @returns object
     */
  get [Getters.GET_LOADED_CAMPAIGNS]() {
    return this.campaignsLoaded;
  }

  get [Getters.GET_CAMPAIGN_SHOW_ALL_ORDERS]() {
    return this.campaignsShowAllOrders;
  }

  /**
     * Get loaded campaign partners
     * @returns object
     */
  get [Getters.GET_CAMPAIGN_PARTNERS]() {
    return this.campaignPartners;
  }

  /**
     * Get update campaigns
     * @returns boolean
     */
  get [Getters.GET_UPDATE_CAMPAIGNS]() {
    return this.updateCampaigns;
  }

  /**
     * Get campaign filters
     * @returns boolean
     */
  get [Getters.GET_MY_CAMPAIGNS_FILTERS]() {
    return this.myCampaignsFilters;
  }

  get [Getters.GET_LOADED_CPI_MODAL_IDS]() {
    return this.loadedCpiModalIds;
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.errors = {};
    this.campaigns = [] as MyCampaignsModel[];
    this.exportCampaigns = [] as MyCampaignsModel[];
    this.campaignPartners = [];
    this.campaignCurrent = {} as MyCampaignsModel;
    this.totalCampaigns = 0;
    this.campaignsCounts = {};
    this.queryParams = {};
    this.campaignsCurrentPage = 1;
    this.campaignsLastPage = 1;
    this.beforeChangeStatusCampaign = {} as MyCampaignsModel;
    this.campaignsLoaded = [] as MyCampaignsModel[];
    this.updateCampaigns = Date.now();
    this.myCampaignsFilters = [];
    this.campaignIdTopFilter = null;
    this.campaignsShowAllOrders = [];
    this.loadedCpiModalIds = [];
    this.myCampaignsFilterShouldBeReloaded = true;
    this.orderBy = 'default';
    this.sortBy = 'asc';
    this.rowPerPage = 10;
  };

    @Mutation
    [Mutations.SET_LOADED_CPI_MODAL_IDS](payload) {
      this.loadedCpiModalIds = payload;
    }

    @Mutation
    [Mutations.SET_CAMPAIGN_ID_TOP_FILTER](payload) {
      this.campaignIdTopFilter = payload;
    }

    @Mutation
    [Mutations.SET_UPDATE_CAMPAIGNS](payload) {
      this.updateCampaigns = payload;
    }

    @Mutation
    [Mutations.SET_MY_CAMPAIGNS_COUNTS](payload) {
      this.campaignsCounts = payload;
    }

    @Mutation
    [Mutations.SET_ERROR_MY_CAMPAIGNS](error) {
      this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_MY_CAMPAIGNS](campaigns) {
      this.campaigns = campaigns;
    }

    @Mutation
    [Mutations.SET_EXPORT_MY_CAMPAIGNS](exportCampaigns) {
      this.exportCampaigns = exportCampaigns;
    }

    @Mutation
    [Mutations.SET_MY_CAMPAIGNS_QUERY_PARAMS](queryParams) {
      this.queryParams = Object.assign({}, queryParams, { isExport: true });
    }

    @Mutation
    [Mutations.SET_MY_CAMPAIGNS_ENTITY_EXPORT](entity) {
      this.entity = entity;
    }

    @Mutation
    [Mutations.SET_MY_CAMPAIGNS_TOTAL](payload) {
      this.totalCampaigns = payload;
    }

    @Mutation
    [Mutations.SET_CURRENT_CAMPAIGN](payload) {
      this.campaignCurrent = payload;
    }

    @Mutation
    [Mutations.SET_CAMPAIGN_PARTNERS](payload) {
      this.campaignPartners = payload;
    }

    @Mutation
    [Mutations.SET_MY_CAMPAIGNS_CURRENT_PAGE](payload) {
      this.campaignsCurrentPage = payload;
    }

    @Mutation
    [Mutations.SET_MY_CAMPAIGNS_LAST_PAGE](payload) {
      this.campaignsLastPage = payload;
    }

    @Mutation
    [Mutations.SET_BEFORE_CHANGE_STATUS_CAMPAIGN](beforeChangeStatusCampaign) {
      this.beforeChangeStatusCampaign = beforeChangeStatusCampaign;
      this.campaigns = this.campaigns?.map((el) => {
        if (el.id === this.beforeChangeStatusCampaign.id) {
          return this.beforeChangeStatusCampaign;
        }
        return el;
      });
    }

    @Mutation
    [Mutations.SET_LOADED_CAMPAIGNS](payload) {
      this.campaignsLoaded = payload;
    }

    @Mutation
    [Mutations.SET_CAMPAIGNS_SHOW_ALL_ORDERS](payload) {
      this.campaignsShowAllOrders = payload;
    }

    @Mutation
    [Mutations.ADD_CAMPAIGNS_SHOW_ALL_ORDERS](payload) {
      this.campaignsShowAllOrders.push(payload);
    }

    @Mutation
    [Mutations.UPDATE_LOADED_CAMPAIGN](payload) {
      const foundCampaign = this.campaignsLoaded.find(campaign => campaign.id === payload.id);
      if (foundCampaign) {
        const index = this.campaignsLoaded.indexOf(foundCampaign);
        this.campaignsLoaded[index] = payload;
      }
    }

    @Mutation
    [Mutations.SET_MY_CAMPAIGNS_FILTERS](payload) {
      this.myCampaignsFilters = payload;
    }

    @Mutation
    [Mutations.SET_MY_CAMPAIGNS_FILTER_SHOULD_BE_RELOADED](payload) {
      this.myCampaignsFilterShouldBeReloaded = payload;
    }

    @Mutation
    [Mutations.SET_MY_CAMPAIGNS_ORDER_BY](payload) {
      this.orderBy = payload;
    }

    @Mutation
    [Mutations.SET_MY_CAMPAIGNS_SORT_BY](payload) {
      this.sortBy = payload;
    }

    @Mutation
    [Mutations.SET_MY_CAMPAIGNS_ROW_PER_PAGE](payload) {
      this.rowPerPage = payload;
    }

    @Action
    [Actions.GET_CAMPAIGNS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}campaigns`, config)
        .then(({ data }) => {
          const serializedData = data.data.map(el => ({ ...serializedPropsElement(el, 'price') }));
          if (params.isExport) {
            this.context.commit(Mutations.SET_EXPORT_MY_CAMPAIGNS, serializedData);
          } else {
            this.context.commit(Mutations.SET_MY_CAMPAIGNS_COUNTS, data.counts);
            this.context.commit(Mutations.SET_MY_CAMPAIGNS_TOTAL, data.meta.total);
            this.context.commit(Mutations.SET_MY_CAMPAIGNS_LAST_PAGE, data.meta.last_page);
            this.context.commit(Mutations.SET_MY_CAMPAIGNS, serializedData);
          }
        })
        .catch(({ response }) => {

          // this.context.commit(Mutations.SET_ERROR_MY_CAMPAIGNS, response?.data?.errors || response?.data?.error);
        });
    }

    @Action
    [Actions.TOGGLE_FAVORITE_CAMPAIGN](id: any) {
      const config: AxiosRequestConfig = {
        params: { id: id }
      };
      return ApiService.put(`${domainUrl}campaigns/toggle`, config)
        .then(({ data }) => {
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_MY_CAMPAIGNS, response?.data?.errors || response?.data?.error);
        }).finally(() => {
          this.context.commit(Mutations.SET_LOADING, false);
        });
    }

    @Action
    [Actions.UPDATE_CAMPAIGN](id) {
      const config: AxiosRequestConfig = {};
      if (!id) return;
      return ApiService.put(`${domainUrlAdmin}campaigns/${id}`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CURRENT_CAMPAIGN, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_MY_CAMPAIGNS, response?.data?.errors || response?.data?.error);
        });
    }

    @Action
    [Actions.GET_CAMPAIGNS_PARTNERS](id) {
      const config: AxiosRequestConfig = {
        method: 'GET',
      };
      if (!id) return;
      return ApiService.query(`${domainUrlAdmin}campaigns/${id}/partners`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CAMPAIGN_PARTNERS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_MY_CAMPAIGNS, response?.data?.errors || response?.data?.error);
        });
    }

    @Action
    [Actions.CURRENT_CAMPAIGN](id) {
      if (!id) return;
      return ApiService.get(`${domainUrl}campaigns/${id}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CURRENT_CAMPAIGN, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_MY_CAMPAIGNS, response?.data?.errors || response?.data?.error);
        });
    }

    @Action
    [Actions.START_CAMPAIGN](id) {
      if (!id) return;
      return ApiService.get(`${domainUrl}campaigns/${id}/start`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_BEFORE_CHANGE_STATUS_CAMPAIGN, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_MY_CAMPAIGNS, response?.data?.errors || response?.data?.error);
        });
    }

    @Action
    [Actions.PAUSE_CAMPAIGN](id) {
      if (!id) return;
      return ApiService.get(`${domainUrl}campaigns/${id}/pause`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_BEFORE_CHANGE_STATUS_CAMPAIGN, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_MY_CAMPAIGNS, response?.data?.errors || response?.data?.error);
        });
    }

    @Action
    [Actions.CANCEL_CAMPAIGN](id) {
      if (!id) return;
      return ApiService.get(`${domainUrl}campaigns/${id}/cancel`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_BEFORE_CHANGE_STATUS_CAMPAIGN, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_MY_CAMPAIGNS, response?.data?.errors || response?.data?.error);
        });
    }

    @Action
    [Actions.RESUME_CAMPAIGN](id) {
      if (!id) return;
      return ApiService.get(`${domainUrl}campaigns/${id}/resume`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_BEFORE_CHANGE_STATUS_CAMPAIGN, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_MY_CAMPAIGNS, response?.data?.errors || response?.data?.error);
        });
    }

    @Action
    [Actions.CLONE_CAMPAIGN](id) {
      if (!id) return;
      return ApiService.post(`${domainUrl}campaigns/${id}/clone`, {})
        .then(({ data }) => {
          this.context.commit(Mutations.SET_BEFORE_CHANGE_STATUS_CAMPAIGN, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_MY_CAMPAIGNS, response?.data?.errors || response?.data?.error);
        });
    }

    @Action
    [Actions.ARCHIVE_CAMPAIGN](id) {
      if (!id) return;
      return ApiService.get(`${domainUrl}campaigns/${id}/archive`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_BEFORE_CHANGE_STATUS_CAMPAIGN, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_MY_CAMPAIGNS, response?.data?.errors || response?.data?.error);
        });
    }

    @Action
    [Actions.ARCHIVE_ALL_CAMPAIGN](params) {
      return ApiService.post(`${domainUrl}campaigns/all/archive`, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR_MY_CAMPAIGNS, {});
        })
        .catch(({ response }) => {
          //this.context.commit(Mutations.SET_ERROR_MY_CAMPAIGNS, response?.data?.errors || response?.data?.error);
        });
    }

    @Action
    [Actions.GET_MY_CAMPAIGN_FILTERS](params) {
      return ApiService.post(`${domainUrl}campaigns/get-filters`, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_MY_CAMPAIGNS_FILTERS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_MY_CAMPAIGNS, response?.data?.errors || response?.data?.error);
        });
    }
}
