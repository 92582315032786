const application = {
  promote:'Promote',
  addApp:'Add new app',
  created:'Add new Application',
  addKeywords:'Add keywords',
  update:'Update',
  delete:'Delete',
  inputSelectCountry:'Please select Country',
  inputSelectApplication:'Please select App',
  inputAppstore:'Please select Appstore',
  deleteApp:'Application successfully uninstalled',
  updateApp:'App updated successfully',
  search:'Search',
  sure:'Are you sure?',
  not:'Nope, cancel it',
};
export default application;
