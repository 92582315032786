import { RolesEnums } from '@/store/enums/RolesEnums';

const app = {
  path: '/',
  redirect: '/resellers-program',
  component: () => import('@/layout/Layout.vue'),
  meta: { roles: [RolesEnums.ALL] },
  children: [
    {
      path: '/resellers-program',
      name: 'resellers-program',
      meta: { roles: [RolesEnums.ALL] },
      component: () => import('@/views/pages/ResellersProgram.vue')
    }

  ]
};
export default app;
