const vendor = {
  modal: {
    editVendor: 'Edit Record',
    exportVendor: 'Export',
    storeVendor: 'Add new record',
    share: 'Share',
    store: 'Store',
    vendor: 'Vendor',
    country: 'Country',
    mature: 'Mature',
    products: 'Products',
    successUpdate: 'Existing record was updated successfully',
    successStore: 'New record was added successfully',
    exportVendorsLoading: 'Export vendors loading...',
  },
  page: {
    adminVendors: 'Admin Vendors',
    adminVendorsOptions: 'Admin Vendor options',
    showing: 'showing',
    vendorsOf: 'vendors of',
    noVendorsFound: 'No Vendors Found',
  },
  tableDropdown: {
    quickActions: 'Quick Actions',
    refresh: 'Refresh',
    exportVendors: 'Export',
    prepareExportVendors: 'Prepare Export',
  },
  actionsDropdown: {
    edit: 'Edit',
    delete: 'Delete',
  },
  messages: {
    vendorDeleted: 'Vendor successfully deleted',
    vendorUpdated: 'Vendor successfully updated',
  }
};

export default vendor;
