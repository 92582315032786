import { Main } from '@/store/enums/MainEnums';
import { AppsSelectModel } from '@/assets/ts/_utils/models/AppsSelectModel';
import { useRouter } from 'vue-router';
import { formatPrice } from '@/assets/ts/_utils/MainHelper';
import { useStore } from 'vuex';
import { AppsDispatcherService } from '@/store/services/AppsDispatcherService';

export class AppsUtil {

  public static goGooglePlayMarket (packageId, country, language) {
    if (packageId && country && language) {
      return `https://play.google.com/store/apps/details?id=${packageId}&gl=${country?.toLowerCase()}&hl=${language}`;
    }
    return '#';
  }

  public static goAppStoreMarket (packageId, country, language) {
    if (packageId && country && language) {
      return `https://apps.apple.com/${country?.toLowerCase()}/app/id${packageId}?l=${language}`;
    }
    return '#';
  }

  public static goKeyword (router = useRouter(), packageId, appstore, country, target = '_self') {
    const params = {};
    if (packageId) {
      params['packageId'] = packageId;
      localStorage.setItem('packageId', packageId);
    }
    if (appstore) {
      params['appstore'] = appstore;
      localStorage.setItem('appstore', appstore);
    }
    if (country) {
      params['country'] = country;
      localStorage.setItem('country', country);
    }
    const routeData = router.resolve({ name: 'keywords', params });
    window.open(routeData.href, target);
  }

  public static goStatistics (router = useRouter(), packageId, appstore, country, target = '_self') {
    const params = {};
    if (packageId) {
      params['packageId'] = packageId;
      localStorage.setItem('packageId', packageId);
    }
    if (appstore) {
      params['appstore'] = appstore;
      localStorage.setItem('appstore', appstore);
    }
    if (country) {
      params['country'] = country;
      localStorage.setItem('country', country);
    }
    const routeData = router.resolve({ name: 'statistics', params });
    window.open(routeData.href, target);
  }

  public static goCampaigns (router = useRouter(), search, target = '_self') {
    const params = {};
    if (search) {
      params['search'] = search;
      localStorage.setItem('search', search);
    }
    const routeData = router.resolve({ name: 'campaigns', params });
    window.open(routeData.href, target);
  }

  public static goAddCampaigns (router = useRouter(), packageId, appstore, country, productType = Main.KEYWORD_INSTALLS, campaignId = null, target = '_self', repeat: boolean = false) {
    const params = {};
    if (packageId) {
      params['packageId'] = packageId;
      localStorage.setItem('packageId', packageId);
    }
    if (appstore) {
      params['appstore'] = appstore;
      localStorage.setItem('appstore', appstore);
    }
    if (productType) {
      params['productType'] = productType;
    }
    if (country) {
      params['country'] = country;
      localStorage.setItem('country', country);
    }
    if (repeat) {
      params['repeat'] = repeat;
      localStorage.setItem('repeat', 'true');
    }
    if (campaignId) {
      params['campaignId'] = campaignId;
      router.push({ name: 'add-campaigns', query: params });
    } else {
      const routeData = router.resolve({ name: 'add-campaigns', params, query: params });
      window.open(routeData.href, target);
    }

  }

  public static goTopUpBalance (router = useRouter(), search, target = '_self') {
    const params = {};
    if (search) {
      params['search'] = search;
      localStorage.setItem('search', search);
    }
    const routeData = router.resolve({ name: 'top-up-balance', params });
    window.open(routeData.href, target);
  }

  public static goApps (router = useRouter(), appstore, openModal, target = '_self') {
    const params = {};
    if (appstore) {
      params['appstore'] = appstore;
      localStorage.setItem('appstore', appstore);
    }
    if (openModal) {
      params['openModal'] = openModal;
      localStorage.setItem('openModal', openModal);
    }
    const routeData = router.resolve({ name: 'apps', params });
    window.open(routeData.href, target);
  }

  public static getSerializedDataApps (apps, selectedCountryId: string|null = null) {
    const appsResult: AppsSelectModel[] = [];
    apps.forEach((el) => {
      const tempItem: AppsSelectModel = {
        id: el?.app?.id,
        icon: el?.app?.icon || this.getPropsValue('icon', el?.app),
        package: el?.app?.package,
        developer: el?.app?.developer?.name,
        appstore: el?.app?.appstore?.tag,
        title: el?.app?.title || this.getPropsValue('title', el?.app),
        votes: el?.app?.votes || this.getPropsValue('votes', el?.app),
        is_alive: this.getIsAliveByCountryPropValue(el, selectedCountryId),
        price: this.getPropsValue('price', el?.app),
        is_favorite: el?.is_favorite,
        rating: formatPrice(el?.app?.rating || this.getPropsValue('rating', el?.app), 1),
        ratingRound: Math.round(el?.app?.rating || this.getPropsValue('rating', el?.app)),
        mostPromoted: el?.promotion_value ?? 0
      };
      appsResult.push(tempItem);
    });
    return appsResult;
  }

  public static getCorrectedParams (query: string) {
    let packageId: string | null = '';
    let lang: string | null = '';
    let country: string = '';
    let appstoreTemp: number = 0;
    if (AppsUtil.isPackageId(query)) {
      switch (AppsUtil.isPackageId(query)) {
        case Main.ANDROID:
          appstoreTemp = Main.STORE_GOOGLE_MARKET;
          break;
        case Main.IOS:
          appstoreTemp = Main.STORE_APPLE_MARKET;
          break;
      }
      packageId = query;
    }
    if (AppsUtil.isValidHttpUrl(query)) {
      const url = new URL(query);
      switch (url.host) {
        case 'play.google.com':
          if (url.searchParams.get('id')) {
            packageId = url.searchParams.get('id');
          }
          if (url.searchParams.get('gl')) {
            lang = url.searchParams.get('gl');
          }
          if (url.searchParams.get('hl')) {
            // @ts-ignore
            country = url.searchParams.get('hl')?.split('-')?.pop()?.toUpperCase();
          }
          appstoreTemp = Main.STORE_GOOGLE_MARKET;
          break;
        case 'apps.apple.com':
          if (url.searchParams.get('l')) {
            lang = url.searchParams.get('l')?.split('-').pop() as string;
          }
          const id = url.pathname.split('id').pop();
          if (id) {
            packageId = id;
          }
          country = url.pathname?.split('/')[1]?.toUpperCase();
          appstoreTemp = Main.STORE_APPLE_MARKET;
          break;
      }
    }
    return {
      appstoreTemp,
      packageId,
      lang,
      country
    };

  }

  public static formatNumber (value) {
    let res;

    (value.indexOf(',') != -1) ? res = new Intl.NumberFormat('ru-RU').format(value.replace(',', '.')).replace('.', ',') : res = new Intl.NumberFormat('ru-RU').format(value).replace(',', '.');

    return res;
  }

  public static getAppPropsContentRatingByCountry (element) {
    if (element?.app?.props?.length) {
      const foundItems = element?.app?.props?.filter(el => el.prop_name === 'content_rating');
      if (foundItems.length) {
        if (foundItems.length === 1) {
          return foundItems[0].prop_value;
        }
        else {
          let output = foundItems[0].prop_value;
          for (let i = 0; i < foundItems.length; i++) {
            if (foundItems[i].country_id?.id === element?.country?.id.toUpperCase()) {
              output = foundItems[i].prop_value;
            }
          }
          return output;
        }
      }
      return false;
    }
    else {
      return false;
    }
  }

  public static getIsAliveByCountryPropValue = (element, selectedCountryId: string|null = null) => {
    let isAlive = true;
    const countryId = element?.country?.id;

    if (countryId && selectedCountryId) {
      const foundProp = element?.app?.props?.find(prop => prop.prop_name === 'alive' && prop?.country_id?.id === selectedCountryId);
      if (foundProp && Object.keys(foundProp)) {
        isAlive = !!Number(foundProp?.prop_value);
      }
    }

    return isAlive;
  };

  public static getPropsValue (nameProp: string, element, prop_name = 'prop_name', prop_value = 'prop_value') {
    if (Object.hasOwn(element, nameProp) && element[nameProp]) {
      return element[nameProp];
    }
    else if (element?.props?.length) {
      const findItem = element?.props?.find(el => el[prop_name] === nameProp);
      if (findItem) {
        return findItem[prop_value];
      } else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  public static getIsPending(props = []): boolean{
    const isPending = this.getPropsValue('is_pending', { props }, 'name', 'value');
    if(isPending){
      return String(isPending).toLowerCase() === '1';
    }
    return  false;

  }
  public static getPropsCountryId (nameProp: string, element) {
    const findItem = element?.props.find(el => el.prop_name === nameProp);
    if (findItem) {
      return findItem.country_id.id;
    } else {
      return false;
    }
  }

  public static getPropsCountryName (nameProp: string, element) {
    const findItem = element?.props.find(el => el.prop_name === nameProp);
    if (findItem) {
      return findItem.country_id.name;
    } else {
      return false;
    }
  }

  public static isIosID (id: string) {
    // @ts-ignore
    const regular = /^[0-9]+$/;
    const a = regular.test(id);
    return ((id.length == 10 || id.length == 9) && a);
  }

  public static isAndroidPackageID (packageId: string) {
    // @ts-ignore
    const regular = /^[a-z0-9]+(\.[a-z0-9]+)+$/ig;
    const a = regular.test(packageId);
    return (a);
  }

  public static isPackageId (packageId: string) {
    if (AppsUtil.isIosID(packageId)) return Main.IOS;
    if (AppsUtil.isAndroidPackageID(packageId)) return Main.ANDROID;
    return false;
  };

  public static isValidHttpUrl (queryUrl: string) {
    let url;
    try {
      url = new URL(queryUrl);
    } catch (_) {
      return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
  }

  public static sortBanned (a, b) {
    return (a.is_alive && !b.is_alive) ? -1 : 1;
  }

  public static sortTitle (a, b) {
    const findFunc = el => el.prop_name === 'title';
    const findItemA = a.app.props.find(findFunc);
    const findItemB = b.app.props.find(findFunc);
    return findItemA.prop_value < findItemB.prop_value ? -1 : 1;
  };

  public static sortFavorite (a, b) {
    if (b.is_favorite < a.is_favorite) return -1;
    if (b.is_favorite > a.is_favorite) return 1;
    return AppsUtil.sortTitle(a, b);
  };

  public static sortDeveloper (a, b) {
    return a?.app?.developer?.name < b?.app?.developer?.name ? -1 : 1;
  };

  public static sortMostPromoted (a, b) {
    if (a?.promotion_value > b?.promotion_value) return -1;
    if (a?.promotion_value < b?.promotion_value) return 1;
    return AppsUtil.sortFavorite(a, b);
  };

  public static changeSort (value = Main.MOST_PROMOTED, sort, apps) {
    sort.value = value;

    if (value) {
      switch (value) {
        case Main.FAV:
          apps.value.sort(AppsUtil.sortFavorite);
          break;
        case Main.TITLE:
          apps.value.sort(AppsUtil.sortTitle);
          break;
        case Main.DEVELOPER:
          apps.value.sort(AppsUtil.sortDeveloper);
          break;
        case Main.MOST_PROMOTED:
          apps.value.sort(AppsUtil.sortMostPromoted);
          break;
      }
    }
    apps.value.sort(AppsUtil.sortBanned);
  }

  public static loadingFilterItem (valueFilter, apps: any = []) {
    if (valueFilter) {
      switch (valueFilter) {
        case Main.ALL:
          return apps;
        case Main.ANDROID:
        case Main.IOS:
          return apps.filter(app => app.app.appstore.tag == valueFilter);
        case Main.BAN:
          return apps.filter(app => app.is_alive === false);
        case Main.FAV:
          return apps.filter(app => app.is_favorite === true);
        default:
          return apps;
      }
    }
  }

  public static loadingSearchItem (valueSearch, apps: any = []) {
    return apps?.filter(app => app?.app?.props.some(
      elem => elem.prop_name == 'title'
                    && elem.prop_value.toLowerCase().includes(valueSearch.toLowerCase())
    ) || app?.app?.package.toLowerCase().includes(valueSearch.toLowerCase())
    );
  }

  public static searchCurrentApps = (store = useStore(), apps) => {
    const AppsDispatcher = new AppsDispatcherService(store);
    const currentApp = AppsDispatcher.getValidatedCurrentApp();
    return apps.value.map((obj) => {
      if (obj?.app?.package === currentApp?.app?.package) {
        return currentApp;
      }
      return obj;
    });
  };

  public static loadingMore (page = 1, pagination, apps) {
    const tempCountItems = pagination.value * page;
    if (tempCountItems > apps.length) {
      pagination.value = apps.length;
    } else {
      pagination.value = tempCountItems;
    }
  };
}
