import { Mutations } from '@/store/enums/StoreEnums';
import { Mutation, Module, VuexModule } from 'vuex-module-decorators';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';


@Module({ name: 'ContactsModule' })
export default class ContactsModule extends VuexModule implements StoreModel {
    @Mutation
  [Mutations.CLEAR_STORE]() {}
}
