const role = {
  over: 'Более {total} участников',
  name: 'Название',
  actions: 'Действия',
  check: 'Проверять',
  nameModule: 'Название модуля',
  enterName: 'Введите Название',
  enterNamespace: 'Введите Пространство имен',
  enterPermissionName: 'Введите название разрешения',
  permissionName: 'Название разрешения',
  inputName: 'Пожалуйста введите Название',
  inputNamespace: 'Пожалуйста введите Пространство имен',
  inputPermissionName: 'Пожалуйста введите название разрешения',
  inputModule: 'Пожалуйста выберите модуль',
  created: 'Создание Функции',
  updated: 'Обновление Функции',
  specifyName: 'Укажите целевое название для будущего использования и ссылки',
  specifyModule: 'Укажите целевой модуль для будущего использования и ссылки',
  specifyNamespace: 'Укажите целевое Пространство имен для будущего использования и ссылки',
  specifyPermissionName: 'Укажите целевое Название разрешения для будущего использования и ссылки',
  selectModule: 'Выберите модуль',
  clickAdd: 'Нажмите, чтобы добавить Функцию',
  namespace: 'Пространство имен',
  newFunction: 'Новая Функция',
  nameFunction: 'Название Функции',
};
export default role;
