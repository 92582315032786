const adminReseller = {
  resellerProgram: 'Reseller Program',
  customersComeFrom: 'Customers come from:',
  suppliers: 'Suppliers:',
  installsSoldMonthly: 'Installs sold monthly:',
  channels: 'Channels:',
  services: 'Services:',
};

export default adminReseller;
