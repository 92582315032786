const statistics = {
  totalByProduct:'Totales por producto',
  totalKeywords:'Palabras clave totales',
  totalCampaigns:'Campañas totales',
  date:'Fecha',
  byKeyword:'Por palabra clave',
  byPackage:'Por paquete',
  rates:'Tarifas',
  reviews:'Reseñas',
  HQReviews:'Reseñas de la sede central',
  keyword:'Palabra clave',
  installs:'Instalaciones',
  rank:'Rango',
  export:'Exportar',
  installsByDate:'Instalaciones por fecha',
  installsByKeyword:'Instalaciones por palabra clave',
  noInstallsFound:'No se encontraron instalaciones',
  howManyInstallsDoYouSellMonthly:'¿Cuántas instalaciones vende mensualmente?',
  accordingToGooglePlayConsole:'Según Google Play Console: los datos diarios se informan en la zona horaria PST (UTC -9:00), cada hora, en la zona horaria del navegador local (UTC +2:00), todos los ingresos se informan en UTC',
  id:'ID',
  type:'Escribe',
  country:'País',
  days:'Dias',
  price:'Precio',
  startTime:'Hora de inicio',
  status:'Estado',
  campaigns:'Campañas',
  noCampaignsFound:'No se encontraron campañas',
  overallInstalls:'Instalaciones totales',
  overallSpendings:'Gastos generales',
  overallKeywords:'Palabras clave generales',
  overallCampaigns:'Campañas generales',
  statisticByKeywords:'Estadística por palabras clave',
  campaignsAndSpendings:'Campañas y gastos',
  totalSpend:'Gasto total',
  totalInstalls:'Instalaciones totales',
  reportByDate:'Informe por fecha',
  results:'resultados',
  noReportsFound:'No se encontraron informes',
  spendings:'Gastos',
  spend:'Gastar',
  reportByKeyword:'Reportar por palabra clave',
  cumulativeReport:'Informe acumulativo',
  spentFor:'Gastado por',
  till:'hasta que',
  reportFor:'Informe para',
  reportForAllTime:'Informe de todos los tiempos',
};

export default statistics;
