const myProfile = {
  editProfile:'Редагувати профіль',
  priceplan:'Прайс-план',
  currentPriceplan:'Актуальний тарифний план',
  totalPayments:'Загальні платежі',
  lastPayment:'Останній платіж',
  profileDetails:'Деталі профіля',
  nickname:'Псевдонім',
  fullName:'ПІБ',
  country:'Країна',
  businessType:'Вид бізнесу',
  gender:'Стать',
  refLink:'Реф посилання',
  apiKey:'ключ API',
  show:'Показати',
  hide:'Сховати',
  contactInformation:'Контакти',
  phone:'Телефон',
  messengers:'Месенджери',
  email:'Ел. адреса',
  changePassword:'Змінити пароль',
  enterYourNickname:'Введіть ваш псевдонім',
  enterYourFullName:'Введіть своє повне ім\'я',
  currentPassword:'Поточний пароль',
  enterYourPassword:'Введіть свій пароль',
  newPassword:'Новий пароль',
  enterYourNewPassword:'Введіть новий пароль',
  repeatNewPassword:'Повторіть новий пароль',
  yourMessengerId:'Ваш ідентифікатор месенджера',
  profileWasSuccessfullyChanged:'Профіль успішно змінено',
  fullNameIsNotValid:'Повне ім\'я недійсне',
  phoneNumberIsNotValid:'Номер телефону недійсний',
  messengerIdIsNotTalid:'Ідентифікатор месенджера недійсний',
};

export default myProfile;
