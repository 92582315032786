const userDetails = {
  infoCard: {
    countApps: 'Count Apps',
    payments: 'Payments',
    spendings: 'Spendings',
    email: 'Email',
    id: 'ID',
    currentPlan: 'Current Plan',
    balance: 'Balance',
    status: 'Status',
    active: 'Active',
    registrationDate: 'Registration date',
    lastLogin: 'Last Login',
    country: 'Country',
    refEmail: 'Referrer',
    reflinkId: 'Reflink code',
    externalParams: 'External params',
  },
  editCard: {
    editDetail: 'Edit user detail',
    activateUser: 'Activate User',
    resendEmail: 'Resend email',
    removeUser: 'Remove User',
    email: 'Email',
    phone: 'Phone',
    role: 'Role',
    priceplan: 'Priceplan',
    manager: 'Manager',
    refId: 'RefLink',
    isUserEuPayee: 'Is User EU Payee',
    save: 'Save',
    userActivated: 'User successfully activated',
    userDeleted: 'User successfully deleted',
    userChanged: 'Changes to user details were successfully applied.',
  },
  paymentsTable: {
    userPayments: 'Payments',
    showing: 'showing',
    paymentsOf: 'payments of',
    noUserPaymentsFound: 'No user payments found',
  },
  transactionTable: {
    userTransactions: 'Transactions',
    showing: 'showing',
    transactionsOf: 'transactions of',
    noUserTransactionsFound: 'No user transactions found',
  },
  referralTable: {
    userReferrals: 'User referrals',
    showing: 'showing',
    referralsOf: 'referrals of',
    noUserReferralsFound: 'No user referrals found',
    addNew: 'Add referral',
    addNewSuccess: 'Referral successfully added',
  },
  changeBalanceCard: {
    changeBalance: 'Change user balance',
    amount: 'Amount',
    account: 'Account',
    type: 'Type',
    reason: 'Reason',
    id: 'Payment unique ID',
    paymentId: 'Payment Id',
    generate: 'Generate',
    description: 'Description',
    balanceChanged: 'Balance was changed successully',
  },
  reflinksModal: {
    reflinksList: 'RefLinks History',
    name: 'Name',
    code: 'Code',
    created: 'Created',
  },
  externalModal: {
    externalParams: 'External params',
    value: 'Value',
  },
  tableDropdown: {
    exportUserPayments: 'Export'
  },
  messagesTable: {
    userMessages: 'Messages',
    showing: 'Showing',
    recordes: 'records',
    noUserMessagesFound: 'No user messages found',
  },
};

export default userDetails;
