import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { AxiosRequestConfig } from 'axios';
import ApiService from '@/core/services/ApiService';
import { domainUrlAdmin } from '@/core/config/DomainConfig';
import { ErrorGetters } from '@/store/enums/ErrorGettersEnums';
import { Getters } from '@/store/enums/GettersEnums';

@Module({ name: 'SalesReportNewClientModule' })
export default class SalesReportNewClientModule extends VuexModule implements StoreModel {
  errors = {};
  salesReportNewClients = [];
  totalSalesReportNewClients = 0;
  currentPageSalesReportNewClients = 1;
  lastPageSalesReportNewClients = 1;
  pageSalesReportNewClients = 1;
  rowsPerPageSalesReportNewClients = 10;
  limitSalesReportNewClients = 10;
  orderBySalesReportNewClients = 'id';
  sortBySalesReportNewClients = 'desc';

  /**
     * Get current page
     * @returns number
     */
  get [Getters.GET_SALES_REPORT_NEW_CLIENTS_CURRENT_PAGE]() {
    return this.currentPageSalesReportNewClients;
  }

  /**
     * Get last page
     * @returns number
     */
  get [Getters.GET_SALES_REPORT_NEW_CLIENTS_LAST_PAGE]() {
    return this.lastPageSalesReportNewClients;
  }

  /**
     * Get page
     * @returns number
     */
  get [Getters.GET_SALES_REPORT_NEW_CLIENTS_PAGE]() {
    return this.pageSalesReportNewClients;
  }

  /**
     * Get rows
     * @returns number
     */
  get [Getters.GET_SALES_REPORT_NEW_CLIENTS_ROWS]() {
    return this.rowsPerPageSalesReportNewClients;
  }

  /**
     * Get limit
     * @returns string
     */
  get [Getters.GET_SALES_REPORT_NEW_CLIENTS_LIMIT]() {
    return this.limitSalesReportNewClients;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [ErrorGetters.GET_ERRORS_SALES_REPORT_NEW_CLIENTS]() {
    return this.errors;
  }

  /**
     * Get current Transaction object
     * @returns array
     */
  get [Getters.ALL_SALES_REPORT_NEW_CLIENTS]() {
    return this.salesReportNewClients;
  }

  /**
     * Get current Total Transactions
     * @returns number
     */
  get [Getters.GET_TOTAL_SALES_REPORT_NEW_CLIENTS]() {
    return this.totalSalesReportNewClients;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_SALES_REPORT_NEW_CLIENTS_ORDER_BY]() {
    return this.orderBySalesReportNewClients;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_SALES_REPORT_NEW_CLIENTS_SORT_BY]() {
    return this.sortBySalesReportNewClients;
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.errors = {};
    this.salesReportNewClients = [];
    this.totalSalesReportNewClients = 0;
    this.currentPageSalesReportNewClients = 1;
    this.lastPageSalesReportNewClients = 1;
    this.pageSalesReportNewClients = 1;
    this.rowsPerPageSalesReportNewClients = 10;
    this.limitSalesReportNewClients = 10;
    this.orderBySalesReportNewClients = 'id';
    this.sortBySalesReportNewClients = 'desc';
  };

    @Mutation
    [Mutations.SET_SALES_REPORT_NEW_CLIENTS_ROWS](payload) {
      this.rowsPerPageSalesReportNewClients = payload;
    }

    @Mutation
    [Mutations.SET_ERROR_SALES_REPORT_NEW_CLIENTS](error) {
      this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_ALL_SALES_REPORT_NEW_CLIENTS](payload) {
      this.salesReportNewClients = payload;
    }

    @Mutation
    [Mutations.SET_TOTAL_SALES_REPORT_NEW_CLIENTS](payload) {
      this.totalSalesReportNewClients = payload;
    }

    @Mutation
    [Mutations.SET_SALES_REPORT_NEW_CLIENTS_CURRENT_PAGE](payload) {
      this.currentPageSalesReportNewClients = payload;
    }

    @Mutation
    [Mutations.SET_SALES_REPORT_NEW_CLIENTS_LAST_PAGE](payload) {
      this.lastPageSalesReportNewClients = payload;
    }

    @Mutation
    [Mutations.SET_SALES_REPORT_NEW_CLIENTS_ORDER_BY](payload) {
      this.orderBySalesReportNewClients = payload;
    }

    @Mutation
    [Mutations.SET_SALES_REPORT_NEW_CLIENTS_SORT_BY](payload) {
      this.sortBySalesReportNewClients = payload;
    }

    @Action
    [Actions.GET_SALES_REPORT_NEW_CLIENTS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}sales-reports/new-clients`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TOTAL_SALES_REPORT_NEW_CLIENTS, data.meta.total);
          this.context.commit(Mutations.SET_SALES_REPORT_NEW_CLIENTS_CURRENT_PAGE, data.meta.current_page);
          this.context.commit(Mutations.SET_SALES_REPORT_NEW_CLIENTS_LAST_PAGE, data.meta.last_page);
          this.context.commit(Mutations.SET_ALL_SALES_REPORT_NEW_CLIENTS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_SALES_REPORT_NEW_CLIENTS, response?.data?.errors);
        });
    }

    @Action
    [Actions.CHANGE_SALES_REPORT_NEW_CLIENTS_MANAGER](params) {
      return ApiService.post(`${domainUrlAdmin}sales-reports/new-clients/change-manager`, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR_SALES_REPORT_NEW_CLIENTS, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_SALES_REPORT_NEW_CLIENTS, response?.data?.errors);
        });
    }
}
