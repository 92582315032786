enum ReviewModeEnums {
    // constant
    CUSTOM = 1,
    GENERATED = 2,
}

enum ReviewLabelsEnums {
    CUSTOM = 'Custom',
    GENERATED = 'Autogenerated',
}
export { ReviewModeEnums, ReviewLabelsEnums };
