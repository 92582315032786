const addCampaigns = {
  products: 'Товари',
  pickAvaialbleCampaignTypes: 'Виберіть один із доступних типів кампаній',
  notes: 'Нотатки',
  pleaseDontChangeYourAppsIcon: 'Пожалуйста, не меняйте значок, имя или имя издателя вашего приложения во время проведения кампании. Наши пользователи будут искать ваше приложение по ключевым словам, и единственный способ найти его — посмотреть на значок и сравнить название приложения.',
  whatIsAdvertisingCpiCampign: 'Що таке рекламна компанія CPI?',
  cpi: 'CPI (cost-per-install) компанія - це інструмент, який використовується для залучення користувачів у вашу програму. Ці користувачі не мотивовані дорогоцінним камінням або монетами, як ті, хто встановлює вашу програму, коли ви замовляєте установку за ключовим словом. Ці користувачі встановлять вашу програму, тільки якщо вона їм сподобається. Це означає, що ви будете залучати людей, які приносять вам дохід та активність у додатку.',
  keyword: 'Ключове слово',
  install: 'встановити',
  direct: 'Прямий',
  installs: 'Встанавлюває',
  guaranteed: 'Гарантовано',
  reviews: 'Відгуки',
  smart: 'Розумна',
  campaign: 'Кампанія',
  addCampaigns: 'Додати кампанії',
  addCampaign: 'Додати кампанію',
  advertising: 'Реклама',
  summary: 'Резюме',
  product: 'Продукти',
  keywordInstalls: 'Налаштування за ключовим словом',
  installsByKeywordsForYourApplication: 'Кількість налаштувань за ключовими словами для вашої програми в цільовому регіоні',
  application: 'Заява',
  targetCountry: 'Цільова країна',
  daysInCampaign: 'Днів у кампанії',
  finishAt: 'Закінчити в',
  campaignOrders: 'Замовлення компанії',
  days: 'дні',
  directInstalls: 'Прямі установки',
  rates: 'Ставки',
  total: 'Загальний',
  fixedPartner: 'Фіксований партнер',
  day: 'День',
  description: 'Опис',
  saveDraft: 'Зберегти чернетку',
  startCampaign: 'Почати компані.',
  iAgreeTo: 'Я згоден з',
  terms: 'умови',
  ofUse: 'використання',
  textSuccessfully: 'Кампанію створено успішно',
  textSuccessfullyDraft: 'Створено новий проект кампанії #{id}',
  refundPolicyForRatesAndReviews: 'Політика відшкодування для тарифів і відгуків',
  almostAllRatesAndReviewsAreGettingDeleted: 'УВАГА! Майже всі оцінки та відгуки видаляються будь-яким магазином додатків, ми НЕ повертатимемо гроші за ціни та відгуки. Використовуйте їх на свій страх і ризик',
  allRatesAndReviewsWillAppearOnMarket: 'Усі тарифи та відгуки з’являться на сторінці вашого додатка через 2-3 години після завершення кампанії. Через 1-2 дні більшість тарифів і відгуків, замовлених у цьому розділі, буде видалено, і це значною мірою залежить від типу вашої програми, її органічного трафіку та звичайних щоденних тарифів, тому, продовжуючи, ви погоджуєтесь прийняти цей ризик.',
  ifYouNeedReviewsAndRates: 'Якщо вам потрібні відгуки та тарифи, щоб залишатися на сторінці програми, а також отримати їх із гарантією повернення грошей або повторним розміщенням протягом 30 днів після завершення замовлення, перейдіть до кампанії «Додати гарантовані відгуки».',
  evenIfItIsOkay: 'Навіть якщо для вас це нормально, що тарифи та відгуки, замовлені в цьому розділі, видаляються, не замовляйте їх безліч. Поведінка тарифів має виглядати як реальний потік користувачів, пропорційний звичайному трафіку вашого додатка у вибраній країні та подібний до вашої поточної кількості ставок.',
  iveReadAndAreeToThe: 'Я прочитав і згоден з',
  chargebackAndRefundPolicy: 'Політика відшкодування та повернення коштів',
  deleteAllKeywords: 'Видалити всі ключові слова',
  keywordInstall: {
    keywordInstallAddKeywordsBody: {
      keywords: 'ключові слова',
      advancedMode: 'Розширений режим',
      dayOne: 'день 1',
      addKeyword: 'Додати ключове слово'
    },
    keywordInstallKeywordsTable: {
      noKeywordsFound: 'Ключових слів не знайдено',
      selectKeyword: 'Виберіть ключове слово',
      addKeywordInstalls: 'Додати установки за ключовим словом',
      increase: 'Збільшити',
      parabolic: 'Параболічний',
      equal: 'Рівні',
      days: 'Дні'
    },
    directInstallTable: {
      addDirectInstalls: 'Переклад',
      days: 'Дні',
      packageOrDirectInstalls: 'Пакетне або пряме встановлення',
      delete: 'Вилучити'
    },
    ratesAndReviewsKeywordInstallBody: {
      ratesAndReviews: 'Ціни та відгуки',
      addRatesAndReviewsToCampaign: 'Додати оцінки та відгуки до кампанії',
      days: 'Дні',
      rates: 'Ставки',
      delete: 'Вилучити',
      reviews: 'Відгуки',
      importReviews: 'Імпорт відгуків',
      errorCheckedTerms: 'Ви повинні погодитися з умовами використання, перш ніж замовляти ціни або відгуки.'
    },
    promotionRuleKeywordInstallBody: {
      promotionRule: 'Правило просування',
      dontPromoteKeywordsWhenTheyReachRank: 'Не просуйте ключові слова, коли вони досягають рейтингу',
      installsDeliveryType: 'Встановлює тип доставки',
      spreadInstallsForTwentyFourHours: 'Розповсюдження установок за 24 години',
      allInstallsAtOnce: 'Усі установки відразу',
      deeplinkTitle: 'Глибоке посилання',
      deeplink: 'Посилання на глибину виконується після встановлення програми, наприклад run?source=keyapp, ',
      deeplinkIos: 'читайте більше в нашому блозі'
    },
    addMyReviewsModal: {
      addMyReviews: 'Додати мої відгуки',
      added: 'Доданий',
      reviews: 'огляди',
      newReview: 'Додати новий огляд',
      addManyRandomReview: 'Додайте 5 випадкових відгуків',
      deleteAll: 'Видалити все',
      saveReview: 'Зберегти відгук',
      deleteReview: 'Видалити відгук',
      editReview: 'Редагувати огляд',
      youHaveNoAddedReviewsYet: 'У вас ще немає відгуків',
      add: 'Додавати',
      titleText: 'Додайте нову назву відгуку',
      bodyText: 'Додайте новий текст відгуку'
    },
    descriptionKeywordInstallBody: {
      description: 'Опис',
      leaveAnyNotesForYourcampaign: 'Залишайте будь-які нотатки для вашої кампанії (лише англійською мовою, не більше 1000 символів)'
    }
  },
  guaranteedReviews: {
    reviewGuaranteedReviewsBody: {
      guaranteedReviews: 'Гарантовані відгуки',
      addGuaranreedReviewsToYourApplication: 'Додайте гарантовані відгуки до вашої програми',
      myCustom: 'Мій звичай',
      reviews: 'огляди',
      writeReviews: 'Пишіть відгуки',
      forMe: 'для мене',
      improveYourRatesAndAppsReputation: 'Покращуйте свої рейтинги та репутацію додатків за допомогою наших оглядів. Отримайте 30-денну гарантію на будь-яке замовлення.',
      campaignDetails: 'Деталі кампанії',
      days: 'Дні',
      _reviews: 'Відгуки',
      importReviews: 'Імпорт відгуків',
      delete: 'Вилучити',
      authorGender: 'Стать автора',
      male: 'Чоловічий',
      female: 'Жіночий',
      both: 'Обадва',
      pickPreferredGenderOfAccount: 'Виберіть підлогу облікового запису, який напише відгук',
      reviewLanguage: 'Мова огляду',
      pickPreferredLanguageForReviews: 'Виберіть потрібну мову для відкликання або використовувати мову вибраної країни за промовчанням.',
      description: 'Опис',
      leaveAnyNotesForYourOrder: 'Залишіть будь-які примітки до замовлення (тільки англійською мовою, 1000 символів), наприклад. кращі ключові слова, фрази і т.д.',
      notes: 'Нотатки',
      thisIsGuaranteedReviewsOrder: 'Це гарантований порядок відгуків, це означає, що при замовленні 100 відгуків ми гарантуємо, що не менше 90 з них залишатимуться на сторінці вашої програми не менше 30 днів, інакше ми переопублікуємо відсутні відгуки. Також ваше замовлення може перебувати у статусі МОДЕРАЦІЯ протягом 12-48 годин, поки ми не перевіримо всі відгуки.'
    }
  },
  advertisingCampaign: {
    advertisingCampaignTab: {
      back: 'Назад',
      startCampaign: 'Почати кампанію',
      pleaseWait: 'Будь ласка зачекайте...',
      next: 'Наступний'
    },
    advertisingCampaignStep1: {
      addTargetCountriesForAppPromotion: 'Додайте цільові країни для просування програми',
      usuallyWeRecommendToStartCpiAampaignsInSuchCases: 'Зазвичай ми рекомендуємо запускати CPI-кампанії.',
      youDontHave: 'У вас немає',
      organicTrafficOnYourApp: 'органічний трафік у вашій програмі, але ви хочете запустити кампанії з просування ключових слів. Коли ви одночасно замовляєте установки за ключовими словами та установки CPI, ранжування ключових слів вашої програми підвищується набагато швидше.',
      youHave: 'У вас есть',
      newAppAndYouWantToGetKeywordsIndexationFaster: 'нову програму і ви хочете прискорити індексацію ключових слів',
      newAppAndYouWantToGetYourMainPageInstallsNumberHigher: 'нову програму, і ви хочете збільшити кількість установок на головній сторінці',
      toGetBetterCategoryRankings: 'щоб отримати найкращий рейтинг у категоріях',
      youEarnMore: 'Ви заробляєте більше',
      fromEachInstallThenYouPayForIt: 'з кожної установки, то ви платите за це',
      toAttractUsersFromSpecifiedRegion: 'для залучення користувачів із зазначеного регіону',
      cryptocurrenciesBinaryOptionsPersonalLoansAppsAreForbidden: 'Криптовалюти, бінарні опціони, споживчі кредити та подібні програми заборонені.',
      youWant: 'Ти хочеш',
      pleaseNote: 'Будь ласка, зверни увагу'
    },
    advertisingCampaignStep2: {
      selectPromotionCountries: 'Виберіть країни акції',
      addTargetCountriesForAppPromotion: 'Додайте цільові країни для просування програми',
      filterByCpi: 'Сортувати за CPI',
      filterByGeo: 'Сортувати за GEO',
      filterByLanguage: 'Сортувати за language',
      highCpi: 'Високий CPI',
      mediumCpi: 'Середній CPI',
      lowCpi: 'Низький CPI',
      europe: 'Європа',
      asia: 'Азія',
      africa: 'Африка',
      latinAmerica: 'Латинська Америка',
      worldwide: 'Світовий',
      english: 'Англійский',
      spanish: 'Іспанский',
      russian: 'Російський',
      targetedCountries: 'Цільові країни'
    },
    advertisingCampaignStep3: {
      campaignBudget: 'Бюджет кампанії',
      defineDailyBudget: 'Визначте денний бюджет, загальну кількість днів та мету CPI',
      dailyBudget: 'Щоденний бюджет',
      targetCpi: 'Ціль CPI',
      daysInCampaign: 'Днів у кампанії',
      unlimited: 'Необмежений',
      days: 'дні'
    },
    advertisingCampaignStep4: {
      headlines: 'Заголовки',
      addHeadlinesAndDescriptionsForAds: 'Додати заголовки та описи для оголошень',
      headlineOne: 'Заголовок #1',
      weUseHeadlinesAndDescriptionsToAdvertiseYourApp: 'Ми використовуємо заголовки та описи для реклами вашої програми. Привабливі заголовки та описи можуть допомогти вам підвищити CTR (більше кліків від переглядів), тому ваш середній CPI знизиться.',
      generate: 'Створити',
      headlineTwo: 'Заголовок #2',
      misleadingDescriptions: 'Описи, що вводять в оману, можуть підвищити CTR, але користувачі не встановлять вашу програму, коли зайдуть на сторінку вашої програми. Таким чином, ви отримаєте низький коефіцієнт конверсії, а ваш CPI збільшиться.',
      description: 'Опис',
      checkThatYourTextMakesSenseForUsers: 'Переконайтеся, що текст має сенс для користувачів. Це важливо, тому що наші партнери з рекламних мереж будуть застосовувати процес затвердження ваших оголошень. Якщо ви порушите деякі правила, ваші оголошення повинні пройти процес затвердження.'
    },
    advertisingCampaignStep5: {
      summary: 'Резюме',
      confirmCampaignDetails: 'Підтвердьте дані кампанії',
      application: 'Заява',
      nameOfApplication: 'Назва програми...',
      developer: 'Розробник',
      countries: 'Країни',
      duration: 'Тривалість',
      targetCpi: 'Ціль CPI',
      dailyBudget: 'Щоденний бюджет',
      startCharge: 'Почати зарядку',
      toStartThisCampaign: 'Щоб розпочати цю кампанію, ми спишемо з вашого балансу 20$. Нам потрібна ця сума (насправді це подвоєний денний бюджет) як передплата для майбутніх витрат на кампанію.',
      weWillChargeYourAccount: 'Щодня ми списуватимемо з вашого рахунку суму, яку ви витратили на просування протягом попереднього дня. Якщо на вашому балансі недостатньо коштів для покриття витрат за попередній день, ми будемо використовувати ваш початковий платіж і припинимо кампанію доти, доки ви не поповните свій баланс і не призупините кампанію вручну.',
      refund: 'Повернення',
      youMayCancelYourCampaign: 'Ви можете скасувати свою кампанію в будь-який час, але ми повернемо гроші лише через 24 години після скасування, щоб зупинити кампанію у всіх рекламних мережах та отримати фактичні витрати на кампанію. Те саме правило стосується і завершення кампаній. Сума повернення розраховується як різниця між ціною кампанії та витратами на кампанію.',
      dailyCharge: 'Щоденна зарядка'
    },
    advertisingCampaignAside: {
      letsStart: 'Давайте почнемо!',
      detailsOfStepOneHere: 'подробиці кроку 1 тут',
      stepTwo: 'Крок 2',
      detailsOfStepTwoHere: 'подробиці кроку 2 тут',
      stepThree: 'Крок 3',
      detailsOfStepThreeHere: 'подробиці кроку 3 тут',
      stepFour: 'Крок 4',
      detailsOfStepFourHere: 'подробиці кроку 4 тут',
      stepFive: 'Крок 5',
      detailsOfStepFiveHere: 'подробиці кроку 5 тут'
    }
  }
};

export default addCampaigns;
