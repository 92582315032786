import { createApp } from 'vue';
import directivesGlobal from './directives/';
import App from './App.vue';

declare global {
    interface Window {
        dataLayer: any[];
    }
}

import { createVuetify } from 'vuetify';
import VuetifyTheme from './assets/ts/_utils/VuetifyThemeUtil';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from './router';
import store from './store';
// import ElementPlus from "element-plus";
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

import i18n from '@/core/plugins/i18n';
//imports for app initialization
import ApiService from '@/core/services/ApiService';
import { initApexCharts } from '@/core/plugins/apexcharts';
import { initInlineSvg } from '@/core/plugins/inline-svg';
import { initVeeValidate } from '@/core/plugins/vee-validate';
import JsonCSV from 'vue-json-csv';
import '@/core/plugins/prismjs';

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'VuetifyTheme',
    themes: {
      VuetifyTheme
    }
  }
});

const app = createApp(App);
// register all directives
directivesGlobal(app);

app.use(router);
app.use(store);
app.use(vuetify);

app.component('EasyDataTable', Vue3EasyDataTable);
app.component('downloadCsv', JsonCSV);

// app.use(ElementPlus);

ApiService.init(app, router);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount('#app');
