const userOrder = {
  header: {
    toolbar: {
      all: 'All',
      keyword: 'Keyword',
      direct: 'Direct',
      cpi: 'CPI',
      hqr: 'HQR',
    }

  },
  table: {
    adminUserCampaigns: 'Admin user campaigns',
    showing: 'showing',
    campaignsOf: 'campaigns оf',
    found: 'found',
    campaignFrom: 'campaign from',
    nothingFoundBySearch: 'Nothing found by search',
    noCampaignsFound: 'No campaigns found',
    submit: 'Submit',
    changeVendor: 'Change Vendor'
  },
  messages: {
    messageSent: 'Notice successfully sent'
  },
  modal: {
    sendMessage: 'Send message',
    recordsNotFound: 'Records not found',
    statusChangeHistory: 'status change history',
    changeProgress: 'Update progress',
  },
  actionsDropdown: {
    updateProgress: 'Update progress',
  }
};

export default userOrder;
