const partnersProgram = {
  partnersProgram: 'Partners Program',
  welcomeToOurResellerProgram: 'Welcome to our reseller program! While we provide services directly to app developers and publishers, we do our best as well to give the possibility for our partners to resell our services at the most profitable rates!',
  benefits: 'Benefits',
  requirements: 'Requirements',
  howToBecome: 'How to Become a Partner?',
  usefulLinks: 'Useful Links for Partners',
  benefitsOfBecoming: 'Benefits of Becoming a Keyapp Reseller',
  accessToKeyapp: 'Access to Keyapp services (installs, rates, and reviews) at considerably lower rates',
  fullAccess: 'Full access to <a href="{link}" class="text-primary">API</a> and API technical support; we will help with all the issues you face while integration',
  weDoNotCharge: 'We do not charge any additional fees for API usage with the aim of campaign settings (there are several API requests where API tokens should be used. 500 free API tokens are provided monthly, and more may be bought at a low cost, find more details about API following <a href="{link}" class="text-primary">this link</a>)', /*this link*/
  personalManagerHelping: 'Personal manager helping with all the questions you have regarding API, services, keyword promotion strategies, and everything you may need to work effectively and provide the best services for your customers!',
  youHaveMore: 'You have more than 1 000 spent on Keyapp.top platform',
  youHaveAWebsite: 'You have a website or blog where you sell the same services as ours',
  youAreAMarketing: 'You are a marketing agency conducting ASO or mobile marketing',
  youAreAnAso: 'You are an ASO specialist promoting your clients’ apps',
  requirementsToBecome: 'Requirements to Become a Reseller Partner',
  howToBecomeAPartner: 'How to Become a Partner?',
  step: 'Step {step}:',
  contactTheSupport: '<a href="{link}" class="text-primary">Contact the support team</a> or your manager',
  contactOurTeamWithTheDetails: 'Contact our team with the details about your business and needs for us to confirm your account as a reseller. Please provide the info about the services you need, your clients, the main sources of customers you use, and an estimated sales monthly. Our support team will ask all the additional questions and provide all the info for you if any is required.',
  afterTheDiscussion: 'After the discussion and when we are ready to confirm your account as a reseller, our manager will change your current price plan for the matching one with lower rates.',
  startWorkingAtMuch: 'Start working at much more profitable rates and use API integration if your business model requires it',
  usefulLinksFor: 'Useful Links for Partners',
  priceplans: 'Priceplans',
  api: 'API',
  contacts: 'Contacts',
  checkAndCompare: 'Check and compare the available price plans - <a href="{link}" class="text-primary">here</a>',
  findAllTheApi: 'Find all the API documentation - <a href="{link}" class="text-primary">here</a>',
  findOurContactDetails: 'Find our contact details to discuss the partnership - <a href="{link}" class="text-primary">here</a>',
};

export default partnersProgram;
