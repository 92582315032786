const role = {
  over: 'Более {total} участников',
  clickAdd: 'Нажмите, чтобы добавить роль',
  newRole: 'Новая роль',
  name: 'Название',
  actions: 'Действия',
  check: 'Проверять',
  nameModule: 'Название модуля',
  enterName: 'Введите Название',
  enterGuardName: 'Введите название охранника',
  inputName: 'Пожалуйста, введите Название',
  inputGuardName: 'Пожалуйста, введите название охранника',
  guardName: 'Название охранника',
  nameRole: 'Название роли',
  created: 'Создание роли',
  updated: 'Обновление роли',
  rolePermissionByModules: 'Разрешения ролей по модулям',
  specifyName: 'Укажите целевое название для будущего использования и ссылки',
  specifyGuardName: 'Укажите название целевого сторожа для будущего использования и ссылки',
};
export default role;
