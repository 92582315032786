import { RolesEnums } from '@/store/enums/RolesEnums';

const app = {
  path: '/',
  redirect: '/apps',
  component: () => import('@/layout/Layout.vue'),
  meta: { roles: [RolesEnums.ALL] },
  children: [
    {
      path: '/apps',
      name: 'apps',
      meta: { roles: [RolesEnums.ALL] },
      component: () => import('@/views/pages/App.vue')
    }

  ]
};
export default app;
