import { Actions } from '@/store/enums/StoreEnums';
import StoreGettersService from '@/store/services/StoreGettersService';
import { Getters } from '@/store/enums/GettersEnums';
import { useStore } from 'vuex';
import { ErrorTypeEnums } from '@/store/enums/ErrorTypeEnums';

export default class NoticeDispatcherService extends StoreGettersService{

  constructor (store = useStore()) {super(store);}

  public async storeNotice(notice) {
    await this.getStore().dispatch(Actions.STORE_NOTICE, {
      external_id: this.getValidatedGetCurrentUserNoticeData().campaignId,
      user_id: this.getValidatedGetCurrentUserNoticeData().userId,
      type: 1,
      text: notice,
    });
  }

  public async viewNotice(unreadNoticeId) {
    await this.getStore().dispatch(Actions.VIEW_NOTICE, unreadNoticeId);
  }

  public async deactivateNotification (id: number) {
    await this.getStore().dispatch(Actions.DEACTIVATE_NOTIFICATION, id);
  }

  public async loadNotices(action, total, page) {
    await this.getStore().dispatch(action, { limit: total, page: page });
  }

  public async getNotices (reloadObject = false) {
    return await this.getAllRecordsByGetter(Getters.GET_ALL_NOTICES, Actions.GET_NOTICES, {}, reloadObject);
  }

  public async getNotifications () {
    return await this.getAllRecordsByGetter(Getters.GET_ALL_NOTIFICATIONS, Actions.GET_NOTIFICATIONS);
  }

  public getValidatedAllNotices () {
    return this.getValidatedGetter(Getters.GET_ALL_NOTICES, ErrorTypeEnums.NOT_REACT);
  }

  public getValidatedUnreadNotices () {
    return this.getValidatedGetter(Getters.GET_UNREAD_NOTICES, ErrorTypeEnums.NOT_REACT);
  }

  public getValidatedChatPageNotifications () {
    return this.getValidatedGetter(Getters.GET_CHAT_PAGE_NOTIFICATIONS);
  }

  public getValidatedEmptyLoadNoticeData () {
    return this.getValidatedGetter(Getters.GET_EMPTY_LOAD_NOTICE_DATA);
  }

  public getValidatedNoticesStatus () {
    return this.getValidatedGetter(Getters.GET_NOTICES_STATUS);
  }

  public getValidatedAllNotifications () {
    return this.getValidatedGetter(Getters.GET_ALL_NOTIFICATIONS, ErrorTypeEnums.NOT_REACT);
  }
  public getValidatedDashboardStartUnreadNotices () {
    return this.getValidatedGetter(Getters.GET_DASHBOARD_START_UNREAD_NOTICES, ErrorTypeEnums.NOT_REACT);
  }
  public getValidatedGetCurrentUserNoticeData () {
    return this.getValidatedGetter(Getters.GET_CURRENT_USER_NOTICE_DATA, ErrorTypeEnums.NOT_REACT);
  }
}
