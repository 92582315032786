import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { AxiosRequestConfig } from 'axios';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { domainUrl } from '@/core/config/DomainConfig';
import { Getters } from '@/store/enums/GettersEnums';

export interface LanguageInfo {
    errors: unknown;
    language: string;
    languages: unknown;
}

@Module({ name: 'LenguageModule' })
export default class LanguageModule extends VuexModule implements LanguageInfo, StoreModel {
  errors = {};
  language = '';
  languages = [];

  /**
     * Get current Language code
     * @returns
     */
  get [Getters.CURRENT_LANGUAGE](): string {
    return this.language;
  }

  /**
     * Get current Languages objects
     * @returns
     */
  get [Getters.ALL_LANGUAGES]() {
    return this.languages;
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.errors = {};
    this.language = '';
    this.languages = [];
  };

    @Mutation
    [Mutations.SET_ERROR_LANGUAGE](error) {
      this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_LANGUAGES](languages) {
      this.languages = languages;
    }

    @Mutation
    [Mutations.SET_LANGUAGE](language) {
      this.language = language;
    }

    @Action
    [Actions.GET_LANGUAGES](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}languages`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LANGUAGES, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_LANGUAGE, response?.data?.errors);
        });
    }


}
