const apps = {
  appToolbar: {
    all:'Все',
    android:'Android',
    ios:'iOS',
    favorite:'Любимый',
    banned:'Запрещено',
    allApps:'Все приложения',
    googlePlayApps:'Google Play приложения',
    appStoreApps:'App Store приложения',
    favoriteApps:'Любимые приложения',
    bannedApps:'Забаненные приложения',
  },
  appList: {
    addNewApp:'Добавить новое приложения',
    shown:'Проказано',
    appsFrom:'приложения с',
    added:'добавлено',
    found:'Найдено',
    applicationsBySearch:'приложения по поиску',
    nothingFoundBySearch:'Поиском ничего не найдено',
    startWithAddingNewApplication:'Начните с добавления нового приложения',
  },
  appItem: {
    unavailable:'Недоступен',
    promote:'Продвигать',
    createKeywordsCampaign:'Создать кампанию с ключевыми словами',
    createDirectInstallsCampaign:'Создайте кампанию с прямыми установками',
    createReviewCampaign:'Создать обзорную кампанию',
    createCpiCampaign:'Создать CPI кампанию',
    addKeywords:'Добавить ключевые слова',
    refreshApp:'Обновить приложение',
    deleteApp:'Удалить приложение',
    applicationId:'ID приложения',
    downloads:'Загрузки',
    category:'Категория',
    contentRating:'Рейтинг контента',
  },
  appstoreToggle: {
    store:'Хранилище',
    googlePlay:'Google Play',
    appStore:'App Store',
  },
  countrySelect: {
    country:'Страна',
  },
  appSelect: {
    pleaseEnterApplicationPackage:'Пожалуйста, введите пакет приложения, идентификатор отслеживания или URL-адрес магазина',
    beforeAddingNewApplication:'Перед добавлением нового приложения проверьте',
    appIsFree:'приложение БЕСПЛАТНО (мы не можем продвигать платные приложения)',
    appIsAvailableInSelectedCountry:'приложение доступно в выбранной стране для большого количества устройств',
    appDoesntRequireSystemPermissions:'приложение не требует каких-либо специальных системных разрешений (таких как Root или NFC)',
  },
  appDropdown: {
    quickActions:'Быстрые действия',
    update:'Обновить',
    updating:'Обновление...',
    delete:'Удалить',
    myKeywords:'Мои ключевые слова',
    statistics:'Статистика',
    externalAnalytics:'Внешняя аналитика',
    showInAppfollow:'Показать в AppFollow',
    showInAsoMobile:'Показать в AsoMobile',
    showInAsoTools:'Показать в AsoTools',
  }
};
export default apps;
