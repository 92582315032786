import { trim } from 'lodash';

const clipboardDirective = (app) => {
  app.directive('clipboard', {
    mounted(el, binding) {
      init(el, binding);
    },
    updated(el, binding) {
      init(el, binding);
    }
  });
};

function init(el, binding) {
  const clipboardText = trim(binding.value) || '';
  const position = binding.arg || 'top';
  const event = JSON.stringify(binding.modifiers) !== '{}' ? binding.modifiers : 'click';

  if (binding.value) {
    el.classList.add('cursor-pointer');
    el.classList.add('z-index-100');
    el.addEventListener(event, async function () {
      // navigator.clipboard should call under https domain
      // see https://stackoverflow.com/questions/51805395/navigator-clipboard-is-undefined
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(clipboardText);
      } else {
        const textArea = document.createElement('textarea');
        try {
          textArea.value = clipboardText;
          textArea.style.position = 'absolute';
          textArea.style.left = '-999999px';
          document.body.prepend(textArea);
          textArea.select();
          document.execCommand('copy');
        } catch (error) {
          console.error(error);
        } finally {
          textArea.remove();
        }
      }

      el.setAttribute('position', position);
      el.setAttribute('tooltip', 'Copied to clipboard');
      setTimeout(function () {
        el.removeAttribute('tooltip');
      }, 1000);
    });
  }
}

export default clipboardDirective;
