const dashboard = {
  campaigns:'Сampaigns',
  leftTo:'Left to',
  myTotalCampaigns:'My total campaigns',
  activeCampaigns:'Active campaigns',
  completedCampaigns:'Completed campaigns',
  guarantedReviews:'Guaranted Reviews',
  smartCampaign:'Smart Campaign',
  advertisingCampaign:'Advertising Campaign',
  improveYourRatesAndApps:"Improve your rates and apps' reputation with our reviews. Get 30 days guarantee for any order.",
  cpi:'CPI (cost-per-install) campaign - is a tool used to attract users to your app.',
  geos:'GEOs',
  availableCountriesForPromotion:'Available countries for promotion',
  topRegions:'Top regions',
  mostPopularRegionsForPromotion:'Most popular regions for promotion',
  lastCampaigns:'Last campaigns',
  myApps:'My Apps',
  platformNews:'Platform news',
  FAQs:'FAQs',
  application:'Application',
  currentPlan:'Current plan',
  FAQAndASONews:'FAQ & ASO News',
  keyappAcademy:'Keyapp Academy',
};

export default dashboard;
