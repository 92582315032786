const role = {
  over: 'Over {total} members',
  clickAdd: 'Click to add a role',
  newRole: 'New Role',
  name: 'Name',
  actions: 'Actions',
  check: 'Check',
  nameModule: 'Name Module',
  enterName: 'Enter name',
  enterGuardName: 'Enter guard name',
  inputName: 'Please input name',
  inputGuardName: 'Please input guard name',
  guardName: 'Guard name',
  nameRole: 'Name Role',
  created: 'Create Role',
  updated: 'Update Role',
  rolePermissionByModules: 'Role permissions by Modules',
  specifyName: 'Specify a target name for future usage and reference',
  specifyGuardName: 'Specify a target guard name for future usage and reference',
};
export default role;
