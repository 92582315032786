import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { AxiosRequestConfig } from 'axios';
import ApiService from '@/core/services/ApiService';
import { domainUrlAdmin } from '@/core/config/DomainConfig';
import { Getters } from '@/store/enums/GettersEnums';

@Module({ name: 'SalesReportStatisticModule' })
export default class SalesReportStatisticModule extends VuexModule implements StoreModel {

  errors = {};
  byStatuses = [];
  byPayments = [];
  lastPayments = [];
  statisticTotals = [];
  export = [];
  queryParams = {};
  lastPaymentsTotal = 0;
  lastPaymentsCurrentPage = 1;
  lastPaymentsLastPage = 1;
  totalClients = 0;
  activeClients = 0;
  paymentsCommitted = 0;
  myPaymentsTotal = 0;

  /**
     * @return array
     */
  get [Getters.GET_SALES_REPORT_STATISTIC_BY_STATUSES]() {
    return this.byStatuses;
  }

  /**
     * @return array
     */
  get [Getters.GET_SALES_REPORT_STATISTIC_BY_PAYMENTS]() {
    return this.byPayments;
  }

  /**
     * @return array
     */
  get [Getters.GET_SALES_REPORT_STATISTIC_ERRORS]() {
    return this.errors;
  }

  get [Getters.GET_SALES_REPORT_STATISTIC_QUERY_PARAMS]() {
    return this.queryParams;
  }

  /**
     * @return array
     */
  get [Getters.GET_SALES_REPORT_STATISTIC_LAST_PAYMENTS]() {
    return this.lastPayments;
  }

  /**
     * @return number
     */
  get [Getters.GET_SALES_REPORT_STATISTIC_TOTAL_CLIENTS]() {
    return this.totalClients;
  }

  /**
     * @return number
     */
  get [Getters.GET_SALES_REPORT_STATISTIC_LAST_PAYMENTS_CURRENT_PAGE]() {
    return this.lastPaymentsCurrentPage;
  }

  /**
     * @return number
     */
  get [Getters.GET_SALES_REPORT_STATISTIC_LAST_PAYMENTS_LAST_PAGE]() {
    return this.lastPaymentsLastPage;
  }

  get [Getters.GET_SALES_REPORT_STATISTIC_LAST_PAYMENTS_EXPORT]() {
    return this.export;
  }

  /**
     * @return number
     */
  get [Getters.GET_SALES_REPORT_STATISTIC_ACTIVE_CLIENTS]() {
    return this.activeClients;
  }

  /**
     * @return number
     */
  get [Getters.GET_SALES_REPORT_STATISTIC_PAYMENTS_COMMITTED]() {
    return this.paymentsCommitted;
  }

  /**
     * @return number
     */
  get [Getters.GET_SALES_REPORT_STATISTIC_MY_PAYMENTS_TOTAL]() {
    return this.myPaymentsTotal;
  }

  /**
     * @return number
     */
  get [Getters.GET_SALES_REPORT_STATISTIC_LAST_PAYMENTS_TOTAL]() {
    return this.lastPaymentsTotal;
  }

  /**
     * @return number
     */
  get [Getters.GET_SALES_REPORT_STATISTIC_TOTAL]() {
    return this.statisticTotals;
  }

  clearStore(): void {
    this.errors = {};
    this.byStatuses = [];
    this.byPayments = [];
    this.lastPayments = [];
    this.totalClients = 0;
    this.lastPaymentsCurrentPage = 1;
    this.lastPaymentsLastPage = 1;
    this.activeClients = 0;
    this.paymentsCommitted = 0;
    this.myPaymentsTotal = 0;
    this.lastPaymentsTotal = 0;
    this.queryParams = {};
    this.export = [];
  }

    @Mutation
  [Mutations.SET_SALES_REPORT_STATISTIC_ERRORS](error) {
    this.errors = { ...error };
  }

    @Mutation
    [Mutations.SET_SALES_REPORT_STATISTIC_BY_PAYMENTS](payload) {
      this.byPayments = payload;
    }

    @Mutation
    [Mutations.SET_SALES_REPORT_STATISTIC_BY_STATUSES](payload) {
      this.byStatuses = payload;
    }

    @Mutation
    [Mutations.SET_SALES_REPORT_STATISTIC_LAST_PAYMENTS](payload) {
      this.lastPayments = payload;
    }

    @Mutation
    [Mutations.SET_SALES_REPORT_STATISTIC_TOTAL_CLIENTS](payload) {
      this.totalClients = payload;
    }

    @Mutation
    [Mutations.SET_SALES_REPORT_STATISTIC_ACTIVE_CLIENTS](payload) {
      this.activeClients = payload;
    }

    @Mutation
    [Mutations.SET_SALES_REPORT_STATISTIC_PAYMENTS_COMMITTED](payload) {
      this.paymentsCommitted = payload;
    }

    @Mutation
    [Mutations.SET_SALES_REPORT_STATISTIC_MY_PAYMENTS_TOTAL](payload) {
      this.myPaymentsTotal = payload;
    }

    @Mutation
    [Mutations.SET_SALES_REPORT_STATISTIC_LAST_PAYMENTS_TOTAL](payload) {
      this.lastPaymentsTotal = payload;
    }



    @Mutation
    [Mutations.SET_SALES_REPORT_STATISTIC_LAST_PAYMENTS_CURRENT_PAGE](payload) {
      this.lastPaymentsCurrentPage = payload;
    }

    @Mutation
    [Mutations.SET_SALES_REPORT_STATISTIC_LAST_PAYMENTS_LAST_PAGE](payload) {
      this.lastPaymentsLastPage = payload;
    }

    @Mutation
    [Mutations.SET_SALES_REPORT_STATISTIC_TOTALS](payload) {
      this.statisticTotals = payload;
    }

    @Mutation
    [Mutations.SET_SALES_REPORT_STATISTIC_LAST_PAYMENTS_EXPORT](payload) {
      this.export = payload;
    }

    @Mutation
    [Mutations.SET_SALES_REPORT_STATISTIC_LAST_PAYMENTS_QUERY_PARAMS](payload) {
      this.queryParams = payload;
    }

    @Action
    [Actions.GET_SALES_REPORT_STATISTIC_BY_STATUSES](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}sales-reports/by-status`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SALES_REPORT_STATISTIC_BY_STATUSES, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_SALES_REPORT_STATISTIC_ERRORS, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_SALES_REPORT_STATISTIC_BY_PAYMENTS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}sales-reports/by-payments`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SALES_REPORT_STATISTIC_BY_PAYMENTS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_SALES_REPORT_STATISTIC_ERRORS, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_SALES_REPORT_STATISTIC_LAST_PAYMENTS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}sales-reports/last-payments`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SALES_REPORT_STATISTIC_LAST_PAYMENTS, data.data);
          this.context.commit(Mutations.SET_SALES_REPORT_STATISTIC_LAST_PAYMENTS_CURRENT_PAGE, data.meta.current_page);
          this.context.commit(Mutations.SET_SALES_REPORT_STATISTIC_LAST_PAYMENTS_LAST_PAGE, data.meta.last_page);
          this.context.commit(Mutations.SET_SALES_REPORT_STATISTIC_LAST_PAYMENTS_TOTAL, data.meta.total);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_SALES_REPORT_STATISTIC_ERRORS, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_SALES_REPORT_STATISTIC_LAST_PAYMENTS_EXPORT](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}sales-reports/last-payments`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SALES_REPORT_STATISTIC_LAST_PAYMENTS_EXPORT, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_SALES_REPORT_STATISTIC_ERRORS, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_SALES_REPORT_STATISTIC_TOTALS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}sales-reports/totals`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SALES_REPORT_STATISTIC_TOTALS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_SALES_REPORT_STATISTIC_ERRORS, response?.data?.errors);
        });
    }
}
