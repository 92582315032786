const role = {
  over: 'Over {total} members',
  name: 'Name',
  actions: 'Actions',
  check: 'Check',
  nameFunc: 'Name Module',
  namespace: 'Namespace',
  permissionName: 'Permission Name',
  enterPermissionName: 'Enter Permission Name',
  enterNamespace: 'Enter Namespace',
  enterName: 'Enter name',
  inputName: 'Please input name',
  inputNamespace: 'Please input Namespace',
  inputPermissionName: 'Please input Permission Name',
  inputModule: 'Please select module',
  created: 'Create Function',
  updated: 'Update Function',
  specifyName: 'Specify a target name for future usage and reference',
  specifyModule: 'Specify a target module for future usage and reference',
  selectModule: 'Select module',
  clickAdd: 'Click to add a function',
  newFunction: 'New Function',
  nameFunction: 'Name Function',
  specifyNamespace: 'Specify a target Namespace for future usage and reference',
  specifyPermissionName: 'Specify a target Permission Name for future usage and reference',

};
export default role;
