import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { AxiosRequestConfig } from 'axios';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { domainUrl } from '@/core/config/DomainConfig';
import { Getters } from '@/store/enums/GettersEnums';

@Module({ name: 'MarketingActionModule' })
export default class MarketingActionModule extends VuexModule implements StoreModel {

  error = {};
  actionText = '';
  actionType = '';
  actionExists = false;
  thresholds = [];

  /**
     * Get  errors
     * @returns object
     */
  get [Getters.GET_MARKETING_ACTION_ERROR]() {
    return this.error;
  }

  get [Getters.GET_MARKETING_ACTION_TEXT]() {
    return this.actionText;
  }

  get [Getters.GET_MARKETING_ACTION_TYPE]() {
    return this.actionType;
  }

  get [Getters.GET_MARKETING_ACTION_EXISTS]() {
    return this.actionExists;
  }

  get [Getters.GET_MARKETING_ACTION_THRESHOLDS]() {
    return this.thresholds;
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.error = {};
    this.actionText = '';
    this.actionType = '';
    this.actionExists = false;
    this.thresholds = [];
  };

    @Mutation
    [Mutations.SET_MARKETING_ACTIONS_ERRORS](error) {
      this.error = error;
    }

    @Mutation
    [Mutations.SET_MARKETING_ACTIONS_EXISTS](error) {
      this.actionExists = error;
    }

    @Mutation
    [Mutations.SET_MARKETING_ACTIONS_TEXT](payload) {
      this.actionText = payload;
    }

    @Mutation
    [Mutations.SET_MARKETING_ACTIONS_TYPE](payload) {
      this.actionType = payload;
    }

    @Mutation
    [Mutations.SET_MARKETING_ACTIONS_THRESHOLDS](payload) {
      this.thresholds = payload;
    }

    @Action
    [Actions.GET_CURRENT_MARKETING_ACTION](data) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: data
      };
      return ApiService.query(`${domainUrl}marketing-actions`, config)
        .then(({ data }) => {
          if (data.status && data.status === 404) {
            this.context.commit(Mutations.SET_MARKETING_ACTIONS_EXISTS, false);
          } else {
            this.context.commit(Mutations.SET_MARKETING_ACTIONS_EXISTS, true);
            this.context.commit(Mutations.SET_MARKETING_ACTIONS_TEXT, data.data.text);
            this.context.commit(Mutations.SET_MARKETING_ACTIONS_TYPE, data.data.type);
            this.context.commit(Mutations.SET_MARKETING_ACTIONS_THRESHOLDS, data.data.thresholds);
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_PAYMENT_ERRORS, response?.data?.errors);
        });
    }

}
