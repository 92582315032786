enum Main {
    // constant
    MIN_INSTALLS = 5,
    MIN_INSTALLS_ANDROID_GUARANTEE = 1,
    MAX_INSTALLS = 5000,
    MIN_DAYS = 1,
    STORE_GOOGLE_MARKET = 1,
    STORE_APPLE_MARKET = 2,
    MAX_DAYS_ADD_KEYWORD_INSTALL = 14,
    ADVERTISING_CAMPAIGN = 'advertising-campaign',
    DIRECT_INSTALLS = 'direct-installs',
    KEYWORD_INSTALLS = 'keyword-installs',
    REVIEWS_WITH_GUARANTEE = 'reviews-with-guarantee',
    SMART_CAMPAIGN = 'smart-campaign',
    ANDROID = 'android',
    IOS = 'ios',
    NAME_IOS = 'iOS',
    NAME_ANDROID = 'Android',
    NAME_IOS_FULL = 'Apple Appstore',
    NAME_ANDROID_FULL = 'Google Play Store',
    DEFAULT_COUNTRY = 'US',
    DEFAULT_LANGUAGE = 'en',
    DEFAULT_AVATAR_MALE = '/media/avatars/default-avatar-male.jpg',
    KEYAPP_LOGO = '/media/logos/keyapp.jpg',
    IOS_URL = 'https://apps.apple.com/us/app/id',
    ANDROID_URL = 'https://play.google.com/store/apps/details?id=',
    THREE_MONTH = 'three_month',
    MONTH = 'month',
    WEEK = 'week',
    ALL = 'all',
    FAV = 'fav',
    BAN = 'ban',
    TITLE = 'title',
    START_DAY_NAME = 'day_1',
    ICON = 'icon',
    DEVELOPER = 'developer',
    TOTAL = 'total',
    MOST_PROMOTED = 'most_promoted',
    NAME_UNLIMITED = 'unlimited',
    NAME_PROP_CAMPAIGN_UNLIMITED = 'unlimited_duration',
}

export { Main };
