const register = {
  forgotPassword:'¿Se te olvidó tu contraseña?',
  enterYourEmailBelowToResetYourPassword:'Ingrese su correo electrónico a continuación para restablecer su contraseña.',
  email:'Correo electrónico',
  cancel:'Cancelar',
  submit:'Enviar',
  pleaseWait:'Espere por favor...',
  weHaveSentYouEmail:'Le hemos enviado un correo electrónico con instrucciones sobre cómo cambiar su contraseña.',
  okLetMeIn:'¡Bien, déjame entrar!',
  tryAgain:'¡Intentar otra vez!',
  resetPassword:'¿Restablecer la contraseña?',
  password:'Clave',
  useMoreCharactersWithMixOfLetters:'Utilice 8 o más caracteres con una combinación de letras, números y símbolos.',
  confirmPassword:'Confirmar contraseña',
  passwordsMustMatch:'Las contraseñas deben coincidir',
  passwordConfirmation:'Confirmación de contraseña',
  yourPasswordIsSuccessfullyChanged:'¡Tu contraseña ha sido cambiada con éxito!',
  loginToYourAccount:'Ingrese a su cuenta',
  orLoginWithEmail:'o iniciar sesión con correo electrónico',
  newHere:'Nuevo aquí?',
  createNewAccount:'Crear una nueva cuenta',
  createAnAccount:'Crea una cuenta',
  loginToAccount:'Iniciar sesión en la cuenta',
  yourAccountIsNotActivated:'Su cuenta no está activada. Consulte su correo electrónico para ver las instrucciones de verificación o haga clic en volver a enviar.',
  activationEmeilWasSuccessfullySent:'El correo electrónico de activación se envió con éxito. Por favor revise su correo electrónico.',
  orEnterYourNewAccountDetailsBelow:'o ingrese los detalles de su nueva cuenta a continuación',
  orEnterYourDetails: 'o ingresa tus datos',
  username:'Nombre de usuario',
  iAmEighteenYearsOldOrOlder:'Confirmo que tengo 18 años o más',
  alreadyHaveAnAccount:'¿Ya tienes una cuenta?',
  login:'Acceso',
  toc:'Tac',
  agreement:'Acuerdo',
  yourAccountHasBeenCreated:'Su cuenta ha sido creada y ha recibido un correo electrónico de activación. Una vez activada, podrá iniciar sesión',
  byCreatingAnAccountWithUs:'Al crear una cuenta con nosotros, acepta automáticamente nuestros',
};

export default register;
