import { RolesEnums } from '@/store/enums/RolesEnums';

const terms = {
  path: '/',
  redirect: '/terms',
  component: () => import('@/layout/Layout.vue'),
  meta: { roles: [RolesEnums.ALL] },
  children: [
    {
      path: '/terms',
      name: 'terms-page',
      meta: { roles: [RolesEnums.ALL] },
      component: () => import('@/views/pages/Terms.vue')
    }

  ]
};
export default terms;
