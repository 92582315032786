const advertisingCampaigns = {
  headlines: 'Headlines',
  headline: 'Headline',
  description: 'Description',
  adsHeadlinesAndDescriptions: 'Ads headlines and descriptions',
  summary: 'Summary',
  targetCpi: 'Target CPI',
  dailyBudget: 'Daily budget',
  showHeadlines:' Show headlines',
  countries: 'Countries',
  duration: 'Duration',
};

export default advertisingCampaigns;
