import { Store, useStore } from 'vuex';
import { getDefaultErrors, modalErrorBackend, swalFireDefaultTemplate } from '@/assets/ts/_utils/SwalFireHelper';
import { ref } from 'vue';
import { SwalFireDefaultModel } from '@/assets/ts/_utils/models/SwalFireDefaultModel';
import { Mutations } from '@/store/enums/StoreEnums';
import { useRouter } from 'vue-router';
import translateI18 from '@/hooks/translateI18';
import { ErrorGetters } from '@/store/enums/ErrorGettersEnums';
import UserDispatcherService from '@/store/services/UserDispatcherService';

export default class ErrorHandlerUtil {

  private readonly _store: Store<any>;

  constructor(store = useStore()) {
    this._store = store;
  }

  public handle(errorGetter: ErrorGetters, errorMutation: Mutations, successFunction, ...successFunctionArgs: any[]) {
    const errors = this._store.getters[errorGetter];
    this._store.commit(errorMutation, {});
    if (Object.keys(errors)?.length === 0) {
      successFunction(...successFunctionArgs);
    } else {
      return modalErrorBackend(errors);
    }
  }

  public async asyncHandle(errorGetter: ErrorGetters, errorMutation: Mutations, successFunction, ...successFunctionArgs: any[]) {
    const errors = this._store.getters[errorGetter];
    this._store.commit(errorMutation, {});
    if (Object.keys(errors)?.length === 0) {
      await successFunction(...successFunctionArgs);
    } else {
      return modalErrorBackend(errors);
    }
  }

  public async handleSignUpErrors(router = useRouter(), refId) {
    const { translate } = translateI18();
    const error = this._store.getters.getErrorsAuth;
    if (Object.keys(error).length === 0) {
      const UserDispatcher = new UserDispatcherService(this._store);
      const user = UserDispatcher.getValidatedCurrentUser();
      const userId = user?.id;
      if (userId) {
        window.dataLayer.push({ 'event': 'register_complete', 'user_id': userId });
      }

      const SwalFireSuccessfullyAdded = ref<SwalFireDefaultModel>({
        text: translate('register.yourAccountHasBeenCreated'),
        confirmButtonText: translate('register.okLetMeIn'),
        confirmButtonClasses: 'btn fw-bold btn-light-primary'
      });
      swalFireDefaultTemplate(SwalFireSuccessfullyAdded).then(function () {
        // Go to page after successfully login
        router.push({ name: 'sign-in' });
      });
    } else {
      const redirect = this._store.getters.getRedirect;
      if (redirect) {
        await router.push({
          name: redirect.name,
          params: { email: redirect.email },
          query: { ref: refId }
        });
      }
      modalErrorBackend(error);
    }
    this._store.commit(Mutations.SET_ERROR, {});
  }

  public getPaymentError = () => {
    const errors = this._store.getters.getPaymentError;
    if (Object.keys(errors)?.length !== 0) {
      return getDefaultErrors(errors);
    }
  };

  public getPaymentErrorString = () => {
    if (this._store.getters.getPaymentError[0]?.amount) {
      return this._store.getters.getPaymentError[0]?.amount[0];
    }
    return '';
  };

  public async handleLoadInfoUserDetailsErrors(router = useRouter()) {
    const errors = this._store.getters.getErrorsUserLists;
    this._store.commit(Mutations.SET_ERROR_USER_LISTS, {});
    if (Object.keys(errors)?.length !== 0) {
      await router.push({ name: 'dashboard' });
    }
  }
}
