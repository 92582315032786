const myProfile = {
  editProfile:'Редактировать профиль',
  priceplan:'Прайс-план',
  currentPriceplan:'Актуальный тарифный план',
  totalPayments:'Общие платежи',
  lastPayment:'Последний платеж',
  profileDetails:'Детали профиля',
  nickname:'Псевдоним',
  fullName:'ФИО',
  country:'Страна',
  businessType:'Вид бизнеса',
  gender:'Пол',
  refLink:'Реф ссылка',
  apiKey:'ключ API',
  show:'Показать',
  hide:'Скрыть',
  contactInformation:'Контакты',
  phone:'Телефон',
  messengers:'Мессенджеры',
  email:'Эл. адрес',
  changePassword:'Изменить пароль',
  enterYourNickname:'Введите ваш псевдоним',
  enterYourFullName:'Введите свое полное имя',
  currentPassword:'Текущий пароль',
  enterYourPassword:'Введите свой пароль',
  newPassword:'Новый пароль',
  enterYourNewPassword:'Введите новый пароль',
  repeatNewPassword:'Повторите новый пароль',
  yourMessengerId:'Ваш идентификатор мессенджера',
  profileWasSuccessfullyChanged:'Профиль успешно изменен',
  fullNameIsNotValid:'Полное имя недействительно',
  phoneNumberIsNotValid:'Номер телефона недействителен',
  messengerIdIsNotTalid:'Идентификатор мессенджера недействителен',
};

export default myProfile;
