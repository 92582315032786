const accessLists = {
  header: {
    toolbar: {
      blackList: 'Blacklist',
      whiteList: 'Whitelist',
    },
    buttons: {
      addNewRecord: 'Add new record'
    }
  },
  accessLists: 'Access Lists',
  showing: 'Showing',
  accessListsOf: 'access lists of',
  noAccessListsFound: 'No access lists found',
  modal: {
    category: 'Category',
    enterCategory: 'Enter category',
    value: 'Value',
    enterValue: 'Enter value',
    inputValue: 'Please input value',
    inputCategory: 'Please input category',
    list_type: 'List type',
    enterListType: 'Please input list type',
    description: 'Description',
    enterDescription: 'Please input description',
    blackList: 'Black List',
    whiteList: 'White List',
  },
  allCategories: 'All Categories',
  successAdded: 'New value was added'
};

export default accessLists;
