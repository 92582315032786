import { RolesEnums } from '@/store/enums/RolesEnums';

const app = {
  path: '/',
  redirect: '/add-campaigns',
  component: () => import('@/layout/Layout.vue'),
  meta: { roles: [RolesEnums.ALL] },
  children: [
    {
      path: '/add-campaigns',
      name: 'add-campaigns',
      meta: { roles: [RolesEnums.ALL] },
      component: () => import('@/views/pages/AddCampaigns.vue')
    }

  ]
};
export default app;
