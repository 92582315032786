const marketingActions = {
  modal: {
    type: 'Type',
    bonus: 'Payment',
    discount: 'Campaign',
    date: 'Date',
    store: 'Store',
    country: 'Country',
    products: 'Products',
    name: 'Name',
    actionText: 'Action`s text',
    priceplans: 'Priceplans',
    storeMarketingAction: 'Add new Action',
    updateMarketingAction: 'Update Action',
  },
  messages: {
    success: 'Action successfully saved',
    destroy: 'Action successfully deleted',
  },
  actions: 'Marketing actions',
  showing: 'showing',
  actionsOf: 'actions of',
  noActionsFound: 'No actions found',
  addNew: 'Add new action',
};

export default marketingActions;
