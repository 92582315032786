const myProfile = {
  editProfile: 'Edit profile',
  priceplan: 'Priceplan',
  currentPriceplan: 'Current priceplan',
  totalPayments: 'Total payments',
  lastPayment: 'Last payment',
  profileDetails: 'Profile details',
  nickname: 'Nickname',
  fullName: 'Full name',
  country: 'Country',
  businessType: 'Business type',
  gender: 'Gender',
  refLink: 'Ref link',
  apiKey: 'API key',
  show: 'Show',
  hide: 'Hide',
  contactInformation: 'Contact information',
  phone: 'Phone',
  messengers: 'Messangers',
  email: 'Email',
  changePassword: 'Change password',
  enterYourNickname: 'Enter your nickname',
  enterYourFullName: 'Enter your full name',
  currentPassword: 'Current password',
  enterYourPassword: 'Enter your password',
  newPassword: 'New password',
  enterYourNewPassword: 'Enter your new password',
  repeatNewPassword: 'Repeat new password',
  yourMessengerId: 'Your messenger id',
  profileWasSuccessfullyChanged: 'Profile was successfully changed',
  fullNameIsNotValid: 'Full name is not valid',
  phoneNumberIsNotValid: 'Phone number is not valid',
  nicknameIsNotValid: 'Nickname is not valid',
  messengerIdIsNotTalid: 'Messenger ID is not valid',
  editImage: 'Change avatar',
  deleteImage: 'Remove avatar',
  successUpdateImage: 'Avatar was successfully updated',
  emailNotifications: 'Email notifications',
  noEmailNotificationsFound: 'No email notifications found',
};

export default myProfile;
