const apps = {
  appToolbar: {
    all:'Todo',
    android:'Android',
    ios:'iOS',
    favorite:'Favorito',
    banned:'Prohibido',
    allApps:'Todas las aplicaciones',
    googlePlayApps:'Google Play aplicaciones',
    appStoreApps:'App Store aplicaciones',
    favoriteApps:'Favoritas aplicaciones',
    bannedApps:'Prohibidas aplicaciones',
  },
  appList: {
    addNewApp:'Agregar nueva aplicación',
    shown:'Mostrado',
    appsFrom:'aplicaciones de',
    added:'adicional',
    found:'Encontrado',
    applicationsBySearch:'aplicaciones por búsqueda',
    nothingFoundBySearch:'Nada encontrado por búsqueda',
    startWithAddingNewApplication:'Comience agregando una nueva aplicación',
  },
  appItem: {
    unavailable:'Indisponible',
    promote:'Promover',
    createKeywordsCampaign:'Crear campaña de palabras clave',
    createDirectInstallsCampaign:'Crear campaña de instalaciones directas',
    createReviewCampaign:'Crear campaña de revisión',
    createCpiCampaign:'Crear campaña de CPI',
    addKeywords:'Añadir palabras clave',
    refreshApp:'Actualizar aplicación',
    deleteApp:'Eliminar aplicación',
    applicationId:'ID de aplicación',
    downloads:'Descargas',
    category:'Categoría',
    contentRating:'Calificación de contenido',
  },
  appstoreToggle: {
    store:'Tienda',
    googlePlay:'Google Play',
    appStore:'App Store',
  },
  countrySelect: {
    country:'País',
  },
  appSelect: {
    pleaseEnterApplicationPackage:'Ingrese el paquete de la aplicación, el ID de seguimiento o la URL de la tienda',
    beforeAddingNewApplication:'Antes de agregar una nueva aplicación, verifique',
    appIsFree:'la aplicación es GRATUITA (no podemos promocionar aplicaciones pagas)',
    appIsAvailableInSelectedCountry:'la aplicación está disponible en el país seleccionado para una gran cantidad de dispositivos',
    appDoesntRequireSystemPermissions:'la aplicación no requiere ningún permiso especial del sistema (como Root o NFC)',
  },
  appDropdown: {
    quickActions:'Acciones rápidas',
    update:'Actualizar',
    updating:'Actualizando...',
    delete:'Borrar',
    myKeywords:'Mis palabras clave',
    statistics:'Estadísticas',
    externalAnalytics:'Análisis externo',
    showInAppfollow:'Mostrar en Appfollow',
    showInAsoMobile:'Mostrar en AsoMobile',
    showInAsoTools:'Mostrar en AsoTools',
  }
};
export default apps;
