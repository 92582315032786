const keywords = {
  keywordHeader: {
    addKeyword: 'Добавити ключове слово',
    importKeywordsFromFile: 'Імпорт ключових слів із файла'
  },
  keywordToolbar: {
    googlePlayApps: 'Google Play програми',
    appStoreApps: 'App Store програми'
  },
  appKeywordSelect: {
    selectYourApp: 'Виберіть свої програми',
    pleaseEnterApplicationPackage: 'Будь ласка, введіть пакет програми, ідентифікатор відстеження або URL-адресу магазину',
    beforeAddingNewApplication: 'Перед додаванням нової програми перевірте',
    appIsFree: 'додаток БЕЗКОШТОВНО (ми не можемо просувати платні програми)',
    appIsAvailableInSelectedCountry: 'програма доступна у вибраній країні для великої кількості пристроїв',
    appDoesntRequireSystemPermissions: 'програма не вимагає будь-яких спеціальних системних дозволів (таких як Root або NFC)'
  },
  createKeywordModal: {
    addKeywords: 'Добавити ключові слова',
    addNewKeyword: 'Добавити нове ключове слово',
    pleaseAddKeyword: 'Будь ласка, додайте ключові слова для просування',
    addKeywordsByPressingEnter: 'Наприклад, павук, гра в шахи або класну програму, додайте ключові слова по одному, натискаючи Enter.',
    dontAddQuotesSlashesHashtagsToKeywords: 'Будь ласка, не додайте до ключових слів лапки, косі риси, хештеги або спеціальні символи.',
    add: 'Добавити'
  },
  keywordChart: {
    keywordRankHistory: 'Історія рейтинга ключових слів',
    threeMonths: '3 Місяця',
    month: 'Місяць',
    noHistory: 'Дані історії відсутні',
    week: 'Тиждень'
  },
  keywordChartDropdown: {
    quickActions: 'Швидкі дії',
    refreshChart: 'Оновити'
  },
  keywordTable: {
    myKeywords: 'Мої ключові слова',
    selected: 'Вибрано',
    keywordsOf: 'ключові слова',
    found: 'Знайдений',
    keywordFrom: 'ключові слова із',
    nothingFoundBySearch: 'Пошуком нічого не знайдено',
    noKeywordsFound: 'Ключові слова не знайдені'
  },
  keywordTableDropdown: {
    quickActions: 'Швидкі дії',
    exportKeywords: 'Експорт ключових слів',
    updateAllRanks: 'Оновить всі ранги',
    deleteKeywords: 'Видалити ключові слова',
    clearKeywords: 'Очистити ключові слова'
  },
  keywordEasyDataTable: {
    noKeywordsFound: 'ключові слова не знайдені',
    rank: 'Категорія',
    scoreIsSearchPopularityIndex: 'Score - це індекс популярності в пошуку, ранжований від 1 [поганий] до 100 [кращий]. Ключові слова з більш високою оцінкою можуть потенційно залучити більше органічного трафіку.',
    estimatedMaximumDailySearches: 'Розрахункова максимальна кількість щоденних пошуків за цим ключовим словом',
    only: 'Тільки',
    keywordsCouldBeSelected: 'ключові слова можуть бути вибрані',
    favorite: 'Улюблений',
    promote: 'Просувати',
    topApps: 'Найкращі програми',
    suggestedKeywords: 'Пропоновані ключові слова',
    delete: 'Видалити'
  },
  keywordSuggested: {
    noSuggestedKeywordsFound: 'Запропоновані ключові слова не знайдені',
    addSuggestedKeywords: 'Додайте запропоновані ключові слова'
  },
  rank: {
    checkActualRank: 'Перевірити фактичну категорію',
    showRankHistoryChart: 'Показати діаграму історії категорій',
    outOfRank: 'Поза категорією',
    rankError: 'Помилка категорії',
    needClickLoading: 'Потрібне завантаження по кліку',
    rankNotChecked: 'Ранг не перевіряється',
    lastUpdate: 'останнє оновлення'
  }
};

export default keywords;
