const globalUrl = '/api/';
const domainUrl = '/api/private/v1/';
const domainUrlPublic = '/api/public/v1/';
const domainUrlAdmin = '/api/admin/v1/';
const domainUrlLegacy = '/api/legacy/v1/';

export {
  globalUrl,
  domainUrl,
  domainUrlPublic,
  domainUrlAdmin,
  domainUrlLegacy
};
