import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import ApiService from '@/core/services/ApiService';
import { domainUrl } from '@/core/config/DomainConfig';
import { ErrorGetters } from '@/store/enums/ErrorGettersEnums';

@Module({ name: 'ResellersProgramModule' })
export default class ResellersProgramModule extends VuexModule implements StoreModel {
  errors = {};

  /**
     * Get  errors
     * @returns array
     */
  get [ErrorGetters.GET_ERRORS_RESELLERS_PROGRAM]() {
    return this.errors;
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.errors = {};
  };

    @Mutation
    [Mutations.SET_ERROR_RESELLER_PROGRAM](error) {
      this.errors = { ...error };
    }

    @Action
    [Actions.RESELLER_PROGRAM_STORE](params) {
      return ApiService.post(`${domainUrl}resellers`, params)
        .then(({ data }) => {
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_DETAILS_PAYMENTS, response?.data?.errors);
        });
    }
}
