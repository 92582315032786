const addCampaigns = {
  products: 'Товары',
  pickAvaialbleCampaignTypes: 'Выберите один из доступных типов кампаний',
  notes: 'Заметки',
  pleaseDontChangeYourAppsIcon: 'Пожалуйста, не меняйте значок, имя или имя издателя вашего приложения во время проведения кампании. Наши пользователи будут искать ваше приложение по ключевым словам, и единственный способ найти его — посмотреть на значок и сравнить название приложения.',
  whatIsAdvertisingCpiCampign: 'Что такое рекламная компания CPI?',
  cpi: 'CPI (cost-per-install) компания - это инструмент, используемый для привлечения пользователей в ваше приложение. Эти пользователи не мотивированы драгоценными камнями или монетами, как те, кто устанавливает ваше приложение, когда вы заказываете установку по ключевому слову. Эти пользователи установят ваше приложение, только если оно им понравится. Это означает, что вы будете привлекать людей, которые приносят вам доход и активность в приложении.',
  keyword: 'Ключевое слово',
  install: 'установить',
  direct: 'Прямой',
  installs: 'Устанавливает',
  guaranteed: 'Гарантировано',
  reviews: 'Отзывы',
  smart: 'Умная',
  campaign: 'Кампания',
  addCampaigns: 'Добавить кампании',
  addCampaign: 'Добавить кампанию',
  advertising: 'Реклама',
  summary: 'Резюме',
  product: 'Товар',
  keywordInstalls: 'Установки по ключевому слову',
  installsByKeywordsForYourApplication: 'Количество установок по ключевым словам для вашего приложения в целевом регионе',
  application: 'Заявление',
  targetCountry: 'Целевая страна',
  daysInCampaign: 'Дней в кампании',
  finishAt: 'Закончить в',
  campaignOrders: 'Campaign orders',
  days: 'дни',
  directInstalls: 'Прямые установки',
  rates: 'Ставки',
  total: 'Общий',
  fixedPartner: 'Фиксированный партнер',
  day: 'День',
  description: 'Описание',
  saveDraft: 'Сохранить черновик',
  startCampaign: 'Start Campaign',
  iAgreeTo: 'я согласен с',
  terms: 'условия',
  ofUse: 'использования',
  textSuccessfully: 'Кампания успешно создана',
  textSuccessfullyDraft: 'Создан новый проект кампании № {id}.',
  refundPolicyForRatesAndReviews: 'Политика возврата средств за тарифы и обзоры',
  almostAllRatesAndReviewsAreGettingDeleted: 'Предупреждение! Почти все оценки и отзывы удаляются любым магазином приложений, мы НЕ вернем деньги за оценки и отзывы. Используйте их на свой страх и риск',
  allRatesAndReviewsWillAppearOnMarket: 'Все оценки и отзывы появятся на странице вашего приложения в маркете через 2-3 часа после завершения кампании. Через 1-2 дня большинство ставок и обзоров, заказанных в этом разделе, будут удалены, и это сильно зависит от типа вашего приложения, его органического трафика и увеличения обычных ежедневных ставок, поэтому, продолжая, вы соглашаетесь на этот риск.',
  ifYouNeedReviewsAndRates: 'Если вам нужны отзывы и оценки, чтобы оставаться на странице приложения, а также получить их с гарантией возврата денег или репостом в течение 30 дней после выполнения заказа, перейдите в акцию «Добавить гарантированные отзывы».',
  evenIfItIsOkay: 'Даже если вас устраивает, что заказанные в этом разделе рейтинги и отзывы удаляются, не заказывайте их тоннами. Поведение ставок должно быть похоже на реальный поток пользователей, пропорционально органическому трафику вашего приложения в выбранной стране и аналогично вашему текущему количеству ставок.',
  iveReadAndAreeToThe: 'Я прочитал и согласен с',
  chargebackAndRefundPolicy: 'Политика возврата и возврата средств',
  deleteAllKeywords: 'Удалить все ключевые слова',
  keywordInstall: {
    keywordInstallAddKeywordsBody: {
      keywords: 'ключевые слова',
      advancedMode: 'Расширенный режим',
      dayOne: 'день 1',
      addKeyword: 'Добавить ключевое слово'
    },
    keywordInstallKeywordsTable: {
      noKeywordsFound: 'Ключевые слова не найдены',
      selectKeyword: 'Выберите ключевое слово',
      addKeywordInstalls: 'Добавить установки по ключевому слову',
      increase: 'Увеличивать',
      parabolic: 'Параболический',
      equal: 'Равный',
      days: 'Дни'
    },
    directInstallTable: {
      addDirectInstalls: 'Перевод',
      days: 'Дни',
      packageOrDirectInstalls: 'Пакетная или прямая установка',
      delete: 'Удалить'
    },
    ratesAndReviewsKeywordInstallBody: {
      ratesAndReviews: 'Цены и отзывы',
      addRatesAndReviewsToCampaign: 'Добавьте оценки и отзывы в кампанию',
      days: 'Дни',
      rates: 'Ставки',
      delete: 'Удалить',
      reviews: 'Отзывы',
      importReviews: 'Импорт отзывов',
      errorCheckedTerms: 'Вы должны согласиться с условиями использования, прежде чем заказывать оценки или обзоры.'
    },
    promotionRuleKeywordInstallBody: {
      promotionRule: 'Правило продвижения',
      dontPromoteKeywordsWhenTheyReachRank: 'Не продвигайте ключевые слова, когда они достигают рейтинга',
      installsDeliveryType: 'Устанавливает тип доставки',
      spreadInstallsForTwentyFourHours: 'Распространение установок за 24 часа',
      allInstallsAtOnce: 'Все установки сразу',
      deeplinkTitle: 'Глубокая ссылка',
      deeplink: 'Глубокая ссылка выполняется после установки приложения, например run?source=keyapp, ',
      deeplinkIos: 'читайте больше в нашем блоге'
    },
    addMyReviewsModal: {
      addMyReviews: 'Добавить мои отзывы',
      added: 'Добавлен',
      reviews: 'обзоры',
      newReview: 'Добавить новый отзыв',
      addManyRandomReview: 'Добавить 5 случайных отзывов',
      deleteAll: 'Удалить все',
      saveReview: 'Сохранить отзыв',
      deleteReview: 'Удалить отзыв',
      editReview: 'Изменить отзыв',
      youHaveNoAddedReviewsYet: 'У вас еще нет добавленных отзывов',
      add: 'Добавлять',
      titleText: 'Добавьте новое название отзыва',
      bodyText: 'Добавьте новый текст отзыва'
    },
    descriptionKeywordInstallBody: {
      description: 'Описание',
      leaveAnyNotesForYourcampaign: 'Оставляйте любые заметки для вашей кампании (только на английском языке, не более 1000 символов)'
    }
  },
  guaranteedReviews: {
    reviewGuaranteedReviewsBody: {
      guaranteedReviews: 'Гарантированные отзывы',
      addGuaranreedReviewsToYourApplication: 'Добавьте гарантированные отзывы к вашему приложению',
      myCustom: 'Мой обычай',
      reviews: 'обзоры',
      writeReviews: 'Пишите отзывы',
      forMe: 'для меня',
      improveYourRatesAndAppsReputation: 'Улучшайте свои рейтинги и репутацию приложений с помощью наших обзоров. Получите 30-дневную гарантию на любой заказ.',
      campaignDetails: 'Детали кампании',
      days: 'Дни',
      _reviews: 'Отзывы',
      importReviews: 'Импорт отзывов',
      delete: 'Удалить',
      authorGender: 'Пол автора',
      male: 'Мужской',
      female: 'женский',
      both: 'Оба',
      pickPreferredGenderOfAccount: 'Выберите предпочитаемый пол аккаунта, который напишет отзыв',
      reviewLanguage: 'Язык обзора',
      pickPreferredLanguageForReviews: 'Выберите предпочитаемый язык для отзывов, или будет использоваться язык выбранной страны по умолчанию.',
      description: 'Описание',
      leaveAnyNotesForYourOrder: 'Оставьте любые примечания к вашему заказу (только на английском языке, 1000 символов), например. предпочтительные ключевые слова, фразы и т. д.',
      notes: 'Заметки',
      thisIsGuaranteedReviewsOrder: 'Это гарантированный порядок отзывов, это означает, что при заказе 100 отзывов мы гарантируем, что не менее 90 из них будут оставаться на странице вашего приложения не менее 30 дней, в противном случае мы переопубликуем недостающие отзывы. Также ваш заказ может находиться в статусе МОДЕРАЦИЯ в течение 12-48 часов, пока мы не проверим все отзывы.'
    }
  },
  advertisingCampaign: {
    advertisingCampaignTab: {
      back: 'Назад',
      startCampaign: 'Начать кампанию',
      pleaseWait: 'Пожалуйста подождите...',
      next: 'Следующий'
    },
    advertisingCampaignStep1: {
      addTargetCountriesForAppPromotion: 'Добавьте целевые страны для продвижения приложения',
      usuallyWeRecommendToStartCpiAampaignsInSuchCases: 'Обычно в таких случаях мы рекомендуем запускать CPI-кампании.',
      youDontHave: 'У вас нет',
      organicTrafficOnYourApp: 'органический трафик в вашем приложении, но вы хотите запустить кампании по продвижению ключевых слов. Когда вы одновременно заказываете установки по ключевым словам и установки CPI, ранжирование ключевых слов вашего приложения повышается намного быстрее.',
      youHave: 'У вас есть',
      newAppAndYouWantToGetKeywordsIndexationFaster: 'новое приложение и вы хотите ускорить индексацию ключевых слов',
      newAppAndYouWantToGetYourMainPageInstallsNumberHigher: 'новое приложение, и вы хотите увеличить количество установок на главной странице',
      toGetBetterCategoryRankings: 'чтобы получить лучший рейтинг в категориях',
      youEarnMore: 'Вы зарабатываете больше',
      fromEachInstallThenYouPayForIt: 'с каждой установки, то вы платите за это',
      toAttractUsersFromSpecifiedRegion: 'для привлечения пользователей из указанного региона',
      cryptocurrenciesBinaryOptionsPersonalLoansAppsAreForbidden: 'Криптовалюты, бинарные опционы, потребительские кредиты и подобные приложения запрещены.',
      youWant: 'Ты хочешь',
      pleaseNote: 'Пожалуйста, обрати внимание'
    },
    advertisingCampaignStep2: {
      selectPromotionCountries: 'Выберите страны акции',
      addTargetCountriesForAppPromotion: 'Добавьте целевые страны для продвижения приложения',
      filterByCpi: 'Сортировать по CPI',
      filterByGeo: 'Сортировать по GEO',
      filterByLanguage: 'Сортировать по language',
      highCpi: 'Высокий CPI',
      mediumCpi: 'Средний CPI',
      lowCpi: 'Низкий CPI',
      europe: 'Европа',
      asia: 'Азия',
      africa: 'Африка',
      latinAmerica: 'Латинская Америка',
      worldwide: 'Мировой',
      english: 'Английский',
      spanish: 'Испанский',
      russian: 'Русский',
      targetedCountries: 'Целевые страны'
    },
    advertisingCampaignStep3: {
      campaignBudget: 'Бюджет кампании',
      defineDailyBudget: 'Определите дневной бюджет, общее количество дней и цель CPI',
      dailyBudget: 'Ежедневный бюджет',
      targetCpi: 'Цель CPI',
      daysInCampaign: 'Дней в кампании',
      unlimited: 'Неограниченный',
      days: 'дни'
    },
    advertisingCampaignStep4: {
      headlines: 'Заголовки',
      addHeadlinesAndDescriptionsForAds: 'Добавьте заголовки и описания для объявлений',
      headlineOne: 'Заголовок #1',
      weUseHeadlinesAndDescriptionsToAdvertiseYourApp: 'Мы используем заголовки и описания для рекламы вашего приложения. Привлекательные заголовки и описания могут помочь вам повысить CTR (больше кликов от просмотров), поэтому ваш средний CPI снизится.',
      generate: 'Создать',
      headlineTwo: 'Заголовок #2',
      misleadingDescriptions: 'Вводящие в заблуждение описания могут повысить CTR, но пользователи не установят ваше приложение, когда зайдут на страницу вашего приложения. Таким образом, вы получите низкий коэффициент конверсии, а ваш CPI увеличится.',
      description: 'Описание',
      checkThatYourTextMakesSenseForUsers: 'Убедитесь, что ваш текст имеет смысл для пользователей. Это важно, потому что наши партнеры из рекламных сетей будут применять процесс утверждения к вашим объявлениям. Если вы нарушите некоторые правила, ваши объявления должны пройти процесс утверждения.'
    },
    advertisingCampaignStep5: {
      summary: 'Резюме',
      confirmCampaignDetails: 'Подтвердите данные кампании',
      application: 'Заявление',
      nameOfApplication: 'Название приложения...',
      developer: 'Разработчик',
      countries: 'Страны',
      duration: 'Продолжительность',
      targetCpi: 'Цель CPI',
      dailyBudget: 'Ежедневный бюджет',
      startCharge: 'Начать зарядку',
      toStartThisCampaign: 'Чтобы начать эту кампанию, мы спишем с вашего баланса 20 $. Нам нужна эта сумма (на самом деле это удвоенный дневной бюджет) в качестве предоплаты для будущих расходов на кампанию.',
      weWillChargeYourAccount: 'Каждый день мы будем списывать с вашего счета сумму, которую вы потратили на продвижение в течение предыдущего дня. Если на вашем балансе недостаточно средств для покрытия расходов за предыдущий день, мы будем использовать ваш начальный платеж и приостановим кампанию до тех пор, пока вы не пополните свой баланс и не приостановите кампанию вручную.',
      refund: 'Возврат',
      youMayCancelYourCampaign: 'Вы можете отменить свою кампанию в любое время, но мы вернем деньги только через 24 часа после отмены, чтобы остановить кампанию во всех рекламных сетях и получить фактические расходы на кампанию. То же правило относится и к завершению кампаний. Сумма возврата рассчитывается как разница между ценой кампании и затратами на кампанию.',
      dailyCharge: 'Ежедневная зарядка'
    },
    advertisingCampaignAside: {
      letsStart: 'Давайте начнем!',
      detailsOfStepOneHere: 'подробности шага 1 здесь',
      stepTwo: 'Шаг 2',
      detailsOfStepTwoHere: 'подробности шага 2 здесь',
      stepThree: 'Шаг 3',
      detailsOfStepThreeHere: 'подробности шага 3 здесь',
      stepFour: 'Шаг 4',
      detailsOfStepFourHere: 'подробности шага 4 здесь',
      stepFive: 'Шаг 5',
      detailsOfStepFiveHere: 'подробности шага 5 здесь'
    }
  }
};

export default addCampaigns;
