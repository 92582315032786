import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { AxiosRequestConfig } from 'axios';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { domainUrlAdmin } from '@/core/config/DomainConfig';
import { Getters } from '@/store/enums/GettersEnums';

@Module({ name: 'AdminPaymentSystemModule' })
export default class AdminPaymentSystemModule extends VuexModule implements StoreModel {

  error = {};
  paysystems = [];
  search = '';
  totalPaysystem = 0;

  /**
     * Get  paysystems
     * @returns object
     */
  get [Getters.GET_ADMIN_PAY_SYSTEMS]() {
    return this.paysystems;
  }

  /**
     * Get  errors
     * @returns object
     */
  get [Getters.GET_ADMIN_PAYMENT_ERROR]() {
    return this.error;
  }

  /**
     * Get  errors
     * @returns object
     */
  get [Getters.GET_ADMIN_PAYSYSTEM_SEARCH]() {
    return this.search;
  }

  /**
     * Get  errors
     * @returns object
     */
  get [Getters.GET_ADMIN_PAYSYSTEM_TOTAL]() {
    return this.totalPaysystem;
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.error = {};
    this.paysystems = [];
    this.search = '';
    this.totalPaysystem = 0;
  };

    @Mutation
    [Mutations.SET_ADMIN_PAYSYSTEMS](payload) {
      this.paysystems = payload;
    }

    @Mutation
    [Mutations.SET_ADMIN_PAYMENT_ERRORS](error) {
      this.error = error;
    }

    @Mutation
    [Mutations.SET_CORE_SETTING_PAYSYSTEM_SEARCH](payload: string) {
      this.search = payload;
    }

    @Mutation
    [Mutations.SET_ADMIN_TOTAL_PAYSYSTEMS](payload: number) {
      this.totalPaysystem = payload;
    }

    @Action
    [Actions.GET_ADMIN_PAYSYSTEMS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}paysystems`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ADMIN_TOTAL_PAYSYSTEMS, data.data.length);
          this.context.commit(Mutations.SET_ADMIN_PAYSYSTEMS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ADMIN_PAYMENT_ERRORS, response?.data?.errors);
        });
    }

    @Action
    [Actions.CHANGE_IS_ACTIVE_PAYSYSTEM](id) {
      return ApiService.update(`${domainUrlAdmin}paysystems/change-is-active-paysystem`, id, {})
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ADMIN_PAYMENT_ERRORS, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ADMIN_PAYMENT_ERRORS, response?.data?.errors);
        }).finally(() => {
          this.context.commit(Mutations.SET_LOADING, false);
        });
    }
}
