import { Actions } from '@/store/enums/StoreEnums';
import StoreGettersService from '@/store/services/StoreGettersService';
import { Getters } from '@/store/enums/GettersEnums';
import { useStore } from 'vuex';

export class PriceplanDispatcherService extends StoreGettersService {

  constructor (store = useStore()) {super(store);}

  public async getAllPriceplans () {
    return await this.getAllRecordsByGetter(Getters.GET_ALL_PRICEPLANS, Actions.GET_PRICEPLANS, { limit: 0, page: 1 });
  }

  public async getCurrentPriceplan () {
    return await this.getAllRecordsByGetter(Getters.GET_PRICEPLAN, Actions.GET_CURRENT_PRICEPLAN, {}, true);
  }

  public getValidatedPriceplan () {
    return this.getValidatedGetter(Getters.GET_PRICEPLAN);
  }

  public getValidatedPriceplansStatus () {
    return this.getValidatedGetter(Getters.GET_PRICEPLANS_STATUS);
  }

  public getValidatedCurrentPriceplanStatus () {
    return this.getValidatedGetter(Getters.GET_CURRENT_PRICEPLAN_STATUS);
  }
}
