const referralProgram = {
  welcomeToOurProgram:'Ласкаво просимо до нашої реферальної програми!',
  youCanEarnMoney:'Ви можете заробляти рекомендуючи наш сервіс іншим користувачам.',
  oneUserRegistersUsingYourReferralLink:'Як тільки користувач зареєструється за вашим реферальним посиланням, ви отримуватимете <b>10%</b> від усіх його платежів у нашому сервісі довічно. Ви можете отримати реферальний платіж у будь-який час, прямо на свій баланс або на свій рахунок Paypal.',
  refLink:'Реф посилання',
  pleaseBeAdviced:'Зверніть увагу, що використання мультиакаунтів на нашій платформі заборонено. Якщо ми виявимо, що ви зловживаєте нашою системою, ваші реферальні платежі не будуть зараховані на ваш рахунок, і ви не зможете отримати їх через Paypal. Безперервні порушення можуть призвести до призупинення дії облікового запису. Наприклад, здійснення платежів для вашого основного та реферального облікового запису з однієї й тієї ж електронної адреси Paypal заборонено.',
  date:'Дата',
  totalPayments:'Усього платежів',
  level:'Рівень',
  userName:'Ім\'я користувача',
  userEmail:'Електронна пошта користувача',
  newPayments:'Нові платежі',
  newPayouts:'Нові виплати',
  earnings:'Дохід',
  registered:'Зареєстровано',
  paymentsHistory:'Історія платежів',
  payoutsHistory:'Історія виплат',
  payments:'платежі',
  showing:'Показано',
  myReferrals:'Мої реферали',
  referrals:'рекомендації',
  noPaymentsFound:'Платежі не знайдено',
  noReferralsFound:'Реферали не знайдені',
  showAll:'Показати всіх',
  showActive:'Показати активних',
  showOnly:'Показати тільки',
  myReferralLink:'Моє реферальне посилання',
  totalReferrals:'Усього рефералів',
  paymentsOfReferrals:'Виплати рефералів',
  totalPayouts:'Усього виплат',
  newPayout:'Нова виплата',
  requestPayout:'Запит виплат',
  status:'Статус',
  all:'Всі',
  hasPayments:'Є платежі',
  withPayments:'С платежами',
  yourPayoutRequestHasBeenCanceled:"Ваш запит на виплату було скасовано. Будь ласка, зв'яжіться зі службою підтримки",
  yourPayoutHasBeenCompletedSuccessfully:'Вашу виплату успішно завершено. Кількість',
  hasBeenAddedToYourAccount:'$ has був доданий до вашого облікового запису.',
};

export default referralProgram;
