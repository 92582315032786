import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ref } from 'vue';
import { SwalFireDefaultErrorModel } from '@/assets/ts/_utils/models/SwalFireDefaultErrorModel';
import translateI18 from '@/hooks/translateI18';

const { translate } = translateI18();

function swalFireTemplateCallbackText(SwalFireDefaultModel, callback) {
  Swal.fire({
    text: SwalFireDefaultModel.value.text,
    icon: 'success',
    buttonsStyling: false,
    confirmButtonText: SwalFireDefaultModel.value.confirmButtonText,
    customClass: {
      confirmButton: 'btn btn-primary',
    },
  }).then((result) => {
    callback(result);
  });

}

function modalErrorTryAgain() {
  const SwalFireTextError = ref<SwalFireDefaultErrorModel>({
    html: translate('swalSorryLooksTryAgain'),
    confirmButtonText: translate('swalConfirmButtonText'),
  });
  swalFireDefaultErrorTemplate(SwalFireTextError);
  return false;
}

function modalErrorBackend(errors, html: string|null = null, textEnd: string|null = null) {
  const text: string[] = getDefaultErrors(errors);
  const SwalFireError = ref<SwalFireDefaultErrorModel>({
    html: text.join('') + (html ? html : '') + (textEnd ? textEnd : ''),
    confirmButtonText: translate('swalConfirmButtonText'),
  });
  swalFireDefaultErrorTemplate(SwalFireError);
  return false;
}

function swalFireTemplateCallback(SwalFireDeleteModel, callback) {
  Swal.fire({
    title: SwalFireDeleteModel.value.title,
    buttonsStyling: false,
    showCancelButton: true,
    confirmButtonText: SwalFireDeleteModel.value.confirmButtonText,
    cancelButtonText: SwalFireDeleteModel.value.cancelButtonText,
    customClass: {
      confirmButton: 'btn btn-primary',
      cancelButton: 'btn btn-danger'
    }
  }).then((result) => {
    callback(result);
  });

}

function swalFireDefaultTemplate(SwalFireDefaultModel) {
  return Swal.fire({
    text: SwalFireDefaultModel.value.text,
    icon: 'success',
    buttonsStyling: false,
    confirmButtonText: SwalFireDefaultModel.value.confirmButtonText,
    customClass: {
      confirmButton: SwalFireDefaultModel.value.confirmButtonClasses ?? 'btn btn-primary',
    },
  });
}

function swalFireDefaultErrorTemplate(SwalFireDefaultErrorModel) {
  Swal.fire({
    html: SwalFireDefaultErrorModel.value.html,
    icon: 'error',
    buttonsStyling: false,
    confirmButtonText: SwalFireDefaultErrorModel.value.confirmButtonText,
    customClass: {
      confirmButton: 'btn btn-primary',
    },
  });
}

function getDefaultErrors(errors) {
  const text: string[] = [];
  let ErrorsKey: string, ErrorsItem: any;
  for ([ErrorsKey, ErrorsItem] of Object.entries(errors)) {
    let key: string, value: any;
    for ([key, value] of Object.entries(ErrorsItem)) {
      text.push(value);
    }
  }
  return text;
}

function questionModal(SwalFireQuestionModel, callback) {
  Swal.fire({
    text: SwalFireQuestionModel.value.text,
    icon: 'question',
    buttonsStyling: false,
    confirmButtonText: SwalFireQuestionModel.value.confirmButtonText ?? translate('yes'),
    showCancelButton: true,
    cancelButtonText: SwalFireQuestionModel.value.cancelButtonText ?? translate('no'),
    customClass: {
      confirmButton: SwalFireQuestionModel.value.confirmButtonClasses ?? 'btn btn-primary',
      cancelButton: SwalFireQuestionModel.value.cancelButtonClasses ?? 'btn btn-danger',
    },
  }).then((result) => {
    callback(result);
  });
}

function paysystemErrorModal(errorMessage) {
  Swal.fire({
    text: errorMessage || translate('swalSorryLooksTryAgain'),
    icon: 'error',
    buttonsStyling: false,
    confirmButtonText: translate('close'),
    customClass: {
      confirmButton: 'btn fw-bold btn-light-danger',
    },
  });
}

export {
  modalErrorTryAgain,
  modalErrorBackend,
  swalFireTemplateCallbackText,
  swalFireTemplateCallback,
  swalFireDefaultTemplate,
  swalFireDefaultErrorTemplate,
  getDefaultErrors,
  questionModal,
  paysystemErrorModal,
};
