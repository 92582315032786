import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { AxiosRequestConfig } from 'axios';
import ApiService from '@/core/services/ApiService';
import { domainUrlAdmin } from '@/core/config/DomainConfig';
import { Getters } from '@/store/enums/GettersEnums';

@Module({ name: 'AdminUserAppModule' })
export default class AdminUserAppModule extends VuexModule implements StoreModel {
  errors = {};
  userApps = [];
  totalUserApps = 0;
  currentPage = 1;
  search = '';
  lastPage = 1;
  page = 1;
  rowsPerPage = 10;
  limit = 10;
  orderBy = 'id';
  sortBy = 'desc';
  queryParams = {};
  export = [];
  appstore = 0;
  columnSearchFilter = '';
  allAppsFilter = true;

  /**
     * Get current page
     * @returns number
     */
  get [Getters.GET_ADMIN_USER_APPS_CURRENT_PAGE]() {
    return this.currentPage;
  }

  get [Getters.GET_ADMIN_USER_APPS_COLUMN_SEARCH_FILTER]() {
    return this.columnSearchFilter;
  }

  /**
     * Get last page
     * @returns number
     */
  get [Getters.GET_ADMIN_USER_APPS_LAST_PAGE]() {
    return this.lastPage;
  }

  /**
     * Get page
     * @returns number
     */
  get [Getters.GET_ADMIN_USER_APPS_PAGE]() {
    return this.page;
  }

  /**
     * Get rows
     * @returns number
     */
  get [Getters.GET_ADMIN_USER_APPS_ROWS]() {
    return this.rowsPerPage;
  }

  /**
     * Get limit
     * @returns string
     */
  get [Getters.GET_ADMIN_USER_APPS_LIMIT]() {
    return this.limit;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ERRORS_ADMIN_USER_APPS]() {
    return this.errors;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ADMIN_USER_APPS_ORDER_BY]() {
    return this.orderBy;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ADMIN_USER_APPS_SORT_BY]() {
    return this.sortBy;
  }

  get [Getters.GET_ADMIN_USER_APPS_QUERY_PARAMS]() {
    return this.queryParams;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ADMIN_USER_APPS_EXPORT]() {
    return this.export;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ADMIN_USER_APPS]() {
    return this.userApps;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ADMIN_USER_APPS_TOTAL]() {
    return this.totalUserApps;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ADMIN_USER_APPS_SEARCH]() {
    return this.search;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ADMIN_USER_APPS_STORE]() {
    return this.appstore;
  }

  get [Getters.GET_ADMIN_USER_APPS_ALL_APPS_FILTER]() {
    return this.allAppsFilter;
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.userApps = [];
    this.totalUserApps = 0;
    this.errors = {};
    this.currentPage = 1;
    this.lastPage = 1;
    this.page = 1;
    this.rowsPerPage = 10;
    this.limit = 10;
    this.orderBy = 'id';
    this.sortBy = 'desc';
    this.columnSearchFilter = '';
    this.search = '';
    this.export = [];
    this.queryParams = [];
    this.appstore = 0;
    this.allAppsFilter = false;
  };

    @Mutation
    [Mutations.SET_USER_APPS_ROWS](payload) {
      this.rowsPerPage = payload;
    }

    @Mutation
    [Mutations.SET_USER_APPS_COLUMN_SEARCH_FILTER](payload) {
      this.columnSearchFilter = payload;
    }

    @Mutation
    [Mutations.SET_ERROR_USER_APPS](error) {
      this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_USER_APPS_CURRENT_PAGE](payload) {
      this.currentPage = payload;
    }

    @Mutation
    [Mutations.SET_USER_APPS_LAST_PAGE](payload) {
      this.lastPage = payload;
    }

    @Mutation
    [Mutations.SET_USER_APPS_ORDER_BY](payload) {
      this.orderBy = payload;
    }


    @Mutation
    [Mutations.SET_USER_APPS_SORT_BY](payload) {
      this.sortBy = payload;
    }

    @Mutation
    [Mutations.SET_USER_APPS_EXPORT](payload) {
      this.export = payload;
    }

    @Mutation
    [Mutations.SET_USER_APPS_QUERY_PARAMS](payload) {
      this.queryParams = payload;
    }

    @Mutation
    [Mutations.SET_TOTAL_USER_APPS](payload) {
      this.totalUserApps = payload;
    }

    @Mutation
    [Mutations.SET_ALL_USER_APPS](payload) {
      this.userApps = payload;
    }

    @Mutation
    [Mutations.SET_USER_APPS_SEARCH](payload) {
      this.search = payload;
    }

    @Mutation
    [Mutations.SET_USER_APPS_STORE](payload) {
      this.appstore = payload;
    }

    @Mutation
    [Mutations.SET_USER_APPS_ALL_APPS_FILTER](payload) {
      this.allAppsFilter = payload;
    }

    @Action
    [Actions.GET_USER_APPS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}apps`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TOTAL_USER_APPS, data.meta.total);
          this.context.commit(Mutations.SET_USER_APPS_CURRENT_PAGE, data.meta.current_page);
          this.context.commit(Mutations.SET_USER_APPS_LAST_PAGE, data.meta.last_page);
          this.context.commit(Mutations.SET_ALL_USER_APPS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_APPS, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_USER_APPS_EXPORT](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}apps`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER_APPS_EXPORT, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_APPS, response?.data?.errors);
        });
    }
}
