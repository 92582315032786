import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { AxiosRequestConfig } from 'axios';
import ApiService from '@/core/services/ApiService';
import { domainUrlAdmin } from '@/core/config/DomainConfig';
import { ErrorGetters } from '@/store/enums/ErrorGettersEnums';
import { Getters } from '@/store/enums/GettersEnums';

@Module({ name: 'AdminVendorModule' })
export default class AdminVendorModule extends VuexModule implements StoreModel {
  errors = {};
  vendors = [];
  currentVendors = {};
  totalVendors = 0;
  currentPage = 1;
  lastPage = 1;
  page = 1;
  rowsPerPage = 10;
  limit = 10;
  orderBy = 'id';
  sortBy = 'desc';
  currentCountryId = null;
  currentSelectedVendorId = 0;
  currentStore = 0;
  currentProduct = '';
  vendorsList = [];
  queryParams = {};
  export = [];
  rawExport = [];

  /**
     * Get current page
     * @returns number
     */
  get [Getters.GET_ADMIN_VENDORS_CURRENT_PAGE]() {
    return this.currentPage;
  }

  /**
     * Get current page
     * @returns number
     */
  get [Getters.GET_CURRENT_VENDORS]() {
    return this.currentVendors;
  }

  /**
     * Get last page
     * @returns number
     */
  get [Getters.GET_ADMIN_VENDORS_LAST_PAGE]() {
    return this.lastPage;
  }

  /**
     * Get page
     * @returns number
     */
  get [Getters.GET_ADMIN_VENDORS_PAGE]() {
    return this.page;
  }

  /**
     * Get rows
     * @returns number
     */
  get [Getters.GET_ADMIN_VENDORS_ROWS]() {
    return this.rowsPerPage;
  }

  /**
     * Get limit
     * @returns string
     */
  get [Getters.GET_ADMIN_VENDORS_LIMIT]() {
    return this.limit;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [ErrorGetters.GET_ERRORS_ADMIN_VENDORS]() {
    return this.errors;
  }

  /**
     * Get current Transaction object
     * @returns array
     */
  get [Getters.ALL_ADMIN_VENDORS]() {
    return this.vendors;
  }

  /**
     * Get current Total Transactions
     * @returns number
     */
  get [Getters.GET_TOTAL_ADMIN_VENDORS]() {
    return this.totalVendors;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ADMIN_VENDORS_ORDER_BY]() {
    return this.orderBy;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ADMIN_VENDORS_SORT_BY]() {
    return this.sortBy;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ADMIN_VENDORS_CURRENT_COUNTRY]() {
    return this.currentCountryId;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ADMIN_VENDORS_CURRENT_SELECTED_VENDOR_ID]() {
    return this.currentSelectedVendorId;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ADMIN_VENDORS_LIST]() {
    return this.vendorsList;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ADMIN_VENDORS_CURRENT_STORE]() {
    return this.currentStore;
  }

  get [Getters.GET_ADMIN_VENDORS_QUERY_PARAMS]() {
    return this.queryParams;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ADMIN_VENDORS_CURRENT_PRODUCT]() {
    return this.currentProduct;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ADMIN_VENDORS_EXPORT]() {
    return this.export;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ADMIN_VENDORS_RAW_EXPORT]() {
    return this.rawExport;
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.errors = {};
    this.vendors = [];
    this.totalVendors = 0;
    this.currentPage = 1;
    this.currentCountryId = null;
    this.currentSelectedVendorId = 0;
    this.currentStore = 0;
    this.currentProduct = '';
    this.lastPage = 1;
    this.vendorsList = [];
    this.page = 1;
    this.rowsPerPage = 10;
    this.limit = 10;
    this.orderBy = 'id';
    this.sortBy = 'desc';
    this.export = [];
    this.queryParams = [];
    this.rawExport = [];
  };

    @Mutation
    [Mutations.SET_VENDORS_ROWS](payload) {
      this.rowsPerPage = payload;
    }

    @Mutation
    [Mutations.SET_ERROR_VENDORS](error) {
      this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_ALL_VENDORS](payload) {
      this.vendors = payload;
    }

    @Mutation
    [Mutations.SET_TOTAL_VENDORS](payload) {
      this.totalVendors = payload;
    }

    @Mutation
    [Mutations.SET_VENDORS_CURRENT_PAGE](payload) {
      this.currentPage = payload;
    }

    @Mutation
    [Mutations.SET_VENDORS_LAST_PAGE](payload) {
      this.lastPage = payload;
    }

    @Mutation
    [Mutations.SET_VENDORS_ORDER_BY](payload) {
      this.orderBy = payload;
    }


    @Mutation
    [Mutations.SET_VENDORS_SORT_BY](payload) {
      this.sortBy = payload;
    }

    @Mutation
    [Mutations.SET_VENDORS_CURRENT_COUNTRY](payload) {
      this.currentCountryId = payload;
    }

    @Mutation
    [Mutations.SET_VENDORS_CURRENT_VENDOR](payload) {
      this.currentSelectedVendorId = payload;
    }

    @Mutation
    [Mutations.SET_VENDORS_LIST](payload) {
      this.vendorsList = payload;
    }

    @Mutation
    [Mutations.SET_VENDORS_CURRENT_STORE](payload) {
      this.currentStore = payload;
    }

    @Mutation
    [Mutations.SET_VENDORS_CURRENT_PRODUCT](payload) {
      this.currentProduct = payload;
    }

    @Mutation
    [Mutations.SET_VENDORS_EXPORT](payload) {
      this.export = payload;
    }

    @Mutation
    [Mutations.SET_VENDORS_RAW_EXPORT](payload) {
      this.rawExport = payload;
    }

    @Mutation
    [Mutations.SET_VENDORS_QUERY_PARAMS](payload) {
      this.queryParams = payload;
    }

    @Action
    [Actions.GET_VENDORS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}vendors/get-list`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_VENDORS_LIST, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_VENDORS, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_VENDORS_CAMPAIGNS_LIST](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}vendors`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TOTAL_VENDORS, data.meta.total);
          this.context.commit(Mutations.SET_VENDORS_CURRENT_PAGE, data.meta.current_page);
          this.context.commit(Mutations.SET_VENDORS_LAST_PAGE, data.meta.last_page);
          this.context.commit(Mutations.SET_ALL_VENDORS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_VENDORS, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_VENDORS_CAMPAIGNS_EXPORT](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}vendors`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_VENDORS_RAW_EXPORT, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_VENDORS, response?.data?.errors);
        });
    }

    @Action
    [Actions.CHANGE_ADMIN_VENDORS_ACTIVITY](params) {
      const { id, ...data } = params;
      return ApiService.update(`${domainUrlAdmin}vendors/change-admin-vendors-activity`, id, data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR_VENDORS, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_VENDORS, response?.data?.errors);
        });
    }

    @Action
    [Actions.CHANGE_ADMIN_COUNTRY_VENDORS_ACTIVITY](params) {
      const { id, ...data } = params;
      return ApiService.update(`${domainUrlAdmin}vendors/change-admin-country-vendors-activity`, id, data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR_VENDORS, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_VENDORS, response?.data?.errors);
        });
    }

    @Action
    [Actions.STORE_VENDORS](params) {
      return ApiService.post(`${domainUrlAdmin}vendors`, params)
        .then(({ data }) => {
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_VENDORS, response?.data?.errors);
        });
    }

    @Action
    [Actions.UPDATE_VENDORS](params) {
      const { id, ...data } = params;
      return ApiService.update(`${domainUrlAdmin}vendors`, id, data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR_VENDORS, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_VENDORS, response?.data?.errors);
        });
    }

    @Action
    [Actions.DELETE_VENDORS](id) {
      return ApiService.delete(`${domainUrlAdmin}vendors/${id}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR_VENDORS, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_VENDORS, response?.data?.errors);
        });
    }
}
