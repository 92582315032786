enum AdvertisingCampaignEnums {
    CPI_COUNTRY = 'cpi_country',
    CPI_TARGET = 'cpi_target',
    IMPRESSIONS = 'impressions',
    CLICKS = 'clicks',
    INSTALLS = 'installs',
    COST = 'cost',
    PRICE = 'price',
    DAILY_BUDGET_VALUE = 10,
    TARGET_CPI_VALUE = 0.15,
    DAYS_IN_CAMPAIGN_VALUE = 7,
    MIN_DAYS_IN_CAMPAIGN = 5,
    MAX_DAYS_IN_CAMPAIGN = 30,
    MIN_HEADLINE = 3,
    MAX_HEADLINE = 30,
    MIN_DESCRIPTION = 3,
    MAX_DESCRIPTION = 90,
};

export { AdvertisingCampaignEnums };
