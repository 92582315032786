const contacts = {
  contactUs: "Зв'язатися з нами",
  dearUserIfYouHaveAnyQuestions: "Дорогий користувач! якщо у вас є питання, ви можете зв'язатися з вашим персональним менеджером, використовуючи форму нижче або будь-який месенджер. Ви також можете скористатися нашим {linkFaq}, є відповіді на більшість питань.",
  howCanWeHelpYou: 'Як ми можемо допомогти вам?',
  subject: 'Предмет',
  enterTheSubject: 'Введіть тему',
  message: 'Повідомлення',
  pleaseProvideYourSupplies: 'Будь ласка, надайте свої поставки рядково',
  weWillAnswerYou: 'Ми відповімо вам якнайшвидше. Запевняємо вас, вам не доведеться довго чекати.',
  sendMessage: 'Надіслати повідомлення',
  youCanAlsoContactOurSupportTeam: "Ви також можете зв'язатися з нашою службою підтримки:"
};

export default contacts;
