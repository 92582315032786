const register = {
  forgotPassword:'Забыл пароль?',
  enterYourEmailBelowToResetYourPassword:'Введите адрес электронной почты ниже, чтобы сбросить пароль.',
  email:'Эл. адрес',
  cancel:'Отмена',
  submit:'Представлять на рассмотрение',
  pleaseWait:'Пожалуйста подождите...',
  weHaveSentYouEmail:'Мы отправили вам электронное письмо с инструкциями по смене пароля.',
  okLetMeIn:'Хорошо, впусти меня!',
  tryAgain:'Попробуйте еще раз!',
  resetPassword:'Сброс пароля?',
  password:'Пароль',
  useMoreCharactersWithMixOfLetters:'Используйте 8 или более символов, сочетая буквы, цифры и символы.',
  confirmPassword:'Подтвердить Пароль',
  passwordsMustMatch:'Пароли должны совпадать',
  passwordConfirmation:'Подтверждение пароля',
  yourPasswordIsSuccessfullyChanged:'Ваш пароль успешно изменен!',
  loginToYourAccount:'Вход в свой аккаунт',
  orLoginWithEmail:'или войдите с электронной почтой',
  newHere:'Новенький тут?',
  createNewAccount:'Создать новый аккаунт',
  createAnAccount:'Завести аккаунт',
  loginToAccount:'Войти в аккаунт',
  yourAccountIsNotActivated:'Ваша учетная запись не активирована. Пожалуйста, проверьте свою электронную почту для получения инструкций по проверке или нажмите повторно.',
  activationEmeilWasSuccessfullySent:'Активационное письмо успешно отправлено. Пожалуйста, проверьте свою электронную почту.',
  orEnterYourNewAccountDetailsBelow:'или введите данные новой учетной записи ниже',
  orEnterYourDetails: 'или введите свои данные',
  username:'Имя пользователя',
  iAmEighteenYearsOldOrOlder:'Я подтверждаю, что мне 18 лет или больше',
  alreadyHaveAnAccount:'У вас уже есть аккаунт?',
  login:'Авторизоваться',
  toc:'Ток',
  agreement:'Соглашение',
  yourAccountHasBeenCreated:'Ваша учетная запись создана, и вы получили электронное письмо с активацией. После активации вы сможете войти в систему.',
  byCreatingAnAccountWithUs:'Создавая у нас учетную запись, вы автоматически принимаете наши',
};

export default register;
