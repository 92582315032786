const myCampaignsPage = {
  notFoundCampaigns: 'No se encontraron campañas',
  header: {
    toolbar: {
      all: 'Todos',
      active: 'Activo',
      pause: 'Pausa',
      draft: 'Reclutar',
      failed: 'Fallido',
      completed: 'Terminado',
      canceled: 'Cancelado',
      archived: 'Archivado'
    }
  },
  table: {
    myCampaigns: 'Mis campañas',
    showing: 'Demostración',
    campaignsOf: 'campañas de',
    found: 'Encontrado',
    campaignFrom: 'campaña(s) de',
    nothingFoundBySearch: 'Nada encontrado por búsqueda',
    noCampaignsFound: 'No se encontraron campañas',
    noOrdersFound: 'No se encontraron pedidos',
    showAll: 'mostrar todo',
    showDetails: 'mostrar detalles',
    details: 'detalles',
    spreadInstallsFor24h: 'Propaga las instalaciones durante 24 horas',
    showReviews: 'Mostrar reseñas',
    promotionRuleStopsPromotionIfRankReachedRequiredLevel: 'La regla de promoción detiene la promoción si el rango alcanza el nivel requerido',
    installsDeliveryType: 'Tipo de entrega de instalaciones',
    vendor: 'Proveedor',
    changeVendor: 'Cambiar proveedor'
  },
  tableDropdown: {
    quickActions: 'Menú de acciones rápidas',
    exportCampaigns: 'Exportar Campañas',
    archiveAll: 'Archiva todo',
    refresh: 'Actualizar'
  },
  actionsDropdown: {
    start: 'Comienzo',
    pause: 'Pausa',
    cancel: 'Cancelar',
    archive: 'Archivo',
    edit: 'Editar',
    resume: 'Reanudar',
    details: 'Detalles',
    repeat: 'Repetir',
    update: 'Actualizar',
    reject: 'Rechazar',
    moderation: 'Enviar para moderación',
    forcedCompletion: 'Finalización forzada',
    forcedDraft: 'Proyecto forzado',
  },
  modalReview: {
    title: 'Mis reseñas de Campaña',
    exportReviews: 'Exportar reseñas'
  }

};
export default myCampaignsPage;
