import { ref } from 'vue';
import { Main } from '@/store/enums/MainEnums';
import { PricePlanStatusModel } from '@/assets/ts/_utils/models/PricePlanStatusModel';
import { PricePlansActiveStatus, PricePlansText } from '@/store/enums/PricePlansEnums';
import store from '@/store';
import { Payments } from '@/store/enums/PaymentsEnums';
import { PriceplanDispatcherService } from '@/store/services/PriceplanDispatcherService';

export class PriceplansUtil {
  public static setPricePlanItems = (array, newArray, os: Main.STORE_GOOGLE_MARKET | Main.STORE_APPLE_MARKET) => {
    // array - default array
    // newArray - sorted array
    // os: (Main.STORE_GOOGLE_MARKET - android, Main.STORE_APPLE_MARKET - ios)

    array.forEach((type) => {
      type?.pricetags?.forEach((item) => {
        if (item.is_active) {
          if (item.appstore_id === os) {
            newArray.value.forEach((element, index) => {
              if (element.type === item.type) {
                let property = type.name;
                if (type.name.split(' ').length > 1) {
                  property = type.name.split(' ').join('_');
                }

                let computedPrice;
                if (item.price.toString().length > 4) {
                  computedPrice = item.price.toString().slice(0, 4);
                } else if (item.price.toString().length < 4) {
                  computedPrice = item.price.toString() + '0';
                } else {
                  computedPrice = item.price;
                }
                newArray.value[index][property] = computedPrice;
              }
            });
          }
        }
      });
    });
    // check if item.length < 3 then remove it, because it's not needed
    newArray.value.forEach((item, index) => Object.keys(item).length < 3 ? newArray.value.splice(index, 1) : null);
  };

  public static setPricePlanHeaders = (priceplans, headers) => {
    priceplans.forEach((priceplan) => {
      headers.forEach((header, index) => {
        if (header.text === PricePlansText.BASE) {
          headers[index].threshold = Payments.THOUSAND;
        } else if (priceplan.name.toLocaleLowerCase() === header.text.toLocaleLowerCase()) {
          headers[index].threshold = priceplan.threshold;
        }
      });
    });
  };

  public static priceplanImage = (priceplan: string) => {
    if (priceplan?.split(' ')?.length > 1) {
      return `fa-${priceplan.split(' ').join('-').toLocaleLowerCase()}.svg`;
    }
    return `fa-${priceplan?.toLocaleLowerCase()}.svg`;
  };

  public static defineIsPriceplanActive = (name: string) => {
    const key = PriceplansUtil.getPriceplanKey(name);
    return PricePlansActiveStatus[key];
  };

  public static getPriceplanColumnName = () => {
    const priceplan: any = PriceplansUtil.priceplanName.value;
    if (priceplan?.split(' ')?.length > 1) {
      PriceplansUtil.priceplanColumnName.value = priceplan?.split(' ')?.join('_')?.toLocaleLowerCase();
    } else {
      PriceplansUtil.priceplanColumnName.value = priceplan;
    }
  };

  public static getPriceplanName = (name: string) => {
    const key = PriceplansUtil.getPriceplanKey(name);
    return PricePlansText[key];
  };

  public static getPriceplanKey = (name: string) => {
    let output = name?.toUpperCase();
    const splitted = name?.split(' ');
    if (splitted?.length) {
      output = splitted?.join('_')?.toUpperCase();
    }
    return output;
  };

  public static priceplanPrice = (appstore_id: number, priceplan: number) => {
    const PriceplanDispatcher = new PriceplanDispatcherService(store);
    if (PriceplanDispatcher.getValidatedPriceplan().pricetags.length) {
      return PriceplanDispatcher.getValidatedPriceplan().pricetags.filter(item => item.appstore_id === appstore_id && item.type === priceplan)[0].price;
    }
    return;
  };

  public static priceplanName = ref<string>('');
  public static priceplanColumnName = ref<string>('');
  public static currentPriceplan = ref<PricePlanStatusModel>({});
  public static nextPriceplanName = ref<string>('');
  public static nextPriceplanThreshold = ref<number|null>(null);
  public static totalPayments = ref<number>();
  public static lastPayments = ref<string>();

  public static setCurrentAndNextPriceplan = async () => {
    const PriceplanDispatcher = new PriceplanDispatcherService(store);
    this.currentPriceplan.value = await PriceplanDispatcher.getCurrentPriceplan();

    if (Object.keys(this.currentPriceplan.value) && this.currentPriceplan.value?.name) {
      this.priceplanName.value = this.getPriceplanName(this.currentPriceplan.value?.name);
    }
    if (this.currentPriceplan.value?.next !== null) {
      if (this.currentPriceplan.value?.next?.name) {
        this.nextPriceplanName.value = this.getPriceplanName(this.currentPriceplan.value?.next?.name);
      }
      if (this.currentPriceplan.value?.next?.threshold) {
        this.nextPriceplanThreshold.value = this.currentPriceplan.value?.next?.threshold;
      }
    }
  };

  public static resetPriceplans = () => {
    this.priceplanName.value = '';
    this.nextPriceplanName.value = '';
    this.currentPriceplan.value = {};
    this.nextPriceplanThreshold.value = null;
  };
}
