import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { useRoute } from 'vue-router';
import StoreGettersService from '@/store/services/StoreGettersService';
import { Getters } from '@/store/enums/GettersEnums';
import JwtService from '@/core/services/JwtService';
import { useStore } from 'vuex';
import { ErrorTypeEnums } from '@/store/enums/ErrorTypeEnums';

export default class UserDispatcherService extends StoreGettersService{

  constructor (store = useStore()) {super(store);}

  public async getUser(user_id) {
    await this.getStore().dispatch(Actions.GET_USER, user_id);
    return this.getValidatedCurrentUserList();
  }

  public async changeUserAccount(email: string) {
    await this.getStore().dispatch(Actions.CHANGE_USER_ACCOUNT, { email: email });
  }

  public async changeUserBalance (id, amount, account_id, paysystem_id, payment_id, description) {
    await this.getStore().dispatch(Actions.CHANGE_USER_BALANCE, {
      id: id,
      amount: amount,
      account_id: account_id,
      paysystem_id: paysystem_id,
      payment_id: payment_id,
      description: description
    });
  }

  public async deleteUserList(id) {
    await this.getStore().dispatch(Actions.DELETE_USER_LIST, id);
  }
  public async updateUserList(paramData) {
    await this.getStore().dispatch(Actions.UPDATE_USER_LIST, paramData);
  }

  public async deleteUserImage() {
    await this.getStore().dispatch(Actions.DELETE_USER_DATA_IMAGE, {});
  }

  public async saveUserImage (fd: FormData) {
    await this.getStore().dispatch(Actions.POST_USER_DATA_IMAGE, fd);
  }

  public async postUserData (nickname, profile) {
    await this.getStore().dispatch(Actions.POST_USER_DATA, { nickname: nickname, profile });
  }

  public async getUserData () {
    return await this.getStoreGettersUserData();
  }

  public async getStoreGettersUserData(params = {}) {
    return await this.getAllRecordsByGetter(
      Getters.GET_USER,
      Actions.GET_USER_DATA,
      params,
      true
    );
  }

  public async getUserDetailsTransactions(params) {
    await this.getStore().dispatch(Actions.GET_USER_DETAILS_TRANSACTIONS, params);
  }

  public async getUserDetailsReferrals (params) {
    await this.getStore().dispatch(Actions.GET_USER_DETAILS_REFERRALS, params);
  }

  public async getUserDetailsPayments (params) {
    await this.getStore().dispatch(Actions.GET_USER_DETAILS_PAYMENTS, params);
  }

  public async getUserDetailsMessages (params) {
    await this.getStore().dispatch(Actions.GET_USER_DETAILS_MESSAGES, params);
  }

  public async getUserPayment (id) {
    await this.getStore().dispatch(Actions.GET_USER_PAYMENT, { user_id: id });
  }

  public async deleteUserPayment (id) {
    await this.getStore().dispatch(Actions.DELETE_USER_PAYMENT, id);
  }

  public async deleteUserDetailsTransaction (id) {
    await this.getStore().dispatch(Actions.DELETE_USER_DETAILS_TRANSACTION, id);
  }

  public async deleteUserDetailsReferrals (id) {
    await this.getStore().dispatch(Actions.DELETE_USER_DETAILS_REFERRALS, id);
  }

  public async postUsersPayout () {
    await this.getStore().dispatch(Actions.POST_USERS_PAYOUT, {});
  }

  public async getUsersReferrals (params) {
    this.getStore().commit(Mutations.SET_USERS_REFERRALS_QUERY_PARAMS, params);
    return await this.getStoreGettersUsersReferrals(params);
  }

  public async getStoreGettersUsersReferrals(params = {}) {
    return await this.getAllRecordsByGetter(
      Getters.GET_ALL_USERS_REFERRALS,
      Actions.GET_USERS_REFERRALS,
      params,
      true
    );
  }
  
  public getUsersReferralsComplexRequests () {
    return [
      this.getStore().dispatch(Actions.GET_USERS_REFERRALS, {}),
      this.getStore().dispatch(Actions.GET_USERS_PAYOUTS, {}),
      this.getStore().dispatch(Actions.GET_WIDGETS_REFERRALS, {}),
    ];
  }

  public async getUserList (params) {
    return await this.getStoreGettersUserList(params);
  }

  public async getStoreGettersUserList(params = {}) {
    return await this.getAllRecordsByGetter(
      Getters.ALL_USER_LISTS,
      Actions.GET_USER_LIST,
      params,
      true
    );
  }

  public async passwordReset (params) {
    await this.getStore().dispatch(Actions.PASSWORD_RESET, params);
  }

  public async forgotPassword (params) {
    await this.getStore().dispatch(Actions.FORGOT_PASSWORD, params);
  }

  public getUsersDetailsComplexRequests (route = useRoute()) {
    return [
      this.getStore().dispatch(Actions.GET_USER, route.params.id),
      this.getStore().dispatch(Actions.GET_USER_DETAILS_PAYMENTS, { user_id: route.params.id, billingOnly: true }),
      this.getStore().dispatch(Actions.GET_USER_DETAILS_MESSAGES, { user_id: route.params.id }),
      this.getStore().dispatch(Actions.GET_USER_DETAILS_TRANSACTIONS, {
        user_id: route.params.id, limit: 10, orderBy: 'created_at', sortBy: 'desc'
      }),
      this.getStore().dispatch(Actions.GET_USER_DETAILS_REFERRALS, {
        user_id: route.params.id, limit: 10, is_admin: true, orderBy: 'created_at', sortBy: 'desc'
      })
    ];
  }

  public async getCurrentUser(reloadObject = false) {
    return await this.getAllRecordsByGetter(Getters.CURRENT_USER, Actions.VERIFY_AUTH, { access_token: JwtService.getToken() }, reloadObject);
  }

  public getValidatedCurrentUser() {
    return this.getValidatedGetter(Getters.CURRENT_USER);
  }

  public getValidatedCurrentUserList() {
    return this.getValidatedGetter(Getters.GET_CURRENT_USER_LIST);
  }

  public async getAllManagers () {
    return await this.getAllRecordsByGetter(Getters.GET_ALL_MANAGERS, Actions.GET_MANAGERS, { limit: 0, page: 1 });
  }

  public async getUsersPayouts () {
    return await this.getAllRecordsByGetter(Getters.GET_ALL_USERS_PAYOUTS, Actions.GET_USERS_PAYOUTS, {}, true);
  }

  public getValidatedUserListsCurrentPage() {
    return this.getValidatedGetter(Getters.GET_USER_LISTS_CURRENT_PAGE);
  }
  public getValidatedUserListsSearch() {
    return this.getValidatedGetter(Getters.GET_USER_LISTS_SEARCH, ErrorTypeEnums.NOT_REACT);
  }
  public getValidatedUserListsRows() {
    return this.getValidatedGetter(Getters.GET_USER_LISTS_ROWS);
  }
  public getValidatedUserListsRole() {
    return this.getValidatedGetter(Getters.GET_USER_LISTS_ROLE, ErrorTypeEnums.NOT_REACT);
  }
  public getValidatedUserListsPriceplan() {
    return this.getValidatedGetter(Getters.GET_USER_LISTS_PRICEPLAN, ErrorTypeEnums.NOT_REACT);
  }
  public getValidatedUserListsSortBy() {
    return this.getValidatedGetter(Getters.GET_USER_LISTS_SORT_BY);
  }
  public getValidatedUserListsOrderBy() {
    return this.getValidatedGetter(Getters.GET_USER_LISTS_ORDER_BY);
  }
  public getValidatedUserListsColumnSearchFilter() {
    return this.getValidatedGetter(Getters.GET_USER_LISTS_COLUMN_SEARCH_FILTER, ErrorTypeEnums.NOT_REACT);
  }
  public getValidatedTotalUserLists() {
    return this.getValidatedGetter(Getters.GET_TOTAL_USER_LISTS);
  }
  public getValidatedUserListsPage() {
    return this.getValidatedGetter(Getters.GET_USER_LISTS_PAGE);
  }
  public getValidatedUserListsLastPage() {
    return this.getValidatedGetter(Getters.GET_USER_LISTS_LAST_PAGE);
  }
  public getValidatedLegacyBalanceData() {
    return this.getValidatedGetter(Getters.LEGACY_BALANCE_DATA, ErrorTypeEnums.NOT_REACT);
  }

  public getValidatedCurrentUserId() {
    return this.getValidatedGetter(Getters.GET_CURRENT_USER_ID, ErrorTypeEnums.NOT_REACT);
  }
  public getValidatedAllUserLists() {
    return this.getValidatedGetter(Getters.ALL_USER_LISTS);
  }

  public getValidatedUserListsQueryParams() {
    return this.getValidatedGetter(Getters.GET_USER_LISTS_QUERY_PARAMS, ErrorTypeEnums.NOT_REACT);
  }
  public getValidatedAllUserListsExport() {
    return this.getValidatedGetter(Getters.ALL_USER_LISTS_EXPORT, ErrorTypeEnums.NOT_REACT);
  }
  public getValidatedRedirect() {
    return this.getValidatedGetter(Getters.GET_REDIRECT);
  }
}
