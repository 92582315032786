import { createI18n } from 'vue-i18n/dist/vue-i18n.cjs.js';
import en_US from '@/core/plugins/lang/en-US/index';
import es_ES from '@/core/plugins/lang/es-ES/index';
import ru_RU from '@/core/plugins/lang/ru-RU/index';
import uk_UA from '@/core/plugins/lang/uk-UA/index';

const messages = {
  'en-US': en_US,
  'es-ES': es_ES,
  'uk-UA': uk_UA,
  'ru-RU': ru_RU,
};

const i18n = createI18n({
  legacy: false,
  locale: 'en-US',
  globalInjection: true,
  messages,
});

export default i18n;
