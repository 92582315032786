import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { AxiosRequestConfig } from 'axios';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { domainUrl, domainUrlAdmin } from '@/core/config/DomainConfig';
import { Getters } from '@/store/enums/GettersEnums';

@Module({ name: 'AdminProductModule' })
export default class AdminProductModule extends VuexModule implements StoreModel {
  error = {};
  totalProducts = 0;
  appstore = 1;
  products = [];

  get [Getters.GET_ADMIN_PRODUCT_ERROR]() {
    return this.error;
  }

  get [Getters.GET_ADMIN_PRODUCT_TOTAL_PRODUCTS]() {
    return this.totalProducts;
  }

  get [Getters.GET_ADMIN_PRODUCT_APPSTORE]() {
    return this.appstore;
  }

  get [Getters.GET_ADMIN_PRODUCTS]() {
    return this.products;
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.error = {};
    this.totalProducts = 0;
    this.appstore = 1;
    this.products = [];
  };

    @Mutation
    [Mutations.SET_ADMIN_PRODUCTS_ERROR](payload) {
      this.error = payload;
    }

    @Mutation
    [Mutations.SET_ADMIN_PRODUCTS_TOTAL_PRODUCTS](payload) {
      this.totalProducts = payload;
    }

    @Mutation
    [Mutations.SET_ADMIN_PRODUCTS_APPSTORE](payload) {
      this.appstore = payload;
    }

    @Mutation
    [Mutations.SET_ADMIN_PRODUCTS_PRODUCTS](payload) {
      this.products = payload;
    }

    @Action
    [Actions.GET_ADMIN_PRODUCTS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}products`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ADMIN_PRODUCTS_TOTAL_PRODUCTS, data.data.length);
          this.context.commit(Mutations.SET_ADMIN_PRODUCTS_PRODUCTS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ADMIN_PRODUCTS_ERROR, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_PRODUCTS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}products`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ADMIN_PRODUCTS_TOTAL_PRODUCTS, data.data.length);
          this.context.commit(Mutations.SET_ADMIN_PRODUCTS_PRODUCTS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ADMIN_PRODUCTS_ERROR, response?.data?.errors);
        });
    }

    @Action
    [Actions.UPDATE_ADMIN_PRODUCTS](id) {
      return ApiService.update(`${domainUrlAdmin}products`, id, {})
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ADMIN_PRODUCTS_ERROR, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ADMIN_PRODUCTS_ERROR, response?.data?.errors);
        });
    }
}
