const priceplans = {
  currentPriceplan:'Current priceplan',
  currentPlan:'Current Plan',
  totalPayments:'Total payments',
  from:'from',
  to:'to',
  topUpBalance:'Top-up balance',
  availablePriceplans:'Available priceplans',
  apply:'Apply',
  upTo:'up to',
  keyword:'Keyword',
  package:'Package',
  rate:'Rate',
  review:'Review',
  HQReview:'HQ Review',
  base:'Base',
  bronze:'Bronze',
  silver:'Silver',
  gold:'Gold',
  platinum:'Platinum',
  diamond:'Diamond',
  VIP:'VIP',
  resselerBase:'Reseller Base',
  resselerGold:'Reseller Gold',
  android:'android',
  iOS:'iOS',
  noBillingsFound:'No billings found',
};

export default priceplans;
