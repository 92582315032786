import objectPath from 'object-path';
import merge from 'deepmerge';
import layoutConfig from '@/core/config/DefaultLayoutConfig';
import { Mutations } from '@/store/enums/StoreEnums';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import LayoutConfigTypes from '@/core/config/LayoutConfigTypes';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { Getters } from '@/store/enums/GettersEnums';

interface StoreInfo {
    config: LayoutConfigTypes;
    initial: LayoutConfigTypes;
}

@Module({ name: 'ConfigModule' })
export default class ConfigModule extends VuexModule implements StoreInfo, StoreModel {
  config = layoutConfig;
  initial = layoutConfig;

  /**
     * Get config from layout config
     * @returns {function(path, defaultValue): *}
     */
  get [Getters.LAYOUT_CONFIG]() {
    return (path, defaultValue) => {
      return objectPath.get(this.config, path, defaultValue);
    };
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.config = layoutConfig;
    this.initial = layoutConfig;
  }

    @Mutation
    [Mutations.SET_LAYOUT_CONFIG](payload): void {
      this.config = payload;
    }

    @Mutation
    [Mutations.RESET_LAYOUT_CONFIG]() {
      this.config = Object.assign({}, this.initial);
    }

    @Mutation
    [Mutations.OVERRIDE_LAYOUT_CONFIG](): void {
      this.config = this.initial = Object.assign(
        {},
        this.initial,
        JSON.parse(window.localStorage.getItem('config') || '{}')
      );
    }

    @Mutation
    [Mutations.OVERRIDE_PAGE_LAYOUT_CONFIG](payload): void {
      this.config = merge(this.config, payload);
    }

    @Mutation
    [Mutations.OPEN_CHAT](): void {
      this.config = { ...this.config, chat: { display: true } };
    }

    @Mutation
    [Mutations.CLOSE_CHAT](): void {
      this.config = { ...this.config, chat: { display: false } };
    }
}
