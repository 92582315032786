import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { AxiosRequestConfig } from 'axios';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { domainUrlAdmin } from '@/core/config/DomainConfig';
import { ErrorGetters } from '@/store/enums/ErrorGettersEnums';
import { Getters } from '@/store/enums/GettersEnums';

export interface Permission {
    id: number,
    name: string
}

export interface PermissionModuleInterface {
    name: string;
    namespace: string;
    permission_name: string
}

export interface PermissionModuleInterfaceGet {
    name: string;
    namespace: string;
    permission_name: string,
    permissions: unknown
}


export interface PermissionFunctionInterfaceCreated {
    name: string;
    namespace: string;
    permission_module_id: unknown;
    permission_name: string
}

export interface PermissionFunctionInterface {
    name: string;
    namespace: string;
    permission_module_id: unknown;
    permissions: unknown
}

export interface PermissionInfo {
    errors: unknown;
    permissionModule: PermissionModuleInterface;
    permissionModules: unknown;
    permissionFunction: PermissionFunctionInterface;
    permissionFunctions: unknown;
    totalPages: unknown;
    isLoading: unknown;
    page: unknown;
}

@Module({ name: 'PermissionModule' })
export default class PermissionModule extends VuexModule implements PermissionInfo, StoreModel {
  errors = {};
  permissionModule = {} as PermissionModuleInterfaceGet;
  permissionModules = [];
  permissionFunction = {} as PermissionFunctionInterface;
  permissionFunctions = [];
  totalPages = 0;
  isLoading = false;
  page = 1;

  /**
     * Get current function object
     * @returns PermissionFunctionInterface
     */
  get [Getters.CURRENT_PERMISSION_FUNCTION](): PermissionFunctionInterface {
    return this.permissionFunction;
  }

  /**
     * Get current function objects
     * @returns permissionFunctions
     */
  get [Getters.ALL_PERMISSION_FUNCTIONS]() {
    return this.permissionFunctions;
  }

  /**
     * Get current module object
     * @returns PermissionModuleInterfaceGet
     */
  get [Getters.CURRENT_PERMISSION_MODULE](): PermissionModuleInterfaceGet {
    return this.permissionModule;
  }

  /**
     * Get current Module objects
     * @returns PermissionModule
     */
  get [Getters.ALL_PERMISSION_MODULES]() {
    return this.permissionModules;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [ErrorGetters.GET_ERRORS_PERMISSION]() {
    return this.errors;
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.errors = {};
    this.permissionModule = {} as PermissionModuleInterfaceGet;
    this.permissionModules = [];
    this.permissionFunction = {} as PermissionFunctionInterface;
    this.permissionFunctions = [];
    this.totalPages = 0;
    this.isLoading = false;
    this.page = 1;
  };

    @Mutation
    [Mutations.SET_ERROR_PERMISSION](error) {
      this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_PERMISSION_FUNCTIONS](permissionFunctions) {
      this.permissionFunctions = permissionFunctions;
    }

    @Mutation
    [Mutations.SET_PERMISSION_FUNCTION](permissionFunction) {
      this.permissionFunction = permissionFunction;
    }

    @Mutation
    [Mutations.SET_PERMISSION_MODULE](permissionModule) {
      this.permissionModule = permissionModule;
    }

    @Mutation
    [Mutations.SET_PERMISSION_MODULES](permissionModules) {
      this.permissionModules = permissionModules;
    }

    @Mutation
    [Mutations.SET_TOTAL_PAGES](totalPages) {
      this.totalPages = totalPages;
    }

    @Mutation
    [Mutations.SET_LOADING](isLoading) {
      this.isLoading = isLoading;
    }

    @Mutation
    [Mutations.SET_PAGE](page) {
      this.page = page;
    }

    @Action
    [Actions.GET_PERMISSION_MODULES](params) {
      // this.context.commit(Mutations.SET_LOADING, true)
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}modules`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PERMISSION_MODULES, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_PERMISSION, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_PERMISSION_MODULES_PAGE](params) {
      // this.context.commit(Mutations.SET_LOADING, true)
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}modules`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TOTAL_PAGES, data.meta.total);
          this.context.commit(Mutations.SET_PERMISSION_MODULES, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_PERMISSION, response?.data?.errors);
        }).finally(() => {
          // this.context.commit(Mutations.SET_LOADING, false);
        });
    }

    @Action
    [Actions.GET_PERMISSION_FUNCTIONS](params) {
      // this.context.commit(Mutations.SET_LOADING, true)
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}functions`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TOTAL_PAGES, data.meta.total);
          this.context.commit(Mutations.SET_PERMISSION_FUNCTIONS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_PERMISSION, response?.data?.errors);
        }).finally(() => {
          // this.context.commit(Mutations.SET_LOADING, false);
        });
    }

    @Action
    [Actions.GET_PERMISSION_MODULE](id) {
      return ApiService.get(`${domainUrlAdmin}modules`, id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PERMISSION_MODULE, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_PERMISSION, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_PERMISSION_FUNCTION](id) {
      return ApiService.get(`${domainUrlAdmin}functions`, id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PERMISSION_FUNCTION, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_PERMISSION, response?.data?.errors);
        });
    }

    @Action
    [Actions.ADD_PERMISSION_FUNCTION](params) {
      return ApiService.post(`${domainUrlAdmin}functions`, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PERMISSION_FUNCTION, data.data);
          this.context.commit(Mutations.SET_ERROR_PERMISSION, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_PERMISSION, response?.data?.errors);
        }).finally(() => {
          // this.context.commit(Mutations.SET_LOADING, false);
        });
    }


    @Action
    [Actions.UPDATE_PERMISSION_FUNCTION](params) {
      const { id, ...data } = params;
      return ApiService.update(`${domainUrlAdmin}functions`, id, data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PERMISSION_FUNCTION, data.data);
          this.context.commit(Mutations.SET_ERROR_PERMISSION, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_PERMISSION, response?.data?.errors);
        }).finally(() => {
          // this.context.commit(Mutations.SET_LOADING, false);
        });
    }

    @Action
    [Actions.DELETE_PERMISSION_FUNCTION](id) {
      return ApiService.delete(`${domainUrlAdmin}functions/` + id)
        .then(({ data }) => {
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_PERMISSION, response?.data?.errors);
        }).finally(() => {
          // this.context.commit(Mutations.SET_LOADING, false);
        });
    }

    @Action
    [Actions.ADD_PERMISSION_MODULE](params) {
      return ApiService.post(`${domainUrlAdmin}modules`, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PERMISSION_MODULE, data.data);
          this.context.commit(Mutations.SET_ERROR_PERMISSION, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_PERMISSION, response?.data?.errors);
        }).finally(() => {
          // this.context.commit(Mutations.SET_LOADING, false);
        });
    }


    @Action
    [Actions.UPDATE_PERMISSION_MODULE](params) {
      const { id, ...data } = params;
      return ApiService.update(`${domainUrlAdmin}modules`, id, data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PERMISSION_MODULE, data.data);
          this.context.commit(Mutations.SET_ERROR_PERMISSION, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_PERMISSION, response?.data?.errors);
        }).finally(() => {
          // this.context.commit(Mutations.SET_LOADING, false);
        });
    }

    @Action
    [Actions.DELETE_PERMISSION_MODULE](id) {
      return ApiService.delete(`${domainUrlAdmin}modules/` + id)
        .then(({ data }) => {
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_PERMISSION, response?.data?.errors);
        }).finally(() => {
          // this.context.commit(Mutations.SET_LOADING, false);
        });
    }
}
