import role from '@/core/plugins/lang/uk-UA/role';
import module from '@/core/plugins/lang/uk-UA/module';
import functions from '@/core/plugins/lang/uk-UA/function';
import select from '@/core/plugins/lang/uk-UA/select';
import application from '@/core/plugins/lang/uk-UA/application';
import apps from '@/core/plugins/lang/uk-UA/apps';
import keywords from '@/core/plugins/lang/uk-UA/keywords';
import addCampaigns from '@/core/plugins/lang/uk-UA/addCampaigns';
import myTransactions from '@/core/plugins/lang/uk-UA/myTransactions';
import priceplans from '@/core/plugins/lang/uk-UA/priceplans';
import myProfile from '@/core/plugins/lang/uk-UA/myProfile';
import topUpBalance from '@/core/plugins/lang/uk-UA/topUpBalance';
import register from '@/core/plugins/lang/uk-UA/register';
import myCampaigns from '@/core/plugins/lang/uk-UA/myCampaigns';
import bonusProgram from '@/core/plugins/lang/uk-UA/bonusProgram';
import referralProgram from '@/core/plugins/lang/uk-UA/referralProgram';
import resellersProgram from '@/core/plugins/lang/uk-UA/resellersProgram';
import statistics from '@/core/plugins/lang/uk-UA/statistics';
import dashboard from '@/core/plugins/lang/uk-UA/dashboard';
import contacts from '@/core/plugins/lang/uk-UA/contacts';

const index = {
  appsKeywords:'КЛЮЧОВІ СЛОВА&ПРОГРАМИ',
  myApps:'Мої додатки',
  myKeywords:'Мої ключові слова',
  services:'Послуги',
  open: 'Відкрити',
  myCampaigns:'Мої кампанії',
  addCampaign:'Додати ключові',
  addCPICampaign:'Додати кампанію CPI',
  addSmartCampaign:'Додати SMART кампанію',
  addHighQualityReviews:'Додайте високоякісні відгуки',
  finance:'ФІНАНСИ',
  topUpBalance:'Поповнення балансу',
  myTransactions:'Мої операції',
  other:'ІНШІ',
  referralProgram:'Реферальна програма',
  bonusProgram:'Бонусна програма',
  APISupport:'Підтримка API',
  faq:'Часто задавані питання',
  blog:'Блог',
  dashboard: 'Панель',
  keywords: 'Ключові слова',
  home: 'Головна',
  app:'Додаток',
  layoutBuilder: 'Конструктор макетів',
  craft: 'Создано',
  pages: 'Страниці',
  profile: 'Профіль',
  profileOverview: 'Огляд',
  settingsRoles: 'Ролі',
  settingsRole: 'Роль',
  settingsFunctions: 'Функції',
  settingsModules: 'Модулі',
  projects: 'Проекти',
  campaigns: 'Компанії',
  documents: 'Документи',
  connections: 'Коннекти',
  horizontal: 'Горизонтальний',
  vertical: 'Вертикальний',
  account: 'Обліковий запис',
  accountOverview: 'Огляд',
  settings: 'Настройки',
  authentication: 'Аутентифікація',
  basicFlow: 'Основний потік',
  signIn: 'Увійти',
  signUp: 'Зареєструватись',
  passwordReset: 'Відновлення паролю',
  multiStepSignUp: 'Багатоетапна реєстрація',
  error404: 'Помилка 404',
  error500: 'Помилка 500',
  apps: 'Програми',
  chat: 'Чат',
  privateChat: 'Приватний чат',
  groupChat: 'Групповий чат',
  widgets: 'Виджети',
  widgetsLists: 'Списки',
  statistics: 'Статистики',
  widgetsCharts: 'Графіки',
  widgetsMixed: 'Змішаний',
  widgetsTables: 'Таблиці',
  changelog: 'Список змін',
  docsAndComponents: 'Документи & компоненти',
  megaMenu: 'Мега меню',
  exampleLink: 'Приклад посилання',
  modals: 'Модалки',
  general: 'Загальний',
  inviteFriends: 'Запросити друзів',
  viewUsers: 'Перегляд користувачів',
  upgradePlan: 'План оновлення',
  shareAndEarn: 'Діліться та заробляйте',
  forms: 'Форми',
  newTarget: 'Нова ціль',
  newCard: 'Нова картка',
  newAddress: 'Новий адрес',
  createAPIKey: 'Створити ключ API',
  twoFactorAuth: 'Двофакторна автентифікація',
  createApp: 'Створити додаток',
  createAccount: 'Create new account',
  documentation: 'Документація',
  components: 'Складові частини',
  resources: 'Ресурси',
  activity: 'Заходи',
  customers: 'Кліенти',
  gettingStarted: 'Починаючи',
  customersListing: 'Список кліентів',
  customerDetails: 'Деталі кліентів',
  calendarApp: 'Календарь',
  subscriptions: 'Подписки',
  getStarted: 'Починаючи',
  subscriptionList: 'Список подписки',
  addSubscription: 'Додати подписку',
  viewSubscription: 'Подивитеся подписку',
  myProfile: 'Мій профіль',
  language: 'Мова',
  english: 'Англійська',
  spanish: 'Іспанська',
  russia: 'Російська',
  ukraine: 'Українська',
  accountSettings: 'Налаштування Аккаунта',
  signOut: 'Вийти',
  priceplans: 'Тарифи',
  forgotPassword: 'Забули пароль ?',
  enterEmailResetPassword: 'Введіть вашу електронну адресу, щоб скинути пароль?',
  email: 'Емейл',
  submit: 'Відправити',
  save: 'Зберегти',
  add: 'Додати новий',
  password: 'Пароль',
  cancel: 'Відміна',
  close: 'Закрити',
  continue: 'Продовжити',
  pleaseWait: 'Будь ласка зачекайте...',
  swalFireText: 'Ви успішно увійшли до системи!',
  swalConfirmButtonText: 'OK',
  swalTryAgain: 'Спробуйте еще раз!',
  swalFormSuccessfully: 'Форму успішно надіслано!',
  swalSorryLooksTryAgain: 'Вибачте, схоже, виявлено деякі помилки. Спробуйте ще раз.',
  swalSuccessfullyLogin: 'Ви успішно увійшли до системи!',
  swalSuccessfullyRegister: 'You have successfully registered account',
  continueApple: 'Продовжити з Apple',
  continueFacebook: 'Продовжити з Facebook',
  continueGoogle: 'Продовжити з Google',
  sigInApple: 'Увійти через Apple',
  sigInFacebook: 'Увійти через Facebook',
  sigInGoogle: 'Увійти через Google',
  or: 'or login with email',
  orSignUp: 'або введіть свої дані',
  newHere: 'Новенький тут?',
  singKeyapp: 'Login to your account',
  alreadyHaveAccount: 'У вас вже є обліковий запис?',
  lastName: 'Прізвище',
  name: "Ім'я",
  surname: 'Прізвище',
  passwordConfirmation: 'Підтвердження пароля',
  firstName: "Ім'я",
  userName: 'Username',
  singInHere: 'Увійдіть тут',
  eightMoreCharacters: 'Використовуйте 8 або більше символів, поєднуючи літери, цифри та символи.',
  confirmPassword: 'Підтвердити пароль',
  termsAndConditions: 'Умови та положення',
  iAgree: 'Я згоден &',
  seemsNothingHere: 'Здається, тут нічого немає',
  returnHome: 'Повернутися додому',
  goToHomepage: 'Перейдіть на домашню сторінку',
  systemError: 'Системна помилка',
  somethingWentWrong: 'Щось пішло не так! <br />\n Будь ласка, спробуйте пізніше.',
  paginationText: 'Показано { from } до { to } із { total } записів',
  loading: 'Завантаження...',
  checking:'перевірка',
  delete:'Видалити',
  edit:'Змінити',
  error:'помилка',
  out:'поза',
  copiedToClipboard:'скопійовано в буфер обміну',
  resellersProgram:'Програма для реселлерів',
  allCountries:'Всі країни',
  appstore:'Appstore',
  pickTargetCountry:'Виберіть цільову країну',
  chooseAppForPromotionOr:'Виберіть додаток для просування або ',
  addNew:'добавте новий',
  mainBalance:'Основний баланс',
  bonusBalance:'Бонусний баланс',
  myPriceplan:'Мій тарифний план',
  yourPersonalManager:'Ваш персональний менеджер',
  iNeedPersonalManager:'Мені потрібен персональний менеджер',
  personalManager:'Персональний менеджер',
  requestForPersonalManagerAssignmentIsSent:'Запит на призначення персонального менеджера надіслано. Ваш новий персональний менеджер зв\'яжеться з вами найближчим часом.',
  pleaseFillYourAdvanceProfile:'Будь ласка, заповніть свій профіль Advance та залиште будь-які контактні дані для кращого спілкування.',
  tryAgainLater:'Попробуйте Пізніше',
  personalManagerAssignmentInProgress:'Виконується призначення персонального менеджера', 
  ourTeamIsWorking:'Наша команда старанно працює над підбором кваліфікованого та відданого своїй справі професіонала, який відповідатиме за задоволення ваших потреб та надання індивідуальної підтримки.',
  forAllYourInquiriesAndCorrespondence:'Для всіх ваших запитів та кореспонденції, будь ласка, надсилайте свої електронні листи за наступною адресою:',
  weAppreciateYourCommunication:'Ми цінуємо ваше спілкування та відповімо на ваше повідомлення якнайшвидше. Дякую за звернення!',
  newMessageToMyKeyappTopManager:'Нове повідомлення моєму менеджеру keyapp.top',
  newMessageToKeyappTopSupport:'Нове повідомлення до служби підтримки keyapp.top',
  supportTeam:'Група підтримки',
  yearsAgo:'років тому',
  monthsAgo:'місяців тому',
  weeksAgo:'тижнів тому',
  daysAgo:'днів тому',
  hoursAgo:'годин тому',
  minutesAgo:'хвилин тому',
  new:'Нове',
  noNewMessagesFromManagers: 'Немає нових повідомлень від менеджерів',
  role: role,
  functions: functions,
  module: module,
  select:select,
  application: application,
  _apps:apps,
  keywordsPage:keywords,
  addCampaigns:addCampaigns,
  myTransactionsPage:myTransactions,
  priceplansPage:priceplans,
  myProfilePage:myProfile,
  topUpBalancePage:topUpBalance,
  register:register,
  myCampaignsPage:myCampaigns,
  bonusProgramPage:bonusProgram,
  referralProgramPage:referralProgram,
  resellersProgramPage:resellersProgram,
  statisticsPage:statistics,
  dashboardPage:dashboard,
  contactsPage:contacts,
};
export default index;
