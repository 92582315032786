enum AccessListTypeEnums {
    BLACKLIST = 'black-list',
    WHITELIST = 'white-list',
}

enum AccessListFields {
    CATEGORY = 'Category',
    VALUE = 'Value',
    DATE = 'Date',
    DESCRIPTION = 'Description',
}

enum AccessListFieldValues {
    CATEGORY = 'category',
    VALUE = 'value',
    DATE = 'date',
    DESCRIPTION = 'description',
    DELETE = 'delete',
}

export { AccessListTypeEnums, AccessListFields, AccessListFieldValues };
