import { RolesEnums } from '@/store/enums/RolesEnums';

const settings = {
  path: '/',
  name: 'settings',
  component: () => import('@/layout/Layout.vue'),
  meta: { roles: [RolesEnums.ADMIN, RolesEnums.MANAGER, RolesEnums.SUPPORT, RolesEnums.PPC, RolesEnums.MARKETER] },
  children: [
    {
      path: '/admin/role',
      name: 'admin-role',
      component: () =>
        import('@/views/pages/settings/user/Role.vue'),
      meta: { roles: [RolesEnums.ADMIN] }
    },
    {
      path: '/admin/modules',
      name: 'admin-modules',
      component: () =>
        import('@/views/pages/settings/user/Module.vue'),
      meta: { roles: [RolesEnums.ADMIN] }
    },
    {
      path: '/admin/functions',
      name: 'admin-functions',
      component: () =>
        import('@/views/pages/settings/user/Function.vue'),
      meta: { roles: [RolesEnums.ADMIN] }
    },
    {
      path: '/admin/access-lists',
      name: 'admin-access-lists',
      component: () =>
        import('@/views/pages/settings/user/AccessList.vue'),
      meta: { roles: [RolesEnums.ADMIN, RolesEnums.MANAGER, RolesEnums.SUPPORT] }
    },
    {
      path: '/admin/user-lists',
      name: 'admin-user-lists',
      component: () =>
        import('@/views/pages/settings/user/UserList.vue'),
      meta: { roles: [RolesEnums.ADMIN, RolesEnums.MANAGER, RolesEnums.SUPPORT, RolesEnums.PPC, RolesEnums.MARKETER] }
    },
    {
      path: '/admin/user-details/:id',
      name: 'admin-user-details',
      component: () =>
        import('@/views/pages/settings/user/UserDetails.vue'),
      meta: { roles: [RolesEnums.ADMIN, RolesEnums.MANAGER, RolesEnums.SUPPORT, RolesEnums.PPC, RolesEnums.MARKETER] }
    },
    {
      path: '/admin/reflinks',
      name: 'admin-reflinks',
      component: () =>
        import('@/views/pages/settings/user/Reflink.vue'),
      meta: { roles: [RolesEnums.ADMIN, RolesEnums.MANAGER, RolesEnums.SUPPORT, RolesEnums.PPC, RolesEnums.MARKETER] }
    },
    {
      path: '/admin/google-ads-report',
      name: 'google-ads-report',
      component: () =>
        import('@/views/pages/settings/user/GoogleAdsReport.vue'),
      meta: { roles: [RolesEnums.ADMIN, RolesEnums.PPC, RolesEnums.MARKETER] }
    },
    {
      path: '/admin/user-orders',
      name: 'admin-user-orders',
      component: () =>
        import('@/views/pages/settings/user/UserOrder.vue'),
      meta: { roles: [RolesEnums.ADMIN, RolesEnums.MANAGER, RolesEnums.SUPPORT, RolesEnums.MARKETER] }
    },
    {
      path: '/admin/core-settings',
      name: 'admin-core-settings',
      component: () =>
        import('@/views/pages/settings/user/CoreSetting.vue'),
      meta: { roles: [RolesEnums.ADMIN] }
    },
    {
      path: '/admin/vendors',
      name: 'admin-vendors',
      component: () =>
        import('@/views/pages/settings/user/Vendor.vue'),
      meta: { roles: [RolesEnums.ADMIN] }
    },
    {
      path: '/admin/user-payments',
      name: 'admin-user-payments',
      component: () =>
        import('@/views/pages/settings/user/UserPayment.vue'),
      meta: { roles: [RolesEnums.ADMIN, RolesEnums.MANAGER, RolesEnums.SUPPORT, RolesEnums.PPC, RolesEnums.MARKETER] }
    },
    {
      path: '/admin/user-apps',
      name: 'admin-user-apps',
      component: () =>
        import('@/views/pages/settings/user/UserApps.vue'),
      meta: { roles: [RolesEnums.ADMIN, RolesEnums.MANAGER, RolesEnums.SUPPORT, RolesEnums.MARKETER] }
    },
    {
      path: '/admin/sales-report',
      name: 'admin-sales-report',
      component: () =>
        import('@/views/pages/settings/user/SalesReport.vue'),
      meta: { roles: [RolesEnums.ADMIN, RolesEnums.MANAGER] }
    },
    {
      path: '/admin/marketing-actions',
      name: 'admin-marketing-actions',
      component: () =>
        import('@/views/pages/settings/user/MarketingAction.vue'),
      meta: { roles: [RolesEnums.ADMIN, RolesEnums.MANAGER, RolesEnums.SUPPORT, RolesEnums.MARKETER] }
    },
    {
      path: '/admin/clients-report',
      name: 'admin-clients-report',
      component: () =>
        import('@/views/pages/settings/user/ClientsReport.vue'),
      meta: { roles: [RolesEnums.ADMIN, RolesEnums.MANAGER, RolesEnums.SUPPORT, RolesEnums.PPC, RolesEnums.MARKETER] }
    },
  ]
};
export default settings;
