import { Mutations } from '@/store/enums/StoreEnums';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { Getters } from '@/store/enums/GettersEnums';

@Module({ name: 'StartingModule' })
export default class StartingModule extends VuexModule implements StoreModel {
  loadCountries = true;
  loadConstants = true;
  loadLanguages = true;
  loadPriceplans = true;
  loadCurrentPriceplan = true;
  loadApps = true;
  loadNotices = true;

  get [Getters.GET_COUNTRIES_STATUS]() {
    return this.loadCountries;
  }

  get [Getters.GET_CONSTANTS_STATUS]() {
    return this.loadConstants;
  }

  get [Getters.GET_LANGUAGES_STATUS]() {
    return this.loadLanguages;
  }

  get [Getters.GET_PRICEPLANS_STATUS]() {
    return this.loadPriceplans;
  }

  get [Getters.GET_CURRENT_PRICEPLAN_STATUS]() {
    return this.loadCurrentPriceplan;
  }

  get [Getters.GET_APPS_STATUS]() {
    return this.loadApps;
  }

  get [Getters.GET_NOTICES_STATUS]() {
    return this.loadNotices;
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.loadCountries = true;
    this.loadConstants = true;
    this.loadLanguages = true;
    this.loadPriceplans = true;
    this.loadCurrentPriceplan = true;
    this.loadApps = true;
    this.loadNotices = true;
  };

    @Mutation
    [Mutations.SET_COUNTRIES_STATUS](payload) {
      this.loadCountries = payload;
    }

    @Mutation
    [Mutations.SET_CONSTANTS_STATUS](payload) {
      this.loadConstants = payload;
    }

    @Mutation
    [Mutations.SET_LANGUAGES_STATUS](payload) {
      this.loadLanguages = payload;
    }

    @Mutation
    [Mutations.SET_PRICEPLANS_STATUS](payload) {
      this.loadPriceplans = payload;
    }

    @Mutation
    [Mutations.SET_CURRENT_PRICEPLAN_STATUS](payload) {
      this.loadCurrentPriceplan = payload;
    }

    @Mutation
    [Mutations.SET_APPS_STATUS](payload) {
      this.loadApps = payload;
    }

    @Mutation
    [Mutations.SET_NOTICES_STATUS](payload) {
      this.loadNotices = payload;
    }
}
