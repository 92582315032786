const sendManagerRequest = {
  sendRequest: 'Send request',
  personalManagerRequest: 'Personal manager request',
  pleaseFillYourProfile: 'Please fill your profile and leave any contact details for better communication.',
  myContact: 'My contact',
  messengerId: 'Your messenger id',
  messenger: 'Messenger',
  requestForPersonalManager: 'Request for personal manager assignment is sent. Your new personal manager will contact you soon.',
};

export default sendManagerRequest;
