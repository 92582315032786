const userLists = {
  userLists: 'Admin User list',
  showing: 'Showing',
  userListsOf: 'users of',
  noUserListsFound: 'Users not found',
  actionsDropdown: {
    details: 'Details',
    deactivate: 'Deactivate',
    edit: 'Edit',
  },
  modal: {
    editUser: 'Edit User',
    name: 'Name',
    email: 'Email',
    role: 'Role',
    messengers: 'Messengers',
    priceplan: 'Priceplan',
    manager: 'Manager',
    isAlive: 'Is active',
    emailVerified: 'Email verified',
    successUpdate: 'Changes to user details were successfully applied.',
  },
  tableDropdown: {
    quickActions: 'Quick actions menu',
    refresh: 'Refresh',
    exportUserList: 'Export',
    exportUserListCurrent: 'Export current',
  }
};

export default userLists;
