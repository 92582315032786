import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { AxiosRequestConfig } from 'axios';
import ApiService from '@/core/services/ApiService';
import { domainUrlAdmin } from '@/core/config/DomainConfig';
import { ErrorGetters } from '@/store/enums/ErrorGettersEnums';
import { Getters } from '@/store/enums/GettersEnums';

@Module({ name: 'AdminMarketingActionModule' })
export default class AdminMarketingActionModule extends VuexModule implements StoreModel {
  errors = {};
  marketingActions = [];
  totalMarketingActions = 0;
  currentPage = 1;
  lastPage = 1;
  page = 1;
  currentMarketingActionId = 0;
  country = null;
  rowsPerPage = 10;
  search = '';
  limit = 10;
  orderBy = 'start';
  sortBy = 'desc';
  queryParams = {};
  export = [];
  filters = [];

  /**
     * Get current page
     * @returns number
     */
  get [Getters.GET_ADMIN_MARKETING_ACTIONS_CURRENT_PAGE]() {
    return this.currentPage;
  }

  /**
     * Get last page
     * @returns number
     */
  get [Getters.GET_ADMIN_MARKETING_ACTIONS_LAST_PAGE]() {
    return this.lastPage;
  }

  /**
     * Get page
     * @returns number
     */
  get [Getters.GET_ADMIN_MARKETING_ACTIONS_PAGE]() {
    return this.page;
  }

  /**
     * Get rows
     * @returns number
     */
  get [Getters.GET_ADMIN_MARKETING_ACTIONS_ROWS]() {
    return this.rowsPerPage;
  }

  /**
     * Get search
     * @returns string
     */
  get [Getters.GET_ADMIN_MARKETING_ACTIONS_SEARCH]() {
    return this.search;
  }

  /**
     * Get limit
     * @returns string
     */
  get [Getters.GET_ADMIN_MARKETING_ACTIONS_LIMIT]() {
    return this.limit;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [ErrorGetters.GET_ERRORS_ADMIN_MARKETING_ACTIONS]() {
    return this.errors;
  }

  /**
     * Get current Transaction object
     * @returns array
     */
  get [Getters.ALL_ADMIN_MARKETING_ACTIONS]() {
    return this.marketingActions;
  }

  /**
     * Get current Total Transactions
     * @returns number
     */
  get [Getters.GET_TOTAL_ADMIN_MARKETING_ACTIONS]() {
    return this.totalMarketingActions;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ADMIN_MARKETING_ACTIONS_ORDER_BY]() {
    return this.orderBy;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ADMIN_MARKETING_ACTIONS_SORT_BY]() {
    return this.sortBy;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ADMIN_MARKETING_ACTIONS_QUERY_PARAMS]() {
    return this.queryParams;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ADMIN_MARKETING_ACTIONS_EXPORT_DATA]() {
    return this.export;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ADMIN_MARKETING_ACTIONS_COUNTRY]() {
    return this.country;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ADMIN_MARKETING_ACTIONS_CURRENT_ID]() {
    return this.currentMarketingActionId;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ADMIN_MARKETING_ACTIONS_FILTERS]() {
    return this.filters;
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.errors = {};
    this.marketingActions = [];
    this.totalMarketingActions = 0;
    this.currentPage = 1;
    this.lastPage = 1;
    this.page = 1;
    this.currentMarketingActionId = 0;
    this.country = null;
    this.rowsPerPage = 10;
    this.search = '';
    this.limit = 10;
    this.orderBy = 'date';
    this.sortBy = 'desc';
    this.queryParams = {};
    this.export = [];
    this.filters = [];
  };

    @Mutation
    [Mutations.SET_ADMIN_MARKETING_ACTIONS_ROWS](payload) {
      this.rowsPerPage = payload;
    }

    @Mutation
    [Mutations.SET_ERROR_ADMIN_MARKETING_ACTIONS](error) {
      this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_ALL_ADMIN_MARKETING_ACTIONS](payload) {
      this.marketingActions = payload;
    }

    @Mutation
    [Mutations.SET_TOTAL_ADMIN_MARKETING_ACTIONS](payload) {
      this.totalMarketingActions = payload;
    }

    @Mutation
    [Mutations.SET_ADMIN_MARKETING_ACTIONS_CURRENT_PAGE](payload) {
      this.currentPage = payload;
    }

    @Mutation
    [Mutations.SET_ADMIN_MARKETING_ACTIONS_LAST_PAGE](payload) {
      this.lastPage = payload;
    }

    @Mutation
    [Mutations.SET_ADMIN_MARKETING_ACTIONS_SEARCH](payload) {
      this.search = payload;
    }

    @Mutation
    [Mutations.SET_ADMIN_MARKETING_ACTIONS_ORDER_BY](payload) {
      this.orderBy = payload;
    }

    @Mutation
    [Mutations.SET_ADMIN_MARKETING_ACTIONS_SORT_BY](payload) {
      this.sortBy = payload;
    }

    @Mutation
    [Mutations.SET_ADMIN_MARKETING_ACTIONS_QUERY_PARAMS](payload) {
      this.queryParams = payload;
    }

    @Mutation
    [Mutations.SET_ADMIN_MARKETING_ACTIONS_EXPORT](payload) {
      this.export = payload;
    }

    @Mutation
    [Mutations.SET_ADMIN_MARKETING_ACTIONS_COUNTRY](payload) {
      this.country = payload;
    }

    @Mutation
    [Mutations.SET_ADMIN_MARKETING_ACTIONS_CURRENT_ID](payload) {
      this.currentMarketingActionId = payload;
    }

    @Mutation
    [Mutations.SET_ADMIN_MARKETING_ACTIONS_FILTERS](payload) {
      this.filters = payload;
    }

    @Action
    [Actions.GET_ADMIN_MARKETING_ACTIONS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}marketing-actions`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TOTAL_ADMIN_MARKETING_ACTIONS, data.meta.total);
          this.context.commit(Mutations.SET_ADMIN_MARKETING_ACTIONS_CURRENT_PAGE, data.meta.current_page);
          this.context.commit(Mutations.SET_ADMIN_MARKETING_ACTIONS_LAST_PAGE, data.meta.last_page);
          this.context.commit(Mutations.SET_ALL_ADMIN_MARKETING_ACTIONS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_ADMIN_MARKETING_ACTIONS, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_ADMIN_MARKETING_ACTIONS_EXPORT](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}marketing-actions`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ADMIN_MARKETING_ACTIONS_EXPORT, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_ADMIN_MARKETING_ACTIONS, response?.data?.errors);
        });
    }

    @Action
    [Actions.ADD_ADMIN_MARKETING_ACTIONS](params) {
      return ApiService.post(`${domainUrlAdmin}marketing-actions`, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR_ADMIN_MARKETING_ACTIONS, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_ADMIN_MARKETING_ACTIONS, response?.data?.errors);
        }).finally(() => {
          this.context.commit(Mutations.SET_LOADING, false);
        });
    }

    @Action
    [Actions.UPDATE_ADMIN_MARKETING_ACTIONS](params) {
      const { id, ...data } = params;
      return ApiService.update(`${domainUrlAdmin}marketing-actions`, id, data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR_ADMIN_MARKETING_ACTIONS, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_ADMIN_MARKETING_ACTIONS, response?.data?.errors);
        }).finally(() => {
          this.context.commit(Mutations.SET_LOADING, false);
        });
    }

    @Action
    [Actions.GET_ADMIN_MARKETING_ACTIONS_FILTERS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}marketing-actions/filters`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ADMIN_MARKETING_ACTIONS_FILTERS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_ADMIN_MARKETING_ACTIONS, response?.data?.errors);
        });
    }

    @Action
    [Actions.DELETE_ADMIN_MARKETING_ACTIONS](id) {
      return ApiService.delete(`${domainUrlAdmin}marketing-actions/` + id)
        .then(() => {
          this.context.commit(Mutations.SET_ERROR_ADMIN_MARKETING_ACTIONS, {});
        }).catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_ADMIN_MARKETING_ACTIONS, response?.data?.errors);
        });
    }
}
