import { RolesEnums } from '@/store/enums/RolesEnums';

const auth = {
  path: '/',
  component: () => import('@/components/page-layouts/Auth.vue'),
  meta: { roles: [RolesEnums.ALL] },
  children: [
    {
      path: '/login',
      name: 'sign-in',
      component: () =>
        import('@/views/crafted/authentication/basic-flow/SignIn.vue'),
      meta: { roles: [RolesEnums.ALL] }
    },
    {
      path: '/signup',
      name: 'sign-up',
      component: () =>
        import('@/views/crafted/authentication/basic-flow/SignUp.vue'),
      meta: { roles: [RolesEnums.ALL] }
    },
    {
      path: '/password-reset',
      name: 'password-reset',
      component: () =>
        import('@/views/crafted/authentication/basic-flow/PasswordReset.vue'),
      meta: { roles: [RolesEnums.ALL] }
    },
    {
      path: '/api/password-reset',
      redirect: '/password-reset',
      meta: { roles: [RolesEnums.ALL] }
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () =>
        import('@/views/crafted/authentication/basic-flow/ForgotPassword.vue'),
      meta: { roles: [RolesEnums.ALL] }
    },
    {
      path: '/terms',
      name: 'terms-basic',
      component: () =>
        import('@/views/pages/Terms.vue'),
      meta: { roles: [RolesEnums.ALL] }
    },
    {
      path: '/privacy',
      name: 'privacy-policy-basic',
      component: () =>
        import('@/views/pages/PrivacyPolicy.vue'),
      meta: { roles: [RolesEnums.ALL] }
    }
  ]
};
export default auth;
