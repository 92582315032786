import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { AxiosRequestConfig } from 'axios';
import ApiService from '@/core/services/ApiService';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { domainUrlAdmin } from '@/core/config/DomainConfig';
import { ErrorGetters } from '@/store/enums/ErrorGettersEnums';
import { Getters } from '@/store/enums/GettersEnums';

@Module({ name: 'AdminUserPaymentModule' })
export default class AdminUserPaymentModule extends VuexModule implements StoreModel {
  errors = {};
  userPayments = [];
  totalUserPayment = 0;
  currentPage = 1;
  lastPage = 1;
  page = 1;
  rowsPerPage = 10;
  search = '';
  limit = 10;
  orderBy = 'created_at';
  sortBy = 'desc';
  priceplan = 0;
  balanceType = 0;
  paymentType = 0;
  queryParams = {};
  export = [];
  columnSearchFilter = '';


  /**
     * Get current page
     * @returns number
     */
  get [Getters.GET_USER_PAYMENTS_CURRENT_PAGE]() {
    return this.currentPage;
  }


  get [Getters.GET_ADMIN_USER_PAYMENTS_COLUMN_SEARCH_FILTER]() {
    return this.columnSearchFilter;
  }

  /**
     * Get last page
     * @returns number
     */
  get [Getters.GET_USER_PAYMENTS_LAST_PAGE]() {
    return this.lastPage;
  }

  /**
     * Get page
     * @returns number
     */
  get [Getters.GET_USER_PAYMENTS_PAGE]() {
    return this.page;
  }

  /**
     * Get rows
     * @returns number
     */
  get [Getters.GET_USER_PAYMENTS_ROWS]() {
    return this.rowsPerPage;
  }

  /**
     * Get search
     * @returns string
     */
  get [Getters.GET_USER_PAYMENTS_SEARCH]() {
    return this.search;
  }

  /**
     * Get limit
     * @returns string
     */
  get [Getters.GET_USER_PAYMENTS_LIMIT]() {
    return this.limit;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [ErrorGetters.GET_ERRORS_USER_PAYMENTS]() {
    return this.errors;
  }

  /**
     * Get current Transaction object
     * @returns array
     */
  get [Getters.ALL_USER_PAYMENTS]() {
    return this.userPayments;
  }

  /**
     * Get current Total Transactions
     * @returns number
     */
  get [Getters.GET_TOTAL_USER_PAYMENTS]() {
    return this.totalUserPayment;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_USER_PAYMENTS_ORDER_BY]() {
    return this.orderBy;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_USER_PAYMENTS_SORT_BY]() {
    return this.sortBy;
  }

  /**
     * Get current Total Transactions
     * @returns number
     */
  get [Getters.GET_USER_PAYMENTS_PRICEPLAN]() {
    return this.priceplan;
  }

  /**
     * Get current Total Transactions
     * @returns number
     */
  get [Getters.GET_USER_PAYMENTS_BALANCE_TYPE]() {
    return this.balanceType;
  }

  /**
     * Get current Total Transactions
     * @returns number
     */
  get [Getters.GET_USER_PAYMENTS_PAYMENT_TYPE]() {
    return this.paymentType;
  }

  /**
     * Get current Total Transactions
     * @returns number
     */
  get [Getters.GET_USER_PAYMENTS_QUERY_PARAMS]() {
    return this.queryParams;
  }

  /**
     * Get current Total Transactions
     * @returns number
     */
  get [Getters.GET_USER_PAYMENTS_EXPORT]() {
    return this.export;
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.errors = {};
    this.userPayments = [];
    this.totalUserPayment = 0;
    this.currentPage = 1;
    this.lastPage = 1;
    this.page = 1;
    this.rowsPerPage = 10;
    this.search = '';
    this.limit = 10;
    this.orderBy = 'id';
    this.sortBy = 'desc';
    this.priceplan = 0;
    this.balanceType = 0;
    this.paymentType = 0;
    this.export = [];
    this.queryParams = {};
    this.columnSearchFilter = '';
  };

    @Mutation
    [Mutations.SET_USER_PAYMENTS_ROWS](payload) {
      this.rowsPerPage = payload;
    }

    @Mutation
    [Mutations.SET_ERROR_USER_PAYMENTS](error) {
      this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_ALL_USER_PAYMENTS](payload) {
      this.userPayments = payload;
    }

    @Mutation
    [Mutations.SET_TOTAL_USER_PAYMENTS](payload) {
      this.totalUserPayment = payload;
    }

    @Mutation
    [Mutations.SET_USER_PAYMENTS_CURRENT_PAGE](payload) {
      this.currentPage = payload;
    }

    @Mutation
    [Mutations.SET_USER_PAYMENTS_LAST_PAGE](payload) {
      this.lastPage = payload;
    }

    @Mutation
    [Mutations.SET_USER_PAYMENTS_SEARCH](payload) {
      this.search = payload;
    }

    @Mutation
    [Mutations.SET_USER_PAYMENTS_ORDER_BY](payload) {
      this.orderBy = payload;
    }

    @Mutation
    [Mutations.SET_USER_PAYMENTS_SORT_BY](payload) {
      this.sortBy = payload;
    }

    @Mutation
    [Mutations.SET_USER_PAYMENTS_PRICEPLAN](payload) {
      this.priceplan = payload;
    }

    @Mutation
    [Mutations.SET_USER_PAYMENTS_BALANCE_TYPE](payload) {
      this.balanceType = payload;
    }

    @Mutation
    [Mutations.SET_USER_PAYMENTS_PAYMENT_TYPE](payload) {
      this.paymentType = payload;
    }

    @Mutation
    [Mutations.SET_USER_PAYMENTS_QUERY_PARAMS](payload) {
      this.queryParams = payload;
    }

    @Mutation
    [Mutations.SET_USER_PAYMENTS_EXPORT](payload) {
      this.export = payload;
    }

    @Mutation
    [Mutations.SET_USER_PAYMENTS_COLUMN_SEARCH_FILTER](payload) {
      this.columnSearchFilter = payload;
    }

    @Action
    [Actions.GET_USER_PAYMENT](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}payments`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TOTAL_USER_PAYMENTS, data.meta.total);
          this.context.commit(Mutations.SET_USER_PAYMENTS_CURRENT_PAGE, data.meta.current_page);
          this.context.commit(Mutations.SET_USER_PAYMENTS_LAST_PAGE, data.meta.last_page);
          this.context.commit(Mutations.SET_ALL_USER_PAYMENTS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_PAYMENTS, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_USER_PAYMENT_EXPORT](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}payments`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER_PAYMENTS_EXPORT, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_PAYMENTS, response?.data?.errors);
        });
    }

    @Action
    [Actions.UPDATE_USER_PAYMENT](params) {
      const { id, ...data } = params;
      return ApiService.update(`${domainUrlAdmin}payments`, id, data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR_USER_PAYMENTS, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_PAYMENTS, response?.data?.errors);
        });
    }

    @Action
    [Actions.DELETE_USER_PAYMENT](id) {
      return ApiService.delete(`${domainUrlAdmin}payments/` + id)
        .then(() => {
          this.context.commit(Mutations.SET_ERROR_USER_PAYMENTS, {});
        }).catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_PAYMENTS, response?.data?.errors);
        });
    }
}
