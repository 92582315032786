const myCampaignsPage = {
  notFoundCampaigns: 'No campaigns found',
  editingDisabled: 'Editing legacy campaign is disabled. Please edit campaign at legacy platform',
  startDisabled: 'Starting legacy campaign is disabled. Please start campaign at legacy platform',
  header: {
    toolbar: {
      all: 'All',
      active: 'Active',
      pause: 'Paused',
      draft: 'Draft',
      delayed: 'Delayed',
      failed: 'Failed',
      completed: 'Completed',
      canceled: 'Canceled',
      archived: 'Archived',
      moderation: 'Moderation',
      pending: 'Pending',
      rejected: 'Rejected'
    }
  },
  table: {
    myCampaigns: 'My Campaigns',
    showing: 'Showing',
    campaignsOf: 'campaigns of',
    found: 'Found',
    campaignFrom: 'campaign(s) from',
    nothingFoundBySearch: 'Nothing found by search',
    noCampaignsFound: 'No campaigns found',
    noOrdersFound: 'No orders found',
    noAdvertisingFound: 'No advertising found',
    showAll: 'show all',
    showMore: 'show more',
    showDetails: 'show details',
    details: 'details',
    spreadInstallsFor24h: 'Spread installs for 24h',
    showReviews: 'Show reviews',
    promotionRuleStopsPromotionIfRankReachedRequiredLevel: 'Promotion rule stops promotion if rank reached required level',
    installsDeliveryType: 'Installs delivery type',
    vendor: 'Vendor',
    changeVendor: 'Change vendor'
  },
  tableDropdown: {
    quickActions: 'Quick actions menu',
    exportCampaigns: 'Export',
    archiveAll: 'Archive all',
    refresh: 'Refresh'
  },
  actionsDropdown: {
    start: 'Start',
    startNow: 'Start now',
    pause: 'Pause',
    cancel: 'Cancel',
    archive: 'Archive',
    edit: 'Edit',
    update: 'Update',
    details: 'Details',
    resume: 'Resume',
    repeat: 'Repeat',
    clone: 'Clone',
    reject: 'Reject',
    moderation: 'Send for moderation',
    sendMessage: 'Send Message',
    forcedCompletion: 'Forced completion',
    forcedDraft: 'Forced draft',
  },
  modalReview: {
    title: 'My reviews for Campaign',
    exportReviews: 'Export'
  }
};
export default myCampaignsPage;
