const application = {
  promote:'Продвигать',
  addKeywords:'Добавить ключевые слова',
  update:'Обновить',
  delete:'Удалить',
  addApp:'Добавить новое приложение',
  created:'Добавить новое приложение',
  inputSelectCountry:'Пожалуйста, выберите страну',
  inputSelectApplication:'Пожалуйста, выберите приложение',
  inputAppstore:'Пожалуйста, выберите магазин приложений',
  deleteApp:'Приложение успешно удалено',
  updateApp:'Приложение успешно обновлено',
  search:'Поиск',
  sure:'Вы уверены?',
  not:'Нет, отменить',
};
export default application;
