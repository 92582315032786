const dashboard = {
  campaigns:'Кампании',
  leftTo:'Осталось до',
  myTotalCampaigns:'Всего моих кампаний',
  activeCampaigns:'Активные кампании',
  completedCampaigns:'Завершенные кампании',
  guarantedReviews:'Гарантированные отзывы',
  smartCampaign:'Умная кампания',
  advertisingCampaign:'Рекламная кампания',
  improveYourRatesAndApps:'Улучшайте свои рейтинги и репутацию приложений с помощью наших обзоров. Получите 30-дневную гарантию на любой заказ.',
  cpi:'Кампания CPI (cost-per-install) — это инструмент, используемый для привлечения пользователей в ваше приложение.',
  geos:'GEOs',
  availableCountriesForPromotion:'Доступные страны для продвижения',
  topRegions:'Лучшие регионы',
  mostPopularRegionsForPromotion:'Самые популярные регионы для продвижения',
  lastCampaigns:'Последние кампании',
  myApps:'Мои приложения',
  platformNews:'Новости платформы',
  FAQs:'FAQs',
  application:'Приложение',
  currentPlan:'Текущий план',
  FAQAndASONews:'FAQ & ASO новости',
  keyappAcademy:'Keyapp Академия',
};

export default dashboard;
