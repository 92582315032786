const resellersProgram = {
  resellersProgram: 'Resellers program',
  dearUser: 'Dear user! You can apply here for the Reseller Base and Reseller Gold ',
  pricePlans: 'priceplans',
  itGivesYouSignificantDiscount: 'It gives you a significant discount on the install price. Please read the ',
  requirements: 'requirements ',
  carefullyBeforeFillingInTheForm: 'carefully before filling in the form',
  feelFreeTo: 'Feel free to ',
  contact: 'contact ',
  usIfYouHaveAnyQuestions: 'us if you have any questions about this Reseller Approval Form and ',
  weNeedMoreInformationAboutYou: 'We need more information about You',
  whereDoYourCustomersComeFrom: 'Where do your customers come from?',
  yourWebsitesBlog: 'Your websites, blog',
  pleaseDescribeYourSources: 'Please describe your sources, so we can understand that you have a regular flow of new clients.',
  whatChannelsDoYouUseToFindClients: 'What channels do you use to find clients?',
  whatServicesDoYouProvide: 'What services do you provide?',
  whatOtherSuppliersDoYouHave: 'What other suppliers do you have?',
  pleaseProvideYourSuppliesLineByLine: 'Please provide your supplies line by line',
  youMayBeUsingOneOfOurServices: 'You may be using one of our services even without knowing it. If one of your providers belongs to us, we will combine your price plans so you will spend less.',
  howManyInstallsDoYouSellMonthly: 'How many installs do you sell monthly?',
  applyAsReseller: 'Apply as Reseller',
  seo: 'SEO',
  paidSearch: 'Paid search',
  forumsBlogs: 'Forums, blogs',
  referralProgram: 'Referral program',
  smm: 'SMM',
  other: 'Other',
  androidInstalls: 'Android installs',
  iosInstalls: 'iOS installs',
  aso: 'ASO',
  userAcquisition: 'User acquisition',
  marketing: 'Marketing',
  less: 'less',
  pleaseSelectAnyService: 'Please select any service',
  pleaseSelectAnyChannel: 'Please select any channel',
  yourReSellerRequestWasCreatedSuccessfully: 'Your Reseller request was created successfully!',
};

export default resellersProgram;
