const priceplans = {
  currentPriceplan:'Поточний тарифний план',
  currentPlan:'Поточнмй план',
  totalPayments:'Всього Платежів',
  from:'із',
  to:'до',
  topUpBalance:'Поповнити баланс',
  availablePriceplans:'Доступні тарифні плани',
  apply:'Подати заяву',
  upTo:'аж до',
  keyword:'Ключове слово',
  package:'Пакет',
  rate:'Оцінка',
  review:'Огляд',
  HQReview:'HQ Огляд',
  base:'Базовий',
  bronze:'Бронзовий',
  silver:'Срібний',
  gold:'Золотий',
  resselerBase:'Базовий реселлер',
  resselerGold:'Золотий реселлер',
  android:'android',
  iOS:'iOS',
  noBillingsFound:'Платежі не знайдені',
};

export default priceplans;
