import { RolesEnums } from '@/store/enums/RolesEnums';

const dashboard = {
  path: '/',
  redirect: '/contacts',
  component: () => import('@/layout/Layout.vue'),
  meta: { roles: [RolesEnums.ALL] },
  children: [
    {
      path: '/contacts',
      name: 'contacts',
      meta: { roles: [RolesEnums.ALL] },
      component: () => import('@/views/pages/Contacts.vue')
    }

  ]
};
export default dashboard;
