const bonusProgram = {
  getFreeBonusProgram:'Получите бесплатную бонусную программу',
  welcomeToGetFreeBonusesPage:'Добро пожаловать на страницу «Получить бесплатные бонусы», здесь вы сможете получить бесплатное пополнение своего счета, выполняя наборы следующих заданий и продвигая наш сервис. На данный момент вы можете заработать эти бонусы:',
  getTwentyPercentMoney:'Получите 20% денег обратно на свой счет или 100 $ за вашу историю успеха',
  getOneHundredBaks:'Получите 100$ за ваш обзор на YouTube',
  getThirtyBaksForAndroid:'Получите 30$ на свой счет за обзор форума Android',
  getThirtyBaksForFacebook:'Получите 30$ на свой счет за публикацию в Facebook',
  successStoryOrCase:'История успеха или кейс',
  inOrderToGetYourTwentyPercentMoneyBack:'Чтобы получить свой 20%-й бонус возврата денег (вы получите 20% ваших денег, которые вы потратили на самый крупный заказ на продвижение приложения, о котором вы пишете свою историю успеха, например, если вы пишете историю о приложение, на продвижение которого вы потратили 500$, и у вас есть 5 заказов, вам вернут 20% денег за самый большой заказ, а не от ваших 500$ расходов) вам нужно найти и зарегистрироваться на любом специализированном форуме Android, создать тему, и опубликуйте свою историю успеха с помощью нашего сервиса, эта история успеха должна соответствовать следующим требованиям:',
  postHasToBeEightHundredSymbols:'Ваше сообщение на форуме должно быть не менее 800 символов.',
  postHasToIncludeLink:'Ваше сообщение на форуме должно содержать ссылку на наш сайт',
  postHasToDescribe:'Ваше сообщение на форуме должно описывать вашу пошаговую работу с нашим сервисом и то, чего вы достигли, используя его.',
  postHasToIncludeScreenshots:'Ваше сообщение на форуме должно включать как минимум 2 снимка экрана вашего приложения и эффективность вашего ключевого слова.',
  youCantMention:'Вы не можете упоминать в своем сообщении на форуме, что вы получаете бесплатные бонусы за это',
  postHasToStayAlive:'Ваш пост должен оставаться в силе не менее 2 дней.',
  youTubeVideoReview:'YouTube видео обзор',
  inOrderToGetOneHundredBaks:'Для того, чтобы получить бонус в размере 100$, вы должны сделать руководство по использованию нашего сайта (или обзорное видео) на своем канале YouTube, которое соответствует следующим требованиям: Вы должны потратить не менее 100$ в нашем сервисе, что означает, что нам нужно качественный обзор (гид) нашего сайта от наших постоянных клиентов. Ваше видео на YouTube должно включать краткое описание нашего сервиса (что-то вроде: "Этот сервис помогает разработчикам в продвижении их приложений для Android. KeyApp.Top предлагает следующие услуги - установки с поиском по ключевым словам и стандартные установки приложений"). Ваш видеообзор на YouTube должен включать в себя руководство по настройке кампании на нашем сайте во вкладке «Мои заказы». В своем видео на YouTube вы должны описать следующие вкладки личного кабинета: «Мои ключевые слова», «Добавить деньги», «Реферальная программа», «Блог» (несколько слов об их основных параметрах).',
  videoHastoBeLong:'Ваш видеообзор на YouTube должен длиться не менее 2 минут.',
  youAreAlwaysWelcome:'Всегда пожалуйста, если вы сделали руководство или обзор на своем родном языке (мы принимаем видео не только на английском языке).',
  videoHasToIncludeWebsiteLink:'Ваше видео на YouTube должно содержать ссылку на наш сайт',
  videoHasToIncludeFBLink:'Ваше видео на YouTube должно содержать ссылку на нашу страницу в FB: ',
  videoHasToDesctiveLikesDislikes:'Ваше видео на YouTube должно описывать, что вам нравится или не нравится в нашем сервисе.',
  youCantMentionInVideo:'Вы не можете упоминать в своем видео на YouTube, что получаете бесплатные бонусы за его создание.',
  videoReviewHasToStayAlive:'Ваш видеообзор на YouTube должен оставаться в силе не менее 2 дней.',
  androidRelatedForum:'Форум по Android',
  inOrderToGetThirtyBaks:'Чтобы получить свой бонус в размере 30 $, вам необходимо найти и зарегистрироваться на любом форуме, посвященном Android, создать тему и опубликовать правдивый отзыв о нашем сервисе, этот обзор должен соответствовать следующим требованиям:',
  postHasToBeFourHundredSymbols:'Ваше сообщение на форуме должно быть не менее 400 символов.',
  postHastoDescribeLikesDislikes:'Ваше сообщение на форуме должно описывать ваши симпатии или антипатии к нашему сервису.',
  postHasToIncludeWebsiteLink:'Ваше сообщение на форуме должно содержать ссылку на наш сайт',
  youCantMentionInPost:'Вы не можете упоминать в своем сообщении на форуме, что вы получаете бесплатные бонусы за это',
  facebookPost:'Facebook пост',
  inOrderToGetThirtyBaksBonus:'Чтобы получить бонус в размере <b>30$<b/>, вам необходимо опубликовать сообщение в своей учетной записи facebook, соответствующее следующим требованиям:',
  facebookHasToIncludeScreenshot:'Ваш пост в Facebook должен содержать снимок экрана с эффективностью вашего рейтинга по ключевым словам или общей производительностью вашего приложения. Пожалуйста, выделите область, в которой вы начали продвижение с нами. Скриншоты со страницы "мои заказы" на keyapp.top не подходят, так как они не показывают разницу в продвижении вашего приложения до и после ваших кампаний с нами.',
  facebookHasToBeLong:'Ваш пост в Facebook должен быть не менее 400 символов.',
  facebookHasToIncludeScreenshotKeyword:'Ваш пост в Facebook должен содержать снимок экрана с эффективностью вашего рейтинга по ключевым словам или общей производительностью вашего приложения. Пожалуйста, выделите область, в которой вы начали продвижение с нами.',
  facebookHasToIncludeWebsiteLink:'Ваш пост в Facebook должен содержать ссылку на наш сайт',
  facebookHasToIncludeFBLink:'Ваш пост в Facebook должен содержать ссылку на нашу страницу в Facebook: ',
  facebookHasToDescribeLikesDislikes:'Ваш пост в Facebook должен описывать, что вам нравится или не нравится в нашем сервисе.',
  youCantMentionInFB:'Вы не можете упоминать в своем посте на Facebook, что получаете бесплатные бонусы за это',
  googleMapsReview:'Google maps обзор',
  inOrderToGetTenBaks:'Чтобы получить бонус в размере 10$, вам необходимо оставить отзыв на картах ',
  googleMaps:'Google maps',
  whichMeetsFollowingRequirements:', отвечающий следующим требованиям. Ваш отзыв на картах Google должен:',
  minimumLong:'быть минимум 350 символов',
  describeLikesDislikes:'опишите, что вам нравится или не нравится в нашем сервисе',
  youCantMentionReview:'вы не можете упомянуть в своем отзыве на Google Maps, что вы получаете бесплатные бонусы за лайки за это',
  yourReviewHasStayAlive:'ваш отзыв на Google Maps должен оставаться в силе не менее 2 месяцев',
  youCanUseYour:'вы можете использовать свой ',
  youCanMakeOnlyOneYouTubeVideo:'Вы можете сделать только 1 видео на YouTube на одного пользователя.',
  youCanWriteOnlyOneForumPost:'Вы можете написать только один пост на форуме хорошего качества, чтобы получить бонус.',
  youCanWriteOnlyOneFacebookPost:'Вы можете написать только один пост в facebook хорошего качества, чтобы получить бонус.',
  youCanWriteOnlyOneReviewOnGoogleMaps:'Вы можете написать только один отзыв на Google Maps хорошего качества, чтобы получить бонус.',
  pleaseEmail:'Пожалуйста, напишите ',
  usLinkToYourPost:' нам ссылку на ваш пост с заголовком "Бонус за пост", мы его рассмотрим, и если он соответствует всем требованиям, вы получите свой бонус.',
  usLinkToYourVideo:' нам ссылку на ваше видео с названием "Видеообзор YouTube", мы его проверим, и если оно соответствует всем требованиям, вы получите свой бонус.',
  usLinkToYourReview:' нам ссылку на ваш отзыв на картах Google с заголовком "Отзыв на картах Google", мы его рассмотрим, и если он соответствует всем требованиям, вы получите свой бонус.',
  referralLink:'Реферальная ссылка',
};

export default bonusProgram;
