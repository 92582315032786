import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { AxiosRequestConfig } from 'axios';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { domainUrl } from '@/core/config/DomainConfig';
import { ErrorGetters } from '@/store/enums/ErrorGettersEnums';
import { Getters } from '@/store/enums/GettersEnums';

@Module({ name: 'MyProfileModule' })
export default class MyProfileModule extends VuexModule implements StoreModel {

  errors = {};
  constants = [];
  userData = {};
  emailTemplates = [];
  emailTemplatesList = [];

  /**
     * Get user data
     * @returns array
     */
  get [Getters.GET_USER]() {
    return this.userData;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [ErrorGetters.GET_ERRORS_MY_PROFILE]() {
    return this.errors;
  }

  /**
     * Get current Transaction object
     * @returns object
     */
  get [Getters.GET_ALL_CONSTANTS]() {
    return this.constants;
  }

  /**
     * Get  paysystems
     * @returns object
     */
  get [Getters.GET_USER_EMAIL_TEMPLATE]() {
    return this.emailTemplates;
  }

  get [Getters.GET_USER_EMAIL_TEMPLATE_LIST]() {
    return this.emailTemplatesList;
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.errors = {};
    this.constants = [];
    this.emailTemplates = [];
    this.userData = {};
    this.emailTemplatesList = [];
  };

    @Mutation
    [Mutations.SET_USER_DATA](payload) {
      this.userData = payload;
    }

    @Mutation
    [Mutations.SET_ERROR_MY_PROFILE](error) {
      this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_CONSTANTS](payload) {
      this.constants = payload;
    }

    @Mutation
    [Mutations.SET_USER_EMAIL_TEMPLATES](payload) {
      this.emailTemplates = payload;
    }

    @Mutation
    [Mutations.SET_USER_EMAIL_TEMPLATES_LIST](payload) {
      this.emailTemplatesList = payload;
    }

    @Action
    [Actions.GET_CONSTANTS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}constants`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CONSTANTS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_MY_PROFILE, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_USER_DATA](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}users/profile`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER_DATA, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_MY_PROFILE, response?.data?.errors);
        });
    }

    @Action
    [Actions.POST_USER_DATA](data) {
      return ApiService.post(`${domainUrl}users/profile`, data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_MY_PROFILE, response?.data?.errors);
        });
    }

    @Action
    [Actions.POST_USER_DATA_IMAGE](data) {
      return ApiService.fileUpload(`${domainUrl}users/profile/image`, data)
        .then(({ data }) => {
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_MY_PROFILE, response?.data?.errors);
        });
    }

    @Action
    [Actions.DELETE_USER_DATA_IMAGE](data) {
      return ApiService.post(`${domainUrl}users/profile/image/delete`, data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR_MY_PROFILE, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_MY_PROFILE, response?.data?.errors);
        });
    }

    @Action
    [Actions.CHANGE_AUTO_REFRESH_CREDITS](data) {
      return ApiService.post(`${domainUrl}users/api-credits/change-auto-refresh`, data)
        .then(({ data }) => {
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_MY_PROFILE, response?.data?.errors);
        });
    }

    @Action
    [Actions.BUY_API_CREDITS](data) {
      return ApiService.post(`${domainUrl}users/api-credits/buy-tokens`, data)
        .then(({ data }) => {
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_MY_PROFILE, response?.data?.errors);
        });
    }

    @Action
    [Actions.RESET_API_TOKEN](data) {
      return ApiService.post(`${domainUrl}users/profile/change-api-token`, data)
        .then(({ data }) => {
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_MY_PROFILE, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_USER_EMAIL_TEMPLATES](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}users/profile/email-templates`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER_EMAIL_TEMPLATES, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_MY_PROFILE, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_USER_EMAIL_TEMPLATES_LIST](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}users/profile/email-templates/list`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER_EMAIL_TEMPLATES_LIST, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_MY_PROFILE, response?.data?.errors);
        });
    }

    @Action
    [Actions.UPDATE_USER_EMAIL_TEMPLATES](id) {
      return ApiService.update(`${domainUrl}users/profile/email-templates`, id, {})
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR_MY_PROFILE, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_MY_PROFILE, response?.data?.errors);
        });
    }
}
