const apiSupport = {
  atKeyappTop: "At Keyapp.top, we understand that technology evolves, and so do the needs of our customers. That's why we offer two distinct versions of our API to cater to different preferences and requirements: the Legacy API and the Latest API. Whether you're a long-time user or just getting started, we've got you covered.",
  legacyAPI: 'Legacy API',
  legacyDocumentation: 'Legacy API Documentation',
  forThoseWhoValueStability: 'For those who value stability and are familiar with our tried-and-true features, our Legacy API is your trusted companion. ',
  latestAPI: 'Latest API',
  latestDocumentation: 'Latest API Documentation',
  ourLatestAPI: "Our Latest API is designed for innovators and forward-thinkers. It's the gateway to our latest features, improvements, and cutting-edge technologies. ",
  apiTokensAreUsed: '{icon} API tokens are used to make paid API calls, such as Rank check or, Rank history. Each <strong>paid</strong> call has its cost, with an average of <strong>1-2</strong> credits per call. Tokens are automatically renewed at the beginning of each month (<strong>{packSize}</strong> tokens). If you need more tokens, you can purchase them for about <strong>${packPrice}</strong> per <strong>{packSize}</strong> tokens. Set the "Auto refresh tokens" checkbox to automatically purchase more tokens when your token balance is low. ',
  apiTokens: 'API tokens',
  successChangeAutoRefreshCredits: 'Credits auto refresh successfully changed',
  successBuyCredits: 'Credits successfully bought',
  successTokenReset: 'Api token successfully refreshed',
};
export default apiSupport;
