import i18n from '@/core/plugins/i18n';

export default function translateI18() {
  /**
     * it's ugly but we can use this to translate strings inside js code
     * @param key
     * @param params
     */
  const translate = (key: string, params?):string => {
    if (!key) {
      return '';
    }
    if (i18n.global.t(key)) {
      return i18n.global.t(key,params);
    } else {
      return key;
    }
  };

  return {
    translate,
  };
}

