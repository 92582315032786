import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { AxiosRequestConfig } from 'axios';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { MyCampaignsModel } from '@/assets/ts/_utils/models/MyCampaignsModel';
import { domainUrlAdmin } from '@/core/config/DomainConfig';
import { CpiAccountsModel } from '@/assets/ts/_utils/models/CpiAccountsModel';
import { ErrorGetters } from '@/store/enums/ErrorGettersEnums';
import { Getters } from '@/store/enums/GettersEnums';

@Module({ name: 'AdminUserOrderModule' })
export default class AdminUserOrderModule extends VuexModule implements StoreModel {
  errors = {};
  userOrders = [] as MyCampaignsModel[];
  totalUserOrders = 0;
  userOrdersCounts = {};
  userOrdersLastPage = 1;
  userOrdersLoaded = [] as MyCampaignsModel[];
  cpiAccounts = [] as CpiAccountsModel[];
  userOrdersPartners = [];
  userOrdersCurrent = {} as MyCampaignsModel;
  userOrdersCurrentPage = 1;
  updateUserOrders = Date.now();
  beforeChangeStatusUserOrders = {} as MyCampaignsModel;
  queryParams = {};
  export = [];
  userOrdersStatuses = [];
  afterChangeObject = {};
  filterStatuses = 0;
  columnSearchFilter = 'uuid';


  get [Getters.GET_USER_ORDERS_COLUMN_SEARCH_FILTER]() {
    return this.columnSearchFilter;
  }

  get [Getters.GET_USER_ORDERS_AFTER_CHANGE_OBJECT]() {
    return this.afterChangeObject;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [ErrorGetters.GET_ERRORS_USER_ORDERS]() {
    return this.errors;
  }

  /**
     * Get  userOrders
     * @returns object
     */
  get [Getters.GET_USER_ORDERS_COUNT]() {
    return this.userOrdersCounts;
  }

  /**
     * Get  userOrders
     * @returns array
     */
  get [Getters.GET_USER_ORDERS_DATA]() {
    return this.userOrders;
  }

  /**
     * Get userOrders archived
     * @returns number
     */
  get [Getters.GET_TOTAL_USER_ORDERS]() {
    return this.totalUserOrders;
  }

  /**
     * Get userOrders archived
     * @returns number
     */
  get [Getters.GET_USER_ORDER_LAST_PAGE]() {
    return this.userOrdersLastPage;
  }

  /**
     * Get campaigns archived
     * @returns number
     */
  get [Getters.GET_CURRENT_USER_ORDER_PAGE]() {
    return this.userOrdersCurrentPage;
  }

  /**
     * Get campaigns archived
     * @returns object
     */
  get [Getters.GET_CURRENT_USER_ORDER]() {
    return this.userOrdersCurrent;
  }

  /**
     * Get cpi accounts
     * @returns object
     */
  get [Getters.GET_DATA_CPI_ACCOUNTS]() {
    return this.cpiAccounts;
  }

  /**
     * Get loaded campaigns
     * @returns object
     */
  get [Getters.GET_LOADED_USER_ORDERS]() {
    return this.userOrdersLoaded;
  }

  /**
     * Get loaded campaign partners
     * @returns object
     */
  get [Getters.GET_USER_ORDER_PARTNERS]() {
    return this.userOrdersPartners;
  }

  /**
     * Get update campaigns
     * @returns boolean
     */
  get [Getters.GET_UPDATE_USER_ORDER]() {
    return this.updateUserOrders;
  }

  /**
     * Get update campaigns
     * @returns boolean
     */
  get [Getters.GET_USER_ORDER_QUERY_PARAMS]() {
    return this.queryParams;
  }

  /**
     * Get update campaigns
     * @returns boolean
     */
  get [Getters.GET_USER_ORDER_EXPORT]() {
    return this.export;
  }


  get [Getters.GET_USER_ORDERS_STATUSES]() {
    return this.userOrdersStatuses;
  }

  get [Getters.GET_FILTER_STATUSES]() {
    return this.filterStatuses;
  }


    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.errors = {};
    this.userOrders = [];
    this.totalUserOrders = 0;
    this.userOrdersCounts = {};
    this.userOrdersLastPage = 1;
    this.userOrdersLoaded = [];
    this.userOrdersPartners = [];
    this.userOrdersCurrent = {} as MyCampaignsModel;
    this.userOrdersCurrentPage = 1;
    this.updateUserOrders = Date.now();
    this.queryParams = {};
    this.export = [];
    this.userOrdersStatuses = [];
    this.filterStatuses = 0;
    this.columnSearchFilter = '';
    this.afterChangeObject = {};
  };


    @Mutation
    [Mutations.SET_USER_ORDER_COUNTS](payload) {
      this.userOrdersCounts = payload;
    }

    @Mutation
    [Mutations.SET_ERROR_USER_ORDER](error) {
      this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_USER_ORDER](userOrders) {
      this.userOrders = userOrders;
    }

    @Mutation
    [Mutations.SET_USER_ORDER_TOTAL](payload) {
      this.totalUserOrders = payload;
    }

    @Mutation
    [Mutations.SET_USER_ORDER_LAST_PAGE](payload) {
      this.userOrdersLastPage = payload;
    }

    @Mutation
    [Mutations.SET_LOADED_USER_ORDERS](payload) {
      this.userOrdersLoaded = payload;
    }

    @Mutation
    [Mutations.SET_UPDATE_USER_ORDER](payload) {
      this.updateUserOrders = payload;
    }

    @Mutation
    [Mutations.SET_CURRENT_USER_ORDER](payload) {
      this.userOrdersCurrent = payload;
    }

    @Mutation
    [Mutations.SET_CPI_ACCOUNTS](payload) {
      this.cpiAccounts = payload;
    }

    @Mutation
    [Mutations.SET_USER_ORDER_CURRENT_PAGE](payload) {
      this.userOrdersCurrentPage = payload;
    }

    @Mutation
    [Mutations.SET_USER_ORDER_AFTER_CHANGE_OBJECT](payload) {
      this.afterChangeObject = payload;
    }

    @Mutation
    [Mutations.SET_BEFORE_CHANGE_STATUS_USER_ORDER](beforeChangeStatusCampaign) {
      this.beforeChangeStatusUserOrders = beforeChangeStatusCampaign;
      this.userOrders = this.userOrders?.map((el) => {
        if (el.id === this.beforeChangeStatusUserOrders.id) {
          return this.beforeChangeStatusUserOrders;
        }
        return el;
      });
    }

    @Mutation
    [Mutations.UPDATE_LOADED_USER_ORDER](payload) {
      const foundOrder = this.userOrdersLoaded.find(userOrder => userOrder.id === payload.id);
      if (foundOrder) {
        const index = this.userOrdersLoaded.indexOf(foundOrder);
        this.userOrdersLoaded[index] = payload;
      }
    }

    @Mutation
    [Mutations.SET_LOADED_USER_ORDER](payload) {
      this.userOrdersLoaded = payload;
    }

    @Mutation
    [Mutations.SET_USER_ORDER_QUERY_PARAMS](payload) {
      this.queryParams = payload;
    }

    @Mutation
    [Mutations.SET_USER_ORDER_EXPORT](payload) {
      this.export = payload;
    }


    @Mutation
    [Mutations.SET_USER_ORDERS_STATUSES](payload) {
      this.userOrdersStatuses = payload;
    }

    @Mutation
    [Mutations.SET_FILTER_STATUSES](payload) {
      this.filterStatuses = payload;
    }


    @Mutation
    [Mutations.SET_USER_ORDER_COLUMN_SEARCH_FILTER](payload) {
      this.columnSearchFilter = payload;
    }

    @Action
    [Actions.GET_USER_ORDERS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}campaigns`, config)
        .then(({ data }) => {

          this.context.commit(Mutations.SET_USER_ORDER_COUNTS, data.counts);
          this.context.commit(Mutations.SET_USER_ORDER_TOTAL, data.meta.total);
          this.context.commit(Mutations.SET_USER_ORDER_LAST_PAGE, data.meta.last_page);
          this.context.commit(Mutations.SET_USER_ORDER, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_ORDER, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_USER_ORDER_STATUS_HISTORY](params) {
      if (!params.campaignId) return;
      return ApiService.post(`${domainUrlAdmin}campaigns/${params.campaignId}/status-history`, params)
        .then(({ data }) => {

          this.context.commit(Mutations.SET_USER_ORDERS_STATUSES, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_ORDER, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_USER_ORDERS_EXPORT](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}campaigns`, config)
        .then(({ data }) => {

          this.context.commit(Mutations.SET_USER_ORDER_EXPORT, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_ORDER, response?.data?.errors);
        });
    }

    @Action
    [Actions.UPDATE_USER_ORDER](id) {
      const config: AxiosRequestConfig = {};
      if (!id) return;
      return ApiService.put(`${domainUrlAdmin}campaigns/${id}`, config)
        .then(({ data }) => {

          this.context.commit(Mutations.SET_CURRENT_USER_ORDER, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_ORDER, response?.data?.errors);
        });
    }

    @Action
    [Actions.SET_CAMPAIGN_PARTNER](params) {
      const { id, ...data } = params;
      if (!id) return;
      return ApiService.post(`${domainUrlAdmin}campaigns/${id}/partners`, data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR_USER_ORDER, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_ORDER, response?.data?.errors);
        }).finally(() => {
          // this.context.commit(Mutations.SET_LOADING, false);
        });
    }

    @Action
    [Actions.GET_USER_ORDER_PROGRESS](params) {
      const { id, ...data } = params;
      if (!id) return;
      return ApiService.post(`${domainUrlAdmin}campaigns/${id}/progress`, data)
        .then(({ data }) => {

          this.context.commit(Mutations.SET_USER_ORDER_AFTER_CHANGE_OBJECT, data.data);
          this.context.commit(Mutations.SET_ERROR_USER_ORDER, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_ORDER, response?.data?.errors);
        });
    }


    @Action
    [Actions.GET_CPI_ACCOUNTS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}campaigns/cpi-accounts`, config)
        .then(({ data }) => {

          this.context.commit(Mutations.SET_CPI_ACCOUNTS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_ORDER, response?.data?.errors);
        });
    }

    @Action
    [Actions.CURRENT_USER_ORDER](id) {
      if (!id) return;
      return ApiService.get(`${domainUrlAdmin}campaigns/${id}`)
        .then(({ data }) => {

          this.context.commit(Mutations.SET_CURRENT_USER_ORDER, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_ORDER, response?.data?.errors);
        });
    }

    @Action
    [Actions.START_USER_ORDER](values) {
      const [id, params] = values;
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params ?? {}
      };
      if (!id) return;
      return ApiService.query(`${domainUrlAdmin}campaigns/${id}/start`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_BEFORE_CHANGE_STATUS_USER_ORDER, data.data);
        })
        .catch(({ response }) => {
          const defaultError = 'Please top-up the balance to start the campaign';
          if (!response?.data?.errors) {
            this.context.commit(Mutations.SET_ERROR_USER_ORDER, defaultError);
          }
          else {
            this.context.commit(Mutations.SET_ERROR_USER_ORDER, response?.data?.errors);
          }
        });
    }

    @Action
    [Actions.REJECTED_USER_ORDER](id) {
      if (!id) return;
      return ApiService.get(`${domainUrlAdmin}campaigns/${id}/rejected`)
        .then(({ data }) => {

          this.context.commit(Mutations.SET_BEFORE_CHANGE_STATUS_USER_ORDER, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_ORDER, response?.data?.errors);
        });
    }

    @Action
    [Actions.PAUSE_USER_ORDER](id) {
      if (!id) return;
      return ApiService.get(`${domainUrlAdmin}campaigns/${id}/pause`)
        .then(({ data }) => {

          this.context.commit(Mutations.SET_BEFORE_CHANGE_STATUS_USER_ORDER, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_ORDER, response?.data?.errors);
        });
    }

    @Action
    [Actions.CANCEL_USER_ORDER](id) {
      if (!id) return;
      return ApiService.get(`${domainUrlAdmin}campaigns/${id}/cancel`)
        .then(({ data }) => {

          this.context.commit(Mutations.SET_BEFORE_CHANGE_STATUS_USER_ORDER, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_ORDER, response?.data?.errors);
        });
    }

    @Action
    [Actions.RESUME_USER_ORDER](id) {
      if (!id) return;
      return ApiService.get(`${domainUrlAdmin}campaigns/${id}/resume`)
        .then(({ data }) => {

          this.context.commit(Mutations.SET_BEFORE_CHANGE_STATUS_USER_ORDER, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_ORDER, response?.data?.errors);
        });
    }

    @Action
    [Actions.ARCHIVE_USER_ORDER](id) {
      if (!id) return;
      return ApiService.get(`${domainUrlAdmin}campaigns/${id}/archive`)
        .then(({ data }) => {

          this.context.commit(Mutations.SET_BEFORE_CHANGE_STATUS_USER_ORDER, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_ORDER, response?.data?.errors);
        });
    }

    @Action
    [Actions.SEND_FOR_MODERATION](id) {
      if (!id) return;
      return ApiService.get(`${domainUrlAdmin}campaigns/${id}/moderation`)
        .then(({ data }) => {

          this.context.commit(Mutations.SET_BEFORE_CHANGE_STATUS_USER_ORDER, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_ORDER, response?.data?.errors);
        });
    }

    @Action
    [Actions.FORCED_COMPLETION](id) {
      if (!id) return;
      return ApiService.get(`${domainUrlAdmin}campaigns/${id}/forced-completion`)
        .then(({ data }) => {

          this.context.commit(Mutations.SET_BEFORE_CHANGE_STATUS_USER_ORDER, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_ORDER, response?.data?.errors);
        });
    }
    @Action
    [Actions.FORCED_DRAFT](id) {
      if (!id) return;
      return ApiService.get(`${domainUrlAdmin}campaigns/${id}/forced-draft`)
        .then(({ data }) => {

          this.context.commit(Mutations.SET_BEFORE_CHANGE_STATUS_USER_ORDER, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_USER_ORDER, response?.data?.errors);
        });
    }
}
