const statistics = {
  totalByProduct: 'Total by product',
  totalKeywords: 'Total keywords',
  totalCampaigns: 'Total campaigns',
  date: 'Date',
  byKeyword: 'By keyword',
  byPackage: 'By package',
  rates: 'Rates',
  reviews: 'Reviews',
  HQReviews: 'HQ Reviews',
  keyword: 'Keyword',
  installs: 'Installs',
  rank: 'Rank',
  export: 'Export',
  installsByDate: 'Installs by date',
  installsByKeyword: 'Installs by keyword',
  noInstallsFound: 'No installs found',
  howManyInstallsDoYouSellMonthly: 'How many installs do you sell monthly?',
  accordingToGooglePlayConsole: 'According to Google Play Console: daily data is reported in PST timezone (UTC -9:00), hourly - in local browser timezone (UTC +2:00) , all revenue is reported in UTC',
  id: 'ID',
  type: 'Type',
  country: 'Country',
  days: 'Days',
  price: 'Price',
  startTime: 'Start time',
  status: 'Status',
  campaigns: 'Campaigns',
  noCampaignsFound: 'No campaigns found',
  overallInstalls: 'Overall installs',
  overallSpendings: 'Overall spendings',
  overallKeywords: 'Overall keywords',
  overallCampaigns: 'Overall campaigns',
  statisticByKeywords: 'Statistic by keywords',
  campaignsAndSpendings: 'Campaigns and spendings',
  totalSpend: 'Total spend',
  totalInstalls: 'Total installs',
  reportByDate: 'Report by date',
  results: 'results',
  of: 'of',
  noReportsFound: 'No reports found',
  spendings: 'Spendings',
  spend: 'Spend',
  reportByKeyword: 'Report by keyword',
  cumulativeReport: 'Cumulative report',
  spentFor: 'Spent for',
  till: 'till',
  reportFor: 'Report for',
  reportForAllTime: 'Report for all time',
};

export default statistics;
