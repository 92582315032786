enum PricePlans {
    // constant
    UNDEFINED = 0,
    PACKAGE = 1,
    KEYWORD = 2,
    RATE = 6,
    REVIEW = 7,
    CPI = 4,
    HQReview = 5,
}

enum PricePlansText {
    UNDEFINED = 'undefined',
    PACKAGE = 'Package',
    KEYWORD = 'Keyword',
    RATE = 'Rate',
    REVIEW = 'Review',
    CPI = 'CPI',
    HQReview = 'HQ Review',
    BASE = 'Base',
    BRONZE = 'Bronze',
    SILVER = 'Silver',
    GOLD = 'Gold',
    PLATINUM = 'Platinum',
    DIAMOND = 'Diamond',
    VIP = 'VIP',
    RESELLER_BASE = 'Reseller Base',
    RESELLER_GOLD = 'Reseller Gold',
}

enum PricePlansIcons {
    PACKAGE = 'fad fa-arrow-alt-to-bottom',
    KEYWORD = 'fad fa-key',
    REVIEW = 'fas fa-comments-alt',
    RATE = 'fas fa-star',
}

enum PricePlansActiveStatus {
    BASE = 1,
    BRONZE = 1,
    SILVER = 1,
    GOLD = 1,
    PLATINUM = 1,
    DIAMOND = 0,
    VIP = 0,
    RESELLER_BASE = 1,
    RESELLER_GOLD = 1,
}

export { PricePlans, PricePlansText, PricePlansIcons, PricePlansActiveStatus };
