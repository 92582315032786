const role = {
  over: 'Más de {total} miembros',
  clickAdd: 'Haga clic para agregar un rol',
  newRole: 'Nuevo rol',
  name: 'Nombre',
  actions: 'Comportamiento',
  check: 'Controlar',
  nameModule: 'Módulo de nombre',
  enterName: 'Ingrese su nombre',
  enterGuardName: 'Ingrese el nombre del guardia',
  inputName: 'Por favor ingrese el nombre',
  inputGuardName: 'Ingrese el nombre del guardia',
  guardName: 'Nombre de guardia',
  nameRole: 'Nombre Role',
  created: 'Crear Role',
  updated: 'Actualizar Role',
  rolePermissionByModules: 'Permisos de rol por módulos',
  specifyName: 'Especifique un nombre de destino para uso futuro y referencia',
  specifyGuardName: 'Especifique un nombre de protección de destino para uso futuro y referencia',

};
export default role;
