const myCampaignsPage = {
  notFoundCampaigns: 'Кампанії не знайдено',
  header: {
    toolbar: {
      all: 'Всі',
      active: 'Активний',
      pause: 'Зупинені',
      draft: 'Чернетка',
      failed: 'Не вдалося',
      completed: 'Виконано',
      canceled: 'Скасовано',
      archived: 'Архівовано'
    }
  },
  table: {
    myCampaigns: 'Мої кампанії',
    showing: 'Показ',
    campaignsOf: 'кампанії',
    found: 'Знайдено',
    campaignFrom: 'кампанія(и) від',
    nothingFoundBySearch: 'Пошуком нічого не знайдено',
    noCampaignsFound: 'Кампанії не знайдено',
    noOrdersFound: 'Замовлень не знайдено',
    showAll: 'показати всі',
    showDetails: 'показати деталі',
    details: 'деталі',
    spreadInstallsFor24h: 'Розповсюдження установок за 24 години',
    showReviews: 'Показати відгуки',
    promotionRuleStopsPromotionIfRankReachedRequiredLevel: 'Правило підвищення зупиняє просування, якщо ранг досягнув необхідного рівня',
    installsDeliveryType: 'Встановлює тип доставки',
    vendor: 'Постачальник',
    changeVendor: 'Змінити постачальника'
  },
  tableDropdown: {
    quickActions: 'Меню швидких дій',
    refresh: 'Оновити',
    exportCampaigns: 'Експортувати кампанії',
    archiveAll: 'Архівувати все'
  },
  actionsDropdown: {
    start: 'Почати',
    pause: 'Пауза',
    cancel: 'Скасувати',
    archive: 'Архів',
    edit: 'Редагувати',
    resume: 'Відновити',
    details: 'Деталі',
    repeat: 'Повторіть',
    reject: 'Відхилити',
    update: 'Оновити',
    moderation: 'Відправити на модерацію',
    forcedCompletion: 'Примусова добудова',
    forcedDraft: 'Примусова Чернетка',
  },
  modalReview: {
    title: 'Мої відгуки про кампанію',
    exportReviews: 'Експорт оглядів'
  }

};
export default myCampaignsPage;
