const apps = {
  appToolbar: {
    all:'All',
    android:'Android',
    ios:'iOS',
    favorite:'Favorite',
    banned:'Banned',
    allApps:'All apps',
    googlePlayApps:'Google Play apps',
    appStoreApps:'App Store apps',
    favoriteApps:'Favorite apps',
    bannedApps:'Banned apps',
  },
  appList: {
    addNewApp:'Add new app',
    shown:'Shown',
    appsFrom:'apps from',
    added:'added',
    found:'Found',
    applicationsBySearch:'applications by search',
    nothingFoundBySearch:'Nothing found by search',
    startWithAddingNewApplication:'Start with adding new application',
  },
  appItem: {
    unavailable:'Unavailable',
    promote:'Promote',
    createKeywordsCampaign:'Create keywords campaign',
    createDirectInstallsCampaign:'Create direct installs campaign',
    createReviewCampaign:'Create review campaign',
    createCpiCampaign:'Create CPI campaign',
    addKeywords:'Add keywords',
    refreshApp:'Refresh app',
    deleteApp:'Delete app',
    applicationId:'Application id',
    downloads:'Downloads',
    category:'Category',
    contentRating:'Content rating',
  },
  appstoreToggle: {
    store:'Store',
    googlePlay:'Google Play',
    appStore:'App Store',
  },
  countrySelect: {
    country:'Country',
  },
  appSelect: {
    pleaseEnterApplicationPackage:'Please enter application package, trackid or store URL',
    beforeAddingNewApplication:'Before adding new application, please check',
    appIsFree:'app is FREE (we can\'t promote paid apps)',
    appIsAvailableInSelectedCountry:'app is available in selected country for large number of devices',
    appDoesntRequireSystemPermissions:'app doesn\'t require any special system permissions (like Root or NFC)',
  },
  appDropdown: {
    quickActions:'Quick actions',
    update:'Update',
    updating:'Updating...',
    delete:'Delete',
    myKeywords:'My keywords',
    statistics:'Statistics',
    externalAnalytics:'External Analytics',
    showInAppfollow:'Show in Appfollow',
    showInAsoMobile:'Show in AsoMobile',
    showInAsoTools:'Show in AsoTools',
  }
};
export default apps;
