const keywords = {
  keywordHeader: {
    addKeyword: 'Agregar palabra clave',
    importKeywordsFromFile: 'Importar palabras clave desde un archivo'
  },
  keywordToolbar: {
    googlePlayApps: 'Google Play aplicaciones',
    appStoreApps: 'App Store aplicaciones'
  },
  appKeywordSelect: {
    selectYourApp: 'Seleccione su aplicación',
    pleaseEnterApplicationPackage: 'Ingrese el paquete de la aplicación, el ID de seguimiento o la URL de la tienda',
    beforeAddingNewApplication: 'Antes de agregar una nueva aplicación, verifique',
    appIsFree: 'la aplicación es GRATUITA (no podemos promocionar aplicaciones pagas)',
    appIsAvailableInSelectedCountry: 'la aplicación está disponible en el país seleccionado para una gran cantidad de dispositivos',
    appDoesntRequireSystemPermissions: 'la aplicación no requiere ningún permiso especial del sistema (como Root o NFC)'
  },
  createKeywordModal: {
    addKeywords: 'Añadir palabras clave',
    addNewKeyword: 'Agregar nueva palabra clave',
    pleaseAddKeyword: 'Agregue palabras clave para la promoción',
    addKeywordsByPressingEnter: 'Por ejemplo, araña, juego de ajedrez o aplicación increíble, agregue palabras clave una por una presionando Entrar',
    dontAddQuotesSlashesHashtagsToKeywords: 'No agregue comillas, barras, etiquetas o símbolos especiales a sus palabras clave.',
    add: 'Agregar'
  },
  keywordChart: {
    keywordRankHistory: 'Historial de clasificación de palabras clave',
    threeMonths: '3 Meses',
    noHistory: 'No hay datos históricos disponibles',
    month: 'Mes',
    week: 'Semana'
  },
  keywordChartDropdown: {
    quickActions: 'Acciones rápidas',
    refreshChart: 'Actualizar'
  },
  keywordTable: {
    myKeywords: 'Mis palabras clave',
    selected: 'Seleccionado',
    keywordsOf: 'palabras clave de',
    found: 'Encontrado',
    keywordFrom: 'palabras clave de',
    nothingFoundBySearch: 'Nada encontrado por búsqueda',
    noKeywordsFound: 'No se encontraron palabras clave'
  },
  keywordTableDropdown: {
    quickActions: 'Acciones rápidas',
    exportKeywords: 'Exportar palabras clave',
    updateAllRanks: 'Actualizar todos los rangos',
    deleteKeywords: 'Eliminar palabras clave',
    clearKeywords: 'Borrar palabras clave'
  },
  keywordEasyDataTable: {
    noKeywordsFound: 'No se encontraron palabras clave',
    rank: 'Rango',
    scoreIsSearchPopularityIndex: 'La puntuación es un índice de popularidad de búsqueda clasificado de 1 [malo] a 100 [mejor]. Las palabras clave con mayor puntuación podrían potencialmente generar más tráfico orgánico.',
    estimatedMaximumDailySearches: 'Búsquedas diarias máximas estimadas por esta palabra clave',
    only: 'Solamente',
    keywordsCouldBeSelected: 'se pueden seleccionar palabras clave',
    favorite: 'Favorito',
    promote: 'Promover',
    topApps: 'Mejores aplicaciones',
    suggestedKeywords: 'Palabras clave sugeridas',
    delete: 'Borrar'
  },
  keywordSuggested: {
    noSuggestedKeywordsFound: 'No se encontraron palabras clave sugeridas',
    addSuggestedKeywords: 'Agregar palabras clave sugeridas'
  },
  rank: {
    checkActualRank: 'Comprobar el rango real',
    showRankHistoryChart: 'Mostrar gráfico de historial de clasificación',
    outOfRank: 'Fuera de rango',
    rankError: 'Error de clasificación',
    needClickLoading: 'Necesito hacer clic en cargar',
    rankNotChecked: 'El rango no está marcado',
    lastUpdate: 'Última actualización'
  }
};

export default keywords;
