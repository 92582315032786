const role = {
  over: 'Понад {total} учасників',
  name: 'Назва',
  actions: 'Дії',
  check: 'Перевірте',
  nameModule: 'Назва модуля',
  enterName: 'Введіть назву',
  enterNamespace: 'Введите Пространство имен',
  enterPermissionName: 'Введите Название разрешения',
  permissionName: 'Название разрешения',
  inputNamespace: 'Пожалуйста, введите Пространство имен',
  inputPermissionName: 'Пожалуйста, введите Название разрешения',
  inputName: 'Будьласка введіть назву',
  inputModule: 'Будьласка виберіть модуль',
  created: 'Створення Функції',
  updated: 'Обновлення Функції',
  specifyName: 'Вкажіть цільову назву для подальшого використання та довідок',
  specifyModule: 'Вкажіть цільовий модуль для подальшого використання та використання',
  specifyNamespace: 'Укажите целевое Пространство имен для будущего использования и ссылки',
  specifyPermissionName: 'Укажите целевое Название разрешения для будущего использования и ссылки',
  selectModule: 'Виберіть модуль',
  clickAdd: 'Натисніть, щоб Функцію',
  namespace: 'Простір імен',
  newFunction: 'Нова Функція',
  nameFunction: 'Назва Функції',
};
export default role;
