const priceplans = {
  currentPriceplan:'Plan de precios actual',
  currentPlan:'Plan actual',
  totalPayments:'Pagos totales',
  from:'de',
  to:'a',
  topUpBalance:'Saldo de recarga',
  availablePriceplans:'Planes de precios disponibles',
  apply:'Aplicar',
  upTo:'hasta',
  keyword:'Palabra clave',
  package:'Paquete',
  rate:'Velocidad',
  review:'Revisar',
  HQReview:'HQ Revisar',
  base:'Base',
  bronze:'Bronce',
  silver:'Plata',
  gold:'Oro',
  resselerBase:'Base de revendedores',
  resselerGold:'revendedor de oro',
  android:'android',
  iOS:'iOS',
  noBillingsFound:'No se encontraron facturas',
};

export default priceplans;
