const priceplans = {
  currentPriceplan:'Текущий тарифный план',
  currentPlan:'Текущий план',
  totalPayments:'Всего платежей',
  from:'из',
  to:'до',
  topUpBalance:'Пополнить баланс',
  availablePriceplans:'Доступные тарифные планы',
  apply:'Подать заявление',
  upTo:'вплоть до',
  keyword:'Ключевое слово',
  package:'Пакет',
  rate:'Оценка',
  review:'Обзор',
  HQReview:'HQ Обзор',
  base:'Базовый',
  bronze:'Бронзовый',
  silver:'Серебряный',
  gold:'Золотой',
  resselerBase:'Базовый реселлер',
  resselerGold:'Золотой реселлер',
  android:'android',
  iOS:'iOS',
  noBillingsFound:'Платежи не найдены',
};

export default priceplans;
