import { RolesEnums } from '@/store/enums/RolesEnums';

const dashboard = {
  path: '/',
  redirect: '/dashboard',
  component: () => import('@/layout/Layout.vue'),
  meta: { roles: [RolesEnums.ALL] },
  children: [
    {
      path: '/dashboard',
      name: 'dashboard',
      meta: { roles: [RolesEnums.ALL] },
      component: () => import('@/views/pages/Dashboard.vue')
    }

  ]
};
export default dashboard;
