import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { Getters } from '@/store/enums/GettersEnums';

interface Breadcrumb {
  title: string;
  pageBreadcrumbPath: Array<string>;
}

interface StoreInfo {
  breadcrumbs: Breadcrumb;
}

@Module({ name: 'BreadcrumbsModule' })
export default class BreadcrumbsModule extends VuexModule implements StoreInfo, StoreModel {
  breadcrumbs = {} as Breadcrumb;

  /**
   * Get breadcrumb object for current page
   * @returns object
   */
  get [Getters.GET_BREADCRUMBS](): Breadcrumb {
    return this.breadcrumbs;
  }

  /**
   * Get breadcrumb array for current page
   * @returns object
   */
  get [Getters.PAGE_BREADCRUMB_PATH](): Array<string> {
    return this.breadcrumbs.pageBreadcrumbPath;
  }

  /**
   * Get current page title
   * @returns string
   */
  get [Getters.PAGE_TITLE](): string {
    return this.breadcrumbs.title;
  }

  @Mutation
  [Mutations.CLEAR_STORE]() {
    this.breadcrumbs = {} as Breadcrumb;
  };

  @Mutation
  [Mutations.SET_BREADCRUMB_MUTATION](payload) {
    this.breadcrumbs = payload;
  }

  @Action
  [Actions.SET_BREADCRUMB_ACTION](payload) {
    this.context.commit(Mutations.SET_BREADCRUMB_MUTATION, payload);
  }
}
