const myTransactions = {
  selectDateRage:'Select date rage',
  selectTransactionType:'Select transaction type',
  selectApplication:'Select application',
  export:'Export',
  myTransactions:'My transactions',
  showing:'Showing',
  transactionsOf:'transactions of',
  noTransactionsFound:'No transactions found',
};

export default myTransactions;
