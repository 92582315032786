import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { BlogModel } from '@/assets/ts/_utils/models/BlogModel';
import { GEOsModel } from '@/assets/ts/_utils/models/GEOsModel';
import { TopRegionsModel } from '@/assets/ts/_utils/models/TopRegionsModel';
import { AxiosRequestConfig } from 'axios';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { domainUrl } from '@/core/config/DomainConfig';
import { Getters } from '@/store/enums/GettersEnums';

@Module({ name: 'WidgetsModule' })
export default class WidgetsModule extends VuexModule implements StoreModel {
  errors = {};
  platformNews: BlogModel[] = [];
  FAQs: BlogModel[] = [];
  GEOs: GEOsModel = {};
  topRegions: TopRegionsModel = {};
  myApps = [];
  campaignsWidgets = {};
  campaignsData = [];
  pinnedNotification = {};

  get [Getters.GET_WIDGETS_ERRORS]() {
    return this.errors;
  }

  get [Getters.GET_NEWS]() {
    return this.platformNews;
  }

  get [Getters.GET_ALL_FAQS]() {
    return this.FAQs;
  }

  get [Getters.GET_ALL_GEOS]() {
    return this.GEOs;
  }

  get [Getters.GET_ALL_TOP_REGIONS]() {
    return this.topRegions;
  }

  get [Getters.GET_MY_ALL_APPS]() {
    return this.myApps;
  }

  get [Getters.GET_ALL_CAMPAIGNS_WIDGETS]() {
    return this.campaignsWidgets;
  }

  get [Getters.GET_ALL_CAMPAIGNS_DATA]() {
    return this.campaignsData;
  }

  get [Getters.GET_PINNED_NOTIFICATION_DATA]() {
    return this.pinnedNotification;
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.errors = {};
    this.platformNews = [];
    this.FAQs = [];
    this.GEOs = {};
    this.topRegions = {};
    this.myApps = [];
    this.campaignsWidgets = {};
    this.campaignsData = [];
    this.pinnedNotification = {};
  };

    @Mutation
    [Mutations.SET_ERROR_WIDGETS](error) {
      this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_PLATFORM_NEWS](payload) {
      this.platformNews = payload;
    }

    @Mutation
    [Mutations.SET_FAQS](payload) {
      this.FAQs = payload;
    }

    @Mutation
    [Mutations.SET_GEOS](payload) {
      this.GEOs = payload;
    }

    @Mutation
    [Mutations.SET_TOP_REGIONS](payload) {
      this.topRegions = payload;
    }

    @Mutation
    [Mutations.SET_MY_APPS](payload) {
      this.myApps = payload;
    }

    @Mutation
    [Mutations.SET_CAMPAIGNS_WIDGETS](payload) {
      this.campaignsWidgets = payload;
    }

    @Mutation
    [Mutations.SET_CAMPAIGNS_DATA](payload) {
      this.campaignsData = payload;
    }

    @Mutation
    [Mutations.SET_PINNED_NOTIFICATION](payload) {
      this.pinnedNotification = payload;
    }

    @Action
    [Actions.GET_PLATFORM_NEWS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}widgets/blog/internal`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PLATFORM_NEWS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_WIDGETS, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_FAQS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}widgets/blog/external`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_FAQS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_WIDGETS, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_CAMPAIGNS_WIDGETS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}widgets/campaigns`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CAMPAIGNS_DATA, data.data);
          this.context.commit(Mutations.SET_CAMPAIGNS_WIDGETS, data.counts);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_WIDGETS, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_PINNED_NOTIFICATION](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}widgets/blog/pinned`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PINNED_NOTIFICATION, data?.data ?? {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_WIDGETS, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_GEOS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}widgets/country/appstores`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_GEOS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_WIDGETS, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_TOP_REGIONS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}widgets/country/popular`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TOP_REGIONS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_WIDGETS, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_MY_APPS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}widgets/apps/popular`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_MY_APPS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_WIDGETS, response?.data?.errors);
        });
    }
}
