const myProfile = {
  editProfile:'Editar perfil',
  priceplan:'Plan de precios',
  currentPriceplan:'Plan de precio actual',
  totalPayments:'Pagos totales',
  lastPayment:'Ultimo pago',
  profileDetails:'Detalles del perfil',
  nickname:'Apodo',
  fullName:'Nombre completo',
  country:'País',
  businessType:'Tipo de negocio',
  gender:'Género',
  refLink:'Enlace de referencia',
  apiKey:'Clave API',
  show:'Espectáculo',
  hide:'Ocultar',
  contactInformation:'Información del contacto',
  phone:'Teléfono',
  messengers:'Mensajeros',
  email:'Correo electrónico',
  changePassword:'Cambia la contraseña',
  enterYourNickname:'Ingresa tu alias',
  enterYourFullName:'Ingresa tu nombre completo',
  currentPassword:'Contraseña actual',
  enterYourPassword:'Ingresa tu contraseña',
  newPassword:'Nueva contraseña',
  enterYourNewPassword:'Introduzca su nueva contraseña',
  repeatNewPassword:'Repita la nueva contraseña',
  yourMessengerId:'Tu identificación de mensajero',
  profileWasSuccessfullyChanged:'El perfil fue cambiado con éxito',
  fullNameIsNotValid:'El nombre completo no es válido',
  phoneNumberIsNotValid:'El número de teléfono no es válido',
  messengerIdIsNotTalid:'El ID de Messenger no es válido',
};

export default myProfile;
