import { Mutations } from '@/store/enums/StoreEnums';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { Getters } from '@/store/enums/GettersEnums';

@Module({ name: 'AlertModule' })
export default class AlertModule extends VuexModule implements StoreModel {
  status = false;
  type = 'info';
  message = '';
  millisecondsToClose = 3000;
  closeOnlyOnClick = false;
  dashboardStartUnreadNotices = [];

  /**
     * Get alert status
     * @returns boolean
    */
  get [Getters.ALERT_STATUS]() {
    return this.status;
  }

  /**
     * Get alert type
     * @returns string
    */
  get [Getters.ALERT_TYPE]() {
    return this.type;
  }

  /**
     * Get alert message
     * @returns string
    */
  get [Getters.ALERT_MESSAGE]() {
    return this.message;
  }

  /**
     * Get alert milliseconds to close
     * @returns number
    */
  get [Getters.ALERT_MILLISECONDS_TO_CLOSE]() {
    return this.millisecondsToClose;
  }

  /**
     * Get alert close only on click
     * @returns boolean
    */
  get [Getters.ALERT_CLOSE_ONLY_ON_CLICK]() {
    return this.closeOnlyOnClick;
  }

  get [Getters.GET_DASHBOARD_START_UNREAD_NOTICES]() {
    return this.dashboardStartUnreadNotices;
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.status = false;
    this.type = 'info';
    this.message = '';
    this.dashboardStartUnreadNotices = [];
  };

    @Mutation
    [Mutations.SET_ALERT_STATUS](payload: boolean) {
      this.status = payload;
    }

    @Mutation
    [Mutations.SET_ALERT_TYPE](payload: string) {
      this.type = payload;
    }

    @Mutation
    [Mutations.SET_ALERT_MESSAGE](payload) {
      this.message = payload;
    }

    @Mutation
    [Mutations.SET_MILLISECONDS_TO_CLOSE](payload) {
      this.millisecondsToClose = payload;
    }

    @Mutation
    [Mutations.SET_CLOSE_ONLY_ON_CLICK](payload) {
      this.closeOnlyOnClick = payload;
    }

    @Mutation
    [Mutations.SET_DASHBOARD_START_UNREAD_NOTICES](payload) {
      this.dashboardStartUnreadNotices = payload;
    }
}
