import AuthModule from '@/store/modules/AuthModule';
import BodyModule from '@/store/modules/BodyModule';
import BreadcrumbsModule from '@/store/modules/BreadcrumbsModule';
import ConfigModule from '@/store/modules/ConfigModule';
import RoleModule from '@/store/modules/RoleModule';
import PermissionModule from '@/store/modules/PermissionModule';
import CountryModule from '@/store/modules/CountryModule';
import AppModule from '@/store/modules/AppModule';
import KeywordModule from '@/store/modules/KeywordModule';
import AddCampaignsModule from '@/store/modules/AddCampaignsModule';
import TransactionModule from '@/store/modules/TransactionModule';
import PriceplansModule from '@/store/modules/PriceplansModule';
import MyProfileModule from '@/store/modules/MyProfileModule';
import LanguageModule from '@/store/modules/LanguageModule';
import MyCampaignsModule from '@/store/modules/MyCampaignsModule';
import WidgetsModule from '@/store/modules/WidgetsModule';
import StatisticsModule from '@/store/modules/StatisticsModule';
import PaymentSystemModule from '@/store/modules/PaymentSystemModule';
import ManagerModule from '@/store/modules/ManagerModule';
import TopUpBalanceModule from '@/store/modules/TopUpBalanceModule';
import AccessListModule from '@/store/modules/AccessListModule';
import UserListModule from '@/store/modules/UserListModule';
import StartingModule from '@/store/modules/StartingModule';
import UserDetailsPaymentsModule from '@/store/modules/UserDetailsPaymentsModule';
import UserDetailsMessagesModule from '@/store/modules/admin/UserDetailsMessagesModule';
import ReferralModule from '@/store/modules/ReferralModule';
import AdminPaymentSystemModule from '@/store/modules/admin/AdminPaymentSystemModule';
import AdminReflinkModule from '@/store/modules/admin/AdminReflinkModule';
import ContactsModule from '@/store/modules/ContactsModule';
import ErrorModule from '@/store/modules/ErrorModule';
import ResellersProgramModule from '@/store/modules/ResellersProgramModule';
import AdminUserOrderModule from '@/store/modules/admin/AdminUserOrderModule';
import AlertModule from '@/store/modules/AlertModule';
import MarketingActionModule from '@/store/modules/MarketingActionModule';
import NotificationModule from '@/store/modules/NotificationModule';
import AdminVendorModule from '@/store/modules/admin/AdminVendorModule';
import AdminUserPaymentModule from '@/store/modules/admin/AdminUserPaymentModule';
import AdminUserAppModule from '@/store/modules/admin/AdminUserAppModule';
import AdminEmailTemplateModule from '@/store/modules/admin/AdminEmailTemplateModule';
import SalesReportMyClientModule from '@/store/modules/admin/SalesReportMyClientModule';
import SalesReportNewClientModule from '@/store/modules/admin/SalesReportNewClientModule';
import SalesReportStatisticModule from '@/store/modules/admin/SalesReportStatisticModule';
import AdminProductModule from '@/store/modules/admin/AdminProductModule';
import AdminMarketingActionModule from '@/store/modules/admin/AdminMarketingActionModule';
import ClientsReportModule from '@/store/modules/admin/ClientsReportModule';
import UserDetailsTransactionsModule from '@/store/modules/UserDetailsTransactionsModule';
import UserDetailsReferralModule from '@/store/modules/UserDetailsReferralModule';
import GoogleAdsReportModule from '@/store/modules/admin/GoogleAdsReportModule';
import { getModule } from 'vuex-module-decorators';
import store from '@/store';

// --------------------------------- Instances --------------------------------- //
const authModuleInstance = getModule(AuthModule, store);
const bodyModuleInstance = getModule(BodyModule, store);
const breadcrumbsModuleInstance = getModule(BreadcrumbsModule, store);
const configModuleInstance = getModule(ConfigModule, store);
const roleModuleInstance = getModule(RoleModule, store);
const permissionModuleInstance = getModule(PermissionModule, store);
const countryModuleInstance = getModule(CountryModule, store);
const appModuleInstance = getModule(AppModule, store);
const keywordModuleInstance = getModule(KeywordModule, store);
const addCampaignsModuleInstance = getModule(AddCampaignsModule, store);
const myCampaignsModuleInstance = getModule(MyCampaignsModule, store);
const transactionModuleInstance = getModule(TransactionModule, store);
const priceplansModuleInstance = getModule(PriceplansModule, store);
const myProfileModuleInstance = getModule(MyProfileModule, store);
const languageModuleInstance = getModule(LanguageModule, store);
const StatisticsModuleInstance = getModule(StatisticsModule, store);
const PaymentSystemModuleInstance = getModule(PaymentSystemModule, store);
const ManagerModuleInstance = getModule(ManagerModule, store);
const TopUpBalanceModuleInstance = getModule(TopUpBalanceModule, store);
const AccessListModuleInstance = getModule(AccessListModule, store);
const StartingModuleInstance = getModule(StartingModule, store);
const UserDetailsPaymentsModuleInstance = getModule(UserDetailsPaymentsModule, store);
const AdminPaymentSystemModuleInstance = getModule(AdminPaymentSystemModule, store);
const ReferralModuleInstance = getModule(ReferralModule, store);
const AdminReflinkModuleInstance = getModule(AdminReflinkModule, store);
const userListModuleInstance = getModule(UserListModule, store);
const widgetsModuleInstance = getModule(WidgetsModule, store);
const contactsModuleInstance = getModule(ContactsModule, store);
const ErrorModuleInstance = getModule(ErrorModule, store);
const resellersProgramModuleInstance = getModule(ResellersProgramModule, store);
const adminUserOrderModuleModuleInstance = getModule(AdminUserOrderModule, store);
const alertModuleInstance = getModule(AlertModule, store);
const notificationModuleInstance = getModule(NotificationModule, store);
const adminVendorModuleInstance = getModule(AdminVendorModule, store);
const adminUserPaymentModuleInstance = getModule(AdminUserPaymentModule, store);
const adminUserDetailsMessagesModuleInstance = getModule(UserDetailsMessagesModule, store);
const adminUserAppModuleModuleInstance = getModule(AdminUserAppModule, store);
const adminAdminEmailTemplateModuleInstance = getModule(AdminEmailTemplateModule, store);
const adminSalesReportMyClientModule = getModule(SalesReportMyClientModule, store);
const adminSalesReportNewClientModule = getModule(SalesReportNewClientModule, store);
const adminSalesReportStatisticModuleModule = getModule(SalesReportStatisticModule, store);
const adminProductModuleModule = getModule(AdminProductModule, store);
const adminAdminMarketingActionModule = getModule(AdminMarketingActionModule, store);
const marketingActionModule = getModule(MarketingActionModule, store);
const clientsReportModule = getModule(ClientsReportModule, store);
const userDetailsTransactionsModule = getModule(UserDetailsTransactionsModule, store);

const userDetailsReferralModule = getModule(UserDetailsReferralModule, store);

const googleAdsReportModule = getModule(GoogleAdsReportModule, store);

export {
  // ----------- Modules ----------- //
  AuthModule,
  BodyModule,
  BreadcrumbsModule,
  ConfigModule,
  RoleModule,
  PermissionModule,
  CountryModule,
  AppModule,
  KeywordModule,
  AddCampaignsModule,
  TransactionModule,
  PriceplansModule,
  MyProfileModule,
  LanguageModule,
  MyCampaignsModule,
  WidgetsModule,
  StatisticsModule,
  PaymentSystemModule,
  ManagerModule,
  AdminMarketingActionModule,
  TopUpBalanceModule,
  AccessListModule,
  UserListModule,
  StartingModule,
  UserDetailsPaymentsModule,
  ReferralModule,
  AdminPaymentSystemModule,
  AdminReflinkModule,
  ContactsModule,
  ResellersProgramModule,
  ErrorModule,
  AdminUserOrderModule,
  AlertModule,
  NotificationModule,
  AdminVendorModule,
  AdminUserPaymentModule,
  UserDetailsMessagesModule,
  AdminUserAppModule,
  AdminEmailTemplateModule,
  SalesReportMyClientModule,
  SalesReportNewClientModule,
  SalesReportStatisticModule,
  AdminProductModule,
  MarketingActionModule,
  ClientsReportModule,
  UserDetailsTransactionsModule,
  UserDetailsReferralModule,
  GoogleAdsReportModule,
  // ----------- Instances ----------- //
  authModuleInstance,
  bodyModuleInstance,
  breadcrumbsModuleInstance,
  configModuleInstance,
  roleModuleInstance,
  permissionModuleInstance,
  countryModuleInstance,
  appModuleInstance,
  keywordModuleInstance,
  addCampaignsModuleInstance,
  myCampaignsModuleInstance,
  transactionModuleInstance,
  priceplansModuleInstance,
  myProfileModuleInstance,
  languageModuleInstance,
  StatisticsModuleInstance,
  PaymentSystemModuleInstance,
  ManagerModuleInstance,
  TopUpBalanceModuleInstance,
  AccessListModuleInstance,
  StartingModuleInstance,
  UserDetailsPaymentsModuleInstance,
  AdminPaymentSystemModuleInstance,
  ReferralModuleInstance,
  AdminReflinkModuleInstance,
  userListModuleInstance,
  widgetsModuleInstance,
  contactsModuleInstance,
  ErrorModuleInstance,
  resellersProgramModuleInstance,
  adminUserOrderModuleModuleInstance,
  alertModuleInstance,
  notificationModuleInstance,
  adminVendorModuleInstance,
  adminUserPaymentModuleInstance,
  adminUserDetailsMessagesModuleInstance,
  adminUserAppModuleModuleInstance,
  adminAdminEmailTemplateModuleInstance,
  adminSalesReportMyClientModule,
  adminSalesReportNewClientModule,
  adminSalesReportStatisticModuleModule,
  adminProductModuleModule,
  adminAdminMarketingActionModule,
  marketingActionModule,
  clientsReportModule,
  userDetailsTransactionsModule,
  userDetailsReferralModule,
  googleAdsReportModule,
};
