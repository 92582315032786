const role = {
  over: 'Понад {total} учасників',
  clickAdd: 'Натисніть, щоб додати роль',
  newRole: 'Нова роль',
  name: 'Назва',
  actions: 'Дії',
  check: 'Перевірте',
  nameModule: 'Назва модуля',
  enterName: 'Введіть назву',
  enterGuardName: 'Введіть назву охоронця',
  inputName: 'Будьласка введіть назву',
  inputGuardName: 'Будь ласка, введіть назву охоронця',
  guardName: 'Назва охоронця',
  nameRole: 'Назва ролі',
  created: 'Створення ролі',
  updated: 'Обновлення ролі',
  rolePermissionByModules: 'Дозволи на роль за модулями',
  specifyName: 'Вкажіть цільову назву для подальшого використання та довідок',
  specifyGuardName: 'Вкажіть назву захисту цілі для подальшого використання та посилання',
};
export default role;
