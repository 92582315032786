import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { AxiosRequestConfig } from 'axios';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { domainUrlAdmin } from '@/core/config/DomainConfig';
import { ErrorGetters } from '@/store/enums/ErrorGettersEnums';
import { Getters } from '@/store/enums/GettersEnums';

export interface Role {
    name: string;
    guard_name: string;
    permissions: [],
}

export interface RoleInfo {
    errors: unknown;
    role: Role;
    roles: unknown;
    totalPages: unknown;
    isLoading: unknown;
    page: unknown;
    limit: unknown;
}

@Module({ name: 'RoleModule' })
export default class RoleModule extends VuexModule implements RoleInfo, StoreModel {
  errors = {};
  role = {} as Role;
  roles = [];
  totalPages = 0;
  isLoading = false;
  page = 1;
  limit = 10;

  /**
     * Get current Role object
     * @returns Role
     */
  get [Getters.CURRENT_ROLE](): Role {
    return this.role;
  }

  /**
     * Get current Role objects
     * @returns []
     */
  get [Getters.CURRENT_ROLES]() {
    return this.roles;
  }

  /**
     * Get current user object
     * @returns Roles
     */
  get [Getters.CURRENT_TOTAL_PAGES]() {
    return this.totalPages;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [ErrorGetters.GET_ERRORS_ROLE]() {
    return this.errors;
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.errors = {};
    this.role = {} as Role;
    this.roles = [];
    this.totalPages = 0;
    this.isLoading = false;
    this.page = 1;
    this.limit = 10;
  };

    @Mutation
    [Mutations.SET_ERROR_ROLE](error) {
      this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_ROLE](role) {
      this.role = role;
    }

    @Mutation
    [Mutations.SET_ROLES](roles) {
      this.roles = roles;
    }

    @Mutation
    [Mutations.SET_TOTAL_PAGES](totalPages) {
      this.totalPages = totalPages;
    }

    @Mutation
    [Mutations.SET_LOADING](isRolesLoading) {
      this.isLoading = isRolesLoading;
    }

    @Mutation
    [Mutations.SET_PAGE](page) {
      this.page = page;
    }

    @Action
    [Actions.GET_ROLES](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrlAdmin}roles`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TOTAL_PAGES, data.meta.total);
          this.context.commit(Mutations.SET_ROLES, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_ROLE, response?.data?.errors);
        }).finally(() => {
          // this.context.commit(Mutations.SET_LOADING, false);
        });
    }

    @Action
    [Actions.ADD_ROLE](params) {
      return ApiService.post(`${domainUrlAdmin}roles`, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ROLE, data.data);
          this.context.commit(Mutations.SET_ERROR_ROLE, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_ROLE, response?.data?.errors);
        }).finally(() => {
          // this.context.commit(Mutations.SET_LOADING, false);
        });
    }


    @Action
    [Actions.UPDATE_ROLE](params) {
      const { id, ...data } = params;
      return ApiService.update(`${domainUrlAdmin}roles`, id, data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ROLE, data.data);
          this.context.commit(Mutations.SET_ERROR_ROLE, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_ROLE, response?.data?.errors);
        }).finally(() => {
          // this.context.commit(Mutations.SET_LOADING, false);
        });
    }

    @Action
    [Actions.DELETE_ROLE](id) {
      return ApiService.delete(`${domainUrlAdmin}roles/` + id)
        .then(({ data }) => {
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_ROLE, response?.data?.errors);
        }).finally(() => {
          // this.context.commit(Mutations.SET_LOADING, false);
        });
    }

    @Action
    [Actions.GET_ROLE](id) {
      return ApiService.get(`${domainUrlAdmin}roles`, id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ROLE, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_ROLE, response?.data?.errors);
        });
    }
}
