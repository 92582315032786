const dashboard = {
  campaigns:'Campañas',
  leftTo:'De izquierda a',
  myTotalCampaigns:'Mis campañas totales',
  activeCampaigns:'Campañas activas',
  completedCampaigns:'Campañas completadas',
  guarantedReviews:'Reseñas Garantizadas',
  smartCampaign:'Campaña inteligente',
  advertisingCampaign:'Campaña de publicidad',
  improveYourRatesAndApps:'Mejora tus tarifas y la reputación de tus aplicaciones con nuestras reseñas. Obtenga 30 días de garantía para cualquier pedido.',
  cpi:'Campaña de CPI (costo por instalación): es una herramienta que se utiliza para atraer usuarios a su aplicación.',
  geos:'GEOs',
  availableCountriesForPromotion:'Países disponibles para la promoción',
  topRegions:'Principales regiones',
  mostPopularRegionsForPromotion:'Regiones más populares para la promoción',
  lastCampaigns:'Últimas campañas',
  myApps:'Mis aplicaciones',
  platformNews:'Noticias de la plataforma',
  FAQs:'FAQs',
  application:'Solicitud',
  currentPlan:'Plan actual',
  FAQAndASONews:'FAQ & ASO noticias',
  keyappAcademy:'Keyapp Academy',
};

export default dashboard;
