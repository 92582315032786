import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { AxiosRequestConfig } from 'axios';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { domainUrl } from '@/core/config/DomainConfig';
import { Getters } from '@/store/enums/GettersEnums';

@Module({ name: 'TransactionModule' })
export default class TransactionModule extends VuexModule implements StoreModel {

  errors = {};
  transactions = [];
  export = [];
  totalTransactions = 0;
  currentPage = 1;
  lastPage = 1;
  page = 1;
  rowsPerPage = 10;
  appstore = 0;
  type = 0;
  date = [];
  package = '';
  from = '';
  to = '';
  search = '';
  limit = 10;
  sortBy = 'date';
  sortType = 'desc';

  /**
     * Get current page
     * @returns number
     */
  get [Getters.GET_TRANSACTIONS_CURRENT_PAGE]() {
    return this.currentPage;
  }

  /**
     * Get last page
     * @returns number
     */
  get [Getters.GET_TRANSACTIONS_LAST_PAGE]() {
    return this.lastPage;
  }

  /**
     * Get sort by
     * @returns string
     */
  get [Getters.GET_TRANSACTIONS_SORT_BY]() {
    return this.sortBy;
  }

  /**
     * Get sort type
     * @returns string
     */
  get [Getters.GET_TRANSACTIONS_SORT_TYPE]() {
    return this.sortType;
  }

  /**
     * Get page
     * @returns number
     */
  get [Getters.GET_TRANSACTIONS_PAGE]() {
    return this.page;
  }

  /**
     * Get rows
     * @returns number
     */
  get [Getters.GET_TRANSACTIONS_ROWS]() {
    return this.rowsPerPage;
  }

  /**
     * Get type
     * @returns number
     */
  get [Getters.GET_TRANSACTIONS_TYPE]() {
    return this.type;
  }

  /**
     * Get appstore
     * @returns number
     */
  get [Getters.GET_TRANSACTIONS_APPSTORE]() {
    return this.appstore;
  }

  /**
     * Get package
     * @returns string
     */
  get [Getters.GET_TRANSACTIONS_PACKAGE]() {
    return this.package;
  }

  /**
     * Get date
     * @returns string
     */
  get [Getters.GET_TRANSACTIONS_DATE]() {
    return this.date;
  }

  /**
     * Get from
     * @returns string
     */
  get [Getters.GET_TRANSACTIONS_FROM]() {
    return this.from;
  }

  /**
     * Get to
     * @returns string
     */
  get [Getters.GET_TRANSACTIONS_TO]() {
    return this.to;
  }

  /**
     * Get search
     * @returns string
     */
  get [Getters.GET_TRANSACTIONS_SEARCH]() {
    return this.search;
  }

  /**
     * Get limit
     * @returns string
     */
  get [Getters.GET_TRANSACTIONS_LIMIT]() {
    return this.limit;
  }

  /**
     * Get  errors
     * @returns array
     */
  get [Getters.GET_ERRORS_TRANSACTIONS]() {
    return this.errors;
  }

  /**
     * Get current Transaction object
     * @returns array
     */
  get [Getters.ALL_TRANSACTIONS]() {
    return this.transactions;
  }

  /**
     * Get current Transaction object
     * @returns array
     */
  get [Getters.ALL_TRANSACTIONS_EXPORT]() {
    return this.export;
  }

  /**
     * Get current Total Transactions
     * @returns number
     */
  get [Getters.GET_TOTAL_TRANSACTIONS]() {
    return this.totalTransactions;
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.errors = {};
    this.transactions = [];
    this.totalTransactions = 0;
    this.currentPage = 1;
    this.lastPage = 1;
    this.page = 1;
    this.rowsPerPage = 10;
    this.appstore = 0;
    this.type = 0;
    this.date = [];
    this.package = '';
    this.from = '';
    this.to = '';
    this.search = '';
    this.limit = 10;
    this.export = [];
  };

    @Mutation
    [Mutations.SET_TRANSACTIONS_PAGE](payload) {
      this.page = payload;
    }

    @Mutation
    [Mutations.SET_TRANSACTIONS_ROWS](payload) {
      this.rowsPerPage = payload;
    }

    @Mutation
    [Mutations.SET_TRANSACTIONS_SORT_TYPE](payload) {
      this.sortType = payload;
    }

    @Mutation
    [Mutations.SET_TRANSACTIONS_SORT_BY](payload) {
      this.sortBy = payload;
    }

    @Mutation
    [Mutations.SET_TRANSACTIONS_TYPE](payload) {
      this.type = payload;
    }

    @Mutation
    [Mutations.SET_TRANSACTIONS_APPSTORE](payload) {
      this.appstore = payload;
    }

    @Mutation
    [Mutations.SET_TRANSACTIONS_PACKAGE](payload) {
      this.package = payload;
    }

    @Mutation
    [Mutations.SET_TRANSACTIONS_DATE](payload) {
      this.date = payload;
    }

    @Mutation
    [Mutations.SET_TRANSACTIONS_SEARCH](payload) {
      this.search = payload;
    }

    @Mutation
    [Mutations.SET_TRANSACTIONS_LIMIT](payload) {
      this.limit = payload;
    }

    @Mutation
    [Mutations.SET_ERROR_TRANSACTION](error) {
      this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_ALL_TRANSACTIONS](payload) {
      this.transactions = payload;
    }

    @Mutation
    [Mutations.SET_TRANSACTIONS_EXPORT](payload) {
      this.export = payload;
    }

    @Mutation
    [Mutations.SET_TOTAL_TRANSACTIONS](payload) {
      this.totalTransactions = payload;
    }

    @Mutation
    [Mutations.SET_TRANSACTIONS_CURRENT_PAGE](payload) {
      this.currentPage = payload;
    }

    @Mutation
    [Mutations.SET_TRANSACTIONS_LAST_PAGE](payload) {
      this.lastPage = payload;
    }

    @Action
    [Actions.GET_TRANSACTIONS](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}transactions`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TOTAL_TRANSACTIONS, data.meta.total);
          this.context.commit(Mutations.SET_TRANSACTIONS_CURRENT_PAGE, data.meta.current_page);
          this.context.commit(Mutations.SET_TRANSACTIONS_LAST_PAGE, data.meta.last_page);
          this.context.commit(Mutations.SET_ALL_TRANSACTIONS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_TRANSACTION, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_TRANSACTIONS_EXPORT](params) {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}transactions`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TRANSACTIONS_EXPORT, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_TRANSACTION, response?.data?.errors);
        });
    }
}
