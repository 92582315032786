const topUpBalance = {
  topUpBalance: 'Top-up baSaldo de recargalance',
  pleaseEnterPaymentAmount: 'Por favor ingrese un monto de pago',
  depositAmount: 'Cantidad del depósito',
  bonus: 'primo',
  pleaseSelectYourPreferredPaymentSystem: 'Por favor seleccione su sistema de pago preferido',
  addFundsBy: 'Añadir fondos por',
  usdtBitcoinEthereum: 'USDT (anclaje), Bitcoin, Ethereum',
  or: 'o',
  cryptocurrencyPaymentWillBeAutomaticallyAdded: 'Tenga en cuenta que el pago de criptomonedas se agregará automáticamente a su cuenta después de que recibamos 3 o más confirmaciones de transacciones.',
  makePayment: 'Hacer el pago',
  weApplyPaymentBonusForEachSingleTransaction: 'Aplicamos bonificación de pago para cada transacción individual, dependiendo de su monto (excluyendo PayPal):',
  bonusValidFor: 'Bono válido por 120 días',
  moreThan: 'más que',
  forExample: 'Por ejemplo, si paga 2000$ - usted recibirá 2000+200 (10% primo) = 2200$, o si pagas 7000$ - usted recibe 7000+1050 (15% como bono) = 8050$!',
  inCaseOfRefundRequest: '¡En caso de una solicitud de reembolso, le devolveremos el monto de su pago sin todos los bonos!',
  ByAddingFundsToYourAccount: 'Al agregar fondos a su cuenta, automáticamente acepta nuestra',
  refundAndChargebackPolicy: 'Política de reembolso y contracargo.',
  ifYouNeedAnInvoice: 'Si necesita una factura, envíe un correo electrónico a {email} con el monto y los detalles del beneficiario o comuníquese con el equipo de soporte en el chat en vivo.',
  withAmountAndYayeeDetails: 'con importe y datos del beneficiario.',
  billingHistory: 'Historial de facturación',
  paymentsOf: 'pagos de',
  creditCard: 'Credit Card',
  googlePay: 'Google Pay',
  applePay: 'Apple Pay',
  payPal: 'PayPal',
  usdt: 'USDT (Tether)',
  bitcoin: 'Bitcoin',
  ethereum: 'Ethereum',
  usdCoin: 'Moneda USD',
  binancePay: 'Binance Pay',
  alipay: 'Alipay',
  payoneer: 'Payoneer',
  weAccept: 'Nosotros aceptamos',
  paymentsOnlyIn: 'pagos solo en',
  manual: 'manual',
  mode: 'modo',
  toTopUpYourBalanceWith: 'Para recargar tu saldo con',
  pleaseContactSupportForTransactionDetails: 'por favor, póngase en contacto con el soporte para los detalles de la transacción',
  wireBankTransfer: 'Wire transferencia bancaria',
  wireBank: 'Wire banco',
  wireTransfer: 'Wire transferir',
  pleaseContactSupportForInvoiceAndTransactionDetails: 'por favor, póngase en contacto con el soporte para la factura y los detalles de la transacción',
  minimumPaymentAmountIs: 'El monto mínimo de pago es',
  maximumPaymentAmountIs: 'El monto máximo de pago es',
  transactionFee: 'tarifa de transacción',
  topUpYourBalanceWithPayPal: 'Recarga tu saldo con PayPal',
  dueYoNewPaypalRestrictions: 'Debido a las nuevas restricciones de Paypal, tenemos que aumentar mucho la tarifa de transacción para todos los pagos a través de <b>Paypal</b>. Es <b>6 %</b> ahora.',
  alsoWeveRemovedAllRegularBonuses: 'También hemos <b>eliminado</b> todos los bonos regulares para pagos superiores a 1000$ procesados con Paypal. Utilice tarjetas de crédito o formas alternativas de recarga de saldo para recibir estos bonos.',
  topUpYourBalanceWithPayoneer: 'Recarga tu saldo con Payoneer',
  topUpYourBalanceWithBankTransfer: 'Recarga tu saldo Transferencia bancaria',
  paymentsOnlyInManualMode: 'pagos solo en modo manual.',
  toTopUpYourBalanceWithPayoneerPlease: 'Para recargar su saldo con Payoneer por favor',
  toTopUpYourBalanceWithBankTransferPlease: 'Para recargar su saldo con transferencia bancaria por favor',
  contact: 'contacto',
  supportForTransactionDetails: 'soporte para los detalles de la transacción.',
  minimumPaymentAmountForThisPaymentMethodIs: 'Tenga en cuenta que el monto mínimo de pago para este método de pago es',
  weAcceptSEPAOrSWIFTBankTransfers: 'Aceptamos transferencias bancarias SEPA o SWIFT solo en modo manual.'
};

export default topUpBalance;
