import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { AxiosRequestConfig } from 'axios';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { domainUrl } from '@/core/config/DomainConfig';
import { Getters } from '@/store/enums/GettersEnums';

export interface Country {
    id: string,
    name: string
    lang: string
    lang_code: string
    location_code_apple: number
    location_code_google: number
}


export interface CountryInfo {
    errors: unknown;
    country: Country;
    countries: unknown;
}

export interface CustomOption {
    label: string,
    text: string,
    icon: string,
    value: string | number | null,
}

@Module({ name: 'CountryModule' })
export default class CountryModule extends VuexModule implements CountryInfo, StoreModel {
  errors = {};
  country = {} as Country;
  lang = 'en-US';
  countries = [];

  get [Getters.CURRENT_LANG](): string {
    return this.lang;
  }

  /**
     * Get current country object
     * @returns Country
     */
  get [Getters.CURRENT_COUNTRY](): Country {
    return this.country;
  }

  /**
     * Get current Countries objects
     * @returns Country
     */
  get [Getters.ALL_COUNTRIES]() {
    return this.countries;
  }

    @Mutation
  [Mutations.CLEAR_STORE]() {
    this.errors = {};
    this.country = {} as Country;
    this.countries = [];
    this.lang = 'en-US';
  }

    @Mutation
    [Mutations.SET_ERROR_COUNTRY](error) {
      this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_COUNTRIES](countries) {
      this.countries = countries;
    }

    @Mutation
    [Mutations.SET_LANG](lang) {
      this.lang = lang;
    }

    @Mutation
    [Mutations.SET_COUNTRY](country) {
      this.country = country;
    }

    @Action
    [Actions.GET_COUNTRIES](params): Promise<any> {
      const config: AxiosRequestConfig = {
        method: 'GET',
        params: params
      };
      return ApiService.query(`${domainUrl}country`, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_COUNTRIES, data.data);
          return true;
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_ERROR_COUNTRY, response?.data?.errors);
        });
    }

    @Action
    [Actions.GET_COUNTRY](id): Promise<any> {
      return ApiService.get(`${domainUrl}country`, id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_COUNTRY, data.data);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_ERROR_COUNTRY, response?.data?.errors);
        });
    }


}
