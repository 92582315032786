enum ErrorGetters {
    // sync calling
    GET_ERRORS_KEYWORDS = 'getErrorsKeywords',
    GET_ERRORS_ADD_CAMPAIGNS = 'getErrorsAddCampaigns',
    GET_ERRORS_USER_DETAILS_PAYMENTS = 'getErrorsUserDetailsPayments',
    GET_ERRORS_USER_LISTS = 'getErrorsUserLists',
    GET_ERRORS_ADMIN_REFLINKS = 'getErrorsAdminReflinks',
    GET_ERRORS_ACCESS_LISTS = 'getErrorsAccessLists',
    GET_ERRORS_APP = 'getErrorsApp',
    GET_ERRORS_PERMISSION = 'getErrorsPermission',
    GET_ERRORS_ROLE = 'getErrorsRole',
    GET_ERRORS_ADMIN_VENDORS = 'getErrorsAdminVendors',
    GET_ERRORS_USER_PAYMENTS = 'getErrorsUserPayments',
    GET_ERRORS_USER_ORDERS = 'getErrorsUserOrders',
    GET_ERRORS_MANAGER = 'getErrorsManager',
    GET_ERRORS_ADMIN_MARKETING_ACTIONS = 'getErrorsAdminMarketingActions',
    GET_ERRORS_USER_DETAILS_REFERRALS = 'getErrorsUserDetailsReferrals',
    GET_ERRORS_MY_CAMPAIGNS = 'getErrorsMyCampaigns',
    GET_ERRORS_RESELLERS_PROGRAM = 'getErrorsResellersProgram',
    GET_TEXT_ERROR = 'getTextError',
    GET_ERRORS_GOOGLE_ADS = 'getErrorsGoogleAds',
    GET_ERRORS_SALES_REPORT_MY_CLIENTS = 'getErrorsSalesReportMyClients',
    GET_ERRORS_AUTH = 'getErrorsAuth',

    // async calling
    GET_ERRORS_MY_PROFILE = 'getErrorsMyProfile',
    GET_ERRORS_REFERRALS = 'getErrorsReferrals',
    GET_ERRORS_SALES_REPORT_NEW_CLIENTS = 'getErrorsSalesReportNewClients',
    GET_ERRORS_USER_DETAILS_TRANSACTIONS = 'getErrorsUserDetailsTransactions',
}


export { ErrorGetters };
