import role from '@/core/plugins/lang/es-ES/role';
import functions from '@/core/plugins/lang/es-ES/function';
import module from '@/core/plugins/lang/es-ES/module';
import select from '@/core/plugins/lang/es-ES/select';
import application from '@/core/plugins/lang/es-ES/application';
import apps from '@/core/plugins/lang/es-ES/apps';
import keywords from '@/core/plugins/lang/es-ES/keywords';
import addCampaigns from '@/core/plugins/lang/es-ES/addCampaigns';
import myTransactions from '@/core/plugins/lang/es-ES/myTransactions';
import priceplans from '@/core/plugins/lang/es-ES/priceplans';
import myProfile from '@/core/plugins/lang/es-ES/myProfile';
import topUpBalance from '@/core/plugins/lang/es-ES/topUpBalance';
import register from '@/core/plugins/lang/es-ES/register';
import myCampaigns from '@/core/plugins/lang/es-ES/myCampaigns';
import bonusProgram from '@/core/plugins/lang/es-ES/bonusProgram';
import referralProgram from '@/core/plugins/lang/es-ES/referralProgram';
import resellersProgram from '@/core/plugins/lang/es-ES/resellersProgram';
import statistics from '@/core/plugins/lang/es-ES/statistics';
import dashboard from '@/core/plugins/lang/es-ES/dashboard';
import contacts from '@/core/plugins/lang/es-ES/contacts';

const index = {
  appsKeywords:'APLICACIONES Y PALABRAS CLAVE',
  myApps:'Mis aplicaciones',
  myKeywords:'Mis palabras clave',
  services:'Servicios',
  myCampaigns:'Mis campañas',
  addCampaign:'Añadir campaña',
  addCPICampaign:'Agregar campaña de CPI',
  addSmartCampaign:'Añadir campaña SMART',
  addHighQualityReviews:'Agregar reseñas de alta calidad',
  finance:'FINANZAS',
  topUpBalance:'Saldo de recarga',
  myTransactions:'Mis transacciones',
  other:'OTRA',
  referralProgram:'Programa de referencia',
  bonusProgram:'Programa de bonificación',
  APISupport:'Soporte de API',
  faq:'Preguntas más frecuentes',
  blog:'Blog',
  settingsRole: 'Role',
  settingsRoles: 'Roles',
  home: 'Hogar',
  close: 'Cerca',
  open: 'Abierto',
  app:'Aplicación',
  settingsFunctions: 'Functions',
  settingsModules: 'Modules',
  dashboard: 'Tablero',
  keywords: 'Palabras clave',
  layoutBuilder: 'Constructor de maquetación',
  craft: 'Elaborado',
  pages: 'Paginas',
  profile: 'Perfil',
  profileOverview: 'Descripción general',
  projects: 'Proyectos',
  campaigns: 'Campañas',
  documents: 'Documentos',
  connections: 'Conexiones',
  wizards: 'Magos',
  horizontal: 'Horizontal',
  vertical: 'Vertical',
  account: 'Cuenta',
  accountOverview: 'Descripción general',
  settings: 'Ajustes',
  authentication: 'Autenticación',
  basicFlow: 'Flujo básico',
  signIn: 'Registrarse',
  signUp: 'Inscribirse',
  passwordReset: 'Restablecimiento de contraseña',
  multiStepSignUp: 'Regístrese Multi-Pasos',
  error404: 'Error 404',
  error500: 'Error 500',
  apps: 'Aplicaciones',
  chat: 'Chat',
  privateChat: 'Chat privado',
  groupChat: 'Grupo de chat',
  drawerChat: 'Chat del cajón',
  widgets: 'Widgets',
  widgetsLists: 'Liza',
  statistics: 'Estadísticas',
  widgetsCharts: 'Gráficos',
  widgetsMixed: 'Mezclada',
  widgetsTables: 'Mesas',
  widgetsFeeds: 'Alimenta',
  changelog: 'Registro de cambios',
  docsAndComponents: 'Documentos & Componentes',
  megaMenu: 'Mega menú',
  exampleLink: 'Enlace de ejemplo',
  modals: 'Modales',
  general: 'General',
  inviteFriends: 'Invitar A Amigos',
  viewUsers: 'Ver Usuarios',
  upgradePlan: 'Plan De Actualización',
  shareAndEarn: 'Compartir Y Ganar',
  forms: 'Formas',
  newTarget: 'Nuevo Objetivo',
  newCard: 'Nueva Tarjeta',
  newAddress: 'Nueva Direccion',
  createAPIKey: 'Crea Clave De Api',
  twoFactorAuth: 'Dos Factores',
  createapps: 'Crear Aplicacion',
  createAccount: 'Create new account',
  activity: 'Actividad',
  documentation: 'Documentación',
  components: 'Componentes',
  resources: 'Recursos',
  customers: 'Clientes',
  gettingStarted: 'Empezando',
  customersListing: 'Listado De Clientes',
  customerDetails: 'Detalles De Los Clientes',
  calendarapps: 'Calendario',
  subscriptions: 'Suscripciones',
  getStarted: 'Empezando',
  subscriptionList: 'Lista De Suscripción',
  addSubscription: 'Añadir Suscripción',
  viewSubscription: 'Suscripción',
  myProfile: 'Mi perfil',
  language: 'Idioma',
  english: 'Inglés',
  spanish: 'Español',
  russia: 'Ruso',
  ukraine: 'Ucrania',
  accountSettings: 'Configuración de la cuenta',
  signOut: 'Salir',
  priceplans: 'Planes de precios',
  forgotPassword: 'Has olvidado tu contraseña ?',
  enterEmailResetPassword: 'Ingrese su correo electrónico para restablecer su contraseña?',
  email: 'Email',
  submit: 'Submit',
  save: 'Ahorrar',
  add: 'Añadir nueva',
  password: 'Contraseña',
  cancel: 'Cancelar',
  continue: 'Continuar',
  pleaseWait: 'Espere por favor...',
  swalFireText: '¡Has iniciado sesión correctamente!',
  swalConfirmButtonText: 'OK',
  swalTryAgain: '¡Intentar otra vez!',
  swalSuccessfullyLogin: '¡Has iniciado sesión correctamente!',
  swalSuccessfullyRegister: 'You have successfully registered account',
  swalFormSuccessfully: '¡El formulario ha sido enviado con éxito!',
  swalSorryLooksTryAgain: 'Lo sentimos, parece que se han detectado algunos errores, inténtalo de nuevo.',
  continueApple: 'Continuar con Apple',
  continueFacebook: 'Continuar con Facebook',
  continueGoogle: 'Continuar con Google',
  sigInApple: ' Inicia sesión con Apple',
  sigInFacebook: 'Inicia sesión con Facebook',
  sigInGoogle: 'Inicia sesión con Google',
  or: 'or login with email',
  orSignUp: 'or enter your details',
  newHere: 'Nuevo aquí?',
  singKeyapps: 'Login to your account',
  alreadyHaveAccount: '¿Ya tienes una cuenta?',
  lastName: 'Apellido',
  name: 'Nombre',
  surname: 'Apellido',
  passwordConfirmation: 'Confirmación de contraseña',
  firstName: 'Primer nombre',
  userName: 'Username',
  singInHere: 'Firme aquí',
  eightMoreCharacters: 'Use 8 o más caracteres con una combinación de letras, números y símbolos.',
  confirmPassword: 'Confirmar contraseña',
  termsAndConditions: 'Términos y condiciones',
  iAgree: 'Estoy de acuerdo &',
  seemsNothingHere: 'Parece que no hay nada aquí.',
  returnHome: 'Volver a casa',
  goToHomepage: 'Ir a la página de inicio',
  systemError: 'Error del sistema',
  somethingWentWrong: '¡Algo salió mal! <br />\n Por favor, inténtelo de nuevo más tarde.',
  paginationText: 'Mostrando { from } a { to } de { total } entradas',
  loading: 'Cargando...',
  checking:'comprobación',
  delete:'Eliminar',
  edit:'Edit',
  error:'error',
  out:'afuera',
  copiedToClipboard:'Copiado al portapapeles',
  resellersProgram:'Programa de revendedores',
  allCountries:'Todos los países',
  appstore:'Appstore',
  pickTargetCountry:'Elija el país de destino',
  chooseAppForPromotionOr:'Elija la aplicación para la promoción o ',
  addNew:'añadir nuevo',
  mainBalance:'Balance principal',
  bonusBalance:'Saldo de bonificación',
  myPriceplan:'Mi plan de precios',
  yourPersonalManager:'Tu gestor personal',
  iNeedPersonalManager:'Necesito un gerente personal',
  personalManager:'Gerente de personal',
  requestForPersonalManagerAssignmentIsSent:'Se envía solicitud de asignación de gestor personal. Su nuevo administrador personal se comunicará con usted pronto.',
  pleaseFillYourAdvanceProfile:'Complete su perfil de Advance y deje cualquier información de contacto para una mejor comunicación.',
  tryAgainLater:'Vuelva a intentarlo más tarde',
  personalManagerAssignmentInProgress:'Asignación de administrador personal en curso', 
  ourTeamIsWorking:'Nuestro equipo está trabajando diligentemente para seleccionar un profesional calificado y dedicado que será responsable de atender sus necesidades y brindarle apoyo personalizado.',
  forAllYourInquiriesAndCorrespondence:'Para todas sus consultas y correspondencia, por favor dirija sus correos electrónicos a la siguiente dirección:',
  weAppreciateYourCommunication:'Agradecemos su comunicación y responderemos a su mensaje lo antes posible. ¡Gracias por contactarte!',
  newMessageToMyKeyappTopManager:'Nuevo mensaje para mi administrador keyapp.top',
  newMessageToKeyappTopSupport:'Nuevo mensaje para el soporte de keyapp.top',
  supportTeam:'Equipo de apoyo',
  yearsAgo:'años atrás',
  monthsAgo:'meses atrás',
  weeksAgo:'semanas atrás',
  daysAgo:'días atrás',
  hoursAgo:'horas atrás',
  minutesAgo:'minutos atrás',
  new:'Nuevo',
  noNewMessagesFromManagers: 'No hay nuevos mensajes de los gerentes',
  role: role,
  functions: functions,
  module: module,
  select:select,
  application: application,
  _apps:apps,
  keywordsPage:keywords,
  addCampaigns:addCampaigns,
  myTransactionsPage:myTransactions,
  priceplansPage:priceplans,
  myProfilePage:myProfile,
  topUpBalancePage:topUpBalance,
  register:register,
  myCampaignsPage:myCampaigns,
  bonusProgramPage:bonusProgram,
  referralProgramPage:referralProgram,
  resellersProgramPage:resellersProgram,
  statisticsPage:statistics,
  dashboardPage:dashboard,
  contactsPage:contacts,
};
export default index;
