const salesReport = {
  statisticsByStatus: 'Statistics by Status',
  statisticsByPayments: 'Statistics by Payments',
  from: 'from',
  till: 'till',
  myClients: 'My clients',
  newClients: 'New clients',
  showing: 'showing',
  usersOf: 'users of',
  noUsersFound: 'no users found',
  managerSuccessfullyChanged: 'Manager successfully changed',
  lastPayments: 'Last payments',
  lastPaymentsOf: 'payments of',
  noSalesReportLastPaymentFound: 'No sales report last payment found',
  pickReportRange: 'Pick report range',
  pickUser: 'Pick user',
  toolbar: {
    totalClients: 'Total clients',
    activeClients: 'Active clients',
    paymentsCommited: 'Payments commited',
    myPaymentsTotal: 'My payments total',
  },
  actionDropdown: {
    changeStatus: 'Change status',
    statusHistory: 'Status history',
    editUser: 'Edit user',
  },
  modal: {
    clientStatusAndNotes: 'Client status and notes',
    status: 'Status',
    note: 'Note',
    statusChanged: 'New note or status added'
  }
};
export default salesReport;
