const role = {
  over: 'Más de {total} miembros',
  name: 'Nombre',
  actions: 'Comportamiento',
  check: 'Controlar',
  nameModule: 'Módulo de nombre',
  enterName: 'Ingrese su nombre',
  permissionName: 'Nombre del permiso',
  inputName: 'Por favor ingrese el nombre',
  inputNamespace: 'Por favor ingrese el Namespace',
  inputPermissionName: 'Por favor ingrese el Nombre del permiso',
  inputModule: 'Por favor ingrese el módulo',
  created: 'Crear Función',
  updated: 'Actualizar Función',
  specifyName: 'Especifique un nombre de destino para uso futuro y referencia',
  specifyModule: 'Especifique un módulo de destino para uso futuro y referencia',
  specifyNamespace: 'Especifique un espacio de nombres de destino para uso futuro y referencia',
  specifyPermissionName: 'Especifique un Nombre del permiso de destino para uso futuro y referencia',
  selectModule: 'Seleccionar módulo',
  clickAdd: 'Haga clic para agregar un función',
  namespace: 'Espacio de nombres',
  newFunction: 'Nuevo función',
  nameFunction: 'Módulo de función',

};
export default role;
