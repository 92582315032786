const application = {
  promote:'Promover',
  addKeywords:'Añadir palabras clave',
  update:'Actualizar',
  delete:'Borrar',
  addApp:'Agregar nueva aplicación',
  created:'Agregar nueva aplicación',
  inputSelectCountry:'Por favor seleccione país',
  inputSelectApplication:'Seleccione la aplicación',
  inputAppstore:'Seleccione la tienda de Appstore',
  deleteApp:'Aplicación desinstalada con éxito ',
  updateApp:'Aplicación actualizada con éxito',
  search:'Buscar',
  sure:'?Estas seguro(-a)?',
  not:'No, cancélalo',
};
export default application;
