const bonusProgram = {
  getFreeBonusProgram:'Отримайте безкоштовну бонусну програму',
  welcomeToGetFreeBonusesPage:'Ласкаво просимо на сторінку «Отримати безкоштовні бонуси», тут ви зможете отримати безкоштовне поповнення свого рахунку, виконуючи наступні набори завдань і просуваючи наш сервіс. На даний момент ви можете заробити ці бонуси:',
  getTwentyPercentMoney:'Отримайте 20% грошей назад на свій рахунок або 100$ за вашу історію успіху',
  getOneHundredBaks:'Отримайте 100$ за ваш огляд на YouTube',
  getThirtyBaksForAndroid:'Отримайте 30$ на свій рахунок за огляд форуму Android',
  getThirtyBaksForFacebook:'Отримайте 30$ на свій рахунок за публікацію у Facebook',
  successStoryOrCase:'Історія успіху чи кейс',
  inOrderToGetYourTwentyPercentMoneyBack:'Щоб отримати свій 20%-й бонус повернення грошей (ви отримаєте 20% ваших грошей, які ви витратили на найбільше замовлення на просування додатку, про яке ви пишете свою історію успіху, наприклад, якщо ви пишете історію про додаток, на просування якого ви витратили 500$, і у вас є 5 замовлень, вам повернуть 20% грошей за найбільше замовлення, а не від ваших 500$ витрат) вам потрібно знайти та зареєструватися на будь-якому спеціалізованому форумі Android, створити тему, та опублікуйте свою історію успіху за допомогою нашого сервісу, ця історія успіху має відповідати наступним вимогам:',
  postHasToBeEightHundredSymbols:'Ваше повідомлення на форумі має бути не менше ніж 800 символів.',
  postHasToIncludeLink:'Ваше повідомлення на форумі має містити посилання на наш сайт',
  postHasToDescribe:'Ваше повідомлення на форумі має описувати вашу покрокову роботу з нашим сервісом та те, чого ви досягли, використовуючи його.',
  postHasToIncludeScreenshots:'Ваше повідомлення на форумі повинно включати щонайменше 2 знімки екрана вашої програми та ефективність вашого ключового слова.',
  youCantMention:'Ви не можете згадувати у своєму повідомленні на форумі, що ви отримуєте безкоштовні бонуси за це',
  postHasToStayAlive:'Ваш пост повинен залишатися в силі не менше 2 днів.',
  youTubeVideoReview:'YouTube відео огляд',
  inOrderToGetOneHundredBaks:'Для того, щоб отримати бонус у розмірі 100$, ви повинні зробити посібник із використання нашого сайту (або оглядове відео) на своєму каналі YouTube, який відповідає наступним вимогам: Ви повинні витратити не менше 100$ у нашому сервісі, що означає, що нам Потрібно якісний огляд (гід) нашого сайту від наших постійних клієнтів. Ваше відео на YouTube має включати короткий опис нашого сервісу (щось на кшталт: "Цей сервіс допомагає розробникам у просуванні їх додатків для Android. KeyApp.Top пропонує такі послуги - установки з пошуком за ключовими словами та стандартні установки додатків"). Ваш відеоогляд на YouTube повинен включати посібник з налаштування кампанії на нашому сайті у вкладці «Мої замовлення». У своєму відео на YouTube ви повинні описати наступні вкладки особистого кабінету: "Мої ключові слова", "Додати гроші", "Реферальна програма", "Блог" (кілька слів про їхні основні параметри).',
  videoHastoBeLong:'Ваш відеоогляд на YouTube повинен тривати не менше 2 хвилин.',
  youAreAlwaysWelcome:'Завжди будь ласка, якщо ви зробили керівництво або огляд рідною мовою (ми приймаємо відео не тільки англійською мовою).',
  videoHasToIncludeWebsiteLink:'Ваше відео на YouTube має містити посилання на наш сайт',
  videoHasToIncludeFBLink:'Ваше відео на YouTube має містити посилання на нашу сторінку у FB: ',
  videoHasToDesctiveLikesDislikes:'Ваше відео на YouTube має описувати, що вам подобається чи не подобається у нашому сервісі.',
  youCantMentionInVideo:'Ви не можете згадувати у своєму відео на YouTube, що отримаєте безкоштовні бонуси за його створення.',
  videoReviewHasToStayAlive:'Ваш відеоогляд на YouTube повинен залишатися в силі не менше 2 днів.',
  androidRelatedForum:'Форум з Android',
  inOrderToGetThirtyBaks:'Щоб отримати свій бонус у розмірі 30$, вам необхідно знайти та зареєструватися на будь-якому форумі, присвяченому Android, створити тему та опублікувати правдивий відгук про наш сервіс, цей огляд має відповідати наступним вимогам:',
  postHasToBeFourHundredSymbols:'Ваше повідомлення на форумі має бути не менше ніж 400 символів.',
  postHastoDescribeLikesDislikes:'Ваше повідомлення на форумі має описувати ваші симпатії чи антипатії до нашого сервісу.',
  postHasToIncludeWebsiteLink:'Ваше повідомлення на форумі має містити посилання на наш сайт',
  youCantMentionInPost:'Ви не можете згадувати у своєму повідомленні на форумі, що ви отримуєте безкоштовні бонуси за це',
  facebookPost:'Facebook пост',
  inOrderToGetThirtyBaksBonus:'Щоб отримати бонус у розмірі <b>30$</b>, вам необхідно опублікувати повідомлення у своєму обліковому записі facebook, що відповідає наступним вимогам:',
  facebookHasToIncludeScreenshot:'Ваш пост у Facebook повинен містити знімок екрана з ефективністю вашого рейтингу за ключовими словами або загальною продуктивністю вашої програми. Будь ласка, виділіть область, де ви почали просування з нами. Скріншоти зі сторінки "мої замовлення" на keyapp.top не підходять, тому що вони не показують різницю в просуванні вашого додатку до і після ваших кампаній з нами.',
  facebookHasToBeLong:'Ваш пост у Facebook має бути не менше 400 символів.',
  facebookHasToIncludeScreenshotKeyword:'Ваш пост у Facebook повинен містити знімок екрана з ефективністю вашого рейтингу за ключовими словами або загальною продуктивністю вашої програми. Будь ласка, виділіть область, де ви почали просування з нами.',
  facebookHasToIncludeWebsiteLink:'Ваш пост у Facebook повинен містити посилання на наш сайт',
  facebookHasToIncludeFBLink:'Ваш пост у Facebook повинен містити посилання на нашу сторінку у Facebook: ',
  facebookHasToDescribeLikesDislikes:'Ваш пост у Facebook повинен описувати, що вам подобається чи не подобається у нашому сервісі.',
  youCantMentionInFB:'Ви не можете згадувати у своєму пості на Facebook, що отримуєте безкоштовні бонуси за це',
  googleMapsReview:'Google maps огляд',
  inOrderToGetTenBaks:'Щоб отримати бонус у розмірі 10$, вам необхідно залишити відгук на ',
  googleMaps:'Google maps',
  whichMeetsFollowingRequirements:', що відповідає наступним вимогам. Ваш відгук на картах Google повинен:',
  minimumLong:'бути мінімум 350 символів',
  describeLikesDislikes:'опишіть, що вам подобається чи не подобається у нашому сервісі',
  youCantMentionReview:'ви не можете згадати у своєму відгуку на Google Maps, що ви отримуєте безкоштовні бонуси за лайки за це',
  yourReviewHasStayAlive:'ваш відгук на Google Maps повинен залишатися чинним не менше 2 місяців',
  youCanUseYour:'ви можете використовувати свій ',
  youCanMakeOnlyOneYouTubeVideo:'Ви можете зробити лише 1 відео на YouTube на одного користувача.',
  youCanWriteOnlyOneForumPost:'Ви можете написати тільки один пост на форумі хорошої якості, щоб отримати бонус.',
  youCanWriteOnlyOneFacebookPost:'Ви можете написати тільки один пост у Facebook хорошої якості, щоб отримати бонус.',
  youCanWriteOnlyOneReviewOnGoogleMaps:'Ви можете написати лише один відгук на Google Maps гарної якості, щоб отримати бонус.',
  pleaseEmail:'Будь ласка, напишіть ',
  usLinkToYourPost:' нам посилання на вашу посаду з заголовком "Бонус за пост", ми його розглянемо, і якщо він відповідає всім вимогам, ви отримаєте свій бонус.',
  usLinkToYourVideo:' нам ссылку на ваше видео с названием "Видеообзор YouTube", мы его проверим, и если оно соответствует всем требованиям, вы получите свой бонус.',
  usLinkToYourReview:' нам посилання на ваш відгук на картах Google із заголовком "Відгук на картах Google", ми його розглянемо, і якщо він відповідає всім вимогам, ви отримаєте свій бонус.',
  referralLink:'Реферальне посилання',
};

export default bonusProgram;
