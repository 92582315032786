const addCampaigns = {
  products: 'Productos',
  pickAvaialbleCampaignTypes: 'Elija uno de los tipos de campaña disponibles',
  notes: 'Notes',
  pleaseDontChangeYourAppsIcon: 'Por favor, no cambie el ícono, el nombre o el nombre del editor de su aplicación mientras ejecuta una campaña. Nuestros usuarios buscarán su aplicación usando palabras clave y la única forma de encontrarla es mirando el icono y comparando el nombre de la aplicación.',
  whatIsAdvertisingCpiCampign: 'Qué es la campaña publicitaria de CPI?',
  cpi: 'CPI (cost-per-install) campaña - es una herramienta utilizada para atraer usuarios a su aplicación. Estos usuarios no están motivados con gemas o monedas como aquellos que instalan su aplicación cuando solicita instalaciones de palabras clave. Estos usuarios instalarán su aplicación solo si les gusta. Eso significa que atraerá a personas que generarán actividad e ingresos en la aplicación para usted.',
  keyword: 'Palabra clave',
  install: 'Instalar en pc',
  direct: 'Directo',
  installs: 'Instalaciones',
  guaranteed: 'Garantizado',
  reviews: 'Reseñas',
  smart: 'Inteligente',
  campaign: 'Campaña',
  addCampaigns: 'Agregar campañas',
  addCampaign: 'Añadir campaña',
  advertising: 'Publicidad',
  summary: 'Resumen',
  product: 'Producto',
  keywordInstalls: 'Instalaciones de palabras clave',
  installsByKeywordsForYourApplication: 'Instalaciones por palabras clave para su aplicación en la región de destino',
  application: 'Solicitud',
  targetCountry: 'País de destino',
  daysInCampaign: 'Días en campaña',
  finishAt: 'terminar en',
  campaignOrders: 'Pedidos de campaña',
  days: 'días',
  directInstalls: 'Instalaciones directas',
  rates: 'Tarifas',
  total: 'Total',
  day: 'Día',
  fixedPartner: 'Socio fijo',
  description: 'Descripción',
  saveDraft: 'Guardar borrador',
  startCampaign: 'Iniciar campaña',
  iAgreeTo: 'estoy de acuerdo con',
  terms: 'términos',
  ofUse: 'de uso',
  textSuccessfully: 'La campaña fue creada con éxito',
  textSuccessfullyDraft: 'Se creó un nuevo borrador de campaña #{id}',
  refundPolicyForRatesAndReviews: 'Política de reembolso para tarifas y reseñas',
  almostAllRatesAndReviewsAreGettingDeleted: '¡Advertencia! Casi todas las tarifas y reseñas están siendo eliminadas por cualquier tienda de aplicaciones, NO reembolsaremos dinero por tarifas y reseñas. Usélos bajo su propio riesgo',
  allRatesAndReviewsWillAppearOnMarket: 'Todas las tarifas y reseñas aparecerán en la página de mercado de su aplicación en 2-3 horas después de que se complete la campaña. Después de 1 o 2 días, la mayoría de las tarifas y reseñas ordenadas en esta sección se eliminarán y depende en gran medida del tipo de aplicación, su tráfico orgánico y las tarifas diarias habituales aumentan, por lo que, al continuar, acepta correr este riesgo.',
  ifYouNeedReviewsAndRates: 'Si necesita reseñas y tarifas para permanecer en la página de la aplicación, además de obtenerlas con la garantía de devolución del dinero o volver a publicarlas durante los 30 días posteriores a la finalización del pedido, vaya a la campaña Agregar reseñas garantizadas.',
  evenIfItIsOkay: 'Incluso si está bien para usted que se eliminen las tarifas y reseñas ordenadas en esta sección, no pida toneladas de ellas. El comportamiento de las tarifas debe verse como un flujo de usuarios real, proporcional al tráfico orgánico de su aplicación en el país seleccionado y similar a su número actual de tarifas.',
  iveReadAndAreeToThe: 'He leído y acepto las',
  chargebackAndRefundPolicy: 'Política de devolución de cargo y reembolso',
  deleteAllKeywords: 'Eliminar todas las palabras clave',
  keywordInstall: {
    keywordInstallAddKeywordsBody: {
      keywords: 'Palabras clave',
      advancedMode: 'Modo avanzado',
      dayOne: 'día 1',
      addKeyword: 'Agregar palabra clave'
    },
    keywordInstallKeywordsTable: {
      noKeywordsFound: 'No se encontraron palabras clave',
      selectKeyword: 'Seleccionar palabra clave',
      addKeywordInstalls: 'Agregar instalaciones de palabras clave',
      increase: 'Aumentar',
      parabolic: 'Parabólico',
      equal: 'Igual',
      days: 'Días'
    },
    directInstallTable: {
      addDirectInstalls: 'Agregar instalaciones directas',
      days: 'Días',
      packageOrDirectInstalls: 'Paquete o instalaciones directas',
      delete: 'Borrar'
    },
    ratesAndReviewsKeywordInstallBody: {
      ratesAndReviews: 'Tarifas y opiniones',
      addRatesAndReviewsToCampaign: 'Agregar tarifas y reseñas a la campaña',
      days: 'Días',
      rates: 'Tarifas',
      delete: 'Borrar',
      reviews: 'Reseñas',
      importReviews: 'Importar reseñas',
      errorCheckedTerms: 'Debe aceptar los términos de uso antes de solicitar tarifas o reseñas.'
    },
    promotionRuleKeywordInstallBody: {
      promotionRule: 'Regla de promoción',
      dontPromoteKeywordsWhenTheyReachRank: 'No promocione las palabras clave cuando alcanzan el rango',
      installsDeliveryType: 'Tipo de entrega de instalaciones',
      spreadInstallsForTwentyFourHours: 'Propaga las instalaciones durante 24 horas',
      allInstallsAtOnce: 'Todas las instalaciones a la vez',
      deeplinkTitle: 'Enlace profundo',
      deeplink: 'El enlace profundo se ejecuta después de la instalación de la aplicación, por ejemplo, run?source=keyapp, ',
      deeplinkIos: 'Lee más en nuestro blog'
    },
    addMyReviewsModal: {
      addMyReviews: 'Agregar mis Reseñas',
      added: 'Agregado',
      reviews: 'reseñas',
      youHaveNoAddedReviewsYet: 'Aún no has añadido reseñas',
      add: 'Agregar',
      newReview: 'Agregar nueva revisión',
      addManyRandomReview: 'Agregar 5 reseñas aleatorias',
      deleteAll: 'Eliminar todos',
      saveReview: 'Guardar reseña',
      deleteReview: 'Eliminar reseña',
      editReview: 'Editar reseña',
      titleText: 'Agregar nuevo título de revisión',
      bodyText: 'Agregar nuevo texto de revisión'
    },
    descriptionKeywordInstallBody: {
      description: 'Descripción',
      leaveAnyNotesForYourcampaign: 'Deje notas para su campaña (solo en inglés, 1000 símbolos como máximo)'
    }
  },
  guaranteedReviews: {
    reviewGuaranteedReviewsBody: {
      guaranteedReviews: 'Reseñas garantizadas',
      addGuaranreedReviewsToYourApplication: 'Agregue revisiones garantizadas a su aplicación',
      myCustom: 'mi costumbre',
      reviews: 'reseñas',
      writeReviews: 'Escribir reseñas',
      forMe: 'for mepara mi',
      improveYourRatesAndAppsReputation: 'Mejora tus tarifas y la reputación de tus aplicaciones con nuestras reseñas. Obtenga 30 días de garantía para cualquier pedido.',
      campaignDetails: 'Detalles de la campaña',
      days: 'Días',
      _reviews: 'Reseñas',
      importReviews: 'Importar reseñas',
      delete: 'Borrar',
      authorGender: 'Género del autor',
      male: 'Masculino',
      female: 'Femenino',
      both: 'Ambos',
      pickPreferredGenderOfAccount: 'Elija un género preferido de cuenta que escribirá una reseña',
      reviewLanguage: 'Revisar idioma',
      pickPreferredLanguageForReviews: 'Elija un idioma preferido para las revisiones, o se utilizará el idioma predeterminado de un país elegido',
      description: 'Descripción',
      leaveAnyNotesForYourOrder: 'Deje notas para su pedido (solo en inglés, 1000 símbolos), p. palabras clave preferidas, frases, etc.',
      notes: 'notas',
      thisIsGuaranteedReviewsOrder: 'Este es un pedido de reseñas garantizadas, lo que significa que si solicita 100 reseñas, le garantizamos que no menos de 90 de ellas permanecerán en la página de su aplicación durante un mínimo de 30 días; de lo contrario, volveremos a publicar las reseñas que falten. Además, su pedido puede estar en estado de MODERACIÓN durante 12-48 horas hasta que verifiquemos todas las revisiones.'
    }
  },
  advertisingCampaign: {
    advertisingCampaignTab: {
      back: 'atrás',
      startCampaign: 'Iniciar campaña',
      pleaseWait: 'Espere por favor...',
      next: 'Próximo'
    },
    advertisingCampaignStep1: {
      addTargetCountriesForAppPromotion: 'Agregar países de destino para la promoción de la aplicación',
      usuallyWeRecommendToStartCpiAampaignsInSuchCases: 'Por lo general, recomendamos iniciar campañas de CPI en tales casos.',
      youDontHave: 'no tienes',
      organicTrafficOnYourApp: 'tráfico orgánico en su aplicación, pero desea iniciar campañas de promoción de palabras clave. Cuando ordena instalaciones de palabras clave e instalaciones de CPI al mismo tiempo, aumenta la clasificación de las palabras clave de su aplicación mucho más rápido',
      youHave: 'Tú tienes',
      newAppAndYouWantToGetKeywordsIndexationFaster: 'una nueva aplicación y desea obtener la indexación de palabras clave más rápido',
      newAppAndYouWantToGetYourMainPageInstallsNumberHigher: 'una nueva aplicación y desea aumentar el número de instalaciones de su página principal',
      toGetBetterCategoryRankings: 'para obtener mejores clasificaciones de categoría',
      youEarnMore: 'ganas mas',
      fromEachInstallThenYouPayForIt: 'de cada instalación luego pagas por ella',
      toAttractUsersFromSpecifiedRegion: 'para atraer usuarios de una región específica',
      cryptocurrenciesBinaryOptionsPersonalLoansAppsAreForbidden: 'Se prohíben las criptomonedas, las opciones binarias, los préstamos personales y aplicaciones similares.',
      youWant: 'Usted quiere',
      pleaseNote: 'tenga en cuenta'
    },
    advertisingCampaignStep2: {
      selectPromotionCountries: 'Seleccionar países de promoción',
      addTargetCountriesForAppPromotion: 'Agregar países de destino para la promoción de la aplicación',
      filterByCpi: 'Filtrado por CPI',
      filterByGeo: 'Filtrado por GEO',
      filterByLanguage: 'Filtrado por language',
      highCpi: 'Alto CPI',
      mediumCpi: 'Medio CPI',
      lowCpi: 'Bajo CPI',
      europe: 'Europa',
      asia: 'Asia',
      africa: 'Áfrico',
      latinAmerica: 'America latina',
      worldwide: 'En todo el mundo',
      english: 'Inglés',
      spanish: 'Español',
      russian: 'Ruso',
      targetedCountries: 'Países objetivo'
    },
    advertisingCampaignStep3: {
      campaignBudget: 'Presupuesto Campaña',
      defineDailyBudget: 'Defina el presupuesto diario, el total de días y el CPI objetivo',
      dailyBudget: 'Presupuesto diario',
      targetCpi: 'Objetivo CPI',
      daysInCampaign: 'Días en campaña',
      unlimited: 'Ilimitado',
      days: 'días'
    },
    advertisingCampaignStep4: {
      headlines: 'Titulares',
      addHeadlinesAndDescriptionsForAds: 'Agregue títulos y descripciones para los anuncios',
      headlineOne: 'Titular #1',
      weUseHeadlinesAndDescriptionsToAdvertiseYourApp: 'Usamos títulos y descripciones para publicitar su aplicación. Los títulos y descripciones atractivos pueden ayudarlo a obtener un mejor CTR (más clics de las vistas), por lo que su CPI promedio será más bajo.',
      generate: 'Generar',
      headlineTwo: 'Titular #2',
      misleadingDescriptions: 'Las descripciones engañosas pueden aumentar el CTR, pero los usuarios no instalarán su aplicación cuando consulten la página de su aplicación. Por lo tanto, obtendrá una tasa de conversión baja y su CPI aumentará.',
      description: 'Descripción',
      checkThatYourTextMakesSenseForUsers: 'Comprueba que tu texto tiene sentido para los usuarios. Es importante porque nuestros socios de redes publicitarias aplicarán un proceso de aprobación a sus anuncios. Si infringe algunas reglas, sus anuncios querrán pasar el proceso de aprobación.'
    },
    advertisingCampaignStep5: {
      summary: 'Resumen',
      confirmCampaignDetails: 'Confirmar detalles de la campaña',
      application: 'Solicitud',
      nameOfApplication: 'Nombre de la aplicación...',
      developer: 'Desarrollador',
      countries: 'Países',
      duration: 'Duración',
      targetCpi: 'Objetivo CPI',
      dailyBudget: 'Presupuesto diario',
      startCharge: 'Iniciar carga',
      toStartThisCampaign: 'Para iniciar esta campaña cargaremos tu saldo por 20 $. Necesitamos esta cantidad (en realidad, es el doble del presupuesto diario) como prepago para futuros gastos de campaña.',
      weWillChargeYourAccount: 'Cargaremos en su cuenta todos los días el monto que gastó en la promoción durante el día anterior. Si no tiene suficientes fondos en su saldo para cubrir los gastos del día anterior, utilizaremos su cargo de Inicio y pausará la campaña hasta que recargue su saldo y reanude manualmente la campaña.',
      refund: 'Reembolso',
      youMayCancelYourCampaign: 'Puede cancelar su campaña en cualquier momento, pero haremos un reembolso solo después de 24 horas después de la cancelación para detener la campaña en todas las redes publicitarias y obtener los gastos reales de la campaña. La misma regla se aplica para completar campañas. El monto del reembolso se calcula como la diferencia entre el precio de la campaña y los gastos de la campaña.',
      dailyCharge: 'Cargo diario'
    },
    advertisingCampaignAside: {
      letsStart: '¡Empecemos!',
      detailsOfStepOneHere: 'detalles del paso 1 aquí',
      stepTwo: 'Paso 2',
      detailsOfStepTwoHere: 'detalles del paso 2 aquí',
      stepThree: 'Paso 3',
      detailsOfStepThreeHere: 'detalles del paso 3 aquí',
      stepFour: 'Paso 4',
      detailsOfStepFourHere: 'detalles del paso 4 aquí',
      stepFive: 'Paso 5',
      detailsOfStepFiveHere: 'detalles del paso 5 aquí'
    }
  }
};

export default addCampaigns;
