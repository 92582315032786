const reflinks = {
  reflinks: 'Admin Reflinks',
  showing: 'showing',
  reflinksOf: 'reflinks of',
  noReflinksFound: 'No reflinks found',
  addNew: 'Add New',
  tableDropdown: {
    quickActions: 'Quick Actions',
    refresh: 'Refresh',
    exportReflinks: 'Export',
  },
  actionsDropdown: {
    update: 'Update',
    delete: 'Delete',
  },
  modal: {
    addNewReflink: 'Add new Reflink',
    email: 'Email',
    name: 'Name',
    code: 'Code',
    category: 'Category',
    bonus: 'Bonus',
    editReflink: 'Edit Reflink',
  },
  messages: {
    success: 'New reflink have been created.',
    updateSuccess: 'Reflink have been saved.',
    deleteSuccess: 'Reflink have been deleted.',
  }
};

export default reflinks;
