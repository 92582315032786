const resellersProgram = {
  resellersProgram:'Programa de revendedores',
  dearUser:'¡Querido usuario! Puede solicitar aquí la base de revendedores y el revendedor Gold ',
  pricePlans:'planes de precios ',
  itGivesYouSignificantDiscount:'Le da un descuento significativo en el precio de instalación. Por favor, lea el ',
  requirements:'requisitos ',
  carefullyBeforeFillingInTheForm:'cuidadosamente antes de llenar el formulario',
  feelFreeTo:'No dude en ',
  contact:'contacto ',
  usIfYouHaveAnyQuestions:'nosotros si tiene alguna pregunta sobre este formulario de aprobación de revendedor y ',
  weNeedMoreInformationAboutYou:'Necesitamos más información sobre usted',
  whereDoYourCustomersComeFrom:'¿De dónde vienen tus clientes?',
  yourWebsitesBlog:'Sus sitios web, blog',
  pleaseDescribeYourSources:'Describa sus fuentes, para que podamos entender que tiene un flujo regular de nuevos clientes.',
  whatChannelsDoYouUseToFindClients:'¿Qué canales utiliza para encontrar clientes?',
  whatServicesDoYouProvide:'¿Qué servicios proporcionan?',
  whatOtherSuppliersDoYouHave:'¿Qué otros proveedores tienes?',
  pleaseProvideYourSuppliesLineByLine:'Proporcione sus suministros línea por línea',
  youMayBeUsingOneOfOurServices:'Es posible que esté utilizando uno de nuestros servicios incluso sin saberlo. Si uno de tus proveedores nos pertenece, combinaremos tus planes de precios para que gastes menos.',
  howManyInstallsDoYouSellMonthly:'¿Cuántas instalaciones vende mensualmente?',
  applyAsReseller:'Aplicar como revendedor',
  seo:'SEO',
  paidSearch:'Búsqueda pagada',
  forumsBlogs:'Foros, blogs',
  referralProgram:'Programa de referencia',
  smm:'SMM',
  other:'Otro',
  androidInstalls:'Instalaciones de Android',
  iosInstalls:'Instalaciones de iOS',
  aso:'ASO',
  userAcquisition:'Adquisición de usuarios',
  marketing:'Marketing',
  less:'menos',
  pleaseSelectAnyService:'Por favor seleccione cualquier servicio',
  pleaseSelectAnyChannel:'Seleccione cualquier canal',
  yourReSellerRequestWasCreatedSuccessfully:'¡Su solicitud de revendedor se creó con éxito!',
};

export default resellersProgram;
