import { RolesEnums } from '@/store/enums/RolesEnums';

const errors =
    {
      path: '/',
      component: () => import('@/components/page-layouts/Empty.vue'),
      meta: { roles: [RolesEnums.ALL] },
      children: [
        {
          // the 404 route, when none of the above matches
          path: '/404',
          name: '404',
          meta: { roles: [RolesEnums.ALL] },
          component: () => import('@/views/crafted/authentication/Error404.vue')
        },
        {
          path: '/500',
          name: '500',
          meta: { roles: [RolesEnums.ALL] },
          component: () => import('@/views/crafted/authentication/Error500.vue')
        },
        {
          path: '/503',
          name: '503',
          meta: { roles: [RolesEnums.ALL] },
          component: () => import('@/views/crafted/authentication/Error503.vue')
        }
      ]
    };
export default errors;
