const bonusProgram = {
  getFreeBonusProgram:'Get Free Bonus program',
  welcomeToGetFreeBonusesPage:'Welcome to "Get free bonuses" page, here you will be able to get a free top up of your account by completing sets of following tasks and promoting our service.For now you can earn these bonuses:',
  getTwentyPercentMoney:'Get 20% money back of your order to your account or 100$ for your success story',
  getOneHundredBaks:'Get 100$ for your Youtube review',
  getThirtyBaksForAndroid:'Get 30$ to your account for Android forum review',
  getThirtyBaksForFacebook:'Get 30$ to your account for Facebook post',
  successStoryOrCase:'Success story or case',
  inOrderToGetYourTwentyPercentMoneyBack:'In order to get your 20% money back bonus (you will get 20% of your money, that you have spent on your biggest order for promoting an app that you are writing your success story about, for example if you write a story about an app which you spent 500$ on promoting, and you have 5 orders, you will get 20% money back for your highest order and not from your 500$ spendings) you have to find and register on any Android dedicated forum, create a topic, and post a your success story with our service, this success story has to meet following requirements:',
  postHasToBeEightHundredSymbols:'Your forum post has to be 800 symbols long minimum',
  postHasToIncludeLink:'Your forum post has to include link to our website',
  postHasToDescribe:'Your forum post has to describe your step by step work with our service and what you achieved by using it',
  postHasToIncludeScreenshots:'Your forum post has to include at least 2 screenshots of your app and your keyword performance',
  youCantMention:'You can’t mention in your forum post that you receive free bonuses for making it',
  postHasToStayAlive:'Your post has to stay alive for 2 days minimum',
  youTubeVideoReview:'YouTube video review',
  inOrderToGetOneHundredBaks:'In order to get your 100$ bonus you have to make a guide how to use our site (or a review video) on your YouTube channel, which meets following requirements: You have to spend at least 100$ in our service that means we need quality review (guide) of our site from our regular customers. Your YouTube video has to include a short description of our service (smth like: "This service help developers in promotion of their Android applications. KeyApp.Top offer following services - keyword search installs and standard application installs"). Your YouTube video review has to include a guide how to set a campaign on our site in the tab "My orders". In your YouTube video you have to describe following tabs of your personal cabinet: "My Keywords", "Add Money", "Referral Program", "Blog" (just a few words about their main options).',
  videoHastoBeLong:'Your YouTube video review has to be 2 minutes long minimum.',
  youAreAlwaysWelcome:'You\'re always welcome if you made a guide or review in your native language (we accept videos not only in English).',
  videoHasToIncludeWebsiteLink:'Your YouTube video has to include link to our website',
  videoHasToIncludeFBLink:'Your YouTube video has to include link to our FB page: ',
  videoHasToDesctiveLikesDislikes:'Your YouTube video has to describe your likes or dislikes of our service.',
  youCantMentionInVideo:'You can’t mention in your YouTube video that you receive free bonuses for making it.',
  videoReviewHasToStayAlive:'Your YouTube video review has to stay alive for 2 days minimum.',
  androidRelatedForum:'Android-related forum',
  inOrderToGetThirtyBaks:'In order to get your 30$ bonus you have to find and register on any Android dedicated forum, create a topic, and post a true review about our service, this review has to meet following requirements:',
  postHasToBeFourHundredSymbols:'Your forum post has to be 400 symbols long minimum',
  postHastoDescribeLikesDislikes:'Your forum post has to describe your likes or dislikes of our service',
  postHasToIncludeWebsiteLink:'Your forum post has to include link to our website',
  youCantMentionInPost:'You can’t mention in your forum post that you receive free bonuses for making it',
  facebookPost:'Facebook post',
  inOrderToGetThirtyBaksBonus:'In order to get your <b>30$</b> bonus, you have to make a post on your facebook account, which meets following requirements:',
  facebookHasToIncludeScreenshot:"Your facebook post has to include a screenshot of your keyword ranking performance, or your app overall performance in progress, please highlight the area where you started promotion with us. The screenshots from the 'my orders' page on keyapp.top are not eligible as they don't show the difference in your app's promotion progress before and after your campaigns with us.",
  facebookHasToBeLong:'Your facebook post has to be 400 symbols long minimum',
  facebookHasToIncludeScreenshotKeyword:'Your facebook post has to include a screenshot of your keyword ranking performance, or your app overall performance, please highlight the area where you started promotion with us',
  facebookHasToIncludeWebsiteLink:'Your facebook post has to include link to our website',
  facebookHasToIncludeFBLink:'Your facebook post has to include link to our facebook page: ',
  facebookHasToDescribeLikesDislikes:'Your facebook post has to describe your likes or dislikes of our service',
  youCantMentionInFB:'You can’t mention in your facebook post that you receive free bonuses for making it',
  googleMapsReview:'Google maps review',
  inOrderToGetTenBaks:'In order to get your 10$ bonus you have to make a review on ',
  googleMaps:'Google maps',
  whichMeetsFollowingRequirements:', which meets following requirements.Your review on Google Maps has to:',
  minimumLong:'be 350 symbols long minimum',
  describeLikesDislikes:'describe your likes or dislikes of our service',
  youCantMentionReview:'you can’t mention in your review on Google Maps that you receive free Likes bonuses for making it',
  yourReviewHasStayAlive:'your review on Google Maps has to stay alive for 2 months minimum',
  youCanUseYour:'you can use your ',
  youCanMakeOnlyOneYouTubeVideo:'You can make only 1 YouTube video per one user.',
  youCanWriteOnlyOneForumPost:'You can write only one forum post of a good quality to receive bonus.',
  youCanWriteOnlyOneFacebookPost:'NOTE. You can write only one facebook post of a good quality to receive bonus.',
  youCanWriteOnlyOneReviewOnGoogleMaps:'NOTE. You can write only one review on Google maps of a good quality to receive bonus.',
  pleaseEmail:'Please email ',
  usLinkToYourPost:' us a link to your post with title "Post bonus", we will review it, and if it meets all requirements, you will get your bonus.',
  usLinkToYourVideo:' us a link to your video with title "YouTube video review", we will check it, and if it meets all requirements, you will get your bonus.',
  usLinkToYourReview:' us a link to your review on Google maps with title "Review on Google maps", we will review it, and if it meets all requirements, you will get your bonus.',
  referralLink:'referral link',

  inOrderToGetYourMoneyBack:'In order to get your <b>20%</b> money back bonus you have to find and register on any Android dedicated forum, create a topic, and post your success story with our service.<br/><br/>',
  thisSuccessStoryHasToMeetFollowingRequirements:'This success story has to meet following requirements',
  toBeSymbolsLongMinimum:'to be 800 symbols long minimum',
  toIncludeLinkToOurWebsite:'to include link to our website (you can use your',
  youWillGetYourMoney:'NOTE. You will get <b>20%</b> of your money that you have spent on your biggest order for promoting an app that you are writing your success story about. For example if you write a story about an app which you spent 500$ on promoting, and you have 5 orders, you will get 20% money back for your highest order and not from your 500$ spendings',
  toDescribeYourStepByStepWork:'to describe your step by step work with our service and what you achieved by using it',
  toIncludeAtLeastTwoScreenshots:'to include at least <b>2 screenshots</b> of your app and your keyword performance',
  youCantMentionInYourForumPost:'You can’t mention in your forum post that you receive free bonuses for making it',
  hasToStayAliveForTwoDaysMinimum:'has to stay alive for 2 days minimum',
  inOrderToGetYourHundredBonus:'In order to get your <b>100$</b> bonus you have to make a guide on how to use our site (or a review video) on your YouTube channel. Before starting, you have to spend at least 100$ in our service. That means we need a quality review (guide) of our site from our regular customers. Success YouTube video has to meet following requirements:',
  includeLinkToOurWebsite:'include a link to our website (you can use your referral link) and short description of our service (smth like: "This service helps developers in promotion of their Android applications. KeyApp.Top offer following services - keyword search installs and standard application installs")',
  includeGuideHowToSetCampaign:'include a guide how to set a campaign on our site in the tab "My orders" and describe following tabs of your personal cabinet: "My campaigns", "Add Money", "Referral Program", "Blog" (just a few words about their main options)',
  includeLinkToOurFBpage:'include link to our FB page:',
  keyappTop:'Keyapp.top',
  beTwoMinutesLongMinimum:'be 2 minutes long minimum',
  describeYourLikesOrDislikesOfOurService:'describe your likes or dislikes of our service.',
  youCantMentionInYourYouTubeVideo:'You can’t mention in your YouTube video that you receive free bonuses for making it',
  yourYouTubeVideoReviewHasToStayAlive:'Your YouTube video review has to stay alive for 7 days minimum.',
  NoteYoureAlwaysWelcome:"NOTE. You're always welcome if you make a guide or review in your native language (we accept videos not only in English).",
  inOrderToGetYourBonus:'In order to get your <b>30$</b> bonus you have to find and register on any Android dedicated forum, create a topic, and post a true review about our service. This review has to meet following requirements:',
  beFourHundredSymbolsLongMinimum:'be 400 symbols long minimum',
  noteYouCanWriteOnlyOneForumPost:'NOTE. You can write only one forum post of a good quality to receive a bonus.',
  youCanLeaveTheReviewToGetTheBonus:'You can leave the review to get the bonus only in a case if you used and tested our platform.',
  includeScreenshotOfYourKeywordRanking:"include a screenshot of your keyword ranking performance, or your app overall performance in progress, please highlight the area where you started promotion with us. The screenshots from the 'my orders' page on keyapp.top are not eligible as they don't show the difference in your app's promotion progress before and after your campaigns with us.",
  includeLinkToOurFacebookPage:'include link to our facebook page:',
};

export default bonusProgram;
