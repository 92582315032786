import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { useRouter } from 'vue-router';
import router from '@/router';
import ApiService from '@/core/services/ApiService';
import UserDispatcherService from '@/store/services/UserDispatcherService';
import ErrorHandlerUtil from '@/assets/ts/_utils/ErrorHandlerUtil';
import { ErrorGetters } from '@/store/enums/ErrorGettersEnums';
import StoreGettersService from '@/store/services/StoreGettersService';
import { useStore } from 'vuex';
import { Getters } from '@/store/enums/GettersEnums';

export class AuthDispatcherService extends StoreGettersService{

  constructor (store = useStore()) {super(store);}

  public async loginInAnotherAccount(email: string, router = useRouter()) {
    const UserDispatcher = new UserDispatcherService(this.getStore());
    const ErrorHandler = new ErrorHandlerUtil(this.getStore());

    await UserDispatcher.changeUserAccount(email);
    ErrorHandler.handle(ErrorGetters.GET_ERRORS_AUTH, Mutations.SET_ERROR, () => {
      const routeData = router.resolve({ name: 'dashboard' });
      window.open(routeData.href, '_blank');
    });
  }

  public logout() {
    this.getStore().dispatch(Actions.LOGOUT)
      .then(() => {
        ApiService.controller.abort();
        router.push({ name: 'sign-in' });
      }).catch(e => console.log(e));
  }

  public async getError() {
    await this.getStore().dispatch(Actions.GET_ERROR);
  }

  public async login(params) {
    await this.getStore().dispatch(Actions.LOGIN, params);
  }
  public async emailVerificationNotification(email) {
    await this.getStore().dispatch(Actions.EMAIL_VERIFICATION_NOTIFICATION, { email: email });
  }
  public async register(params) {
    await this.getStore().dispatch(Actions.REGISTER, params);
  }

  public getValidatedIsUserAuthenticated () {
    return this.getValidatedGetter(Getters.IS_USER_AUTHENTICATED);
  }

}
