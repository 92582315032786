const resellersProgram = {
  resellersProgram:'Програма для реселерів',
  dearUser:'Дорогий користувач! Ви можете подати заявку тут на Reseller Base та Reseller Gold ',
  pricePlans:'тарифні плани',
  itGivesYouSignificantDiscount:'Це дає значну знижку на вартість установки. Будь ласка, прочитайте ',
  requirements:'вимоги ',
  carefullyBeforeFillingInTheForm:'уважно перед заповненням форми',
  feelFreeTo:'Не соромся ',
  contact:'контакт ',
  usIfYouHaveAnyQuestions:'нам, якщо у вас є питання про цю форму затвердження торгового посередника і ',
  weNeedMoreInformationAboutYou:'Нам потрібно більше інформації про Вас',
  whereDoYourCustomersComeFrom:'Звідки приходять ваші клієнти?',
  yourWebsitesBlog:'Ваші сайти, блог',
  pleaseDescribeYourSources:'Будь ласка, опишіть джерела, щоб ми могли зрозуміти, що у вас є регулярний потік нових клієнтів.',
  whatChannelsDoYouUseToFindClients:'Які канали ви використовуєте для пошуку клієнтів?',
  whatServicesDoYouProvide:'Які послуги ви надаєте?',
  whatOtherSuppliersDoYouHave:'Які ще є постачальники?',
  pleaseProvideYourSuppliesLineByLine:'Будь ласка, надайте свої поставки рядково',
  youMayBeUsingOneOfOurServices:'Ви можете використовувати одну з наших послуг навіть не підозрюючи про це. Якщо один із ваших провайдерів належить нам, ми об\'єднаємо ваші тарифні плани, щоб ви витрачали менше.',
  howManyInstallsDoYouSellMonthly:'Скільки установок ви продаєте щомісяця?',
  applyAsReseller:'Подати заявку як торгового посередника',
  seo:'SEO',
  paidSearch:'Платний пошук',
  forumsBlogs:'Форуми, блоги',
  referralProgram:'Реферальна програма',
  smm:'SMM',
  other:'Інший',
  androidInstalls:'Android встановлює',
  iosInstalls:'iOS встановлює',
  aso:'ASO',
  userAcquisition:'Залучення користувачів',
  marketing:'Маркетинг',
  less:'менше',
  pleaseSelectAnyService:'Виберіть будь-яку послугу',
  pleaseSelectAnyChannel:'Виберіть будь-який канал',
  yourReSellerRequestWasCreatedSuccessfully:'Ваш запит реселлера було успішно створено!',
};

export default resellersProgram;
